import { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { closeButton } from '../../assets/imgs';
import { validatePassword } from '../../utils/validation';
import { useNavigate, useParams } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import e from 'cors';

export const SetPassword = ({ setShowSetPassword, setEmail }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [conpasswordError, setConPasswordError] = useState('');
  const [valid, setValid] = useState(false);
  const urlData = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    const verifyLinkExist = async () => {
      console.log(urlData.token);
      try {
        const response = await handleApiResponse("/api/v1/auth/view_member_link/", {
          member_token: urlData.token
        });
        console.log("API Response:", response, response.data);
        if (response.status_code === 200) {
          setValid(true)
        } else if (response.status_code === 400) {
          navigate('/not-found');
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    };

      verifyLinkExist();
  }, [urlData]);

  const sendPass = async () => {
    setPasswordError('');
    setConPasswordError('');
    setError('');
    if (!password && !cpassword) {
      setPasswordError('Please enter the password');
      setConPasswordError('Please enter the confirm password');
      return;
    }
    if (!cpassword) {
      setConPasswordError('Please enter the confirm password');
      return;
    }
    if (!password) {
      setPasswordError('Please enter the password');
      return;
    }
    if (password !== cpassword) {
      setError("Passwords doesn't match");
      return;
    } else {
      const reqData = {
        member_token: urlData.token,
        password: password,
        cpassword: cpassword,
      };

      const response = await handleApiResponse(
        '/api/v1/auth/add_member_credentials/',
        reqData
      );
      console.log(response.data);
      if(response.status_code === 200){
        navigate('/')
      }else{
        toast.error('Link can only be used once.')
        navigate('/');
      }
    }
  };

  const handelSetPass = (e) => {
    const { name, value } = e.target;
    if (value.trim().length === 0) {
      return;
    }
    const isValid = validatePassword(value);
    if (name === 'Password1') {
      setPassword(value);
      if (isValid.status) {
        setPasswordError('');
      } else {
        setPasswordError(isValid.msg);
        console.log('error');
      }
    } else {
      setCPassword(value);
      if (isValid.status) {
        setConPasswordError('');
      } else {
        setConPasswordError(isValid.msg);
      }
    }
  };
   

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPassVisibility = () => {
    setShowConPassword(!showConPassword);
  };
  if(valid){
  return (
    <div className="absolute bg-white w-[100vw]  z-10 h-[100vh] flex justify-center items-center  text-text-color-code1">
      <span className=" relative w-[32rem] mt-5 bg-fill-color-code1  border-2 flex flex-col justify-center items-center p-12 py-6 gap-8">
        <h1 className="text-xl">Set your new Password</h1>
        <div className="relative w-[22rem]">
          <input
            name="Password1"
            className="p-2 border-2 outline-none w-full rounded-lg pr-10"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            onChange={(e) => {
              handelSetPass(e);
            }}
          />
          <button
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 h-full flex items-center px-2"
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>
        {passwordError && <p className="text-red-500">{passwordError}</p>}
        <div className="relative w-[22rem]">
          <input
            name="Password2"
            className="p-2 border-2 outline-none w-[22rem]"
            type={showConPassword ? 'text' : 'password'}
            placeholder="Re-Enter Password"
            onChange={(e) => {
              handelSetPass(e);
            }}
          />
          <button
            onClick={toggleConfirmPassVisibility}
            className="absolute inset-y-0 right-0 h-full flex items-center px-2"
          >
            {showConPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>
        {conpasswordError && <p className="text-red-500">{conpasswordError}</p>}

        {error && <p className="text-red-500">{error}</p>}

        <button className="bg-fill-btn-color-code1 p-2 px-4" onClick={sendPass}>
          Set Password
        </button>
      </span>
    </div>
  );
}
if(!valid){
   navigate('/not-found')
}
};
