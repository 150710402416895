import {
  AIMockGIF,
  AIMockImg,
  ainonspeaking,
  aispeaking,
  inactive,
  send,
} from "../../assets/imgs";
import ChatBox from "../../components/chatBox";
import MessageInput from "../../components/textArea";
import WebCam from "../WebCam";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NewCodeEditor } from "../codeEditor";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocal } from "../../utils/localStorage";
import { handleApiResponse } from "../../API/services";
import { Loader } from "../../components/loader";
import { ModalPopup } from "../../components/AdminComponents/modal";

const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;
// const SOCKET_BASE_URL = "wss://k8back2.docooper.com";
// const SOCKET_BASE_URL = 'http://10.1.7.186:8001';

export const Interview = () => {
  const warningLimit = 5;
  const [refreshCount, setRefreshCount] = useState(
    sessionStorage.getItem("firstLoadDone")
      ? parseInt(sessionStorage.getItem("firstLoadDone"), 10)
      : 0
  );
  const navigate = useNavigate();
  let location = useLocation();
  const videoRefElement = useRef(null);
  const [recording, setRecording] = useState(false);
  let item = location?.state?.isCoding_quest_allow;
  const [endInterviewCounter, setEndInterviewCounter] = useState(
    sessionStorage.getItem("setEndInterviewCounter") === "true"
  );
  const [faceSocket, setFaceSocket] = useState(null);

  const [isStarted, setStarted] = useState(
    sessionStorage.getItem("isStarted")
      ? sessionStorage.getItem("isStarted")
      : false
  );
  const { isCoding_quest_allow, additionalState } = location.state || {};
  const [interviewTime, setInterviewTime] = useState(
    sessionStorage.getItem("interviewTime")
      ? parseInt(sessionStorage.getItem("interviewTime"), 10)
      : 0
  );
  const [interviewTimeRun, setInterviewTimeRun] = useState(
    sessionStorage.getItem("interviewTimeRun") === "true"
  );
  const [timer, setTimer] = useState(
    sessionStorage.getItem("timer")
      ? parseInt(sessionStorage.getItem("timer"), 10)
      : 90
  );
  const [timerRunning, setTimerRunning] = useState(
    sessionStorage.getItem("timerRunning") === "true"
  );
  const [socket, setSocket] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [speech, setSpeech] = useState("");

  const [message, setMessage] = useState("");
  const videoRef = useRef(null);
  const job_role =
    sessionStorage.getItem("jobRole") || sessionStorage.getItem("roleName");
  const company = sessionStorage.getItem("organization");
  // const codeWindow = {text: "Please solve this code using the editor.", isUser: false,}
  const [messages, setMessages] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [first, setFirst] = useState(0);
  const [warningCount, setWarningCount] = useState(
    parseInt(sessionStorage.getItem("wc"), 10) || 0
  );
  const [hasFocus, setHasFocus] = useState(document.hasFocus());
  const [warningShown, setWarningShown] = useState(false);
  const first_name1 = sessionStorage.getItem("candFName");
  const last_name1 = sessionStorage.getItem("candLName");
  const first_name = sessionStorage.getItem("candFName");
  const last_name = sessionStorage.getItem("candLName");
  const lastName = useSelector((state) => state.fillForm?.lastName);
  const job = useSelector((state) => state?.candidateData?.user?.job_profile);
  const [fullScreenCounter, setFullScreenCounter] = useState(0);
  const [idleCounter, setIdleCounter] = useState(0);
  // const [questCounter, setQuestCounter] = useState(0);
  const [reloaded, setReloaded] = useState(false);
  const [inputHeight, setInputHeight] = useState("3.5rem");
  const [showLoader, setShowLoader] = useState(false);
  const [codingQuestions, setCodingQuestions] = useState([]);
  const [openCodeFirstTime, setOpenCodeFirstTime] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [audioSource, setAudioSource] = useState("");
  const [audio, setAudio] = useState();
  const [pauseAlerts, setPauseAlerts] = useState(false);
  const [questcounter, setQuestcounter] = useState(
    sessionStorage.getItem("questionCounter")
      ? parseInt(sessionStorage.getItem("questionCounter"), 10)
      : 1
  );
  const [loader, setLoader] = useState(
    sessionStorage.getItem("chatroomLoading")
      ? sessionStorage.getItem("chatroomLoading")
      : false
  );
  const [exitquestion, setExitQuestion] = useState(false);
  const [nextQuestion, setNextQuestion] = useState(false);
  const [next, setNext] = useState("");
  const [exit, setExit] = useState(
    sessionStorage.getItem("endInterview")
      ? sessionStorage.getItem("endInterview")
      : ""
  );
  const [face_detect, setFace_detect] = useState(
    sessionStorage.getItem("no_face_detect")
      ? sessionStorage.getItem("no_face_detect")
      : ""
  );
  const [cover, setCover] = useState(
    sessionStorage.getItem("setcoverModel")
      ? sessionStorage.getItem("setcoverModel")
      : false
  );
  // const [face_detect_counter, setFace_detect_counter] = useState(0);
  // sessionStorage.setItem("face_detect_counter", 0);

  const [endTimer, setEndTimer] = useState(
    sessionStorage.getItem("setEndTimer")
      ? parseInt(sessionStorage.getItem("setEndTimer"), 10)
      : 30
  );
  const [isEndTimer, setIsEndTimer] = useState(
    sessionStorage.getItem("setIsEndTimer")
      ? sessionStorage.getItem("setIsEndTimer")
      : false
  );

  const [no_Face_detected, setNo_Face_detected] = useState(
    sessionStorage.getItem("no_face_detect_count")
      ? parseInt(sessionStorage.getItem("no_face_detect_count"), 10)
      : 0
  );
  const [coveringCTimer, setCoveringCTimer] = useState(
    sessionStorage.getItem("setcoveringCTimer")
      ? parseInt(sessionStorage.getItem("setcoveringCTimer"), 10)
      : 900
  );

  const [coveringCamera, setCoveringCamera] = useState(
    sessionStorage.getItem("setCoveringCamera")
      ? sessionStorage.getItem("setCoveringCamera")
      : false
  );

  const [avtorVoice, setAvtorVoice] = useState("");

  useEffect(() => {
    let interval;
    if (isEndTimer) {
      interval = setInterval(() => {
        setEndTimer((prevTimer) => {
          const newTime = prevTimer - 1;
          sessionStorage.setItem("setEndTimer", newTime);
          if (newTime <= 0) {
            clearInterval(interval);
          }
          return newTime;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isEndTimer]);

  useEffect(() => {
    let interval;
    if (coveringCamera) {
      interval = setInterval(() => {
        setCoveringCTimer((prevTimer) => {
          const newTime = prevTimer - 1;
          sessionStorage.setItem("setcoveringCTimer", newTime);
          if (newTime <= 0) {
            clearInterval(interval);
          }
          return newTime;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [coveringCamera]);

  useEffect(() => {
    if (exit === "NO") {
      setTimerRunning(false);
      setIsListening(false);
      setIsSpeaking(false);
      setLoader(true);
    }
  }, [exit === "NO"]);

  useEffect(() => {
    sessionStorage.removeItem("newExp");
    sessionStorage.removeItem("newExpMonths");
    sessionStorage.removeItem("file");
  });

  const fname = getLocal("first_name");
  const lname = getLocal("last_name");
  const role = getLocal("job_role");

  const idleTimeAlert = 60;
  const idleTimeEnd = 300;

  const [isFullScreen, setIsFullScreen] = useState(
    window.screen.width === window.screen.availWidth
  );

  const [openEditor, setOpenEditor] = useState(false);

  const [webSocket, setWebSocket] = useState();

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  // useEffect(() => {
  //   // const send = JSON.stringify({ suspicious_count: warningCount });

  //   // const send = JSON.stringify({
  //   //   suspicious_cause: face_detect
  //   //     ? face_detect
  //   //     : "Don't manipulate the window",
  //   // });

  //   // if (faceSocket) {
  //     if (sessionStorage.getItem('causeArray')) {
  //       sessionStorage.setItem(
  //         'causeArray',
  //         JSON.stringify({
  //           suspicious_cause: [
  //             ...JSON.parse(sessionStorage.getItem('causeArray'))
  //               ?.suspicious_cause,
  //             face_detect ? face_detect : "Don't manipulate the window",
  //           ],
  //         })
  //       );
  //     } else {

  //       sessionStorage.setItem(
  //         'causeArray',
  //         JSON.stringify({
  //           suspicious_cause: [
  //             face_detect ? face_detect : "Don't manipulate the window",
  //           ],
  //         })
  //       );
  //     }

  //     const send = sessionStorage.getItem('causeArray');

  //     try {
  //       faceSocket.send(send);
  //       console.log('sending suspicious', send);
  //     } catch (error) {
  //       console.error('Failed to send data via faceSocket', error);
  //     }
  //   // }
  // }, [warningCount]);

  const resetIdleCounter = () => {
    setIdleCounter(0);
  };

  useEffect(() => {
    let timeCounter;
    if (isStarted) {
      timeCounter = setInterval(() => {
        setIdleCounter((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => clearInterval(timeCounter);
  }, [idleCounter, isStarted]);

  // useEffect(() => {
  //   if (idleCounter > idleTimeEnd) {
  //     setTimerRunning(false);
  //     setSocket(null);
  //     console.log("connection closed");
  //     navigate("../certificate", {
  //       state: { timer: formatTime(timer), status: "Terminated" },
  //     });
  //   }
  // }, [idleCounter]);

  const handleConfirm = () => {
    setShowModal(false);
  };

  const openFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch((err) => {
        console.error("Failed to enter fullscreen:", err);
      });
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  // useEffect(() => {
  //   if (endInterviewCounter) {
  //     endInterview();
  //   }
  // }, [endInterviewCounter]);

  // const endInterview = () => {
  //   setStarted(!isStarted);
  //   setTimerRunning(false);
  //   setIsListening(false);
  //   setInterviewTimeRun(false);
  //   if (socket) {
  //     socket.close();
  //     console.log("Socket connection closed");
  //   }
  //   setSocket(null);
  //   navigate("../certificate", { state: { timer: formatTime(timer) } });
  // };

  useEffect(() => {
    openFullscreen();
  }, []);

  const alertUser = (resposne) => {
    if (!pauseAlerts || (refreshCount % 4 === 0 && refreshCount > 0)) {
      let updatedCount;
      handleSocketCount(resposne);
      setWarningCount((prevCount) => {
        updatedCount = prevCount + 1;
        sessionStorage.setItem("wc", updatedCount);
        return updatedCount;
      });
      setWarningShown(true);
      sessionStorage.setItem("no_face_detect", "");
      setTimeout(() => {
        setWarningShown(false);
        setFace_detect("");
        if (updatedCount >= 5) {
          endInterview();
        }
      }, 3000);
    }
  };

  const handleSocketCount = (currentFaceDetect) => {
    if (sessionStorage.getItem("causeArray")) {
      sessionStorage.setItem(
        "causeArray",
        JSON.stringify({
          suspicious_cause: [
            ...JSON.parse(sessionStorage.getItem("causeArray"))
              ?.suspicious_cause,
            currentFaceDetect
              ? currentFaceDetect
              : "Don't manipulate the window",
          ],
        })
      );
    } else {
      sessionStorage.setItem(
        "causeArray",
        JSON.stringify({
          suspicious_cause: [
            currentFaceDetect
              ? currentFaceDetect
              : "Don't manipulate the window",
          ],
        })
      );
    }

    const send = sessionStorage.getItem("causeArray");

    try {
      faceSocket.send(send);
      console.log("sending suspicious", send);
    } catch (error) {
      console.error("Failed to send data via faceSocket", error);
    }
  };

  function windowSize() {
    let permit =
      window.screen.availWidth - window.innerWidth < 35 ? true : false;
    console.log(permit, window.innerWidth, window.screen.availWidth);
    setIsFullScreen(permit);
  }

  useEffect(() => {
    windowSize();
    window.addEventListener("resize", windowSize);
    return () => {
      window.removeEventListener("resize", windowSize);
    };
  }, []);

  useEffect(() => {
    if (!document.fullscreenElement) {
      openFullscreen();
    }
    if (isFullScreen && fullScreenCounter > 1) {
      console.log("this insided full", fullScreenCounter);
      if (socket && recording) {
        if (
          sessionStorage.getItem("isNext") ||
          sessionStorage.getItem("isEnd")
        ) {
          setTimerRunning(false);
        } else {
          setTimerRunning(true);
        }
        setInterviewTimeRun(true);
        alertUser();
      }
      console.log("due to window resize");
    }

    if (!isFullScreen) {
      setTimerRunning(false);
      setInterviewTimeRun(false);
      setIsListening(false);
      setIsSpeaking(false);
      if (audio) {
        audio.pause();
        setIsListening(false);
        console.log("stop audio");
      }
    }

    setFullScreenCounter((prev) => prev + 1);
  }, [isFullScreen]);

  useEffect(() => {
    // console.log(message);
    if (speech?.length > 0 && !openEditor) {
      if (speech.trim() !== "") {
        // if (nextQuestion || exitquestion) {
        //   setMessage(speech);
        // } else {
        const newMess = message + " " + speech;
        setMessage(newMess);
        // }
        setSpeech("");
        resetIdleCounter(0);
      }
    }
  }, [speech, setSpeech]);

  // useEffect(()=>{
  //   if(reloaded){
  //     endInterview();
  //   }
  // },[reloaded])

  useEffect(() => {
    if (sessionStorage.getItem("isStarted")) {
      setOpenEditor(Boolean(sessionStorage.getItem("openEditor")));
      const newRefreshCount = parseInt(refreshCount) + 1;
      sessionStorage.setItem("firstLoadDone", newRefreshCount);
      if (!sessionStorage.getItem("isNext")) {
        setTimerRunning(true);
      }
      handleSocket();
      handleFaceDetect();
      if (face_detect !== "No face detected") {
        setRefreshCount(newRefreshCount);
      }
    }
    return () => {
      sessionStorage.removeItem("firstLoadDone");
    };
  }, []);

  useEffect(() => {
    if (refreshCount % 4 === 0 && refreshCount > 0) {
      if (face_detect !== "No face detected") {
        alertUser();
      }
      setRefreshCount(1);
      sessionStorage.setItem("firstLoadDone", 1);
    }
  }, [refreshCount]);

  useEffect(() => {
    const handleFocus = () => {
      if (document.hasFocus()) {
        console.log("Window regained focus");
        setHasFocus(true);
        if (socket) {
          console.log("socket true now it is showing error", socket);
          if (face_detect !== "No face detected") {
            alertUser();
          }
        }
      }
    };

    const handleBlur = () => {
      console.log("Window lost focus");
      if (audio) {
        audio.pause();
        console.log("stop audio");
      }
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setIsListening(false);
      setHasFocus(false);
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, [hasFocus, warningShown]);

  const decreaseCredit = async () => {
    const reqData = {
      candidate_uid: campType === "Regular" ? uid : uid1,
    };
    const response = await handleApiResponse(
      "/api/v1/candidate_credit_count/",
      reqData,
      1
    );
    console.log(response);
  };

  const uid = getLocal("user");
  const campType = sessionStorage.getItem("campType");
  const [loader1, setLoader1] = useState(false);
  // const fetchVoices = () => {
  //   const availableVoices = window.speechSynthesis.getVoices();
  //   // const femaleVoices = availableVoices.filter(voice => voice.name.includes('Female'));
  //   const femaleVoices = availableVoices.filter((voice) => voice.name);
  //   // console.log(availableVoices[19])
  //   return availableVoices[10];
  //   // return availableVoices[2];
  // };
  const uid1 = sessionStorage.getItem("CandidateUid");

  // const msg = new SpeechSynthesisUtterance();
  // console.log(msg, "msgggggggggggggggg");
  // msg.rate = 0.9;
  // msg.voice = fetchVoices();

  // msg.onend = function (event) {
  //   setIsSpeaking(false);
  //   if (openEditor) {
  //     setIsListening(false);
  //     setTimerRunning(false);
  //     setTimer(0);
  //   }
  //   setIsListening(true);
  //   setTimerRunning(true);
  //   setTimer(120);
  // };
  // msg.onstart = function (e) {
  //   setTimeout(() => {
  //     setIsSpeaking(true);
  //     setIsListening(false);
  //     setTimerRunning(false);
  //   }, 1000);
  // };

  useEffect(() => {
    if (!audioSource) {
      console.error("No audio source provided.");
      return;
    }

    const newAudio = new Audio(audioSource);
    setAudio(newAudio);

    const handlePause = (event) => {
      setIsSpeaking(false);
      if (openEditor || nextQuestion) {
        setIsListening(false);
        setTimerRunning(false);
        setTimer(0);
      } else if (questcounter === 15) {
        setIsListening(false);
        setTimerRunning(false);
        setTimer(0);
        setLoader(true);
      } else {
        if (isFullScreen) {
          setIsListening(true);
          setTimerRunning(true);
          setTimer((prev) => {
            if (prev !== 180) {
              return 90;
            } else {
              return 180;
            }
          });
        }
        // console.log("timer after pause", timer);
      }
    };

    const handlePlay = (event) => {
      if (nextQuestion) {
        setIsSpeaking(false);
      } else {
        setIsSpeaking(true);
        setIsListening(false);
        setTimerRunning(false);
      }
    };

    newAudio.addEventListener("pause", handlePause);
    newAudio.addEventListener("play", handlePlay);

    newAudio.play().catch((error) => {
      console.error("Error playing audio:", error);
    });

    return () => {
      newAudio.removeEventListener("pause", handlePause);
      newAudio.removeEventListener("play", handlePlay);
    };
  }, [audioSource]);

  const endInterview = async () => {
    setLoader1(true);
    let networkError = sessionStorage.getItem("networkError");
    // await handleVideoSubmit();
    if (!loader1) {
      if (
        warningCount >= "5" ||
        no_Face_detected >= "3" ||
        exit === "NO" ||
        endInterviewCounter ||
        isEndTimer ||
        endTimer <= 1 ||
        coveringCTimer <= 1 ||
        networkError
      ) {
        if (socket) {
          socket.close();
          console.log("Socket connection closed");
        }
        if (faceSocket) {
          faceSocket.close();
          console.log(" face Socket connection closed");
        }
        setAudioSource("");
        if (audio) {
          audio.pause();
        }
        setIsSpeaking(false);
        setTimerRunning(false);
        setInterviewTimeRun(false);
        setSocket(null);
        setFaceSocket(null);
        window.speechSynthesis.cancel();
        sessionStorage.removeItem("hasStartedRecording");
        const initialInterviewTime = 0;
        const initialTimer = 90;
        setInterviewTime(initialInterviewTime);
        setTimer(initialTimer);
        setTimerRunning(false);
        setInterviewTimeRun(false);
        sessionStorage.setItem("interviewTime", initialInterviewTime);
        sessionStorage.setItem("timer", initialTimer);
        sessionStorage.removeItem("showNext");
        sessionStorage.removeItem("endInterview");
        sessionStorage.removeItem("lastEndInterview");
        sessionStorage.removeItem("chatroomLoading");
        sessionStorage.removeItem("questionCounter");
        sessionStorage.removeItem("setIsEndTimer");
        sessionStorage.removeItem("setCoveringCamera");
        sessionStorage.removeItem("setcoveringCTimer");
        sessionStorage.removeItem("setcoverModel");
        setTimeout(() => {
          setLoader1(false);
          navigate("../certificate", { state: { timer: formatTime(timer) } });
        }, 5000);
      }
    }
  };

  // useEffect(() => {
  //   if (audioDuration > 0) {
  //     setIsSpeaking(true);
  //     setIsListening(false);

  //     const interval = setInterval(() => {
  //       setAudioDuration((prev) => {
  //         if (prev <= 1) {
  //           sessionStorage.removeItem("faceDetect");
  //           return 0;
  //         }
  //         return prev - 1;
  //       });
  //     }, 1000);

  //     return () => clearInterval(interval);
  //   } else {
  //     setIsSpeaking(false);
  //     setIsListening(true);
  //   }
  // }, [audioDuration]);

  // useEffect(()=>{
  //   // let interval;
  //   // if(timer){
  //   //   interval=setInterval(() => {
  //   //     sendFrame(socket)
  //   //     console.log("websocket",timer)
  //   //   }, 2000);
  //   // }
  //   // return () => clearInterval(interval)
  //   setTimeout(()=>{
  //     sendFrame(socket)
  //   },2000)
  // },[timer])

  // const sendFrame = (socket) => {
  //   const videoElement = videoRef.current;

  //   if (videoElement) {
  //     const canvas = document.createElement("canvas");
  //     canvas.width = videoElement.videoWidth;
  //     canvas.height = videoElement.videoHeight;
  //     const ctx = canvas.getContext("2d");

  //     try {
  //       ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  //       canvas.toBlob((blob) => {
  //         if (blob) {
  //           socket.send(blob);
  //         }
  //       }, "image/jpeg");
  //     } catch (e) {
  //       console.error("Error capturing video frame:", e);
  //     }

  //     setTimeout(() => sendFrame(socket), 2000); // Send frame every 2000 milliseconds (2 seconds)
  //   }
  // };

  const handleSocket = () => {
    setShowLoader(true);
    const ws = new WebSocket(
      `${SOCKET_BASE_URL}/ws/interview/${campType === "Regular" ? uid : uid1}/`
    );

    if (ws) {
      ws.onopen = () => {
        setSocket(ws);
        decreaseCredit();
        setShowLoader(false);
      };

      ws.onmessage = (event) => {
        const res = JSON.parse(event.data);
        console.log(res, "response time");
        if (res?.avatar?.video_base64) {
          const base64Data =
            res.avatar.video_base64.split(",")[1] || res.avatar.video_base64;

          try {
            const byteCharacters = atob(base64Data);
            const byteArray = new Uint8Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteArray[i] = byteCharacters.charCodeAt(i);
            }

            const videoBlob = new Blob([byteArray], { type: "video/mp4" });
            const videoUrl = URL.createObjectURL(videoBlob);
            setAvtorVoice(videoUrl);
          } catch (error) {
            console.error("Error decoding Base64 video data:", error);
          }
        }
        if (res?.speech) {
          // setAvtorVoice(res?.avatar);
          setLoader(false);
          const binaryString = window.atob(res?.speech);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes.buffer], { type: "audio/mpeg" });
          const audioUrl = URL.createObjectURL(blob);
          setAudioSource(audioUrl);
          setQuestcounter(res?.counter);

          if (res?.codeQuest) {
            setIsListening(false);
          }
        } else {
          if (res?.isEND) {
            setExitQuestion(res?.isEND);
            // setIsSpeaking(false);
            setIsListening(false);
            sessionStorage.setItem("isEnd", res?.isEND);
          } else if (res?.isNext) {
            setNextQuestion(res?.isNext);
            setIsSpeaking(false);
            setIsListening(false);
            sessionStorage.setItem("isNext", res?.isNext);
          }
        }

        if (res?.counter === 15) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: res.text,
              isUser: false,
            },
          ]);
          sessionStorage.setItem("lastEndInterview", true);
          sessionStorage.setItem("chatroomLoading", true);
          sessionStorage.setItem("questionCounter", res?.counter);
          setIsEndTimer(true);
          sessionStorage.setItem("setIsEndTimer", true);
          sessionStorage.setItem("setEndInterviewCounter", true);
        } else {
          // if (res?.isEND || res?.isNext) {
          //   if (!sessionStorage.getItem("isNext")) {
          //     setMessages((prevMessages) => [
          //       ...prevMessages,
          //       {
          //         text: res?.isNext
          //           ? "Do you want to proceed to the next question?"
          //           : "Do you want to continue?",
          //         isUser: false,
          //       },
          //     ]);
          //   }
          // }
          //  else {
          if (typeof res.text === "string" || res?.codeQuest) {
            if (res.Is_essay) {
              console.log("setting 180 as the timer");
              setTimer(180);
            }
            setMessages((prevMessages) => [
              ...prevMessages,
              res?.codeQuest
                ? {
                    text: "Open the code editor to answer the coding questions",
                    isUser: false,
                    codeEditor: true,
                  }
                : {
                    text: res.text,
                    isUser: false,
                  },
            ]);
          } else {
            if (Array.isArray(res?.text)) {
              if (
                sessionStorage.getItem("isNext") ||
                sessionStorage.getItem("isEnd")
              ) {
                let data = res.text.slice(0, -1);
                data.sort((a, b) => a.id - b.id);
                data.forEach((ele, index) => {
                  if (index)
                    if (!ele.msg.includes("Coding_quest")) {
                      setOpenEditor(false);
                      console.log(ele.msg);
                      setMessages((prevMessages) => [
                        ...prevMessages,
                        {
                          text: ele.msg,
                          isUser: !ele.is_ai,
                        },
                      ]);
                    }
                });
              } else {
                const filteredText = res.text.sort((a, b) => a.id - b.id);
                filteredText.forEach((ele) => {
                  if (!ele.msg.includes("Coding_quest")) {
                    setMessages((prevMessages) => [
                      ...prevMessages,
                      {
                        text: ele.msg,
                        isUser: !ele.is_ai,
                      },
                    ]);
                  }
                });
              }
            }
          }
          // }
          if (res?.codeQuest) {
            setCodingQuestions(() => {
              return [...res.text];
            });
            setIsListening(false);
          } else {
            if (res.speech) {
              setIsSpeaking(true);
              setIsListening(false);
            } else {
              setIsSpeaking(false);
              setIsListening(false);
            }
          }
        }
      };
    }
  };

  const handleFaceDetect = (sendFrameCallback) => {
    const ws = new WebSocket(
      `${SOCKET_BASE_URL}/ws/face-detection/${
        campType === "Regular" ? uid : uid1
      }/`
    );
    ws.onopen = () => {
      setFaceSocket(ws);
      let causeArray = JSON.parse(sessionStorage.getItem("causeArray"));
      let send = causeArray?.suspicious_cause;
      console.log("faceSocket", send);
      if (send?.length > 0) {
        ws.send(sessionStorage.getItem("causeArray"));
      }
      if (sendFrameCallback) {
        sendFrameCallback();
      }
    };

    ws.onmessage = (event) => {
      const res = JSON.parse(event.data);
      if (res.face_detect) {
        switch (res.face_detect[0]) {
          // case 1:
          //   setNo_Face_detected(1);
          //   console.log("got 1");
          //   break;
          case "More than one face detected!":
            console.log("Multi face");
            setFace_detect("Multiple face detected");
            alertUser("Multiple face detected");
            break;
          case "No face detected!":
            // console.log("No face");
            sessionStorage.setItem("no_face_detect", "No face detected");
            sessionStorage.setItem(
              "no_face_detect_count",
              no_Face_detected + 1
            );
            setFace_detect("No face detected");
            setNo_Face_detected(no_Face_detected + 1);
            // alertUser();
            break;
          case "Candidate not looking forward!":
            console.log("Not looking forward");
            setFace_detect("Candidate not looking forward");
            alertUser("Candidate not looking forward");
            break;
          case "Looking away from the screen!":
            console.log("Looking away");
            setFace_detect("Candidate looking away");
            alertUser("Candidate looking away");
            break;
        }
      }
    };

    ws.onclose = () => {
      console.log("WebSocket2 connection closed.");
    };

    ws.onerror = (error) => {
      console.error("WebSocket2 error:", error);
    };

    return ws;
  };

  useEffect(() => {
    if (face_detect === "No face detected") {
      setTimerRunning(false);
      setInterviewTimeRun(false);
      setIsListening(false);
      setIsSpeaking(false);
      setCover(true);
      sessionStorage.setItem("setcoverModel", true);
      setAudioSource("");
      sessionStorage.setItem("setCoveringCamera", true);
      setCoveringCamera(true);
      if (audio) {
        audio.pause();
      }
      if (no_Face_detected == "3") {
        alertUser("No face detected");
      }
    }
  }, [face_detect]);

  const handleResumeInterview = () => {
    if (sessionStorage.getItem("isNext") || sessionStorage.getItem("isEnd")) {
      setTimerRunning(false);
    } else {
      setTimerRunning(true);
    }
    setInterviewTimeRun(true);
    handleFaceDetect();
    alertUser("No face detected");
    setCover(false);
    sessionStorage.removeItem("setcoverModel");
    sessionStorage.setItem("setCoveringCamera", false);
    setCoveringCamera(false);
    sessionStorage.setItem("setcoveringCTimer", 900);
  };

  return (
    <div
      id="componentToCapture"
      className="text-text-color-code1 flex mx-8 justify-around fill-available gap-[3rem] h-[80vh]"
      onKeyDown={resetIdleCounter}
      onMouseDown={resetIdleCounter}
      onMouseMove={resetIdleCounter}
      onClick={openFullscreen}
    >
      {!isFullScreen ? (
        <div className="z-10 bg-black absolute top-0 left-0 w-full h-full flex flex-col gap-4 justify-center items-center">
          <h1>Not Allowed. Go to Full Screen mode.</h1>
          <button onClick={openFullscreen}>Unlock</button>
          {isListening ? setIsListening(false) : ""}
          {timerRunning ? setTimerRunning(false) : ""}
        </div>
      ) : null}
      {/* {showModal && (
        <ModalPopup
          message="You need to share your entire screen for proceeding with the interview."
          onConfirm={handleConfirm}
        />
      )} */}
      {loader1 && <Loader />}
      {/* {showNoFaceDetectedPopUp (
        <div className="z-[12] bg-fill-color-code1 absolute top-0 left-0 w-full h-full flex flex-col gap-4 justify-center items-center">
          <article className="p-8 bg-fill-btn-color-code1 border-2 rounded-3xl w-[48rem] h-[32rem] flex justify-center items-center flex flex-col gap-4">
            <h1 className="text-[3rem] text-text-color-code1 font-semibold ">
              Where'd you go? We can't see you. To resume the interview uncover you camera or be in a well lit area.
            </h1>
            <p>
              It's so silent over there. Drag your mouse to activate the screen
            </p>
            <img
              src={inactive}
              alt="inActive"
              className="w-[32rem] h-[20rem]"
            />
          </article>
        </div>
      ) : null} */}
      {/* {idleCounter > idleTimeAlert ? (
        <div className="z-[12] bg-fill-color-code1 absolute top-0 left-0 w-full h-full flex flex-col gap-4 justify-center items-center">
          <article className="p-8 bg-fill-btn-color-code1 border-2 rounded-3xl w-[48rem] h-[32rem] flex justify-center items-center flex flex-col gap-4">
            <h1 className="text-[3rem] text-text-color-code1 font-semibold ">
              Where'd you go?
            </h1>
            <p>
              It's so silent over there. Drag your mouse to activate the screen
            </p>
            <img
              src={inactive}
              alt="inActive"
              className="w-[32rem] h-[20rem]"
            />
          </article>
        </div>
      ) : null} */}
      {warningCount <= "5" && warningShown && !cover ? (
        <div className="z-[9999] absolute bg-red-500 p-5 text-white top-[0.51rem] right-0 w-[22rem]  flex flex-col gap-2 justify-center items-center">
          <p>
            Warning {warningCount}/{warningLimit}
          </p>
          {face_detect?.length > 0 ? (
            face_detect
          ) : (
            <p>Don't manupulate the window</p>
          )}

          {/* <p>face detection {face_detect_counter}</p> */}
        </div>
      ) : cover ? (
        <div className="z-[9999] bg-fill-color-code1 absolute top-0 left-0 w-full h-full flex flex-col gap-4 justify-center items-center">
          <article className="p-8 bg-fill-btn-color-code1 border-2 rounded-3xl w-[48rem] h-[32rem] flex justify-center items-center flex flex-col gap-4">
            <h1 className="text-[3rem] text-text-color-code1 font-semibold ">
              {/* Where'd you go? We can't see you. To resume the interview uncover you camera or be in a well lit area. */}
              {no_Face_detected}/3
            </h1>
            <p>
              {/* It's so silent over there. Drag your mouse to activate the screen */}
              No face detected or your camera might be covered. Please adjust
              your camera and click the button to resume your interview.
            </p>
            {no_Face_detected <= 2 && (
              <button
                className="bg-slate-800 rounded-sm"
                onClick={handleResumeInterview}
              >
                Resume Your Interview
              </button>
            )}

            {/* <img
              src={inactive}
              alt="inActive"
              className="w-[32rem] h-[20rem]"
            /> */}
          </article>
        </div>
      ) : null}

      <div className="flex flex-col gap-4 fill-available ">
        <div className="flex gap-20 justify-between">
          <div>
            <p className="flex text-lg">
              Job Interview for {!job ? <>{role} - </> : <>{job} - </>}
              <span className="font-semibold">
                {!first_name1 && !last_name1 ? (
                  <>
                    {first_name} {last_name}
                  </>
                ) : (
                  <>
                    {first_name1} {last_name1}
                  </>
                )}
              </span>
            </p>
            <p>Job Role: {job_role} </p>
            <p>Company Name: {company} </p>
          </div>
          <div>
            {isSpeaking && !openEditor ? (
              // <img
              //   src={aispeaking}
              //   alt="interviewer gif"
              //   className="w-[150px] h-[150px]"
              //   onLoad={resetIdleCounter}
              // />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2rem",
                  backgroundColor: "white",
                  width: "150px",
                  height: "150px",
                }}
              >
                <video
                  loop
                  muted
                  autoPlay
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                >
                  <source src={avtorVoice} type="video/mp4" />
                </video>
              </div>
            ) : (
              <img
                src={ainonspeaking}
                alt="interviewer photo"
                className="w-[150px] h-[150px]"
                onLoad={resetIdleCounter}
              />
            )}
          </div>
          {/* <div><p>Company Name: {company} </p></div> */}
        </div>
        {/* <div className="flex justify-between">
          <p className="flex text-lg">
            Job Interview for {!job ? <>{role} - </> : <>{job} - </>}
            <span className="font-semibold">
              {!first_name1 && !last_name1 ? (
                <>
                  {first_name} {last_name}
                </>
              ) : (
                <>
                  {first_name1} {last_name1}
                </>
              )}
            </span>
          </p>
          <p>Job Role: {job_role} </p>
        </div>
        <p>Company Name: {company} </p> */}

        {/* {idleCounter} */}
        {/* <ScreenRecorder /> */}
        {/* <button onClick={captureScreenshot}>Capture Screenshot</button> */}
        <WebCam
          setPauseAlerts={setPauseAlerts}
          no_Face_detected={no_Face_detected}
          loader1={loader1}
          setMessages={setMessages}
          setLoader1={setLoader1}
          isListening={isListening}
          warningLimit={warningLimit}
          warningCount={warningCount}
          setIsListening={setIsListening}
          setSpeech={setSpeech}
          timerRunning={timerRunning}
          setTimerRunning={setTimerRunning}
          setSocket={setSocket}
          timer={timer}
          setTimer={setTimer}
          isSpeaking={isSpeaking}
          setIsSpeaking={setIsSpeaking}
          socket={socket}
          endInterviewCounter={endInterviewCounter}
          setEndInterviewCounter={setEndInterviewCounter}
          resetIdleCounter={resetIdleCounter}
          setStarted={setStarted}
          isStarted={isStarted}
          setFirst={setFirst}
          handleSocket={handleSocket}
          interviewTime={interviewTime}
          setInterviewTime={setInterviewTime}
          interviewTimeRun={interviewTimeRun}
          setInterviewTimeRun={setInterviewTimeRun}
          openEditor={openEditor}
          videoRefElement={videoRefElement}
          setLoader={setLoader}
          loader={loader}
          audioSource={audioSource}
          exitquestion={exitquestion}
          questcounter={questcounter}
          exit={exit}
          setExit={setExit}
          first_name={first_name}
          first_name1={first_name1}
          last_name={last_name}
          last_name1={last_name1}
          recording={recording}
          setRecording={setRecording}
          face_detect={face_detect}
          handleFaceDetect={handleFaceDetect}
          setFaceSocket={setFaceSocket}
          faceSocket={faceSocket}
          setExitQuestion={setExitQuestion}
          setNext={setNext}
          endTimer={endTimer}
          isEndTimer={isEndTimer}
          setAudioSource={setAudioSource}
          audio={audio}
          coveringCTimer={coveringCTimer}
          setCover={setCover}
          openFullscreen={openFullscreen}
        />
      </div>
      <div className="max-w-[29rem] h-[102%] border-[0.07rem] bg-fill-color-code1 flex flex-col  fill-available relative">
        <div className="border-b-[0.07rem] p-4">
          <p>ChatBox</p>
        </div>
        <div className="bg-fill-color-code1 h-[40rem] p-4">
          <ChatBox
            timer={timer}
            setTimer={setTimer}
            messages={messages}
            timerRunning={timerRunning}
            setTimerRunning={setTimerRunning}
            setIsListening={setIsListening}
            setOpenEditor={setOpenEditor}
            nextQuestion={nextQuestion}
            setNextQuestion={setNextQuestion}
            setSpeech={setSpeech}
            next={next}
            setNext={setNext}
            setIsSpeaking={setIsSpeaking}
            setLoader={setLoader}
            setMessage={setMessage}
            setMessages={setMessages}
            first_name={first_name}
            first_name1={first_name1}
            last_name={last_name}
            last_name1={last_name1}
            exitquestion={exitquestion}
            setExitQuestion={setExitQuestion}
            setExit={setExit}
            exit={exit}
            refreshCount={refreshCount}
            setRefreshCount={setRefreshCount}
            handleSocket={handleSocket}
          />
        </div>

        {openEditor && (
          <NewCodeEditor
            setOpenCodeFirstTime={setOpenCodeFirstTime}
            messages={codingQuestions}
            setOpenEditor={setOpenEditor}
            setMessages={setMessages}
            socket={socket}
            timer={timer}
            setLoader={setLoader}
            setIsSpeaking={setIsSpeaking}
          />
        )}

        <MessageInput
          sendImg={send}
          setMessages={setMessages}
          messages={messages}
          message={message}
          openEditor={openEditor}
          setMessage={setMessage}
          setIsListening={setIsListening}
          timerRunning={timerRunning}
          socket={socket}
          setSocket={setSocket}
          timer={timer}
          setTimer={setTimer}
          setTimerRunning={setTimerRunning}
          setIsSpeaking={setIsSpeaking}
          isSpeaking={isSpeaking}
          setEndInterviewCounter={setEndInterviewCounter}
          first={first}
          inputHeight={inputHeight}
          setInputHeight={setInputHeight}
          loader={loader}
          setLoader={setLoader}
          questcounter={questcounter}
          warningCount={warningCount}
          exit={exit}
          setNext={setNext}
          next={next}
        />
      </div>
    </div>
  );
};
