import React, { useState, useEffect, useRef } from "react";
import { closeButton, resume, resumeUpload } from "../../assets/imgs";
import * as XLSX from "xlsx";

import {
  InputBox,
  InputBox1,
  InputBox3,
  InputBoxColor,
  InputBoxRed,
} from "../../features/pages/FillFormPage";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { CustomButton, CustomButtonWhite } from "../buttons";
import { UploadResumeOnly } from "../../features/pages/FillFormPage";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  handleApiResponse,
  handleFormDataApiResponse,
} from "../../API/services";
import axios from "axios";
import OtpValidation from "./OtpValidation";
import {
  validateDesignation,
  validateEmail,
  validateName,
} from "../../utils/validation";
import { toast } from "react-toastify";
import { FiRewind } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Loader } from "../loader";
import { propTypesInteractive } from "@material-tailwind/react/types/components/popover";
const base_url = process.env.REACT_APP_URL;
const file_url = process.env.REACT_APP_URL;

export const Modal = ({ editData, onClose }) => {
  const [status, setStatus] = useState(editData?.status === 1 ? true : false);
  const [resume, setResume] = useState(true);

  const [mobile, setMobile] = useState(editData?.mobile || "");
  const [dept, setDept] = useState(editData?.department || "");
  const organization = sessionStorage.getItem("organization");

  const [firstChange, setFirstChange] = useState(editData?.first_name || "");
  const [lastChange, setLastChange] = useState(editData?.last_name || "");
  const [emailChange, setEmailChange] = useState(editData?.email || "");
  const [designationChange, setDesignationChange] = useState(
    editData?.designation || ""
  );
  const [apiResponseError, setApiResponseError] = useState("");
  const [error, setError] = useState({
    first: "0",
    last: "0",
    email: "0",
    designation: "0",
  });
  console.log("edit ", editData);
  console.log("edit resume ", editData.is_resume_upload_allow);

  useEffect(() => {
    console.log(error);
  }, [error]);

  const handleDeptChange = (e) => {
    let newValue = e.target.value;
    setDept(newValue);
  };

  const handleMobileChange = (e) => {
    if (/^\d{0,10}$/.test(e.target.value)) {
      setMobile(e.target.value);
    }
  };

  const setClose = () => {
    setFirstChange("");
    setLastChange("");
    setEmailChange("");
    setDesignationChange("");
    setApiResponseError("");
    setError({
      first: "0",
      last: "0",
      email: "0",
      designation: "0",
    });
    onClose();
  };

  const setCloseOnFocus = () => {
    // setConfirmPassword('');
    // setPassword('');
    // setFirstChange('');
    // setLastChange('');
    // setEmailChange('');
    // setDesignationChange('');
    // setApiResponseError('');
    // setError({
    //   first: '0',
    //   last: '0',
    //   email: '0',
    //   designation: '0',
    //   password: '0',
    //   confirmPassword: '0',
    // });
    onClose();
  };

  const handleFirstChange = (e) => {
    let first = e.target.value;
    if (validateName(first)) {
      setError((prevState) => ({
        ...prevState,
        first: "0",
      }));
      console.log("valid", first);
      setFirstChange(first);
    } else {
      return;
    }
  };

  const handleLastChange = (e) => {
    let newValue = e.target.value;
    if (validateName(newValue)) {
      setError((prevState) => ({
        ...prevState,
        last: "0",
      }));
      setLastChange(newValue);
    }
  };

  const handleDesignationChange = (e) => {
    let newValue = e.target.value;
    if (newValue === "") {
      // Limit to 40 characters
      newValue = newValue;
      setError((prevState) => ({
        ...prevState,
        designation: newValue ? "0" : "1",
      }));

      setDesignationChange(newValue);
    }
  };

  const validation = () => {
    let isValid = 1;

    if (!firstChange) {
      console.log("set firstname");
      setError((prevState) => ({
        ...prevState,
        first: "1",
      }));
      isValid = 0;
    }

    if (!lastChange) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        last: "1",
      }));
      isValid = 0;
    }

    if (!validateEmail(emailChange)) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        email: "1",
      }));
      isValid = 0;
    }

    if (!designationChange) {
      console.log("set designation", designationChange);
      setError((prevState) => ({
        ...prevState,
        designation: "1",
      }));
      isValid = 0;
    }
    if (mobile.length !== 10 && mobile.length !== 0) {
      console.log("set mob", mobile.length);
      setError((prevState) => ({
        ...prevState,
        mobile: "1",
      }));
      isValid = 0;
    }

    //   if (!password) {
    //     console.log('set password');
    //     setError((prevState) => ({
    //       ...prevState,
    //       password: '1',
    //     }));
    //     isValid = 0;
    //   }

    //   if (!confirmPassword) {
    //     console.log('set designation');
    //     setError((prevState) => ({
    //       ...prevState,
    //       confirmPassword: '1',
    //     }));
    //     isValid = 0;
    //   }

    //   if (password !== confirmPassword) {
    //     toast.error('Password does not match');
    //     isValid = 0;
    //   }
    return isValid;
  };

  const handleAdd = async () => {
    console.log(
      "form Data ",
      firstChange,
      lastChange,
      emailChange,
      designationChange
    );
    if (validation()) {
      console.log("inside validate", validation());
      const reqData = {
        uid: editData?.uid,
        first_name: firstChange,
        last_name: lastChange,
        email: emailChange,
        designation: designationChange,
        status: status ? 1 : 0,
        is_resume_upload_allow: resume,
        organization: organization,
      };

      if (dept.length) {
        reqData["department"] = dept;
      }

      const response = await handleApiResponse(
        "/api/v1/updateuser/",
        reqData,
        1
      );
      if (response?.status === 200) {
        if (response.data.status_code === 200) {
          toast.success("User Updated");
          setClose();
        } else {
          if (response.data.msg.split(":")[0].includes("duplicate")) {
            setApiResponseError("User already exists");
          } else {
            setApiResponseError(response.data.msg);
          }
        }
      }
    }
  };
  const handleEmailChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      email: "0",
    }));
    setEmailChange(e.target.value);
  };

  const handleToggle = () => {
    setStatus((prevStatus) => !prevStatus);
  };
  const handleToggleResume = () => {
    setResume((prevState) => !prevState);
  };

  useEffect(() => {
    console.log(editData);
  }, []);
  return (
    <>
      <div className="fixed inset-0 overflow-auto flex justify-end z-50">
        <div className="fill-available" onClick={setCloseOnFocus}></div>
        <div className="w-[34.792vw] h-auto bg-white rounded-lg p-8 shadow-md absolute add-member-animation  ">
          <div className="items-center flex justify-between">
            <p className="text-[1.528vw] font-medium">Edit Member</p>
            <img
              src={closeButton}
              alt="close button"
              onClick={setClose}
              className="cursor-pointer"
            />
          </div>
          <hr className="my-2" />
          <div className="flex gap-4 justify-between mt-4">
            <div className="w-[50%] font-normal mb-[10px]">
              <InputBox1
                name={"First Name"}
                holder={"Enter first name"}
                value={firstChange}
                onChange={handleFirstChange}
                error={"Fill a valid first name"}
                showErrorOnInput={error["first"]}
              />
            </div>
            <div className="w-[50%] font-normal mb-[10px]">
              <InputBox1
                name={"Last Name"}
                holder={"Enter last name"}
                value={lastChange}
                onChange={handleLastChange}
                error={"Fill a valid last name"}
                showErrorOnInput={error["last"]}
              />
            </div>
          </div>
          <div className="font-normal mb-[10px]">
            <InputBox1
              name={"Email"}
              holder={"Enter email"}
              value={emailChange}
              // onChange={handleEmailChange}
              // error={"Fill a valid Email"}
              // showErrorOnInput={error["email"]}
              readOnly
            />
          </div>
          <div className="font-normal mb-[10px] fill-available">
            <InputBox1
              name={"Designation"}
              holder={"Enter designation"}
              value={designationChange}
              onChange={handleDesignationChange}
              error={"Fill a valid Designation"}
              showErrorOnInput={error["designation"]}
            />
          </div>

          {/* <div className="font-normal mb-[10px]">
            <label htmlFor="">organization</label>
            <span className="text-red-500">*</span>
            <input
              type="text"
              className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
              value={organization}
              readOnly
            />
          </div> */}
          <div className="font-normal mb-[10px]">
            <label htmlFor="">Department</label>
            <input
              type="text"
              className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
              value={dept}
              onChange={handleDeptChange}
            />
          </div>
          {/* <div className="font-normal mb-[10px]">
            <label htmlFor="">Mobile number</label>
            <input
              type="text"
              className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
              value={mobile}
              onChange={handleMobileChange}
            />
          </div> */}

          {/* <div className="w-auto flex font-normal mb-[10px] relative">
            <span className="flex flex-col w-screen">
              <span className="flex">
                <label htmlFor="">Password</label>
                <span className="text-red-500">*</span>
              </span>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Create password"
                value={password}
                className=" border-[#F1F2F4] outline-0 bg-[#FBFBFB] p-3 rounded-lg mt-2"
                onChange={handlePasswordChange}
              />
            </span>
            <button
              onClick={togglePasswordVisibility}
              className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          {error['password'] === '1' ? (
            <div className=" mt-[-12px] mb-[12px] text-red-500">
              Fill the password
            </div>
          ) : null}
          <div className="w-auto flex font-normal mb-[10px] relative">
            <span className="flex flex-col w-screen">
              <span className="flex">
                <label htmlFor="">Confirm Password</label>
                <span className="text-red-500">*</span>
              </span>
              <input
                type={showPassword1 ? 'text' : 'password'}
                value={confirmPassword}
                placeholder="Re-enter password"
                className="border-[#F1F2F4] bg-[#FBFBFB] p-3 rounded-lg outline-0 mt-2"
                onChange={handleConfirmPasswordChange}
              />
            </span>
            <button
              onClick={togglePasswordVisibility1}
              className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
            >
              {showPassword1 ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          {error['confirmPassword'] === '1' ? (
            <div className="mt-[-12px] mb-[12px] text-red-500">
              Fill the confirm password
            </div>
          ) : null} */}

          <div className="flex gap-2 mt-6">
            <label htmlFor="">Active</label>
            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
              <input
                type="checkbox"
                id="toggle"
                className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
                  status ? "right-0 border-green-400" : "left-0 border-gray-300"
                }`}
                checked={status}
                onChange={handleToggle}
              />
              <label
                htmlFor="toggle"
                className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
          }`}
              ></label>
            </div>
          </div>

          {/* <div className="flex gap-2 my-6">
            <label htmlFor="">Upload resume</label>
            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
              <input
                type="checkbox"
                id="toggleResume"
                className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
                  resume ? 'right-0 border-green-400' : 'left-0 border-gray-300'
                }`}
                checked={resume}
                onChange={handleToggleResume}
              />
              <label
                htmlFor="toggleResume"
                className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
          }`}
              ></label>
            </div>
          </div> */}

          {<div className="text-red-500">{apiResponseError}</div>}

          <div className="mt-12 gap-4 flex justify-center">
            <CustomButtonWhite text={"Cancel"} onClick={setClose} />

            {/* <CustomButton text={'Add'} onChange={handleAdd}/> */}
            <button
              className="h-12 w-[12.5rem] border-2"
              style={{
                backgroundColor: "white",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F1F2F4";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "";
              }}
              onClick={handleAdd}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const Modal1 = ({ isOpen, onClose }) => {
  const organization = sessionStorage.getItem("organization");
  const [mobile, setMobile] = useState("");
  const [dept, setDept] = useState("");
  const [password, setPassword] = useState("1234");
  const [firstChange, setFirstChange] = useState("");
  const [lastChange, setLastChange] = useState("");
  const [emailChange, setEmailChange] = useState("");
  const [designationChange, setDesignationChange] = useState("");
  console.log(designationChange, "designationChange");
  const [apiResponseError, setApiResponseError] = useState("");
  const [error, setError] = useState({
    first: "0",
    last: "0",
    email: "0",
    designation: "0",
    mobile: 0,
  });
  const [status, setStatus] = useState(true);
  const [resume, setResume] = useState(true);

  const handleToggle = () => {
    setStatus((prevStatus) => !prevStatus);
  };

  const handleToggleResume = () => {
    setResume((prev) => !prev);
  };

  const handlePasswordChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      password: "0",
    }));
    setPassword(e.target.value);
  };

  const setClose = () => {
    setFirstChange("");
    setLastChange("");
    setEmailChange("");
    setDesignationChange("");
    setApiResponseError("");
    setDept("");
    setMobile("");
    setError({
      first: "0",
      last: "0",
      email: "0",
      designation: "0",
      mobile: 0,
    });
    onClose();
  };

  const setCloseOnFocus = () => {
    // setConfirmPassword('');
    // setPassword('');
    // setFirstChange('');
    // setLastChange('');
    // setEmailChange('');
    // setDesignationChange('');
    // setApiResponseError('');
    // setError({
    //   first: '0',
    //   last: '0',
    //   email: '0',
    //   designation: '0',
    //   password: '0',
    //   confirmPassword: '0',
    // });
    onClose();
  };

  const handleFirstChange = (e) => {
    let first = e.target.value;
    if (validateName(first)) {
      setError((prevState) => ({
        ...prevState,
        first: "0",
      }));
      console.log("valid", first);
      setFirstChange(first);
    } else {
      return;
    }
  };

  const handleLastChange = (e) => {
    let newValue = e.target.value;
    if (validateName(newValue)) {
      setError((prevState) => ({
        ...prevState,
        last: "0",
      }));
      setLastChange(newValue);
    }
  };

  const handleEmailChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      email: "0",
    }));
    setEmailChange(e.target.value);
  };

  const handleDesignationChange = (e) => {
    let newValue = e.target.value;

    // Remove leading spaces
    newValue = newValue.replace(/^\s+/, "");

    // Validate using a regex that allows spaces and certain special characters
    const validInput = /^[A-Za-z][A-Za-z0-9@\s!#%^&*'()_\-+=]*$/.test(newValue);

    if (newValue === "" || validInput) {
      // Limit to 40 characters
      newValue = newValue;
      setError((prevState) => ({
        ...prevState,
        designation: newValue ? "0" : "1",
      }));

      setDesignationChange(newValue);
    }
  };

  const handleDeptChange = (e) => {
    let newValue = e.target.value;

    setDept(newValue);
  };

  const handleMobileChange = (e) => {
    if (/^\d{0,10}$/.test(e.target.value)) {
      setMobile(e.target.value);
    }
  };

  const validation = () => {
    let isValid = 1;

    if (!firstChange) {
      console.log("set firstname");
      setError((prevState) => ({
        ...prevState,
        first: "1",
      }));
      isValid = 0;
    }

    if (!lastChange) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        last: "1",
      }));
      isValid = 0;
    }

    if (!validateEmail(emailChange)) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        email: "1",
      }));
      isValid = 0;
    }

    if (!designationChange) {
      console.log("set designation", designationChange);
      setError((prevState) => ({
        ...prevState,
        designation: "1",
      }));
      isValid = 0;
    }

    if (mobile.length !== 10 && mobile.length !== 0) {
      console.log("set mob", mobile.length);
      setError((prevState) => ({
        ...prevState,
        mobile: "1",
      }));
      isValid = 0;
    }

    // if (!password) {
    //   console.log('set password');
    //   setError((prevState) => ({
    //     ...prevState,
    //     password: '1',
    //   }));
    //   isValid = 0;
    // }

    // if (!confirmPassword) {
    //   console.log('set designation');
    //   setError((prevState) => ({
    //     ...prevState,
    //     confirmPassword: '1',
    //   }));
    //   isValid = 0;
    // }

    // if (password !== confirmPassword) {
    //   toast.error('Password does not match');
    //   isValid = 0;
    // }
    return isValid;
  };

  const handleAdd = async () => {
    console.log(
      "form Data ",
      firstChange,
      lastChange,
      emailChange,
      designationChange
    );
    console.log("return from validation", validation());
    if (validation()) {
      console.log("inside validate", validation());
      let reqData = {
        first_name: firstChange,
        last_name: lastChange,
        email: emailChange,
        designation: designationChange,
        status: status ? 1 : 0,
        is_resume_upload_allow: resume,

        organization: organization,
      };
      if (dept.length) {
        reqData["department"] = dept;
      }

      console.log(reqData);

      const response = await handleApiResponse(
        "/api/v1/auth/generate_member_link/",
        reqData,
        1
      );
      if (response?.status === 200) {
        if (response.data.status_code === 200) {
          toast.success("User Added");
          setClose();
        } else {
          if (response.data.msg.split(":")[0].includes("duplicate")) {
            setApiResponseError("User already exists");
          } else {
            setApiResponseError(response.data.msg);
          }
        }
      }
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 overflow-auto flex justify-end z-50">
          <div className="fill-available" onClick={setCloseOnFocus}></div>
          <div className="w-[34.792vw] h-auto bg-white rounded-lg p-8 shadow-md absolute add-member-animation  ">
            <div className="items-center flex justify-between">
              <p className="text-[1.528vw] font-medium">Add Member</p>
              <img
                src={closeButton}
                alt="close button"
                onClick={setClose}
                className="cursor-pointer"
              />
            </div>
            <hr className="my-2" />
            <div className="flex gap-4 justify-between mt-4">
              <div className="w-[50%] font-normal mb-[10px]">
                <InputBox1
                  name={"First Name"}
                  holder={"Enter first name"}
                  value={firstChange}
                  onChange={handleFirstChange}
                  error={"Fill a valid first name"}
                  showErrorOnInput={error["first"]}
                />
              </div>
              <div className="w-[50%] font-normal mb-[10px]">
                <InputBox1
                  name={"Last Name"}
                  holder={"Enter last name"}
                  value={lastChange}
                  onChange={handleLastChange}
                  error={"Fill a valid last name"}
                  showErrorOnInput={error["last"]}
                />
              </div>
            </div>
            <div className="font-normal mb-[10px]">
              <InputBox1
                name={"Email"}
                holder={"Enter email"}
                value={emailChange}
                onChange={handleEmailChange}
                error={"Fill a valid Email"}
                showErrorOnInput={error["email"]}
              />
            </div>
            <div className="font-normal mb-[10px] fill-available">
              <InputBox1
                name={"Designation"}
                holder={"Enter designation"}
                value={designationChange}
                onChange={handleDesignationChange}
                error={"Fill a valid Designation"}
                showErrorOnInput={error["designation"]}
              />
            </div>
            {/* <div className="font-normal mb-[10px]">
              <label htmlFor="">organization</label>
              <span className="text-red-500">*</span>
              <input
                type="text"
                className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
                value={organization}
                readOnly
              />
            </div> */}
            <div className="font-normal mb-[10px]">
              <label htmlFor="">Department</label>
              <input
                type="text"
                placeholder={"Enter department"}
                className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
                value={dept}
                onChange={handleDeptChange}
              />
            </div>
            {/* <div className="font-normal mb-[10px]">
              <label htmlFor="">Mobile number</label>
              <input
                type="text"
                className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
                value={mobile}
                onChange={handleMobileChange}
              />
            </div>
            {error.mobile === '1' ? (
              <div className="text-red-500">Mobile should be of 10 digits</div>
            ) : null} */}

            {/* <div className="w-auto flex font-normal mb-[10px] relative">
              <span className="flex flex-col w-screen">
                <span className="flex">
                  <label htmlFor="">Password</label>
                  <span className="text-red-500">*</span>
                </span>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Create password"
                  value={password}
                  className=" border-[#F1F2F4] outline-0 bg-[#FBFBFB] p-3 rounded-lg mt-2"
                  onChange={handlePasswordChange}
                />
              </span>
              <button
                onClick={togglePasswordVisibility}
                className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>
            {error['password'] === '1' ? (
              <div className=" mt-[-12px] mb-[12px] text-red-500">
                Fill the password
              </div>
            ) : null}
            <div className="w-auto flex font-normal mb-[10px] relative">
              <span className="flex flex-col w-screen">
                <span className="flex">
                  <label htmlFor="">Confirm Password</label>
                  <span className="text-red-500">*</span>
                </span>

                <input
                  type={showPassword1 ? 'text' : 'password'}
                  value={confirmPassword}
                  placeholder="Re-enter password"
                  className="border-[#F1F2F4] bg-[#FBFBFB] p-3 rounded-lg outline-0 mt-2"
                  onChange={handleConfirmPasswordChange}
                />
              </span>
              <button
                onClick={togglePasswordVisibility1}
                className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
              >
                {showPassword1 ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>
            {error['confirmPassword'] === '1' ? (
              <div className="mt-[-12px] mb-[12px] text-red-500">
                Fill the confirm password
              </div>
            ) : null} */}

            <div className="flex gap-2 my-6">
              <label htmlFor="">Active</label>
              <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                <input
                  type="checkbox"
                  id="toggle"
                  className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
                    status
                      ? "right-0 border-green-400"
                      : "left-0 border-gray-300"
                  }`}
                  checked={status}
                  onChange={handleToggle}
                />
                <label
                  htmlFor="toggle"
                  className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
          }`}
                ></label>
              </div>
            </div>

            {/* <div className="flex gap-2 my-6">
              <label htmlFor="">Upload resume</label>
              <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                <input
                  type="checkbox"
                  id="toggleResume"
                  className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
                    resume
                      ? 'right-0 border-green-400'
                      : 'left-0 border-gray-300'
                  }`}
                  checked={resume}
                  onChange={handleToggleResume}
                />
                <label
                  htmlFor="toggleResume"
                  className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
          }`}
                ></label>
              </div>
            </div> */}

            {<div className="text-red-500">{apiResponseError}</div>}

            <div className=" gap-4 flex justify-center">
              <CustomButtonWhite text={"Cancel"} onClick={setClose} />

              {/* <CustomButton text={'Add'} onChange={handleAdd}/> */}
              <button
                className="h-12 w-[12.5rem] border-2"
                style={{
                  backgroundColor: "white",
                  transition: "background-color 0.3s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#F1F2F4";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "";
                }}
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const Modal2 = ({ onClose, uid, setUuid }) => {
  console.log(uid);

  const deleteMemeber = async () => {
    const reqData = {
      uid: uid,
      is_deleted: 1,
    };
    const response = await handleApiResponse("/api/v1/updateuser/", reqData, 1);
    onClose();
    setUuid("");
    if (response.status === 200) {
      console.log(response);
      toast.success("Deleted successfully.");
    } else {
      toast.warning("Something went wrong.");
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex z-10 justify-center">
        <div className="w-[100vw] h-[100vh]" onClick={onClose}></div>
        <div className="absolute w-[38.792vw] z-50 bg-white rounded-lg p-8 shadow-md add-member-animation-top ">
          <div className="flex justify-between mb-8">
            <div className="ml-[100px] text-center leading-[30px] self-center w-[20vw] text-[1.389vw] font-medium tracking-[0.15px]">
              Are you sure you want to delete this member?
            </div>
            <img
              src={closeButton}
              alt="close button"
              onClick={onClose}
              className="cursor-pointer w-[40px] h-[40px]"
            />
          </div>
          <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />
            <CustomButton text={"Yes"} onClick={deleteMemeber} />
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalGenerateSelfAnalytics = ({
  taskId,
  candPhoto,
  name,
  candCompany,
  candJobRole,
  candUid,
  onClose,
  uid,
  setUuid,
}) => {
  console.log(candUid);
  const navigate = useNavigate();
  const generateSelfAnalytics = async () => {
    try {
      const response = await handleApiResponse(
        "/api/v1/generate_performance/",
        {
          uid: candUid,
        },
        1
      );
      if (response?.data?.status_code === 200) {
        navigate(
          `/admin/analytics1?uid=${encodeURIComponent(
            candUid
          )}&candPhoto=${encodeURIComponent(
            candPhoto
          )}&candName=${encodeURIComponent(
            name
          )}&candCompany=${encodeURIComponent(
            candCompany
          )}&candJobRole=${encodeURIComponent(candJobRole)}`
        );
      } else if (response?.data.status_code === 400) {
        // setLoader(true);
        // toast.warning("Interview is not completed.");
        // setGenerateSelfAnalyticsPopup(true);
      } else {
        toast.error(response?.msg);
      }
      // setLoader(false);
    } catch (error) {
      // Handle errors here
    }
  };
  return (
    <>
      <div className="fixed inset-0 flex z-10 justify-center">
        <div className="w-[100vw] h-[100vh]" onClick={onClose}></div>
        <div className="absolute w-[38.792vw] z-50 bg-white rounded-lg p-8 shadow-md add-member-animation-top ">
          <div className="flex justify-between mb-8">
            <div className="ml-[100px] text-center leading-[30px] self-center w-[20vw] text-[1.389vw] font-medium tracking-[0.15px]">
              Generate Analytics
            </div>
            <img
              src={closeButton}
              alt="close button"
              onClick={onClose}
              className="cursor-pointer w-[40px] h-[40px]"
            />
          </div>
          <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />
            <CustomButton text={"Generate"} onClick={generateSelfAnalytics} />
          </div>
        </div>
      </div>
    </>
  );
};

// export const ModalAddJobRole = ({
//   isOpen,
//   onClose,
//   setData,
//   setjobroleValue,
//   setJobRoleUid,
//   openEditConfirmation,
//   head,
// }) => {
//   const [uploadFile, setUploadFile] = useState(openEditConfirmation?.jd);
//   const [jobRoleName, setJobRoleName] = useState(openEditConfirmation?.name);
//   const [editResumeChange, setEditResumeChange] = useState(false);
//   const [jobRoleDescription, setJobRoleDescription] = useState(
//     openEditConfirmation?.description
//   );
//   const [jobRoleExperienceFrom, setJobRoleExperienceFrom] = useState(
//     openEditConfirmation?.experience_from
//   );
//   const [jobRoleExperienceTo, setJobRoleExperienceTo] = useState(
//     openEditConfirmation?.experience_to
//   );
//   const [uid, setUid] = useState(openEditConfirmation?.uid);
//   const [passwordError, setPasswordError] = useState('');
//   const [error, setError] = useState({
//     name: '0',
//     description: '0',
//     experience_from: '0',
//     experience_to: '0',
//     jd: '0',
//   });
//   //this is job role page job role
//   const validation = () => {
//     let isValid = 1;

//     if (!jobRoleName) {
//       console.log('set firstname');
//       setError((prevState) => ({
//         ...prevState,
//         name: '1',
//       }));
//       isValid = 0;
//     }

//     if (!jobRoleDescription) {
//       console.log('set lastname');
//       setError((prevState) => ({
//         ...prevState,
//         description: '1',
//       }));
//       isValid = 0;
//     }

//     if (!jobRoleExperienceFrom) {
//       console.log('set lastname');
//       setError((prevState) => ({
//         ...prevState,
//         experience_from: '1',
//       }));
//       isValid = 0;
//     }

//     if (!jobRoleExperienceTo) {
//       console.log('set designation');
//       setError((prevState) => ({
//         ...prevState,
//         experience_to: '1',
//       }));
//       isValid = 0;
//     }

//     if (!uploadFile) {
//       console.log('set password');
//       setError((prevState) => ({
//         ...prevState,
//         jd: '1',
//       }));
//       isValid = 0;
//     }
//     return isValid;
//   };
//   const setClose = () => {
//     setJobRoleName('');
//     setJobRoleDescription('');
//     setJobRoleExperienceFrom('');
//     setJobRoleExperienceTo('');
//     setUploadFile('');
//     setError({
//       name: '0',
//       description: '0',
//       experience_from: '0',
//       experience_to: '0',
//       jd: '0',
//     });
//     onClose();
//   };
//   //console.log(uploadFile)
//   const handleCreateJobRole = async (e) => {
//     e.preventDefault();
//     setPasswordError('');
//     if (validation()) {
//       console.log('inside validate', validation());
//     }
//     console.log(uploadFile);
//     if (uploadFile === '') {
//       setPasswordError('Please upload jd.');
//     } else if (jobRoleExperienceFrom > jobRoleExperienceTo) {
//       setPasswordError('Invalid value for job experience.');
//     } else {
//       console.log(openEditConfirmation);
//       console.log(jobRoleName);
//       if (
//         jobRoleName === undefined ||
//         jobRoleDescription === '' ||
//         jobRoleExperienceFrom === '' ||
//         jobRoleExperienceTo === '' ||
//         uploadFile === '' ||
//         jobRoleName === '' ||
//         jobRoleDescription === undefined ||
//         jobRoleExperienceFrom === undefined ||
//         jobRoleExperienceTo === undefined
//       ) {
//         console.log('----------------');
//       } else {
//         try {
//           // console.log(uploadFile,"-----------------------------------------------------------")
//           const formData = new FormData();
//           formData.append('name', jobRoleName);
//           formData.append('description', jobRoleDescription);
//           formData.append('experience_from', jobRoleExperienceFrom);
//           formData.append('experience_to', jobRoleExperienceTo);
//           formData.append('jd', uploadFile);
//           if (openEditConfirmation) {
//             formData.append('uid', uid);
//           }

//           const response = await handleFormDataApiResponse(
//             '/api/v1/interview/add_jobrole/',
//             formData,
//             1
//           );
//           console.log('data', response.data.data);
//           if (response.data.status_code === 200) {
//             if (openEditConfirmation) {
//               toast.success('Job Role updated successfully.');
//               onClose();
//             } else {
//               toast.success('Job Role added successfully.');
//               try {
//                 setData((prev) => {
//                   let updated = [response?.data?.data, ...prev];
//                   updated = updated.filter((ele) => {
//                     if (ele.name !== 'Fetching...') {
//                       return ele;
//                     }
//                   });
//                   return updated;
//                 });
//               } catch (e) {
//                 console.log(e);
//               }
//               onClose();
//               setjobroleValue(response?.data?.data?.name);
//               setJobRoleUid(response?.data?.data?.uid);
//               sessionStorage.setItem(
//                 'expFrom',
//                 response?.data?.data?.experience_from
//               );
//               sessionStorage.setItem(
//                 'expTo',
//                 response?.data?.data?.experience_to
//               );
//             }
//           } else {
//             if (response.data.status_code === 400) {
//               toast.error('The submitted data is not correct.');
//             }
//           }
//         } catch (error) {
//           console.error('API Error:', error);
//         }
//       }
//     }
//   };

//   const handleJobRoleNameChange = (e) => {
//     setError((prevState) => ({
//       ...prevState,
//       name: '0',
//     }));
//     let newValue = e.target.value;
//     newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);
//     newValue = newValue.slice(0, 40);
//     setJobRoleName(newValue);
//   };

//   const handleJobRoleDescriptionChange = (e) => {
//     setError((prevState) => ({
//       ...prevState,
//       description: '0',
//     }));
//     let newValue = e.target.value;
//     newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);
//     newValue = newValue.slice(0, 200);
//     setJobRoleDescription(newValue);
//   };

//   const handleJobRoleExperienceFromChange = (e) => {
//     setError((prevState) => ({
//       ...prevState,
//       experience_from: '0',
//     }));
//     const inputValue = e.target.value;
//     if (inputValue.length <= 2) {
//       setJobRoleExperienceFrom(e.target.value);
//     }
//   };

//   const handleJobRoleExperienceToChange = (e) => {
//     setError((prevState) => ({
//       ...prevState,
//       experience_to: '0',
//     }));
//     const inputValue = e.target.value;
//     if (inputValue.length <= 2) {
//       setJobRoleExperienceTo(e.target.value);
//     }
//   };

//   const handleKeyDown2 = (e) => {
//     const key = e.key;
//     const isAlphabetic = key.match(/[a-zA-Z]/);
//     const isSpace = key === ' ';
//     if (!isAlphabetic && !isSpace) {
//       e.preventDefault();
//     }
//   };
//   return (
//     <>
//       <div className="fixed inset-0 flex items-center justify-end z-50 ">
//         <div
//           className="fill-available w-[100%] h-[100%]"
//           onClick={setClose}
//         ></div>
//         <div className="w-[34.792vw] bg-white rounded-lg p-8 shadow-md add-member-animation">
//           <div className="items-center flex justify-between">
//             <p className="text-[1.528vw] font-medium">{head} Job Role</p>
//             <img
//               src={closeButton}
//               alt="close button"
//               onClick={setClose}
//               className="cursor-pointer"
//             />
//           </div>
//           <hr className="my-2" />
//           <div className="text-[0.972vw]">
//             <div className="font-normal mb-[10px]">
//               <InputBox1
//                 name={"Job Role Name"}
//                 holder={"Enter job role name"}
//                 onChange={(e) => handleJobRoleNameChange(e)}
//                 value={jobRoleName}
//                 onKeyDown={handleKeyDown2}
//                 error={'Fill the Job Role Name'}
//                 showErrorOnInput={error['name']}
//                 required
//               />
//             </div>
//             <div className="font-normal mb-[10px]">
//               <InputBox1
//                 name={'Description'}
//                 holder={'Enter description'}
//                 onChange={(e) => handleJobRoleDescriptionChange(e)}
//                 value={jobRoleDescription}
//                 error={'Fill the Description'}
//                 showErrorOnInput={error['description']}
//               />
//             </div>
//             <div className="flex gap-4">
//               <div className="w-[50%] font-normal mb-[10px]">
//                 <span className="flex flex-col ">
//                   <span className="flex">
//                     <label htmlFor="">Experience Years (from)</label>
//                     <span className="text-red-500">*</span>
//                   </span>
//                   <input
//                     type="number"
//                     placeholder="Enter experience years (from)"
//                     className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm"
//                     onChange={(e) => handleJobRoleExperienceFromChange(e)}
//                     value={jobRoleExperienceFrom}
//                     maxLength="2"
//                   />
//                   {error['experience_from'] === '1' ? (
//                     <div className="text-red-500">
//                       Fill the experience range
//                     </div>
//                   ) : null}
//                 </span>
//               </div>
//               <div className="w-[50%] font-normal mb-[10px]">
//                 <span className="flex flex-col ">
//                   <span className="flex">
//                     <label htmlFor="">Experience Years (to)</label>
//                     <span className="text-red-500">*</span>
//                   </span>
//                   <input
//                     type="number"
//                     placeholder="Enter experience years (to)"
//                     className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm"
//                     onChange={(e) => handleJobRoleExperienceToChange(e)}
//                     value={jobRoleExperienceTo}
//                     maxLength="2"
//                   />
//                   {error['experience_to'] === '1' ? (
//                     <div className="text-red-500">
//                       Fill the experience range
//                     </div>
//                   ) : null}
//                 </span>
//               </div>
//             </div>
//             <span className="flex flex-col ">
//               <label htmlFor="">Upload JD</label>
//               <UploadResumeOnly
//                 setUploadResume={setUploadFile}
//                 uploadResume={uploadFile}
//                 error={'Upload JD'}
//                 showErrorOnInput={error['jd']}
//               />
//             </span>
//             <div style={{ color: 'red', marginTop: '10px' }}>
//               {passwordError}
//             </div>
//             <div className="mt-8 flex justify-center gap-4">
//               <CustomButtonWhite text={'Cancel'} onClick={setClose} />
//               <CustomButton
//                 text={openEditConfirmation ? 'Edit' : 'Add'}
//                 onClick={(e) => {
//                   handleCreateJobRole(e);
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

export const ModalAddJobRole = ({
  isOpen,
  onClose,
  setData,
  setjobroleValue,
  setJobRoleUid,
  openEditConfirmation,
  head,
}) => {
  let creator = sessionStorage.getItem("uid");
  const [isLoader, setLoader] = useState(false);
  const [uploadFile, setUploadFile] = useState(openEditConfirmation?.jd);
  // const [jobRoleName, setJobRoleName] = useState();
  const [editResumeChange, setEditResumeChange] = useState(false);
  const [creatorId, setCreator] = useState(openEditConfirmation?.creator);
  const [isurlToFile, setIsUrlToFile] = useState(false);
  const [jobRoleDescription, setJobRoleDescription] = useState(
    openEditConfirmation?.description
  );
  const [jobRoleExperienceFrom, setJobRoleExperienceFrom] = useState(
    openEditConfirmation?.experience_from
  );
  const [jobRoleExperienceTo, setJobRoleExperienceTo] = useState(
    openEditConfirmation?.experience_to
  );
  const [jobRoleName, setJobRoleName] = useState(
    creator !== creatorId ? "" : openEditConfirmation?.name
  );
  console.log(creatorId);
  console.log(creator);
  console.log(creator === creatorId);
  // const isClickable = creator === ;

  const [uid, setUid] = useState(openEditConfirmation?.uid);
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState({
    name: "0",
    description: "0",
    experience_from: "0",
    experience_to: "0",
    jd: "0",
  });

  const validation = () => {
    let isValid = 1;

    if (!jobRoleName) {
      console.log("set firstname");
      setError((prevState) => ({
        ...prevState,
        name: "1",
      }));
      isValid = 0;
    }

    if (!jobRoleDescription) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        description: "1",
      }));
      isValid = 0;
    }

    if (!jobRoleExperienceFrom) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        experience_from: "1",
      }));
      isValid = 0;
    }

    if (!jobRoleExperienceTo) {
      console.log("set designation");
      setError((prevState) => ({
        ...prevState,
        experience_to: "1",
      }));
      isValid = 0;
    }

    if (!uploadFile) {
      console.log("set password");
      setError((prevState) => ({
        ...prevState,
        jd: "1",
      }));
      isValid = 0;
    }
    return isValid;
  };
  const setClose = () => {
    setJobRoleName("");
    setJobRoleDescription("");
    setJobRoleExperienceFrom("");
    setJobRoleExperienceTo("");
    setUploadFile("");
    setError({
      name: "0",
      description: "0",
      experience_from: "0",
      experience_to: "0",
      jd: "0",
    });
    onClose();
  };

  const incrementNameSuffix = (name) => {
    let newName = name;
    const match = name.match(/^(.*?)(\d*)$/);
    if (match && match[2] !== "") {
      const baseName = match[1];
      const suffix = parseInt(match[2], 10);
      newName = `${baseName}${suffix + 1}`;
    } else {
      newName = `${name}1`;
    }
    return newName;
  };

  const urlToFile = async (url) => {
    const response = await fetch(url);
    const data = await response.blob();
    const filename = url.substring(url.lastIndexOf("/") + 1);
    const file = new File([data], filename, {
      type: response.headers.get("content-type"),
    });
    setIsUrlToFile(true);
    return file;
  };

  const handleCreateJobRole = async (e) => {
    // setLoader(true);
    e.preventDefault();
    setPasswordError("");
    if (validation()) {
      console.log("inside validate", validation());
    }
    console.log(uploadFile);
    if (uploadFile === "" || uploadFile === undefined) {
      setPasswordError("Please upload jd.");
    } else if (Number(jobRoleExperienceFrom) > Number(jobRoleExperienceTo)) {
      setPasswordError("Invalid value for job experience.");
    } else {
      console.log(openEditConfirmation);
      console.log(jobRoleName);
      if (
        jobRoleName === undefined ||
        jobRoleDescription === "" ||
        jobRoleExperienceFrom === "" ||
        jobRoleExperienceTo === "" ||
        uploadFile === "" ||
        jobRoleName === "" ||
        jobRoleDescription === undefined ||
        jobRoleExperienceFrom === undefined ||
        jobRoleExperienceTo === undefined
        // ||
        // (openEditConfirmation && jobRoleName === openEditConfirmation.name)
      ) {
        console.log("----------------");
      } else {
        setLoader(true);
        try {
          const formData = new FormData();
          formData.append("name", jobRoleName);
          formData.append("description", jobRoleDescription);
          formData.append("experience_from", jobRoleExperienceFrom);
          formData.append("experience_to", jobRoleExperienceTo);
          console.log(uploadFile, "file", file_url);
          if (openEditConfirmation) {
            // if (uploadFile.name !== undefined) {
            // formData.append("jd", uploadFile);
            formData.append("jd_file", uploadFile);
            if (uploadFile instanceof File) {
              formData.append("jd", uploadFile.name);
            } else {
              formData.append("jd", uploadFile);
            }

            // }
            // else {
            //   const url = file_url + uploadFile;
            //   urlToFile(url)
            //     .then((file) => {
            //       formData.append("jd", file);
            //     })
            //     .catch((error) => {
            //       console.error("Error converting URL to file:", error);
            //     });
            // }
          } else {
            // formData.append("jd", uploadFile);
            formData.append("jd_file", uploadFile);
          }

          if (creator === creatorId) {
            if (openEditConfirmation) {
              formData.append("uid", uid);
            }
          }
          for (var pair of formData.entries()) {
            console.log(pair[0] + ": " + pair[1], "add job role");
          }
          const response = await handleFormDataApiResponse(
            "/api/v1/interview/add_jobrole/",
            formData,
            1
          );
          if (response.data.status_code === 200) {
            setLoader(false);
            if (openEditConfirmation) {
              toast.success("Job Role updated successfully.");
              onClose();
            } else {
              toast.success("Job Role added successfully.");
              try {
                setData((prev) => {
                  let updated = [response?.data?.data, ...prev];
                  updated = updated.filter((ele) => {
                    if (ele.name !== "Fetching...") {
                      return ele;
                    }
                  });
                  return updated;
                });
              } catch (e) {
                console.log(e);
              }
              onClose();
              setjobroleValue(response?.data?.data?.name);
              setJobRoleUid(response?.data?.data?.uid);
              sessionStorage.setItem(
                "expFrom",
                response?.data?.data?.experience_from
              );
              sessionStorage.setItem(
                "expTo",
                response?.data?.data?.experience_to
              );
            }
          } else {
            if (response.data.status_code === 400) {
              // toast.error("The submitted data is not correct.");
              setLoader(false);
              toast.error(response?.data?.msg);
            }
          }
        } catch (error) {
          setLoader(false);
          console.error("API Error:", error);
        }
      }
    }
  };

  const handleJobRoleNameChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      name: "0",
    }));
    let newValue = e.target.value;
    setJobRoleName(newValue);
  };

  const handleJobRoleDescriptionChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      description: "0",
    }));
    let newValue = e.target.value;
    setJobRoleDescription(newValue);
  };

  const handleJobRoleExperienceFromChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      experience_from: "0",
    }));

    // Get the value from the input
    const inputValue = e.target.value;

    // Use a regular expression to allow only numeric values and ensure the length does not exceed 2
    const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Set the value only if it is valid and does not exceed the length
    if (numericValue.length <= 2) {
      setJobRoleExperienceFrom(numericValue);
    }
  };

  const handleKeyPress = (e) => {
    if (
      e.key === "e" ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === "E"
    ) {
      e.preventDefault();
    }
  };

  const handleJobRoleExperienceToChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      experience_to: "0",
    }));

    // Get the value from the input
    const inputValue = e.target.value;

    // Use a regular expression to allow only numeric values and ensure the length does not exceed 2
    const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Set the value only if it is valid and does not exceed the length
    if (numericValue.length <= 2) {
      setJobRoleExperienceTo(numericValue);
    }
  };

  const handleKeyDown2 = (e) => {
    const key = e.key;
    const isAlphabetic = key.match(/[a-zA-Z]/);
    const isSpace = key === " ";
    // if (!isAlphabetic && !isSpace) {
    //   e.preventDefault();
    // }
  };

  console.log(
    "first",
    error["experience_from"],
    jobRoleExperienceFrom,
    jobRoleExperienceTo
  );
  return (
    <>
      <div className="fixed inset-0 flex items-center justify-end z-50 ">
        {isLoader ? <Loader /> : ""}
        <div className="fill-available h-[100%]" onClick={setClose}></div>
        <div className="w-[34.792vw] bg-white rounded-lg p-8 shadow-md add-member-animation">
          <div className="items-center flex justify-between">
            <p className="text-[1.528vw] font-medium">{head} Job Role</p>
            <img
              src={closeButton}
              alt="close button"
              onClick={setClose}
              className="cursor-pointer"
            />
          </div>
          <hr className="my-2" />
          <div className="text-[0.972vw]">
            {/* <div className="font-normal mb-[10px]">
              <InputBoxRed
                name={"Job Role Name"}
                holder={"Enter job role name"}
                onChange={(e) => handleJobRoleNameChange(e)}
                openEditConfirmation={openEditConfirmation}
                value={jobRoleName}
                onKeyDown={handleKeyDown2}
                error={
                  jobRoleName !== undefined ||
                  (jobRoleName !== "") === openEditConfirmation?.name
                    ? "Job role name can't be same"
                    : "Fill the Job Role Name"
                }
                showErrorOnInput={error["name"]}
                required
              />
            </div> */}
            {creator === creatorId && (
              <div className="font-normal mb-[10px]">
                <InputBox1
                  name={"Job Role Name"}
                  holder={"Enter job role name"}
                  onChange={(e) => handleJobRoleNameChange(e)}
                  openEditConfirmation={openEditConfirmation}
                  value={jobRoleName}
                  onKeyDown={handleKeyDown2}
                  error={
                    jobRoleName !== undefined ||
                    (jobRoleName !== "") === openEditConfirmation?.name
                      ? "Job role name can't be same"
                      : "Fill the Job Role Name"
                  }
                  showErrorOnInput={error["name"]}
                  required
                />
              </div>
            )}
            {creator !== creatorId && (
              <div className="font-normal mb-[10px]">
                <InputBoxRed
                  name={"Job Role Name"}
                  holder={"Enter job role name"}
                  onChange={(e) => handleJobRoleNameChange(e)}
                  openEditConfirmation={openEditConfirmation}
                  value={jobRoleName}
                  onKeyDown={handleKeyDown2}
                  error={
                    jobRoleName !== undefined ||
                    (jobRoleName !== "") === openEditConfirmation?.name
                      ? "Job role name can't be same"
                      : "Fill the Job Role Name"
                  }
                  showErrorOnInput={error["name"]}
                  required
                />
              </div>
            )}

            <div className="font-normal mb-[10px]">
              <InputBox3
                name={"Brief description"}
                holder={"Enter brief description"}
                onChange={(e) => handleJobRoleDescriptionChange(e)}
                value={jobRoleDescription}
                error={"Fill the Description"}
                showErrorOnInput={error["description"]}
              />
            </div>
            <div className="flex gap-4">
              <div className="w-[50%] font-normal mb-[10px]">
                <span className="flex flex-col ">
                  <span className="flex">
                    <label htmlFor="">Experience Years (from)</label>
                    <span className="text-red-500">*</span>
                  </span>
                  <input
                    type="text"
                    placeholder="Enter experience years (from)"
                    className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm"
                    onChange={(e) => handleJobRoleExperienceFromChange(e)}
                    value={jobRoleExperienceFrom}
                    onKeyDown={(e) => handleKeyPress(e)}
                    maxLength="2"
                  />
                  {!jobRoleExperienceFrom &&
                  error["experience_from"] === "1" ? (
                    <div className="text-red-500">
                      Fill the experience range
                    </div>
                  ) : null}
                </span>
              </div>
              <div className="w-[50%] font-normal mb-[10px]">
                <span className="flex flex-col ">
                  <span className="flex">
                    <label htmlFor="">Experience Years (to)</label>
                    <span className="text-red-500">*</span>
                  </span>
                  <input
                    type="text"
                    placeholder="Enter experience years (to)"
                    className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm"
                    onChange={(e) => handleJobRoleExperienceToChange(e)}
                    value={jobRoleExperienceTo}
                    onKeyDown={(e) => handleKeyPress(e)}
                    maxLength="2"
                  />
                  {!jobRoleExperienceTo && error["experience_to"] === "1" ? (
                    <div className="text-red-500">
                      Fill the experience range
                    </div>
                  ) : null}
                </span>
              </div>
            </div>
            <span className="flex flex-col ">
              <span className="flex">
                <label htmlFor="">Upload JD</label>
                <span className="text-red-500">*</span>
              </span>

              <UploadResumeOnly
                setUploadResume={setUploadFile}
                uploadResume={uploadFile}
                error={"Upload JD"}
                showErrorOnInput={error["jd"]}
                setEditResumeChange={setEditResumeChange}
              />
            </span>
            {!uploadFile && error["jd"] === "1" ? (
              <div className="text-red-500">Upload jd_file</div>
            ) : null}
            {/* <div style={{ color: "red", marginTop: "10px" }}>
              {passwordError}
            </div> */}
            <div className="mt-8 flex justify-center gap-4">
              <CustomButtonWhite text={"Cancel"} onClick={setClose} />
              <CustomButton
                text={openEditConfirmation ? "Edit" : "Add"}
                onClick={(e) => {
                  handleCreateJobRole(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalAddCandidate = ({
  isOpen,
  onClose,
  setExcelData,
  uploadFile,
  setUploadFile,
  onAddCandidate,
  excelData,
  setSingleAddCandidate,
  setSelectedCheckboxes,
  selectedCheckboxes,
}) => {
  const location = useLocation();
  const [mobile, setMobile] = useState("");
  const [firstChange, setFirstChange] = useState("");
  const [lastChange, setLastChange] = useState("");
  const [emailChange, setEmailChange] = useState("");
  const [apiResponseError, setApiResponseError] = useState("");
  const [error, setError] = useState({
    first: "",
    last: "",
    email: "",
    // mobile: "",
  });

  console.log(error, "errorrrrrrrrrr");

  const setClose = () => {
    setFirstChange("");
    setLastChange("");
    setEmailChange("");
    setApiResponseError("");
    setMobile("");
    setError({
      first: "",
      last: "",
      email: "",
      // mobile: "",
    });
    onClose();
  };

  const setCloseOnFocus = () => {
    onClose();
  };

  const handleFirstChange = (e) => {
    let first = e.target.value;
    if (validateName(first)) {
      setError((prevState) => ({
        ...prevState,
        first: "",
      }));
      setFirstChange(first);
    }
  };

  const handleLastChange = (e) => {
    let newValue = e.target.value;
    if (validateName(newValue)) {
      setError((prevState) => ({
        ...prevState,
        last: "",
      }));
      setLastChange(newValue);
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailChange(email);
    if (validateEmail(email)) {
      setError((prevState) => ({
        ...prevState,
        email: "",
      }));
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    // if (/^\d{0,10}$/.test(value)) {
    setMobile(value);
    // if (value.length === 10) {
    //   setError((prevState) => ({
    //     ...prevState,
    //     mobile: "",
    //   }));
    // }
    // }
  };

  const validateName = (name) => typeof name === "string" && name.trim() !== "";
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validation = () => {
    let isValid = true;

    if (!firstChange) {
      setError((prevState) => ({
        ...prevState,
        first: "First name is required",
      }));
      isValid = false;
    }

    if (!lastChange) {
      setError((prevState) => ({
        ...prevState,
        last: "Last name is required",
      }));
      isValid = false;
    }

    if (!validateEmail(emailChange)) {
      setError((prevState) => ({
        ...prevState,
        email: "Invalid email format",
      }));
      isValid = false;
    }

    // if (mobile.length !== 10) {
    //   setError((prevState) => ({
    //     ...prevState,
    //     mobile: "Mobile number must be 10 digits",
    //   }));
    //   isValid = false;
    // }

    return isValid;
  };

  const handleAdd = async () => {
    if (!validation()) {
      return;
    }

    const newCandidate = {
      first_name: firstChange,
      last_name: lastChange,
      email: emailChange,
      mobile: mobile,
      is_selected: true,
    };

    const existingExcelData =
      JSON.parse(sessionStorage.getItem("excelData")) || [];
    const existingCheckedFilteredData =
      JSON.parse(sessionStorage.getItem("checkedFilteredData")) || [];
    const existingSingleAddCandidateData =
      JSON.parse(sessionStorage.getItem("singleAddCandidate")) || [];

    const isDuplicateEmailInState = excelData.some(
      (candidate) => candidate.email === newCandidate.email
    );

    const isDuplicateEmail =
      existingExcelData.some(
        (candidate) => candidate.email === newCandidate.email
      ) ||
      existingCheckedFilteredData.some(
        (candidate) => candidate.email === newCandidate.email
      ) ||
      existingSingleAddCandidateData.some(
        (candidate) => candidate.email === newCandidate.email
      );

    if (isDuplicateEmailInState || isDuplicateEmail) {
      toast.warning(
        "A candidate with this email already exists. Duplicate emails are not acceptable."
      );
      return;
    }

    setSingleAddCandidate((prevData) => {
      const updatedData = [...existingSingleAddCandidateData, newCandidate];
      sessionStorage.setItem("singleAddCandidate", JSON.stringify(updatedData));

      return updatedData;
    });

    let uploadSelected = { [newCandidate.email]: true };

    setSelectedCheckboxes({ ...selectedCheckboxes, ...uploadSelected });

    sessionStorage.setItem(
      "selectedCheckboxes",
      JSON.stringify({ ...selectedCheckboxes, ...uploadSelected })
    );

    toast.success("Candidate Added.");
    onClose();
  };

  const preventBackspaceOnEmpty = (e, valueSetter, fieldValue) => {
    if (e.key === "Backspace" && fieldValue.length <= 1) {
      e.preventDefault();
      valueSetter("");
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 overflow-auto flex justify-end z-50">
          <div className="fill-available" onClick={setCloseOnFocus}></div>
          <div className="w-[34.792vw] h-auto bg-white rounded-lg p-8 shadow-md absolute add-member-animation">
            <div className="items-center flex justify-between">
              <p className="text-[1.528vw] font-medium">Add Candidate</p>
              <img
                src={closeButton}
                alt="close button"
                onClick={setClose}
                className="cursor-pointer"
              />
            </div>
            <hr className="my-2" />
            <div className="flex gap-4 justify-between mt-4">
              <div className="w-[50%] font-normal mb-[10px]">
                <InputBox1
                  name={"First Name"}
                  holder={"Enter first name"}
                  value={firstChange}
                  onChange={handleFirstChange}
                  onKeyDown={(e) =>
                    preventBackspaceOnEmpty(e, setFirstChange, firstChange)
                  }
                />
                {error.first && (
                  <div className="text-red-500 mt-1">{error.first}</div>
                )}
              </div>
              <div className="w-[50%] font-normal mb-[10px]">
                <InputBox1
                  name={"Last Name"}
                  holder={"Enter last name"}
                  value={lastChange}
                  onChange={handleLastChange}
                  error={error.last}
                  onKeyDown={(e) =>
                    preventBackspaceOnEmpty(e, setLastChange, lastChange)
                  }
                />
                {error.last && (
                  <div className="text-red-500 mt-1">{error.last}</div>
                )}
              </div>
            </div>
            <div className="font-normal mb-[10px]">
              <InputBox1
                name={"Email"}
                holder={"Enter email"}
                value={emailChange}
                onChange={handleEmailChange}
                error={error.email}
              />
              {error.email && (
                <div className="text-red-500 mt-1">{error.email}</div>
              )}
            </div>
            <div className="font-normal mb-[10px] flex flex-col">
              <div className="flex">
                <label htmlFor="mobile">Mobile Number</label>
              </div>
              <input
                type="number"
                className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] bg-[#FBFBFB]"
                placeholder="Enter mobile number"
                onChange={handleMobileChange}
                name="mobile"
                value={mobile}
              />
              {/* <InputBoxColor
                head={"Mobile Number"}
                name={"mobile"}
                holder={"Enter mobile number"}
                value={mobile}
                onChange={handleMobileChange}
                error={error.mobile}
              /> */}
              {/* {error.mobile && (
                <div className="text-red-500 mt-1">{error.mobile}</div>
              )} */}
            </div>
            {apiResponseError && (
              <div className="text-red-500">{apiResponseError}</div>
            )}
            <div className="gap-4 flex justify-center mt-12">
              <CustomButtonWhite text={"Cancel"} onClick={setClose} />
              <button
                className="h-12 w-[12.5rem] border-2"
                style={{
                  backgroundColor: "white",
                  transition: "background-color 0.3s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#F1F2F4";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "";
                }}
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const ModalEditJobRole = ({ isOpen, onClose, item, uid }) => {
  const [uploadFile, setUploadFile] = useState(false);
  const [status, setStatus] = useState(false);
  const [jobRoleName, setJobRoleName] = useState("");
  const [jobRoleDescription, setJobRoleDescription] = useState("");
  const [jobRoleExperienceFrom, setJobRoleExperienceFrom] = useState("");
  const [jobRoleExperienceTo, setJobRoleExperienceTo] = useState("");
  console.log(item);
  const handleToggle = () => {
    setStatus((prevStatus) => !prevStatus);
  };
  useEffect(() => {
    const handleGetJobRole = async () => {
      try {
        const formData = new FormData();
        formData.append("name", jobRoleName);
        formData.append("description", jobRoleDescription);
        formData.append("experience_from", jobRoleExperienceFrom);
        formData.append("experience_to", jobRoleExperienceTo);
        formData.append("jd", uploadFile);
        formData.append("uid", uid);

        const response = await handleFormDataApiResponse(
          "/api/v1/interview/add_jobrole/",
          formData,
          1
        );
        console.log(response.data);
        if (response.data.status_code === 200) {
          toast.success("Yes.");
          toast.success("Job Role added successfully.");
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    };
    handleGetJobRole();
  });

  const handleJobRoleNameChange = (e) => {
    setJobRoleName(e.target.value);
  };

  const handleJobRoleDescriptionChange = (e) => {
    setJobRoleDescription(e.target.value);
  };

  const handleJobRoleExperienceFromChange = (e) => {
    setJobRoleExperienceFrom(e.target.value);
  };

  const handleKeyDown = (e) => {
    // Prevent entering a decimal point as the first character
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  const handleJobRoleExperienceToChange = (e) => {
    setJobRoleExperienceTo(e.target.value);
  };
  //  console.log(jobRoleName);
  //  console.log(jobRoleDescription);
  //  console.log(jobRoleExperienceFrom);
  //  console.log(jobRoleExperienceTo);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-end z-50 ">
          <div className="w-[34.792vw] bg-white rounded-lg p-8 shadow-md add-member-animation">
            <div className="items-center flex justify-between">
              <p className="text-[1.528vw] font-medium">Edit Job Role</p>
              <img
                src={closeButton}
                alt="close button"
                onClick={onClose}
                className="cursor-pointer"
              />
            </div>
            <hr className="my-2" />
            <div className="text-[0.972vw]">
              <div className="font-normal mb-[10px]">
                <InputBox1
                  name={"Name"}
                  holder={"Enter job role name"}
                  onChange={(e) => handleJobRoleNameChange(e)}
                  value={jobRoleName}
                />
              </div>
              <div className="font-normal mb-[10px]">
                <InputBox1
                  name={"Description"}
                  holder={"Enter description"}
                  onChange={(e) => handleJobRoleDescriptionChange(e)}
                  value={jobRoleDescription}
                />
              </div>
              <div className="flex gap-4">
                <div className="w-[50%] font-normal mb-[10px]">
                  <InputBox1
                    name={"Experience Years (from)"}
                    holder={"Enter experience years (from)"}
                    onChange={(e) => handleJobRoleExperienceFromChange(e)}
                    value={jobRoleExperienceFrom}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="w-[50%] font-normal mb-[10px]">
                  <InputBox1
                    name={"Experience Years (to)"}
                    holder={"Enter experience years (to)"}
                    onChange={(e) => handleJobRoleExperienceToChange(e)}
                    value={jobRoleExperienceTo}
                  />
                </div>
              </div>
              <span className="flex flex-col ">
                <label htmlFor="">Upload JD</label>
                <UploadResumeOnly
                  setUploadResume={setUploadFile}
                  uploadResume={uploadFile}
                />
              </span>
              <div className="flex gap-2 mt-6">
                <label htmlFor="">Status</label>
                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input
                    type="checkbox"
                    id="toggle"
                    className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
                      status
                        ? "right-0 border-green-400"
                        : "left-0 border-gray-300"
                    }`}
                    checked={status}
                    onChange={handleToggle}
                  />
                  <label
                    htmlFor="toggle"
                    className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
          }`}
                  ></label>
                </div>
              </div>
              <div className="mt-8 flex justify-center gap-4">
                <CustomButtonWhite
                  text={"Cancel"}
                  onClick={() => {
                    onClose();
                  }}
                />
                <CustomButton text={"Save"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const ModalDeleteJobRole = ({ onClose, uid, setUuid }) => {
  console.log(uid);

  const deleteJobRole = async () => {
    const reqData = {
      uid: uid,
      is_deleted: 1,
    };
    const response = await handleApiResponse(
      "/api/v1/interview/add_jobrole/",
      reqData,
      1
    );
    onClose();
    setUuid("");
    console.log(response);
  };

  return (
    <>
      <div className="fixed inset-0 flex z-50 justify-center">
        <div className="fill-available  h-[100%]" onClick={onClose}></div>
        <div className="w-[38.792vw]  bg-white rounded-lg p-8 shadow-md add-member-animation-top ">
          <div className="flex justify-between mb-8">
            <div className="ml-[100px] text-center leading-[30px] self-center w-[20vw] text-[1.389vw] font-medium tracking-[0.15px]">
              Are you sure you want to delete this job role?
            </div>
            <img
              src={closeButton}
              alt="close button"
              onClick={onClose}
              className="cursor-pointer w-[40px] h-[40px]"
            />
          </div>
          <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />
            <CustomButton text={"Yes"} onClick={deleteJobRole} />
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalAddConfirmationPopup = ({
  setQuestionsAllowed,
  onClose,
  apiCall,
  setCurrent,
  selectedOption,
  questions3,
  hrQuesCount,
  blendedmandatoryCount,
  questions1,
  questions,
  campaignType,
  filterAiMadated,
  filterHrMadated,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="fixed inset-0 flex z-50 justify-center">
        <div className="fill-available  h-[100%]" onClick={onClose}></div>
        <div className="w-[38.792vw]  bg-white rounded-lg p-8 shadow-md add-member-animation-top ">
          <div className="flex justify-between mb-8">
            <div className="ml-[100px] text-center leading-[30px] self-center w-[20vw] text-[1.389vw] font-medium tracking-[0.15px]">
              {selectedOption === "option3" ? (
                <p>
                  <b className="text-blue-600">
                    {questions3 - blendedmandatoryCount} AI Question
                  </b>{" "}
                  and{" "}
                  <b className="text-green-600">
                    {hrQuesCount ? hrQuesCount : blendedmandatoryCount} HR
                    Question
                  </b>{" "}
                  will be a part of your campaign for interview. Do you wish to
                  proceed further ?
                </p>
              ) : selectedOption === "option1" && questions1 ? (
                <p>
                  <b className="text-blue-600">
                    {questions1} AI question ({filterAiMadated} Mandated)
                  </b>{" "}
                  will be a part of your campaign for interview. Do you wish to
                  proceed further ?
                </p>
              ) : (
                selectedOption === "option2" && (
                  <p>
                    <b className="text-green-600">
                      {questions} HR question ({filterHrMadated} Mandated)
                    </b>{" "}
                    will be a part of your campaign for interview. Do you wish
                    to proceed further ?
                  </p>
                )
              )}
            </div>
          </div>
          <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />
            <CustomButton
              text={"Yes"}
              onClick={() => {
                if (sessionStorage.getItem("camp_type") === "1") {
                  toast.success("Questions updated");
                  setCurrent(2);
                } else {
                  toast.success("Campaign Updated successfully.");
                  navigate("/admin/campaign");
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalCandidateConfirmationPopup = ({
  totatCandidates,
  checkedFilteredData,
  onClose,
  handleCallApi,
}) => {
  return (
    <>
      <div className="fixed inset-0 flex z-50 justify-center">
        <div className="fill-available  h-[100%]" onClick={onClose}></div>
        <div className="w-[38.792vw]  bg-white rounded-lg p-8 shadow-md add-member-animation-top ">
          <div className="flex justify-between mb-8">
            <div className="ml-[100px] text-center leading-[30px] self-center w-[20vw] text-[1.389vw] font-medium tracking-[0.15px]">
              {`Out of ${totatCandidates} candidates, you have selected ${checkedFilteredData}. Do you want to continue?`}
            </div>
          </div>
          <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />
            <CustomButton text={"Yes"} onClick={() => handleCallApi()} />
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalDeleteQuestionPopup = ({
  onClose,
  onConfirm,
  rowToDelete,
}) => {
  return (
    <>
      <div className="fixed inset-0 flex z-50 justify-center">
        <div className="fill-available  h-[100%]" onClick={onClose}></div>
        <div className="w-[38.792vw]  bg-white rounded-lg p-8 shadow-md add-member-animation-top ">
          <div className="flex justify-between mb-8">
            <div className="ml-[100px] text-center leading-[30px] self-center w-[20vw] text-[1.389vw] font-medium tracking-[0.15px]">
              Are you sure you want to delete?
              {rowToDelete}
            </div>
          </div>
          <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />
            <CustomButton
              text={"Yes"}
              onClick={() => {
                onConfirm();
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalViewJobRole = ({ isOpen, onClose }) => {
  const handleClickOutsideModal = (e) => {
    if (
      e.target.classList.contains("backdrop-blur") &&
      !e.target.closest("img")
    ) {
      onClose();
    }
  };
  return (
    <>
      {isOpen && (
        <div
          className="border-3 border-[#A6AEBA] fixed inset-0 flex justify-center items-center z-50 backdrop-blur"
          onClick={handleClickOutsideModal}
        >
          <img
            src={resume}
            alt="resume job role"
            className="h-[90vh] border-2 "
          />
        </div>
      )}
    </>
  );
};

export const ModalDeleteCampaign = ({ isOpen, deleteUid, onClose }) => {
  const modalStyles = {
    transition: "transform 3s ease-out", // Reduced duration for quicker transition
    transform: `translateY(${isOpen ? "0" : "-100%"})`,
  };

  const deleteCamp = async () => {
    console.log("in modal", deleteUid);
    const reqData = {
      uid: deleteUid,
      is_deleted: 1,
    };
    const response = await handleApiResponse(
      "/api/v1/interview/add_campaign/",
      reqData,
      1
    );
    console.log("response", response);
    if (response?.data?.status_code === 200) {
      toast.success("Campaign Deleted");
    } else {
      toast.error("Something went wrong, try again");
    }
    onClose();
  };
  return (
    <>
      <div className="fixed inset-0 flex justify-center items-center z-10  ">
        <div
          className="absolute w-[100vw] z-30 h-[100vh] "
          onClick={onClose}
        ></div>
        <div
          className="w-[38.792vw] h-[14vw] z-50 bg-white rounded-lg p-8 shadow-md add-member-animation-top "
          style={modalStyles}
        >
          <img
            src={closeButton}
            alt="close button"
            onClick={onClose}
            className=" absolute right-5 top-3 cursor-pointer w-[40px] h-[40px]"
          />

          <div className="flex items-center justify-center mb-8 fill-available text-[1.389vw]">
            <div className=" text-center w-[20vw]  font-medium ">
              Are you sure you want to delete this campaign?
            </div>
          </div>

          <div className="flex justify-center gap-4">
            {" "}
            {/* Removed unnecessary 'self-center' class */}
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />{" "}
            {/* Close modal on cancel */}
            <CustomButton text={"Yes"} onClick={deleteCamp} />
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalChangeCampaign = ({
  isOpen,
  deleteUid,
  onClose,
  handleYesClick,
}) => {
  const modalStyles = {
    transition: "transform 3s ease-out", // Reduced duration for quicker transition
    transform: `translateY(${isOpen ? "0" : "-100%"})`,
  };

  return (
    <>
      <div className="fixed inset-0 flex justify-center items-center z-10  ">
        <div
          className="absolute w-[100vw] z-30 h-[100vh] "
          onClick={onClose}
        ></div>
        <div
          className="w-[38.792vw] h-[14vw] z-50 bg-white rounded-lg p-8 shadow-md add-member-animation-top "
          style={modalStyles}
        >
          <img
            src={closeButton}
            alt="close button"
            onClick={onClose}
            className=" absolute right-5 top-3 cursor-pointer w-[40px] h-[40px]"
          />

          <div className="flex items-center justify-center mb-8 fill-available text-[1.389vw]">
            <div className=" text-center w-[20vw]  font-medium ">
              Editing this will change the Question Builder of your existing
              campaign. Do you still want to proceed?
            </div>
          </div>

          <div className="flex justify-center gap-4">
            {" "}
            {/* Removed unnecessary 'self-center' class */}
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />{" "}
            {/* Close modal on cancel */}
            <CustomButton type="button" text={"Yes"} onClick={handleYesClick} />
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalVerifyEmail = ({ setVerified, setShowEmailVerification }) => {
  const [otp, setOtp] = useState("");

  const handleOtpSubmit = () => {
    setShowEmailVerification(false);
  };

  return (
    <div className="w-[40%] h-[30%] p-12 bg-fill-color-code1 z-10 flex gap-4 flex-col justify-around items-center shadow-md ">
      <p className="text-center text-text-color-code1">
        We have sent you the OTP on your provided email. If it doesn't appear
        within a few minutes, check your spam folder.
      </p>
      <input
        className="p-2 border-2"
        type="text"
        required
        name="opt"
        onChange={(e) => setOtp(e.target.value)}
      />
      <button className="p-2 bg-fill-btn-color-code1" onClick={handleOtpSubmit}>
        <NavLink to={"/admin/dashboard"}>Submit</NavLink>
      </button>
    </div>
  );
};

export const ModalForgetPassword = ({
  setShowForgetPassword,
  setShowCheckOTP,
  email,
  setEmail,
  handleChange,
}) => {
  const [error, setError] = useState("");

  const sendOTP = async () => {
    if (!validateEmail(email)) {
      setError("Please enter the vaild email address");
      return;
    }
    const reqData = { email: email , otp_type : 2 };
    const response = await handleApiResponse("/api/v1/generateotp/", reqData);

    if (response?.status_code === 200) {
      setShowForgetPassword(false);
      setEmail({ email: email });
      console.log("email sent reponse : ", response.data);
      setShowCheckOTP(true);
    } else {
      console.log(response?.msg);
      setError(response?.msg);
    }
  };

  return (
    <div className="absolute w-[100vw] bg-white z-10 h-[100vh] flex justify-center items-center  text-text-color-code1">
      <span className=" relative w-[32rem] bg-fill-color-code1   h-[22rem] border-2 flex flex-col justify-center items-center p-12 py-6 gap-8">
        <img
          className="absolute top-2 right-2 cursor-pointer"
          src={closeButton}
          alt="close"
          onClick={() => {
            setShowForgetPassword(false);
          }}
        />
        <h1 className="text-xl">Get back into your account</h1>
        <h2 className="text-xl">Who are you?</h2>
        <p className="text-xs">
          To recover your account, enter your email address below.
        </p>
        <input
          className="p-2 border-2 outline-none w-[22rem]"
          type="email"
          placeholder="Email"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <div className="text-red-500">{error}</div>
        <button className="bg-fill-btn-color-code1 p-2 px-4" onClick={sendOTP}>
          Next
        </button>
      </span>
    </div>
  );
};

export const ModalCheckOTP = ({
  setShowCheckOTP,
  email,
  setShowSetPassword,
}) => {
  return (
    <div className="absolute bg-white w-[100vw]  z-10 h-[100vh] flex justify-center items-center  text-text-color-code1">
      <OtpValidation
        setShowSetPassword={setShowSetPassword}
        setShowCheckOTP={setShowCheckOTP}
        email={email}
      />
    </div>
  );
};

export const ModalSetPassword = ({ setShowSetPassword, setEmail }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [conpasswordError, setConPasswordError] = useState("");
  const [errors, setErrors] = useState({
    minLength: true,
    uppercase: true,
    lowercase: true,
    number: true,
    specialChar: true,
  });

  const sendPass = async () => {
    console.log("-----------------");
    setPasswordError("");
    setConPasswordError("");
    setError("");
    if (!password && !cpassword) {
      setPasswordError("Please enter the password");
      setConPasswordError("Please enter the confirm password");
      return;
    }
    if (!cpassword) {
      setConPasswordError("Please enter the confirm password");
      return;
    }
    if (password !== cpassword) {
      setError("Passwords doesn't match");
      return;
    } else {
      const reqData = {
        password: password,
        cpassword: cpassword,
      };
      const AuthTkn = sessionStorage.getItem("accesTKN");
      console.log(AuthTkn);
      const response = await axios.post(
        `${base_url}/api/v1/reset_password/`,
        reqData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AuthTkn}`,
          },
        }
      );
      if (response.status === 200) {
        setShowSetPassword(false);
        toast.success("Password reset successfully!");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPassVisibility = () => {
    setShowConPassword(!showConPassword);
  };

  const validatePassword = (password) => {
    const newErrors = {
      minLength: password.length < 8,
      uppercase: !/[A-Z]/.test(password),
      lowercase: !/[a-z]/.test(password),
      number: !/[0-9]/.test(password),
      specialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const hasErrors = () => {
    return Object.values(errors).some((error) => error);
  };

  return (
    <div className="absolute bg-white w-[100vw]  z-10 h-[100vh] flex justify-center items-center  text-text-color-code1">
      <span className=" relative w-[32rem] mt-5 bg-fill-color-code1  border-2 flex flex-col justify-center items-center p-12 py-6 gap-8">
        <div className="absolute top-2 right-5 cursor-pointer">
          <img
            src={closeButton}
            alt="close"
            onClick={() => setShowSetPassword(false)}
          />
        </div>
        <h1 className="text-xl">Set your new Password</h1>
        <div>
          <div className="relative w-[22rem]">
            <input
              className="p-2 border-2 outline-none w-full rounded-lg pr-10"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onChange={handleChange}
            />
            <button
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 h-full flex items-center px-2"
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          <div className="mt-2 text-red-500">
            {errors.minLength && (
              <div>Password must be at least 8 characters long.</div>
            )}
            {errors.uppercase && <div>At least one uppercase letter.</div>}
            {errors.lowercase && <div>At least one lowercase letter.</div>}
            {errors.number && <div>At least one number.</div>}
            {errors.specialChar && <div>At least one special character.</div>}
          </div>
        </div>
        {passwordError && <p className="text-red-500">{passwordError}</p>}
        <div className="relative w-[22rem]">
          <input
            className="p-2 border-2 outline-none w-[22rem]"
            type={showConPassword ? "text" : "password"}
            placeholder="Re-Enter Password"
            onChange={(e) => {
              setCPassword(e.target.value);
            }}
          />
          <button
            onClick={toggleConfirmPassVisibility}
            className="absolute inset-y-0 right-0 h-full flex items-center px-2"
          >
            {showConPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>
        {conpasswordError && <p className="text-red-500">{conpasswordError}</p>}

        {error && <p className="text-red-500">{error}</p>}

        <button
          className={`bg-fill-btn-color-code1 p-2 px-4 ${
            hasErrors() ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={sendPass}
          disabled={hasErrors()}
        >
          Set Password
        </button>
      </span>
    </div>
  );
};

export const ModalImportMembers = ({
  onClose,
  onOpen,
  excelData,
  setExcelData,
}) => {
  const [error, setError] = useState("");
  const [memberFile, setMemberFile] = useState(null);
  const [forceRender, setForceRender] = useState(false);
  const handleDropMember = (e) => {
    e.preventDefault();
    const permissible = [".xlsx", ".xls"];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setMemberFile(files[0]);
      handleUpload(files[0]);
    }
  };

  const handleUploadMember = (e) => {
    setError("");
    const file = e.target.files[0];
    if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
      handleUpload(file);
    } else {
      setError("File not supported");
    }
  };

  const handleRemoveResume = () => {
    setExcelData([]);
    const currentFileName = memberFile ? memberFile.name : null;
    setMemberFile(null);
    const fileInput = document.getElementById("member-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    const handleUploadMemberAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setMemberFile(file);
      } else {
        setMemberFile(file);
      }
      handleUpload(file);
    };
    fileInput.addEventListener("change", handleUploadMemberAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  const handleUpload = (file) => {
    const reader = new FileReader();
    const requiredHeaders = [
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Department",
    ];
    const columnsToExtract = [
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Department",
    ];
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const FirstRow = parsedData[0];

      const isValidFormat = requiredHeaders.every(
        (header, index) => FirstRow[index] === header
      );

      if (isValidFormat) {
        const dataRows = parsedData.slice(1);
        const finalData = dataRows.filter((ele) => {
          if (ele.length !== 0) {
            return ele;
          }
        });

        const isDataValid = finalData.every((row) =>
          columnsToExtract.every(
            (column) =>
              row[FirstRow.indexOf(column)] !== undefined &&
              row[FirstRow.indexOf(column)] !== ""
          )
        );

        console.log("is valid data ", isDataValid);
        if (isDataValid) {
          setMemberFile(file);
          setExcelData(parsedData);
          setError("");
        } else {
          setExcelData([]);
          setMemberFile(null);
          setError("Missing data in required columns");
        }
      } else {
        setExcelData([]);
        setMemberFile(null);
        setError("Wrong Format");
      }
    };

    reader.onerror = () => {
      setError("Error reading file");
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className=" z-10 top-[0rem] left-[0rem] absolute w-[100vw] h-[100vh] backdrop-blur flex flex-col justify-center items-center">
      <div
        className="bg-white border-2 p-16 flex flex-col items-center gap-3 w-[32rem] relative"
        onDrop={handleDropMember}
        onDragOver={(e) => e.preventDefault()}
      >
        <img
          className="absolute top-3 right-3 cursor-pointer"
          src={closeButton}
          alt="close"
          onClick={() => {
            onClose();
            setExcelData([]);
          }}
        />
        <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
        <p>Drag or drop Member list here (xlsx, xls)</p>
        <label
          htmlFor="member-upload"
          className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
        >
          Browse
          <input
            type="file"
            id="member-upload"
            className="hidden"
            onChange={handleUploadMember}
          />
        </label>
        {error && <p className="text-red-500">{error}</p>}
        <div
          className={`bg-fill-btn-color-code1 p-4 rounded-lg flex gap-4 justify-between items-center ${
            memberFile ? "block" : "hidden"
          }`}
        >
          <p>
            File Name:{" "}
            {memberFile?.name.length > 10
              ? memberFile?.name.slice(0, 10) + "..."
              : memberFile?.name || ""}
          </p>
          <p
            onClick={handleRemoveResume}
            className="border-2 p-1 cursor-pointer"
          >
            Close
          </p>
        </div>
      </div>
      {excelData.length !== 0 && (
        <p
          onClick={() => {
            onOpen();
            onClose();
          }}
          className=" p-2 w-[32rem] text-center bg-fill-btn-color-code1 cursor-pointer"
        >
          Next
        </p>
      )}
    </div>
  );
};

export const ModalAddHrQuestions = ({
  onClose,
  onOpen,
  excelData,
  setExcelData,
}) => {
  const [error, setError] = useState("");
  const [memberFile, setMemberFile] = useState(null);
  const [forceRender, setForceRender] = useState(false);
  const handleDropMember = (e) => {
    e.preventDefault();
    const permissible = [".xlsx", ".xls"];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setMemberFile(files[0]);
      handleUpload(files[0]);
    }
  };

  const handleUploadMember = (e) => {
    setError("");
    const file = e.target.files[0];
    if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
      handleUpload(file);
    } else {
      setError("File not supported");
    }
  };

  const handleRemoveResume = () => {
    setExcelData([]);
    const currentFileName = memberFile ? memberFile.name : null;
    setMemberFile(null);
    const fileInput = document.getElementById("member-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    const handleUploadMemberAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setMemberFile(file);
      } else {
        setMemberFile(file);
      }
      handleUpload(file);
    };
    fileInput.addEventListener("change", handleUploadMemberAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  const handleUpload = (file) => {
    const reader = new FileReader();
    const requiredHeaders = ["Question", "Answer"];
    const columnsToExtract = ["Quesiton", "Answer"];
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const FirstRow = parsedData[0];

      const isValidFormat = requiredHeaders.every((header, index) => {
        console.log(FirstRow[index], header);
        return FirstRow[index] === header;
      });
      console.log("->>>", isValidFormat);

      if (isValidFormat) {
        const dataRows = parsedData.slice(1);
        const finalData = dataRows.filter((ele) => {
          if (ele.length !== 0) {
            return ele;
          }
        });

        const isDataValid = finalData.every((row) =>
          columnsToExtract.every((column) => {
            console.log("__==__==", column);
            return true;
            // s
          })
        );

        console.log("is valid data ", parsedData.slice(1));
        if (isDataValid) {
          setMemberFile(file);
          const DataWithoutHeader = parsedData.slice(1);
          setExcelData(DataWithoutHeader);
          setError("");
        } else {
          setExcelData([]);
          setMemberFile(null);
          setError("Missing data in required columns");
        }
      } else {
        setExcelData([]);
        setMemberFile(null);
        setError("Wrong Format");
      }
    };

    reader.onerror = () => {
      setError("Error reading file");
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className=" z-10 top-[0rem] left-[0rem] absolute w-[100vw] h-[100vh] backdrop-blur flex flex-col justify-center items-center">
      <div
        className="bg-white border-2 p-16 flex flex-col items-center gap-3 w-[32rem] relative"
        onDrop={handleDropMember}
        onDragOver={(e) => e.preventDefault()}
      >
        <img
          className="absolute top-3 right-3 cursor-pointer"
          src={closeButton}
          alt="close"
          onClick={() => {
            onClose();
            setExcelData([]);
          }}
        />
        <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
        <p>Drag or drop Member list here (xlsx, xls)</p>
        <label
          htmlFor="member-upload"
          className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
        >
          Browse
          <input
            type="file"
            id="member-upload"
            className="hidden"
            onChange={handleUploadMember}
          />
        </label>
        {error && <p className="text-red-500">{error}</p>}
        <div
          className={`bg-fill-btn-color-code1 p-4 rounded-lg flex gap-4 justify-between items-center ${
            memberFile ? "block" : "hidden"
          }`}
        >
          <p>
            File Name:{" "}
            {memberFile?.name.length > 10
              ? memberFile?.name.slice(0, 10) + "..."
              : memberFile?.name || ""}
          </p>
          <p
            onClick={handleRemoveResume}
            className="border-2 p-1 cursor-pointer"
          >
            Close
          </p>
        </div>
      </div>
      {excelData.length !== 0 && (
        <p
          onClick={() => {
            onOpen();
            onClose();
          }}
          className=" p-2 w-[32rem] text-center bg-fill-btn-color-code1 cursor-pointer"
        >
          Next
        </p>
      )}
    </div>
  );
};

export const ModalAddingBulkMembers = ({ onClose, excelData, closeModal }) => {
  const [openLoader, setOpenLoader] = useState(true);
  const [errorFromAPI, setErrorFromAPI] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const toastIdRef = useRef(null);

  const handleAdd = async (
    first,
    last,
    email,
    designation,
    organization,
    dept
  ) => {
    const reqData = {
      first_name: first,
      last_name: last,
      email: email.replace(/\s+/g, ""),
      designation: designation.replace(/\s+/g, ""),
      is_resume_upload_allow: true,
      organization: organization.replace(/\s+/g, ""),
    };

    if (dept) {
      reqData["department"] = dept.replace(/\s+/g, "");
    }

    try {
      const response = await handleApiResponse(
        "/api/v1/auth/generate_member_link/",
        reqData,
        1
      );
      if (response?.status === 200) {
        if (response?.data?.status_code === 200) {
          setSuccessCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === 50) {
              toast.success("User Added");
              setSuccessCount(0);
            }
            return newCount;
          });
          setOpenLoader(false);
        } else {
          const errorMessage = response?.data?.msg
            ?.split(":")[0]
            .includes("duplicate")
            ? "User already exists"
            : response.data.msg;
          setErrorFromAPI((prev) => [
            ...prev,
            [email.replace(/\s+/g, ""), errorMessage],
          ]);
          setOpenLoader(false);
        }
      } else {
        setOpenLoader(false);
      }
    } catch (error) {
      setOpenLoader(false);
      console.error("API call failed", error);
      setErrorFromAPI((prev) => [
        ...prev,
        [email, "Couldn't connect to the server"],
      ]);
    }
  };

  const processExcelData = async () => {
    const organization = sessionStorage.getItem("organization");
    for (let i = 1; i < excelData.length; i++) {
      const [first, last, email, designation, department] = excelData[i];
      await handleAdd(
        first,
        last,
        email,
        designation,
        organization,
        department
      );
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    if (excelData) {
      processExcelData();
    }
  }, [excelData]);

  useEffect(() => {
    console.log("useEffect triggered with:", {
      errorFromAPI,
      excelData,
      successCount,
    });

    if (excelData.length - 1 === 0) {
      closeModal();
      toast.warn("Empty file uploaded.");
    } else if (successCount > 0) {
      const message =
        successCount === 1
          ? "1 Member added successfully."
          : `${successCount} Members Added Successfully`;

      if (toastIdRef.current === null) {
        toastIdRef.current = toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.update(toastIdRef.current, {
          render: message,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [errorFromAPI, excelData, successCount]);

  return (
    <div className="z-10 top-0 left-0 absolute w-full h-full backdrop-blur flex flex-col justify-center items-center">
      {openLoader && <Loader />}
      {errorFromAPI.length !== 0 && (
        <div className="flex flex-col mt-4 p-4 gap-2 justify-between border-2 bg-white w-3/4 items-center h-3/4">
          <p className="text-red-500 text-2xl">
            Encountered Error in Adding Following users
          </p>
          <div className="w-full h-full overflow-auto">
            {errorFromAPI.map((ele, index) => (
              <div key={index} className="flex text-red-500 mt-4 gap-2">
                <span>{ele[0]}</span>
                <span>-</span>
                <span>{ele[1]}</span>
              </div>
            ))}
          </div>
          <button
            className="p-1 border-2 w-32 bg-fill-btn-color-code1"
            onClick={() => {
              onClose();
              setErrorFromAPI([]);
            }}
          >
            Finish
          </button>
        </div>
      )}
      {errorFromAPI.length == 0 && (
        <div className="flex flex-col mt-4 p-4 gap-2 justify-between border-2 bg-white w-[32rem] items-center h-[8rem]">
          {excelData.length - 1 !== 0 && (
            <p className="text-green-500 text-2xl">
              {successCount} Members Added Successfully
            </p>
          )}

          <button
            className="p-1 border-2 w-32 bg-fill-btn-color-code1"
            onClick={onClose}
          >
            Finish
          </button>
        </div>
      )}
    </div>
  );
};

export const ModalPopup = ({ message, onConfirm }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <button onClick={onConfirm}>OK</button>
      </div>
    </div>
  );
};
