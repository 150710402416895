import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { closeButton } from '../../assets/imgs';

const JobRolePage = () => {
  const location = useLocation();
  const { state } = location;
  const { fileUrl, jdUrl } = state || {};
  const pdfUrl = fileUrl || jdUrl;
  const navigate = useNavigate();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const handleFetchError = (error) => {
    console.error('Failed to fetch PDF:', error);
  };

  return (
    <div
      className="flex flex-end flex-col justify-end"
      style={{ backgroundColor: '#fff', padding: '20px', height: '100vh', overflow: 'auto' }}
    >
      <img
        src={closeButton}
        className="w-[40px] justify-end cursor-pointer"
        onClick={() => navigate(-1)}
      />
      {pdfUrl ? (
        pdfUrl.endsWith('.pdf') ? (
          <Worker
            workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
            onError={handleFetchError}
          >
            <div style={{ flex: 1, overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '90%', maxWidth: '1600px' }}>
                <Viewer fileUrl={pdfUrl} defaultScale={1.5} plugins={[zoomPluginInstance]} />
              </div>
            </div>
          </Worker>
        ) : pdfUrl.endsWith('.docx') ? (
          <iframe
            src={`https://docs.google.com/gview?url=${pdfUrl}&embedded=true`}
            style={{ width: '100%', height: '100vh', border: 'none' }}
            title="DOCX Viewer"
          />
        ) : (
          <div>Unsupported file format</div>
        )
      ) : (
        <div>No document available</div>
      )}
    </div>
  );
};

export default JobRolePage;
