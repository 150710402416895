export function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export function validateName(name) {
  return true;
}

export function validateDesignation(name) {
  const nameRegex = /^[a-zA-Z0-9\s]*$/;
  return nameRegex.test(name);
}

export function concatenateArgs(companyName, id) {
  const processedName =
    companyName?.length > 4
      ? companyName?.slice(0, 4).toUpperCase()
      : companyName?.toUpperCase();
  return `${processedName}00${id}`;
}


// export function validatePassword (password) => {
//   let errorMessage = "Password must be at least 8 characters long.\nAt least one uppercase letter.\nAt least one lowercase letter.\nAt least one number.\nAt least one special character.";

//   // Check for minimum length
//   if (password?.length >= 8) {
//     errorMessage = errorMessage.replace("Password must be at least 8 characters long.\n", "");
//   }

//   // Check for uppercase letter
//   if (/[A-Z]/.test(password)) {
//     errorMessage = errorMessage.replace("At least one uppercase letter.\n", "");
//   }

//   // Check for lowercase letter
//   if (/[a-z]/.test(password)) {
//     errorMessage = errorMessage.replace("At least one lowercase letter.\n", "");
//   }

//   // Check for number
//   if (/\d/.test(password)) {
//     errorMessage = errorMessage.replace("At least one number.\n", "");
//   }

//   // Check for special character
//   if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
//     errorMessage = errorMessage.replace("At least one special character.", "");
//   }

//   setErrors((prevError) => ({
//     ...prevError,
//     password: errorMessage.trim() ? errorMessage : "",
//   }));

//   return !errorMessage.trim(); // Return true if no errors
// };
export function validatePassword(password) {
  let errorMessage = '';
  let isValid = true;

  // Check for minimum length
  if (password?.length < 8) {
    errorMessage += 'Password must be at least 8 characters long.\n';
    isValid = false;
  }

  // Check for uppercase letter
  if (!/[A-Z]/.test(password)) {
    errorMessage += 'At least one uppercase letter.\n';
    isValid = false;
  }

  // Check for lowercase letter
  if (!/[a-z]/.test(password)) {
    errorMessage += 'At least one lowercase letter.\n';
    isValid = false;
  }

  // Check for number
  if (!/\d/.test(password)) {
    errorMessage += 'At least one number.\n';
    isValid = false;
  }

  // Check for special character
  if (!/[\W_]/.test(password)) {
    errorMessage += 'At least one special character.\n';
    isValid = false;
  }

  return {
    status: isValid,
    msg: isValid ? 'ok' : errorMessage.trim(), // Remove trailing new line if present
  };
}

