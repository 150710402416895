export const ScoreCard = (props) => {
  return (
    <div className="p-2 flex flex-col gap-[2rem] w-[19.167vw] items-center bg-white border-[0.05rem] rounded-lg px-8 py-4 shadow-custom">
      <p className="text-[1.111vw] font-medium ">{props.text}</p>
      <p className="text-5xl font-normal text-[4.167vw] font-anton">
        {props.number}
      </p>
    </div>
  );
};
