import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { logo, signature } from "../../assets/imgs";
import { useLocation } from "react-router-dom";

const Certificatepage = () => {
  const location = useLocation();
  const firstName = sessionStorage.getItem("candFName");
  const lastName = sessionStorage.getItem("candLName");
  const warningCount = sessionStorage.getItem("wc");
  const on_face_detect = sessionStorage.getItem("no_face_detect_count");
  const networkError = sessionStorage.getItem("networkError");

  const first_name = useSelector(
    (state) => state?.certificateData?.data?.first_name
  );
  const last_name = useSelector(
    (state) => state?.certificateData?.data?.last_name
  );
  const job_role = useSelector(
    (state) => state?.certificateData?.data?.campign_info?.jr_name
  );

  const date = useSelector((state) => state?.certificateData.data.created_date);

  const [formattedDateTime, setFormattedDateTime] = useState("");

  useEffect(() => {
    sessionStorage.removeItem("newExp");
    sessionStorage.removeItem("newExpMonths");
    sessionStorage.removeItem("file");
  });
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${currentDate.getFullYear()}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    setFormattedDateTime(`${formattedDate} - ${formattedTime} IST`);
  }, []);

  return (
    <>
      <div className="w-full max-w-2xl mx-auto p-8 border-4 border-gray-300 rounded-lg bg-white text-center">
        <div className="mb-6">
          <img
            src={logo}
            alt="Cloud Academy Logo"
            className="w-60 mx-auto mb-2"
          />
          <h2 className="text-3xl text-blue-600 font-semibold mb-4">
            {first_name ? first_name : firstName}{" "}
            {last_name ? last_name : lastName}
          </h2>
          <h1 className="text-4xl font-bold">GOT INTERVIEWED BY AI</h1>
          {/* <p className="text-xl">OF ACHIEVEMENT</p> */}
        </div>
        {/* <h2 className="text-3xl text-blue-600 font-semibold mb-4">
  Josephine Reyes
</h2> */}
        <p className="text-lg">for {job_role}</p>
        {/* <h3 className="text-2xl font-bold my-4">
  The Infrastructure Developer's Guide to Terraform: GCP Edition
</h3> */}
        {/* <p className="text-md mb-4">
  Certificate: 21793592
</p> */}

        <div className="flex justify-between items-center mt-24">
          <p className="text-md">{formattedDateTime}</p>
          <div className="text-center flex flex-col justify-center">
            <img
              src={signature}
              alt="Cloud Academy Logo"
              className="w-48 mx-auto mb-2"
            />
            <p className="text-md">Authorized</p>
            <p className="text-sm">Signature</p>
          </div>
        </div>
      </div>

      {/* {warningCount >= "5" ||
        (on_face_detect >= "3" && (
          <div className="fixed top-12 text-2xl text-center left-1/2 transform -translate-x-1/2 bg-red-500 text-white p-4 rounded shadow-lg z-50">
            Suspicious Activity Encounter ! The interview has ended. For further
            questions, please connect with your HR.
          </div>
        ))} */}
    </>
  );
};

export default Certificatepage;
