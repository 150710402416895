import React, { useEffect, useState } from "react";
import { AnalyticsPageBtn } from "../../components/buttons";
import { Table } from "../../components/AdminComponents/table";
import { deleteIcon, editIcon, userImg } from "../../assets/imgs/index";
import {
  Modal,
  Modal1,
  Modal2,
  ModalAddingBulkMembers,
  ModalImportMembers,
} from "../../components/AdminComponents/modal";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
//import axios from "axios";
import { handleApiResponse } from "../../API/services";
import { toast } from "react-toastify";
import VideoPlayer from "../../utils/VideoPlayer";

function Members() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [noPermission, setNoPermission] = useState();
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    setNoPermission(sessionStorage.getItem("notPermited"));
  }, []);

  //const base_url = 'http://65.0.139.208:8000';
  const [openImport, setOpenImport] = useState(false);
  const itemsPerPage = 6;
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const [openProcess, setOpenProcess] = useState(false);
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      limit: userLimit,
      page: pageNo,
      is_deleted: 0,
    };
    const response = await handleApiResponse("/api/v1/userlist/", reqData, 1);
    console.log(response);
    if (response.data.status_code === 200) {
      //console.log("user displayed", Object.values(response?.data?.data).flat())
      let dataToFilter = Object.values(response?.data?.data)?.flat()?.reverse();
      dataToFilter = dataToFilter.filter((ele) => {
        console.log(ele.user_type);
        if (ele.user_type !== 2) {
          return ele;
        }
      });
      console.log(dataToFilter);
      setTableData(dataToFilter);
    }
  };

  useEffect(() => {
    getUser(itemsPerPage, pageNo);
  }, [
    pageNo,
    isModalOpen1,
    openDeleteConfirmation,
    openEdit,
    openImport,
    openProcess,
  ]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [uuid, setUuid] = useState("");
  const setuuid = (uuid) => {
    setUuid(uuid);
  };
  const checkUid = () => {
    if (uuid.length) {
      setOpenDeleteConfirmation(true);
      console.log("open");
    }
  };
  useEffect(() => {
    checkUid();
    console.log(uuid);
  }, [uuid]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal1 = () => {
    setIsModalOpen1(true);
  };
  const closeModalAdd = () => {
    setIsModalOpen1(false);
  };
  const closeModal1 = () => {
    setOpenEdit(false);
  };

  const closeModal2 = () => {
    if (openDeleteConfirmation) {
      setOpenDeleteConfirmation(false);
      console.log("close");
    }
  };
  useEffect(() => {
    console.log("no permission ", noPermission);
  }, [noPermission]);

  const openImportPopup = () => {
    setOpenImport(true);
  };

  const closeImportMember = () => {
    setOpenImport(false);
  };

  const openProcessing = () => {
    if(excelData.length - 1 === 0){
      closeProcessing();
      toast.warn("Empty file uploaded.")
    }
    else{
      setOpenProcess(true);
    }
  };
  const closeProcessing = () => {
    setOpenProcess(false);
    setExcelData([]);
  };

  const generateExcelFile = () => {
    const requiredHeaders = [
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Department",
    ];

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    // Append the headers
    XLSX.utils.sheet_add_aoa(ws, [requiredHeaders], { origin: "A1" });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and trigger download
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "members_template.xlsx");
  };

  return (
    <div className="text-[#A6AEBA] ml-[1.5rem] mr-[1.5rem] gap-16 flex flex-col">
      {openDeleteConfirmation && (
        <Modal2 uid={uuid} onClose={closeModal2} setUuid={setUuid} />
      )}

      {openEdit ? <Modal editData={editData} onClose={closeModal1} /> : null}

      {openImport && (
        <ModalImportMembers
          onClose={closeImportMember}
          onOpen={openProcessing}
          excelData={excelData}
          setExcelData={setExcelData}
        />
      )}
      {openProcess && (
        <ModalAddingBulkMembers
          onClose={closeProcessing}
          excelData={excelData}
          closeModal={closeImportMember}
        />
      )}

      <div className="flex justify-between" onClick={closeModal2}>
        <div className="font-semibold text-[1.25vw] mb-[1rem]">Members</div>
        {noPermission === "false" && (
          <div className="flex gap-2">
            <AnalyticsPageBtn
              name={"Download Format"}
              img={4}
              onClick={generateExcelFile}
            />
            <AnalyticsPageBtn
              name={"Import Member"}
              img={4}
              onClick={openImportPopup}
            />
            <AnalyticsPageBtn
              name={"Add Member"}
              img={5}
              onClick={openModal1}
            />
            <Modal1 isOpen={isModalOpen1} onClose={closeModalAdd} />
          </div>
        )}
      </div>

      <div>
        <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-[#A6AEBA] text-left rtl:text-right">
            <thead class="text-[0.833vw] font-semibold text-[#A6AEBA] border-b h-[2px] border-[#E4E7EB]">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Email
                </th>
                <th scope="col" class="px-6 py-3">
                  Designation
                </th>
                <th scope="col" class="px-6 py-3">
                  Created On
                </th>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Number of Campaigns
                </th>
                {noPermission === "false" && (
                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((rowData, index) => (
                <Table
                  noPermission={noPermission}
                  key={index}
                  icon={userImg}
                  all={rowData}
                  name={rowData["first_name"]}
                  row12={rowData["email"]}
                  row13={rowData["designation"]}
                  row14={rowData["created_date"].split("T")[0]}
                  row15={rowData["status"] === 1 ? "Active" : "Inactive"}
                  editIcon={editIcon}
                  deleteIcon={deleteIcon}
                  openModal={openModal}
                  closeModal={closeModal}
                  isModalOpen={isModalOpen}
                  campaigns={rowData["created_campaigns"]}
                  uid={rowData["uid"]}
                  setuuid={setuuid}
                  isModalOpen2={isModalOpen2}
                  setIsModalOpen2={setIsModalOpen2}
                  setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                  setEditData={setEditData}
                  setOpenEdit={setOpenEdit}
                />
              ))}
            </tbody>
          </table>
          <div className="pagination flex justify-end mt-4">
            {[...Array(Math.ceil(tableData?.length / itemsPerPage)).keys()].map(
              (page) => (
                <button
                  key={page + 1}
                  onClick={() => handlePageChange(page + 1)}
                  className={`px-3 py-2 mx-1 border rounded-md ${
                    currentPage === page + 1
                      ? "bg-[#A6AEBA1A] text-[#A6AEBA]"
                      : "bg-white text-[#A6AEBA]"
                  }`}
                >
                  {page + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Members;
