import React, { useState, useEffect, useMemo, useRef } from "react";
import { cam, full } from "../assets/imgs";
import { Loader } from "../components/loader";
import { useSpeechRecognition } from "react-speech-recognition";

import { useNavigate } from "react-router-dom";
import { handleApiResponse } from "../API/services";
import { useParams } from "react-router-dom";

import { WhisperSTT } from "./WhisperSTT";
import { formatTime } from "../utils/formatTime";
import { useSelector } from "react-redux";
import { getLocal } from "../utils/localStorage";

const WebcamComponent = ({
  no_Face_detected,
  warningCount,
  setMessages,
  isListening,
  setIsListening,
  setSpeech,
  timerRunning,
  setTimerRunning,
  setSocket,
  timer,
  setTimer,
  isSpeaking,
  setIsSpeaking,
  socket,
  endInterviewCounter,
  setEndInterviewCounter,
  resetIdleCounter,
  setStarted,
  isStarted,
  setFirst,
  handleSocket,
  interviewTime,
  setInterviewTime,
  interviewTimeRun,
  setInterviewTimeRun,
  openEditor,
  videoRefElement,
  setLoader,
  loader,
  audioSource,
  exitquestion,
  questcounter,
  exit,
  first_name,
  first_name1,
  last_name,
  last_name1,
  recording,
  setRecording,
  loader1,
  setLoader1,
  face_detect,
  handleFaceDetect,
  setFaceSocket,
  faceSocket,
  setExitQuestion,
  setNext,
  setExit,
  endTimer,
  isEndTimer,
  setAudioSource,
  audio,
  coveringCTimer,
  setCover,
  setPauseAlerts,
}) => {
  const uid1 = sessionStorage.getItem("CandidateUid");
  const uid = getLocal("user");
  const campType = sessionStorage.getItem("campType");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const partNumberRef = useRef(1);
  const { browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [lastApiResponse, setLastApiResponse] = useState([]);
  const [showSurePopup, setShowSurePopup] = useState(false);
  const [videoOn, setVideoOn] = useState(true);
  const [videoStream, setVideoStream] = useState(null);
  const [isSure, setIsSure] = useState(false);
  const chunkIntervalRef = useRef(null);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [recordingBlob, setRecordingBlob] = useState(null);

  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const videoRef = useRef(null);
  const videoStreaming = useRef(null);
  const prevIsOnlineRef = useRef(isOnline);
  const [multiFaceDetected, setMultiFaceDetected] = useState(false);
  const [headDirection, setHeadDirection] = useState("");
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const disconnectTimeRef = useRef(null);
  const timeoutRef = useRef(null);
  const [startLoader, setIsStartLoader] = useState(false);
  const handleOnline = () => {
    setIsOnline(true);
    clearTimeout(timeoutRef.current);
    disconnectTimeRef.current = null;
  };

  const handleOffline = () => {
    setIsOnline(false);
    disconnectTimeRef.current = Date.now();
    timeoutRef.current = setTimeout(() => {
      const now = Date.now();
      if (
        disconnectTimeRef.current &&
        now - disconnectTimeRef.current >= 3 * 60 * 1000
      ) {
        sessionStorage.setItem("networkError", true);
        // alert('Network has been disconnected for 0.5 minute');
        // navigate("../certificate");
        endInterview();
      }
    }, 3 * 60 * 1000); //3 minutes
  };

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleRecordingComplete = (blob) => {
    setRecordingBlob(blob);
  };

  useEffect(() => {
    let interviewTimeInterval;
    if (interviewTimeRun) {
      interviewTimeInterval = setInterval(() => {
        setInterviewTime((prevTime) => {
          const newTime = prevTime + 1;
          sessionStorage.setItem("interviewTime", newTime);
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(interviewTimeInterval);
  }, [interviewTimeRun]);

  useEffect(() => {
    let timerInterval;
    if (timerRunning) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          sessionStorage.setItem("timer", newTimer);
          if (newTimer <= 0) {
            clearInterval(timerInterval);
          }
          return newTimer;
        });
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [timerRunning]);

  useEffect(() => {
    sessionStorage.setItem("interviewTimeRun", interviewTimeRun);
  }, [interviewTimeRun]);

  useEffect(() => {
    sessionStorage.setItem("timerRunning", timerRunning);
  }, [timerRunning]);

  //console.log("network status", isOnline ? "Online" : "Offline");

  const endInterview = async () => {
    setLoading(true);
    let networkError = sessionStorage.getItem("networkError");
    // await handleVideoSubmit();
    if (!loading) {
      if (
        warningCount >= "5" ||
        no_Face_detected >= "3" ||
        exit === "NO" ||
        endInterviewCounter ||
        isEndTimer ||
        endTimer <= 1 ||
        coveringCTimer <= 1 ||
        networkError
      ) {
        if (socket) {
          socket.close();
          console.log("Socket connection closed");
        }
        if (faceSocket) {
          faceSocket.close();
          console.log(" face Socket connection closed");
        }
        setAudioSource("");
        if (audio) {
          audio.pause();
        }
        setIsSpeaking(false);
        setTimerRunning(false);
        setInterviewTimeRun(false);
        setSocket(null);
        setFaceSocket(null);
        window.speechSynthesis.cancel();
        sessionStorage.removeItem("hasStartedRecording");
        const initialInterviewTime = 0;
        const initialTimer = 90;
        setInterviewTime(initialInterviewTime);
        setTimer(initialTimer);
        setTimerRunning(false);
        setInterviewTimeRun(false);
        sessionStorage.setItem("interviewTime", initialInterviewTime);
        sessionStorage.setItem("timer", initialTimer);
        sessionStorage.removeItem("showNext");
        sessionStorage.removeItem("endInterview");
        sessionStorage.removeItem("lastEndInterview");
        sessionStorage.removeItem("chatroomLoading");
        sessionStorage.removeItem("questionCounter");
        sessionStorage.removeItem("setIsEndTimer");
        sessionStorage.removeItem("setCoveringCamera");
        sessionStorage.removeItem("setcoveringCTimer");
        sessionStorage.removeItem("setcoverModel");
        setTimeout(() => {
          setLoading(false);
          navigate("../certificate", { state: { timer: formatTime(timer) } });
        }, 5000);
      }
    }
  };

  useEffect(() => {
    if (!isOnline) {
      setLoading(true);
      setTimerRunning(false);
      setInterviewTimeRun(false);
    } else {
      setLoading(false);
      const savedTimer = sessionStorage.getItem("timer");
      const savedTimer1 = sessionStorage.getItem("interviewTime");
      if (savedTimer !== null && parseInt(savedTimer, 10) > 0) {
        setTimer(parseInt(savedTimer, 10));
        setTimerRunning(true);
      }
      if (savedTimer1 !== null && parseInt(savedTimer1, 10) > 0) {
        setInterviewTime(parseInt(savedTimer1, 10));
        setInterviewTimeRun(true);
      }
    }

    // Check if the network status has changed from offline to online
    if (prevIsOnlineRef.current === false && isOnline === true) {
      window.location.reload();
    }

    // Update the ref with the current isOnline value
    prevIsOnlineRef.current = isOnline;
  }, [isOnline]);

  // const handleVideoSubmit = async () => {
  //   console.log('setting loading')
  //   setLoading(true);
  //   try {
  //     await handleStopRecording(async () => {
  //       try {
  //         const parts = lastApiResponse.map((response, index) => ({
  //           ETag: response.ETag,
  //           PartNumber: response.PartNumber,
  //         }));
  //         const formData = new FormData();
  //         formData.append(
  //           'candidate_uid',
  //           sessionStorage.getItem('CandidateUid')
  //         );
  //         formData.append('upload_id', sessionStorage.getItem('upload_id'));
  //         formData.append('is_complted', '1');
  //         formData.append('parts', JSON.stringify(parts));

  //         const response = await handleApiResponse(
  //           '/api/v1/interview/upload_video/',
  //           formData,
  //           0
  //         );

  //         if (response?.status_code === 200) {
  //           console.log("Recording sent successfully.");
  //           setLoading(false);
  //           setIsSpeaking(false);
  //           setTimerRunning(false);
  //           setInterviewTimeRun(false);
  //           sessionStorage.removeItem('CandidateUid');
  //           if (socket) {
  //             socket.close();
  //             console.log('Socket connection closed');
  //           }
  //           setSocket(null);
  //           window.speechSynthesis.cancel();
  //           console.log('here')
  //           navigate("../certificate", { state: { timer: formatTime(timer) } });
  //         } else {
  //           setLoading(false);
  //           console.error("Failed to send recording:", response);
  //         }
  //       } catch (error) {
  //         setLoading(false);
  //         console.error("Failed to submit video", error);
  //       }
  //     });
  //   } catch (error) {
  //     console.error('Error in handleStopRecording:', error);
  //   }
  // };

  const scheduleInterview = async () => {
    if (isStarted) {
      if (endInterviewCounter) {
        setStarted(!isStarted); //false
        sessionStorage.setItem("isStarted", !isStarted);
        endInterview();
        console.log("first");
      } else {
        setShowSurePopup(true);
      }
    } else {
      // start interview
      setInterviewTimeRun(true);
      setFirst(1);
      setStarted(!isStarted);
      sessionStorage.setItem("isStarted", !isStarted);
      handleSocket();
      handleFaceDetect(sendFrame);
    }
  };

  // let frameInterval;

  // const startSendingFrames = () => {
  //   frameInterval = setInterval(() => {
  //     sendFrame();
  //   }, 33.33);
  // };

  // const stopSendingFrames = () => {
  //   clearInterval(frameInterval);
  // };

  useEffect(() => {
    const isStarted = sessionStorage.getItem("isStarted") === "true";
    if (isStarted) {
      sendFrame();
      // startSendingFrames();
    }

    // return () => {
    //   stopSendingFrames();
    // };
  }, [interviewTime]);

  useEffect(() => {
    if (isSure) {
      setStarted(!isStarted);
      sessionStorage.setItem("isStarted", !isStarted);
      endInterview();
    }
  }, [isSure]);

  useEffect(() => {
    console.log("reason", no_Face_detected);
    if (warningCount >= "5" || no_Face_detected >= "3") {
      setTimeout(() => {
        setCover(false);
        endInterview();
      }, 3000);
    }
  }, [warningCount, no_Face_detected]);

  useEffect(() => {
    if (endTimer <= 1) {
      endInterview();
    }
  }, [endTimer]);

  useEffect(() => {
    if (coveringCTimer <= 1) {
      sessionStorage.setItem("coverCamera", true);
      endInterview();
    }
  }, [coveringCTimer]);

  useEffect(() => {
    let stream = null;
    if (
      videoOn &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    ) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((streamObj) => {
          setVideoStream(streamObj);
          stream = streamObj;
          setTimeout(() => {
            stream = streamObj;
            videoRef.current.srcObject = streamObj;
          }, 5000);
        })
        .catch((error) => {
          console.error("Error accessing webcam:", error);
        });
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        setVideoStream(null); // Clear the video stream state
      }
    };
  }, [videoOn]);

  const sendFrame = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef?.current?.videoWidth;
    canvas.height = videoRef?.current?.videoHeight;
    const ctx = canvas.getContext("2d");

    try {
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (blob) {
          faceSocket?.send(blob);
        }
      }, "image/jpeg");
    } catch (e) {
      return;
    }
  };

  const toggleVideo = () => {
    setVideoOn((prevVideoOn) => !prevVideoOn);
    setVideoStream(null);
  };

  // useEffect(() => {
  //   if (endInterviewCounter) {
  //     // handleVideoSubmit();
  //     endInterview();
  //   }
  // }, [endInterviewCounter]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const screenStreamRef = useRef(null);

  // const startScreenCapture = async () => {
  //   try {
  //     while (true) {
  //       try {
  //         const stream = await navigator.mediaDevices.getDisplayMedia({
  //           video: {
  //             cursor: "always",
  //           },
  //           audio: true,
  //         });

  //         const videoTrack = stream.getVideoTracks()[0];
  //         const settings = videoTrack.getSettings();

  //         if (settings.displaySurface === "monitor") {
  //           setError(null);
  //           setLoading(false);
  //           return stream;
  //         } else {
  //           stream.getTracks().forEach((track) => track.stop());
  //           setError("You must select the entire screen to share.");
  //           setLoading(true);
  //         }
  //       } catch (err) {
  //         if (
  //           err.name === "NotAllowedError" ||
  //           err.name === "PermissionDeniedError"
  //         ) {
  //           setError("Screen sharing was cancelled. Please try again.");
  //           setLoading(true);
  //         } else {
  //           console.error("Error accessing display media.", err);
  //           setError("Failed to start screen capture. Please try again.");
  //           setLoading(false);
  //           return null;
  //         }
  //       }
  //     }
  //   } catch (err) {
  //     console.error("Unexpected error.", err);
  //     setError("An unexpected error occurred. Please try again.");
  //     setLoading(false);
  //     return null;
  //   }
  // };

  // const startScreenCaptureAndRecording = async () => {
  //   setLoading(true);

  //   let stream = screenStreamRef.current;
  //   if (!stream) {
  //     stream = await startScreenCapture();
  //     if (stream) {
  //       screenStreamRef.current = stream;
  //     }
  //   }

  //   if (stream) {
  //     recordedChunksRef.current = [];
  //     mediaRecorderRef.current = new MediaRecorder(stream, {
  //       mimeType: "video/webm; codecs=vp9",
  //     });

  //     mediaRecorderRef.current.ondataavailable = (event) => {
  //       if (event.data.size > 0) {
  //         recordedChunksRef.current.push(event.data);
  //       }
  //     };

  //     mediaRecorderRef.current.onstop = () => {
  //       const blob = new Blob(recordedChunksRef.current, {
  //         type: "video/webm",
  //       });
  //       handleRecordingComplete(blob);
  //     };

  //     // Start recording immediately
  //     mediaRecorderRef.current.start();
  //     setRecording(true);
  //     setError(null);
  //     setLoading(false);
  //     sessionStorage.setItem("hasStartedRecording", "true");
  //   }
  // };

  // useEffect(() => {
  //   const hasStartedRecording = sessionStorage.getItem("hasStartedRecording");

  //   if (!hasStartedRecording) {
  //     startScreenCaptureAndRecording();
  //   }

  //   return () => {
  //     if (
  //       mediaRecorderRef.current &&
  //       mediaRecorderRef.current.state !== "inactive"
  //     ) {
  //       mediaRecorderRef.current.stop();
  //     }
  //     if (screenStreamRef.current) {
  //       screenStreamRef.current.getTracks().forEach((track) => track.stop());
  //     }
  //   };
  // }, []);

  const startScreenCaptureAndRecording = async () => {
    setLoading(true);
    setPauseAlerts(true);

    let stream = screenStreamRef.current;
    if (!stream) {
      stream = await startScreenCapture();
      if (stream) {
        screenStreamRef.current = stream;
      }
    }

    if (stream) {
      recordedChunksRef.current = [];
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm; codecs=vp9",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(recordedChunksRef.current, {
          type: "video/webm",
        });
        console.log("Generated Blob:", blob);
        handleRecordingComplete(blob);
        await sendChunkToAPI(blob);
        recordedChunksRef.current = [];
      };

      // Start recording immediately
      mediaRecorderRef.current.start();
      setRecording(true);
      setError(null);
      setLoading(false);
      setPauseAlerts(false);
      sessionStorage.setItem("hasStartedRecording", "true");

      // Set interval to stop and restart recording every 5 minutes (300000 milliseconds)
      chunkIntervalRef.current = setInterval(() => {
        if (mediaRecorderRef.current.state === "recording") {
          mediaRecorderRef.current.stop();
          // Start recording again after a short delay
          setTimeout(() => {
            mediaRecorderRef.current.start();
          }, 1000); // Adjust the delay as needed
        }
      }, 5000); // 5 seconds
    }
  };

  const startScreenCapture = async () => {
    let constraints = { video: { frameRate: { ideal: 8, max: 9 } } };

    try {
      while (true) {
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia({
            video: {
              cursor: "always",
            },
            audio: true, // Include audio
          });

          const videoTrack = stream.getVideoTracks()[0];
          videoTrack.applyConstraints(constraints).catch((e) => console.log(e));
          const settings = videoTrack.getSettings();

          if (settings.displaySurface === "monitor") {
            setError(null);
            setLoading(false);

            // Capture system audio if needed
            const audioStream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            const combinedStream = new MediaStream([
              ...stream.getVideoTracks(),
              ...audioStream.getAudioTracks(),
            ]);

            return combinedStream;
          } else {
            stream.getTracks().forEach((track) => track.stop());
            setError("You must select the entire screen to share.");
            setLoading(true);
          }
        } catch (err) {
          if (
            err.name === "NotAllowedError" ||
            err.name === "PermissionDeniedError"
          ) {
            setError("Screen sharing was cancelled. Please try again.");
            setLoading(true);
          } else {
            console.error("Error accessing display media.", err);
            setError("Failed to start screen capture. Please try again.");
            setLoading(false);
            return null;
          }
        }
      }
    } catch (err) {
      console.error("Unexpected error.", err);
      setError("An unexpected error occurred. Please try again.");
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    const hasStartedRecording = sessionStorage.getItem("hasStartedRecording");

    // if (!hasStartedRecording) {
    startScreenCaptureAndRecording();
    // }

    return () => {
      if (chunkIntervalRef.current) {
        clearInterval(chunkIntervalRef.current);
      }
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      if (screenStreamRef.current) {
        screenStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const handleStopRecording = (callback) => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(recordedChunksRef.current, {
          type: "video/webm",
        });
        setRecordingBlob(blob);
        await sendChunkToAPI(blob);
        if (typeof callback === "function") {
          callback();
        }
      };
      mediaRecorderRef.current.stop();
      setRecording(false);
      if (chunkIntervalRef.current) {
        clearInterval(chunkIntervalRef.current);
      }
    }
  };

  const sendChunkToAPI = async (blob) => {
    const formData = new FormData();
    formData.append("candidate_uid", sessionStorage.getItem("CandidateUid"));
    // formData.append("upload_id", sessionStorage.getItem("upload_id"));
    formData.append("chunk_number", partNumberRef.current);
    formData.append("chunk", blob, "chunk.webm");

    try {
      const response = await handleApiResponse(
        "/api/v1/interview/upload_video_2/",
        formData
      );
      if (response?.status_code === 200) {
        // setLastApiResponse((prevResponses) => [
        //   ...prevResponses,
        //   response?.data,
        // ]);
        partNumberRef.current += 1; // Increment part number after successful API call
      } else {
        console.error("Failed to send chunk to API:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending chunk to API:", error);
    }
  };

  const renderVideo = useMemo(() => {
    if (!videoStream) return null;
    return (
      <video
        className="w-full h-full object-cover"
        autoPlay={true}
        ref={(videoRefElement) => {
          if (videoRefElement) {
            videoRefElement.srcObject = videoStream;
            videoRef.current = videoRefElement;
          }
        }}
      />
    );
  }, [videoStream]);

  useEffect(() => {
    const screenValue = sessionStorage.getItem("Screen");
    if (screenValue !== "false") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  // const handleStopRecording = (callback) => {
  //   if (
  //     mediaRecorderRef.current &&
  //     mediaRecorderRef.current.state !== "inactive"
  //   ) {
  //     mediaRecorderRef.current.onstop = () => {
  //       const blob = new Blob(recordedChunksRef.current, {
  //         type: "video/webm",
  //       });
  //       setRecordingBlob(blob);
  //       if (typeof callback === "function") {
  //         callback();
  //       }
  //     };
  //     mediaRecorderRef.current.stop();
  //     setRecording(false);
  //   }
  // };

  return (
    <div className="relative">
      <div>
        {loading && <Loader />}
        {error && <p style={{ color: "red" }}>{error}</p>}
        {/* <div>
          {recording && (
            <button className="" onClick={handleStopRecording}>
              Stop Recording
            </button>
          )}
        </div> */}
      </div>
      {browserSupportsSpeechRecognition ? null : (
        <span>Browser doesn't support speech recognition.</span>
      )}
      {showSurePopup ? (
        <Surepopup
          setExit={setExit}
          setShowSurePopup={setShowSurePopup}
          setIsSure={setIsSure}
          handleStopRecording={handleStopRecording}
          endInterview={endInterview}
          setMessages={setMessages}
          socket={socket}
          timer={timer}
          setExitQuestion={setExitQuestion}
          setNext={setNext}
          setSpeech={setSpeech}
          setEndInterviewCounter={setEndInterviewCounter}
        />
      ) : null}
      {showSurePopup ? setIsListening(false) : null}

      {/* <button onClick={handleVideoSubmit}>Submit</button> */}
      <div className="absolute top-4 text-sm text-text-color-code1 left-1/2 transform -translate-x-1/2 bg-fill-btn-color-code1  p-2 px-4 rounded-2xl">
        {/* {formatTime(timer)} */}
        {formatTime(interviewTime)}
      </div>
      <div className="inset-0 md:h-[37rem] lg:h-[62.8vh] flex items-center justify-center  bg-fill-color-code1 ">
        {renderVideo}
        {/* {multiFaceDetected ? <div>Multiple faces detected!</div> : <div>Single face only!</div>}
        {headDirection && <div>{headDirection}</div>} */}
      </div>
      <div className="absolute bottom-4 left-4 flex md:gap-4 lg:gap-0  md:flex-col lg:flex-row justify-between items-end fill-available px-4">
        <button
          className={`${socket && !isSpeaking ? null : "pointer-events-none"}`}
        >
          {/* <Mic
            isListening={isListening}
            setIsListening={setIsListening}
            setSpeech={setSpeech}
            isSpeaking={isSpeaking}
          /> */}
          {/* <GoogleCloudSTT
            isListening={isListening}
            setIsListening={setIsListening}
            setSpeech={setSpeech}
            isSpeaking={isSpeaking}
            setIsSpeaking={setIsSpeaking}
          /> */}
          <WhisperSTT
            timer={timer}
            setTimer={setTimer}
            setTimerRunning={setTimerRunning}
            isListening={isListening}
            setIsListening={setIsListening}
            setSpeech={setSpeech}
            isSpeaking={isSpeaking}
            setIsSpeaking={setIsSpeaking}
            openEditor={openEditor}
            setLoader={setLoader}
            loader={loader}
            exitquestion={exitquestion}
            first_name={first_name}
            first_name1={first_name1}
            last_name={last_name}
            last_name1={last_name1}
            face_detect={face_detect}
            questcounter={questcounter}
          />
        </button>
        {/* <button
          className={` p-3 ${
            videoOn ? 'bg-green-200' : 'bg-fill-btn-color-code1'
          } rounded-full`}
          onClick={toggleVideo}
        >
          <img src={cam} alt="vidBtn" />
        </button> */}
        <button className="p-3 bg-fill-btn-color-code1 rounded-full">
          <img src={full} alt="fullScreen" />
        </button>
        {!isStarted ? (
          <button
            onClick={scheduleInterview}
            className="p-4 px-12 rounded-3xl text-sm bg-fill-btn-color-code1 text-text-color-code1"
          >
            Start Interview
          </button>
        ) : exit === "NO" ? (
          <button
            onClick={scheduleInterview}
            className="p-4 px-12 rounded-3xl text-sm bg-fill-btn-color-code1 text-text-color-code1"
            // disabled={questcounter !== 16}
          >
            End Interview
          </button>
        ) : questcounter === 15 ? (
          <button
            onClick={() => endInterview()}
            className="p-4 px-12 rounded-3xl text-sm bg-fill-btn-color-code1 text-text-color-code1"
            // disabled={questcounter !== 16}
          >
            End Interview
          </button>
        ) : (
          ""
        )}
        {/* <button
            onClick={scheduleInterview}
            className="p-4 px-12 rounded-3xl text-sm bg-fill-btn-color-code1 text-text-color-code1"
            disabled={questcounter !== 19 }
          >
            {isStarted ? "End Interview" : "Start Interview"}
          </button> */}

        {/* {isSpeaking ? (
          <img
            src={AIgif}
            alt="interviewer gif"
            className="w-[250px] h-[261px]"
            onLoad={resetIdleCounter}
          />
        ) : (
          <img
            src={AIImage}
            alt="interviewer photo"
            className="w-[250px] h-[261px]"
            onLoad={resetIdleCounter}
          />
        )} */}
      </div>
    </div>
  );
};

export default WebcamComponent;

const Surepopup = ({
  setIsSure,
  setShowSurePopup,
  handleVideoSubmit,
  handleStopRecording,
  endInterview,
  setEndInterviewCounter,
  setMessages,
  timer,
  socket,
  setSpeech,
  setNext,
  setExitQuestion,
  setExit,
}) => {
  const formatTimeFromSeconds = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
  const convertToSeconds = (time) => {
    const [minutes, seconds] = time?.split(":")?.map(Number);
    return minutes * 60 + seconds;
  };

  const calculateRemainingTime = (currentTime) => {
    const startTime = "01:30";
    const startInSeconds = convertToSeconds(startTime);
    const currentInSeconds = convertToSeconds(currentTime);
    const remainingTimeInSeconds = startInSeconds - currentInSeconds;
    return formatTimeFromSeconds(remainingTimeInSeconds);
  };

  const currentTime = formatTime(timer);
  const remainingTime = calculateRemainingTime(currentTime);

  return (
    <section className="absolute z-10  w-[163%] h-[105%] flex items-end pb-[9rem] pl-[18.2rem]">
      <div className="bottom-[0%] left-[35%] gap-4 bg-red-400 p-4 text-white flex flex-col w-[15rem] items-center">
        <p>Are You Sure?</p>
        <div className="flex px-2 justify-between gap-5">
          <button
            className="border-2 p-2 w-[5rem]"
            onClick={() => {
              setIsSure(true);
              setShowSurePopup(false);
              setEndInterviewCounter(true);
              endInterview();
            }}
          >
            Yes
          </button>
          <button
            className="border-2 p-2 w-[5rem]"
            onClick={() => {
              setSpeech("Yes..");
              setNext("YES");
              setExit("");
              setExitQuestion(false);
              setIsSure(false);
              setShowSurePopup(false);
              sessionStorage.removeItem("endInterview");
            }}
          >
            No
          </button>
        </div>
      </div>
    </section>
  );
};
