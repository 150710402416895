import { isAuthorized } from '../../utils/localStorage'

export const noError = {
  isError: false,
}


export const initialState = {
  token: '',
  error: noError,
  isLoading: false,
  user: {},
  isLoggedIn: isAuthorized(),
}

export const certificateInitialState = {
  data: {}
}

export const formInitialState = {
    firstName: '',
    lastName: '',
    number: '',
    job: '',
  };