import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleApiResponse } from "../../API/services";
import { closeButton } from "../../assets/imgs";
import { toast } from "react-toastify";

function OtpValidation({ setShowCheckOTP, email, setShowSetPassword }) {
  const formRef = useRef(null);
  const inputsRef = useRef([]);
  const navigate = useNavigate();
  const [wrongOTP, setWrongOTP] = useState("");
  const [hovered, setHovered] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const toggleHover = () => {
    setHovered(!hovered);
  };

  useEffect(() => {
    let countdown;
    if (isResendDisabled) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setIsResendDisabled(false);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [isResendDisabled]);

  useEffect(() => {
    const form = formRef.current;
    const inputs = inputsRef.current;
    const submit = form.querySelector("button");
  
    const handleKeyDown = (e) => {
      const index = inputs.indexOf(e.target);

      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }
  
      if (e.key === "Backspace" && index > 0) {
        e.preventDefault();
        inputs[index].value = "";
        inputs[index - 1].focus();
      }

      if (e.ctrlKey && e.key === "v") {
        e.preventDefault();
        navigator.clipboard.readText().then((text) => {
          const digits = text.split("");
          inputs.forEach((input, index) => {
            input.value = digits[index] || "";
          });
          submit.focus();
        });
      }
    };
  
    const handleInput = (e) => {
      const { target } = e;
      const index = inputs.indexOf(target);
      if (target.value) {
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        } else {
          submit.focus();
        }
      }
    };
  
    const handleFocus = (e) => {
      e.target.select();
    };
  
    const handlePaste = (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text");
      if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
        return;
      }
      const digits = text.split("");
      inputs.forEach((input, index) => (input.value = digits[index]));
      submit.focus();
    };
  
    inputs.forEach((input) => {
      input?.addEventListener("input", handleInput);
      input?.addEventListener("keydown", handleKeyDown);
      input?.addEventListener("focus", handleFocus);
      input?.addEventListener("paste", handlePaste);
    });
  
    // return () => {
    //   inputs.forEach((input) => {
    //     input.removeEventListener("input", handleInput);
    //     input.removeEventListener("keydown", handleKeyDown);
    //     input.removeEventListener("focus", handleFocus);
    //     input.removeEventListener("paste", handlePaste);
    //   });
    // };
  }, []);  

  const resendOTP = async () => {
    setHovered(false);
    const reqData = { email: email,otp_type : 2  };
    const response = await handleApiResponse("/api/v1/generateotp/", reqData);
    setWrongOTP("");
    toast.success("OTP resent");
    setIsResendDisabled(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userOTP = inputsRef.current.map((input) => input.value).join("");
    if (userOTP.length !== 6) {
      setWrongOTP("Please enter the correct OTP.");
      return;
    }
    const reqData = {
      email: email,
      otp: userOTP,
    };
    const response = await handleApiResponse("/api/v1/verifyotp/", reqData);
    if (response.status_code === 200) {
      setShowCheckOTP(false);
      const tkn = response?.data?.access;
      sessionStorage.setItem("accesTKN", tkn);
      setShowSetPassword(true);
    } else {
      setWrongOTP(response?.msg);
    }
  };

  return (
    <div className="absolute max-w-md mx-auto text-center bg-fill-color-code1 px-4 sm:px-8 py-10 rounded-xl shadow backdrop-blur">
      <div
        className="flex justify-end cursor-pointer"
        onClick={() => setShowCheckOTP(false)}
      >
        <img className="" src={closeButton} alt="close" />
      </div>
      <header className="mb-8">
        <h1 className="text-2xl text-text-color-code1 font-bold mb-1">
          Email Verification
        </h1>
        <p className="text-[15px] text-text-color-code1">
          Enter the 6-digit verification code that was sent to your email.
        </p>
      </header>
      <form id="otp-form" ref={formRef} onSubmit={handleSubmit}>
        <div className="flex items-center justify-center gap-3">
          {Array.from({ length: 6 }, (_, index) => (
            <input
              key={index}
              type="text"
              className="w-14 h-14 text-center text-2xl font-extrabold text-text-color-code1 bg-slate-100 border border-transparent hover:border-[#A6AEBA] appearance-none rounded p-4 outline-none focus:bg-white focus:border-[#A6AEBA] focus:ring-2 focus:ring-[#A6AEBA]"
              maxLength={1}
              ref={(el) => (inputsRef.current[index] = el)}
            />
          ))}
        </div>
        <div className="max-w-[260px] mx-auto mt-4">
          {wrongOTP && <div className="text-red-500 mb-4">{wrongOTP}</div>}
          <button
            type="submit"
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-[#A6AEBA1A] px-3.5 py-2.5 text-sm font-medium text-[#A6AEBA] shadow-sm focus:outline-none focus:ring focus-visible:outline-none duration-150"
          >
            Verify Account
          </button>
        </div>
      </form>
      <div
        className={`text-sm text-[#A6AEBA] mt-4 cursor-pointer ${
          hovered ? "text-gray-700" : ""
        }`}
        onClick={isResendDisabled ? null : resendOTP}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {isResendDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
      </div>
    </div>
  );
}

export default OtpValidation;
