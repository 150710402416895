import { useEffect } from "react";
import { getLocal } from "../utils/localStorage";
import { useNavigate } from "react-router-dom";

function ProtectedRoute(props){
    const {Component}=props;
    const navigate=useNavigate();
    useEffect(()=>{
        let login= getLocal('adminToken');
        console.log(login)
        if(!login){
          navigate('/');
        }
    })
  return(
    <div>
        <Component />
    </div>
  )
}
export default ProtectedRoute