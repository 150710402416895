export const userActions = {
    LOGIN_USER: "LOGIN_USER",
    LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
    LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  };
  
  export const loginRequest = (payload) => ({
    type: userActions.LOGIN_USER,
    payload
  });
  
  export const loginSuccess = (data) => ({
    type: userActions.LOGIN_USER_SUCCESS,
    payload: data,
  });
  
  export const loginFailure = (error) => ({
    type: userActions.LOGIN_USER_FAILURE,
    payload: error,
  });