// import { useEffect, useRef, useState } from 'react';
// import { mic, micoff } from '../assets/imgs';
// import axios from 'axios';

// export const WhisperSTT = ({
//   setTimer,
//   setTimerRunning,
//   isListening,
//   setIsListening,
//   setSpeech,
//   setLoader,
//   loader,
//   openEditor,
//   timer
// }) => {
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const recorderRef = useRef(null);
//   const audioContextRef = useRef(
//     new (window.AudioContext || window.webkitAudioContext)()
//   );

//   const handleStartRecording = () => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         const audioContext = audioContextRef.current;

//         const source = audioContext.createMediaStreamSource(stream);

//         const analyser = audioContext.createAnalyser();
//         analyser.fftSize = 2048;

//         source.connect(analyser);

//         const recorder = new MediaRecorder(stream);
//         recorder.ondataavailable = (event) => {
//           setAudioBlob(event.data);
//         };
//         recorder.start();
//         recorderRef.current = recorder;
//         setMediaRecorder(recorder);
//         setIsListening(true);
//       })
//       .catch((error) => {
//         console.error('Error accessing microphone:', error);
//       });
//   };

//   const handleStopRecording = () => {
//     if (isListening && mediaRecorder) {
//       mediaRecorder.stop();
//       setIsListening(false);
//       setTimerRunning(false);
//     }
//   };

//   useEffect(() => {
//     if (timer <= 0 && mediaRecorder) {
//       handleStopRecording();
//     }
//   }, [timer, openEditor]);

//   useEffect(() => {
//     if (isListening) {
//       handleStartRecording();
//     }
//   }, [isListening]);

//   useEffect(() => {
//     if (audioBlob) {
//       processAudioBlob(audioBlob);
//     }
//   }, [audioBlob]);

//   const processAudioBlob = (blob) => {
//     const reader = new FileReader();
//     reader.readAsArrayBuffer(blob);
//     reader.onloadend = () => {
//       const arrayBuffer = reader.result;
//       audioContextRef.current.decodeAudioData(arrayBuffer, (audioBuffer) => {
//         if (!isSilent(audioBuffer)) {
//           sendAudioToWhisper(blob);
//         } else {
//           console.error("no-response")
//           setSpeech('no_response');
//         }
//         setLoader(false);
//       });
//     };
//   };

//   const isSilent = (audioBuffer) => {
//     const rawData = audioBuffer.getChannelData(0); // Get the first channel data
//     const threshold = 0.02; // Silence threshold (adjust as needed)
//     const silenceLength = 180 * audioBuffer.sampleRate; // 0.5 seconds of silence

//     let silenceCounter = 0;
//     for (let i = 0; i < rawData.length; i++) {
//       if (Math.abs(rawData[i]) < threshold) {
//         silenceCounter++;
//         if (silenceCounter >= silenceLength) {
//           return true;
//         }
//       } else {
//         silenceCounter = 0;
//       }
//     }
//     return false;
//   };

//   const sendAudioToWhisper = async (audioBlob) => {
//     if (!audioBlob) {
//       console.error('Error: audioBlob is null or undefined');
//       return;
//     }
//     const apiKey = process.env.REACT_APP_WHISPER_API_KEY;
//     setLoader(true);
//     const formData = new FormData();
//     formData.append('file', audioBlob, 'audio.wav');
//     formData.append('model', 'whisper-1');
//     formData.append('language', 'en');
//     formData.append(
//       'prompt',
//       'Please transcribe this audio. The sentence may be cut off, do not make up words to fill in the rest of the sentence. If you feel there are lot of voices then keep the respnse empty. also if you feel there is very very low voice and hard to transcribe then also send empty response. '
//     );

//     try {
//       const response = await axios.post(
//         'https://api.openai.com/v1/audio/transcriptions',
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${apiKey}`,
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );
//       const transcription = response?.data?.text;
//       console.log(transcription, 'transcription');

//       if (filterOutput(transcription)) {
//         setSpeech(transcription);
//       } else {
//         setSpeech('no_response');
//       }
//       setLoader(false);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const filterOutput = (output) => {
//     const irrelevantPhrases = [
//       'bye',
//       'thanks for watching',
//       'like and subscribe',
//     ];
//     return !irrelevantPhrases.some((phrase) =>
//       output.toLowerCase().includes(phrase)
//     );
//   };

//   return (
//     <>
//       {!loader ? (
//         <div
//           className={`p-3 ${
//             isListening && !openEditor
//               ? 'bg-green-200'
//               : 'bg-fill-btn-color-code1'
//           } rounded-full`}
//           onClick={() => {
//             return isListening ? handleStopRecording() : handleStartRecording();
//           }}
//         >
//           <div className="microphone-icon-container">
//             <img
//               src={isListening && !openEditor ? mic : micoff}
//               alt={isListening && !openEditor ? 'mic' : 'other'}
//               className="microphone-icon"
//             />
//           </div>
//         </div>
//       ) : (
//         <div className={`p-3 bg-fill-btn-color-code1 rounded-full`}>
//           <div className="microphone-icon-container">
//             <img
//               src={isListening && !openEditor ? mic : micoff}
//               alt={isListening && !openEditor ? 'mic' : 'other'}
//               className="microphone-icon"
//             />
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// import { useEffect, useRef, useState } from 'react';
// import { mic, micoff } from '../assets/imgs';
// import { franc } from 'franc';
// import axios from 'axios';

// export const WhisperSTT = ({
//   setTimer,
//   timer,
//   setTimerRunning,
//   isListening,
//   setIsListening,
//   setSpeech,
//   setLoader,
//   loader,
//   openEditor,
// }) => {
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const recorderRef = useRef(null);
//   const audioContextRef = useRef(
//     new (window.AudioContext || window.webkitAudioContext)()
//   );
//   const canvasRef = useRef(null);

//   const handleStartRecording = () => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         const audioContext = audioContextRef.current;

//         const source = audioContext.createMediaStreamSource(stream);

//         const analyser = audioContext.createAnalyser();
//         analyser.fftSize = 2048;

//         source.connect(analyser);

//         const recorder = new MediaRecorder(stream);
//         recorder.ondataavailable = (event) => {
//           setAudioBlob(event.data);
//         };
//         recorder.start();
//         recorderRef.current = recorder;
//         setMediaRecorder(recorder);
//         setIsListening(true);

//         visualize(analyser);
//       })
//       .catch((error) => {
//         console.error('Error accessing microphone:', error);
//       });
//   };

//   const handleStopRecording = () => {
//     if (isListening && mediaRecorder) {
//       mediaRecorder.stop();
//       setIsListening(false);
//       setTimerRunning(false);
//     }
//   };

//   useEffect(() => {
//     if (timer <= 0 && mediaRecorder) {
//       handleStopRecording();
//     }
//   }, [timer, openEditor]);

//   useEffect(() => {
//     if (isListening) {
//       handleStartRecording();
//     }
//   }, [isListening]);

//   useEffect(() => {
//     if (audioBlob) {
//       processAudioBlob(audioBlob);
//     }
//   }, [audioBlob]);

//   const processAudioBlob = (blob) => {
//     const reader = new FileReader();
//     reader.readAsArrayBuffer(blob);
//     reader.onloadend = () => {
//       const arrayBuffer = reader.result;
//       audioContextRef.current.decodeAudioData(arrayBuffer, (audioBuffer) => {
//         if (!isSilent(audioBuffer)) {
//           sendAudioToWhisper(blob);
//         } else {
//           setSpeech('no_response');
//           setLoader(false);
//         }
//       });
//     };
//   };

//   const isSilent = (audioBuffer) => {
//     const rawData = audioBuffer.getChannelData(0); // Get the first channel data
//     const threshold = 0.01; // Lower threshold to detect quieter sounds
//     const minNonSilentDuration = 0.1 * audioBuffer.sampleRate; // 0.1 seconds of non-silent audio required

//     let nonSilentSamples = 0;
//     for (let i = 0; i < rawData.length; i++) {
//       if (Math.abs(rawData[i]) >= threshold) {
//         nonSilentSamples++;
//         if (nonSilentSamples >= minNonSilentDuration) {
//           return false; // Not silent
//         }
//       }
//     }
//     return true; // Silent
//   };

//   const sendAudioToWhisper = async (audioBlob) => {
//     if (!audioBlob) {
//       console.error('Error: audioBlob is null or undefined');
//       return;
//     }
//     const apiKey = process.env.REACT_APP_WHISPER_API_KEY;
//     setLoader(true);
//     const formData = new FormData();
//     formData.append('file', audioBlob, 'audio.wav');
//     formData.append('model', 'whisper-1');
//     formData.append('language', 'en');
//     formData.append('prompt', 'Please transcribe this audio.');

//     try {
//       const response = await axios.post(
//         'https://api.openai.com/v1/audio/transcriptions',
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${apiKey}`,
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );
//       const transcription = response?.data?.text;
//       console.log(transcription, 'transcription');

//       if (filterOutput(transcription)) {
//         setSpeech(transcription);
//       } else {
//         setSpeech('no_response');
//       }
//       setLoader(false);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const filterOutput = (output) => {
//     const irrelevantPhrases = [
//       'bye',
//       'thanks for watching',
//       'like and subscribe',
//     ];
//     return !irrelevantPhrases.some((phrase) =>
//       output.toLowerCase().includes(phrase)
//     );
//   };

//   const visualize = (analyser) => {
//     const canvas = canvasRef.current;
//     const canvasCtx = canvas.getContext('2d');

//     const WIDTH = canvas.width;
//     const HEIGHT = canvas.height;

//     analyser.fftSize = 2048;
//     const bufferLength = analyser.frequencyBinCount;
//     const dataArray = new Uint8Array(bufferLength);

//     const draw = () => {
//       requestAnimationFrame(draw);

//       analyser.getByteFrequencyData(dataArray);

//       canvasCtx.fillStyle = 'rgb(0, 0, 0)';
//       canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

//       const barWidth = (WIDTH / bufferLength) * 2.5;
//       let barHeight;
//       let x = 0;

//       for (let i = 0; i < bufferLength; i++) {
//         barHeight = dataArray[i];

//         canvasCtx.fillStyle = 'rgb(' + (barHeight + 100) + ',50,50)';
//         canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight / 2);

//         x += barWidth + 1;
//       }

//       // Draw frequency labels
//       canvasCtx.fillStyle = 'white';
//       canvasCtx.font = '10px Arial';
//       for (let i = 0; i < bufferLength; i += 10) {
//         const frequency =
//           (i * audioContextRef.current.sampleRate) / analyser.fftSize;
//         canvasCtx.fillText(
//           Math.round(frequency) + 'Hz',
//           i * barWidth * 2.5,
//           HEIGHT
//         );
//       }

//       // Draw amplitude labels
//       for (let i = 0; i < HEIGHT; i += 20) {
//         canvasCtx.fillText(Math.round((HEIGHT - i) * 2) + 'dB', 0, i);
//       }
//     };

//     draw();
//   };

//   return (
//     <>
//       <canvas ref={canvasRef} width="600" height="300"></canvas>
//       {!loader ? (
//         <div
//           className={`p-3 ${
//             isListening && !openEditor
//               ? 'bg-green-200'
//               : 'bg-fill-btn-color-code1'
//           } rounded-full`}
//           onClick={() => {
//             return isListening ? handleStopRecording() : handleStartRecording();
//           }}
//         >
//           <div className="microphone-icon-container">
//             <img
//               src={isListening && !openEditor ? mic : micoff}
//               alt={isListening && !openEditor ? 'mic' : 'other'}
//               className="microphone-icon"
//             />
//           </div>
//         </div>
//       ) : (
//         <div className={`p-3 bg-fill-btn-color-code1 rounded-full`}>
//           <div className="microphone-icon-container">
//             <img
//               src={isListening && !openEditor ? mic : micoff}
//               alt={isListening && !openEditor ? 'mic' : 'other'}
//               className="microphone-icon"
//             />
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

import { useEffect, useRef, useState } from "react";
import { mic, micoff } from "../assets/imgs";
import { franc } from "franc";
import axios from "axios";
import e from "cors";

export const WhisperSTT = ({
  setTimer,
  setTimerRunning,
  isListening,
  setIsListening,
  setSpeech,
  isSpeaking,
  timer,
  openEditor,
  setLoader,
  loader,
  exitquestion,
  first_name,
  first_name1,
  last_name,
  last_name1,
  face_detect,
  questcounter
}) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const recorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceRef = useRef(null);
  const analyserRef = useRef(null);
  const filterRef = useRef(null);
  const thresholdValueForSTT = 36;

  const [audioThreshold, setAudioThreshold] = useState(0);
  const [earlyStop, setEarlyStop] = useState();

  // console.log(timer, "timer");

  const handleStartRecording = () => {
    setEarlyStop(false);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContextRef.current = audioContext;

        const source = audioContext.createMediaStreamSource(stream);
        sourceRef.current = source;

        const analyser = audioContext.createAnalyser();
        analyserRef.current = analyser;
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        const filter = audioContext.createBiquadFilter();
        filter.type = "lowpass";
        filter.frequency.setValueAtTime(1000, audioContext.currentTime);
        filterRef.current = filter;

        source.connect(filter);
        filter.connect(analyser);

        const recorder = new MediaRecorder(stream);
        recorder.ondataavailable = (event) => {
          clearInterval(checkAudioInterval);
          setAudioBlob(event.data);
          analyserRef.current.getByteFrequencyData(dataArray);
          const frequencies = Array.from(dataArray);
          setAudioThreshold(frequencies.reduce((a, b) => a + b, 0) / 50);
          console.log("threshold", frequencies.reduce((a, b) => a + b, 0) / 50);
        };
        recorder.start();
        recorderRef.current = recorder;
        setMediaRecorder(recorder);
        setIsListening(true);

        // Start periodic threshold checking
        const checkAudioInterval = setInterval(() => {
          console.log("analysing");
          analyser.getByteFrequencyData(dataArray);
          const frequencies = Array.from(dataArray);
          const averageFrequency = frequencies.reduce((a, b) => a + b, 0) / 50;

          if (averageFrequency < thresholdValueForSTT) {
            // Replace 'yourThresholdValue' with the appropriate threshold
            console.log("Please speak loudly");
            // You can add additional code here to display a message to the user
          }
          console.log("avg freq after 10sec", averageFrequency);
        }, 10000); // Check every 10 seconds

        // recorderRef.current.onstop = () => {
        //   clearInterval(checkAudioInterval); // Clear the interval when the recording stops
        // };
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  // const handleStartRecording = () => {
  //   navigator.mediaDevices
  //     .getUserMedia({ audio: true })
  //     .then((stream) => {
  //       const recorder = new MediaRecorder(stream);
  //       recorder.ondataavailable = (event) => {
  //         setAudioBlob(event.data);
  //       };
  //       recorder.start();
  //       recorderRef.current = recorder;
  //       setMediaRecorder(recorder);
  //       setIsListening(true);
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing microphone:", error);
  //     });
  // };

  useEffect(() => {
    if (audioBlob) {
      sendAudioToWhisper(audioBlob);
    }
  }, [audioBlob]);

  useEffect(() => {
    if (isListening) {
      handleStartRecording();
      // setTimerRunning(true);
      // setTimer(120);
    }
  }, [isListening]);

  const handleStopRecording = () => {
    if (isListening && mediaRecorder) {
      setEarlyStop(true);
      mediaRecorder.stop();
      setIsListening(false);
      setTimerRunning(false);
    }
  };

  function checkConsecutiveWords(str) {
    // Split the string into words
    const words = str.split(/\s+/); // Splitting by whitespace

    // Initialize a counter and track the previous word
    let previousWord = null;
    let count = 1;

    for (let i = 0; i < words.length; i++) {
      if (words[i] === previousWord) {
        count++;
        // If the word appears 4 times consecutively, return true
        if (count === 4) {
          console.log(words[i]);
          return true;
        }
      } else {
        // Reset the counter if the current word is different
        previousWord = words[i];
        count = 1;
      }
    }

    return false;
  }

  const checkString = (str) => {
    const restrictedPhrases = [
      "thank you for watching",
      "please subscribe",
      "please like and subscribe",
      "upload the next video",
      "please like comment and subscribe",
      "please like comment",
    ];

    // Check if the string length is not more than 10
    if (str.length > 10) {
      return false;
    }

    // Check if the string contains any of the restricted phrases
    for (let phrase of restrictedPhrases) {
      if (str.toLowerCase().includes(phrase.toLowerCase())) {
        return true;
      }
    }

    return false;
  };

  function checkWordFrequency(str) {
    const freqMap = {};

    // Split the string into words
    const words = str.split(/\s+/); // Splitting by whitespace (space, tab, newline, etc.)

    // Create frequency map
    for (const word of words) {
      freqMap[word] = (freqMap[word] || 0) + 1;
    }

    // Get the number of unique words
    const uniqueWordCount = Object.keys(freqMap).length;

    // Check if the frequency map has 6 or more unique words
    if (uniqueWordCount <= 6) {
      // Check if any word has a count greater than 7
      for (const count of Object.values(freqMap)) {
        if (count > 7) {
          console.log("freqMap", freqMap);
          return true;
        }
      }
    }

    if (uniqueWordCount <= 6) {
      // Check if any word has a count greater than 80% of the whole sentence
      for (const count of Object.values(freqMap)) {
        if (count >= uniqueWordCount * 0.75) {
          console.log("freqMap", freqMap);
          return true;
        }
      }
    }

    return false;
  }

  function checkSentenceFrequency(str) {
    const freqMap = {};

    // Split the string into words
    const sentences = str.split("."); // Splitting by whitespace (space, tab, newline, etc.)

    // Create frequency map
    for (const sentence of sentences) {
      freqMap[sentence] = (freqMap[sentence] || 0) + 1;
    }

    // Check if any word has a count greater than 7
    for (const count of Object.values(freqMap)) {
      if (count > 2) {
        console.log("freqMap", freqMap);
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    if (timer <= 0) {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
      if (!isListening && !openEditor && !isSpeaking) {
        if (questcounter < 15) {
          setSpeech("no_response");
        }
      }
      setIsListening(false);
    }
  }, [timer]);

  const sendAudioToWhisper = async (audioBlob) => {
    if (!audioBlob) {
      console.error("Error: audioBlob is null or undefined");
      return;
    }
    const apiKey = process.env.REACT_APP_WHISPER_API_KEY;
    setLoader(true);
    console.log("blob", audioBlob);
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.wav");
    formData.append("model", "whisper-1");
    formData.append("language", "en");
    formData.append(
      "prompt",
      "transcribe file"
      //'Please transcribe this audio. The sentence may be cut off, do not make up words to fill in the rest of the sentence. If you feel there are lot of voices then keep the respnse empty. also if you feel there is very very low voice and hard to transcribe then also send empty response. '
    );

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/audio/transcriptions",
        formData,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const transcription = response?.data?.text;
      if (!transcription) {
        console.log("no transcript", "transcription");
      } else {
        console.log(transcription, "transcription");
      }
      if (earlyStop) {
        setSpeech(transcription);
        setLoader(false);
      } else {
        console.log(
          "freq",
          checkWordFrequency(transcription),
          "threshold",
          audioThreshold <= thresholdValueForSTT,
          "you",
          transcription === "you",
          "audio",
          audioThreshold,
          "multiple consecutive word",
          checkConsecutiveWords(transcription),
          "sentence freq count",
          checkSentenceFrequency(transcription),
          "restricted phrases",
          checkString(transcription)
        );
        if (
          checkWordFrequency(transcription) ||
          audioThreshold <= thresholdValueForSTT ||
          transcription === "you" ||
          checkConsecutiveWords(transcription) ||
          checkSentenceFrequency(transcription) ||
          checkString(transcription)
        ) {
          setSpeech("no_response");
          setLoader(false);
        } else {
          setSpeech(transcription);
          setLoader(false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setAudioThreshold(0);
    }
  };

  return (
    <>
      {!loader ? (
        <div
          className={`p-3 ${
            isListening && !openEditor
              ? "bg-green-200"
              : "bg-fill-btn-color-code1"
          } rounded-full`}
          onClick={() => {
            return isListening ? handleStopRecording() : handleStartRecording();
          }}
        >
          <div className="microphone-icon-container">
            <img
              src={isListening && !openEditor ? mic : micoff}
              alt={isListening && !openEditor ? "mic" : "other"}
              className="microphone-icon"
            />
          </div>
        </div>
      ) : (
        <div
          className={`p-3 bg-fill-btn-color-code1 rounded-full`}
          // onClick={() => {
          //   return isListening ? handleStopRecording() : handleStartRecording();
          // }}
        >
          <div className="microphone-icon-container">
            <img
              src={isListening && !openEditor ? mic : micoff}
              alt={isListening && !openEditor ? "mic" : "other"}
              className="microphone-icon"
            />
          </div>
        </div>
      )}
    </>
  );
};
