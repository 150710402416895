import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { RiCalendar2Line } from 'react-icons/ri';

export const Calander = ({ setStartDate, endDate, editStartDate }) => {
  const [selectedDate, setSelectedDate] = useState(editStartDate || null);
  const monthMap = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };

  function compareDates2(date1, date2) {
    const [year1, month1, day1] = date1.split('-').map(Number);
    const [year2, month2, day2] = date2.split('-').map(Number);
    // console.log('Start', year1, month1, day1);
    // console.log('End', year2, month2, day2);

    if (day1 === day2 && month1 === month2 && year1 === year2) {
      return true;
    }

    if (year1 < year2) {
      return true;
    } else if (year1 > year2) {
      return false;
    }

    if (month1 < month2) {
      return true;
    } else if (month1 > month2) {
      return false;
    }

    return day1 < day2;
  }

  function compareDates(date1, date2) {
    console.log('date 1', date1, 'date 2', date2);
    let [month1, day1, year1] = date1.split(' ');
    let [month2, day2, year2] = date2.split(' ');
    month1 = monthMap[month1];
    month2 = monthMap[month2];
    console.log('Start', day1, month1, year1);
    console.log('End', day2, month2, year2);

    if (day1 === day2 && month1 === month2 && year1 === year2) {
      return true;
    }

    if (year1 < year2) {
      return true;
    } else if (year1 > year2) {
      return false;
    }

    if (month1 < month2) {
      return true;
    } else if (month1 > month2) {
      return false;
    }

    return day1 < day2;
  }

  const handleDateChange = (date) => {
    const todayDate = new Date();
    let userDate = String(date).slice(4, 15);
    let thisDate = String(todayDate).slice(4, 15);
    let result = compareDates(thisDate, userDate);
    console.log('comp today and selected date', result);

    if (result) {
      setSelectedDate(date);
      const dateString =
        String(date).split(' ')[3] +
        '-' +
        monthMap[String(date).split(' ')[1]] +
        '-' +
        String(date).split(' ')[2];

      if (endDate) {
        console.log('this is end date after change ', endDate);
        const isOlder = compareDates2(dateString, endDate);
        console.log('older', isOlder);
        if(isOlder){
          setStartDate(dateString);
          console.log(dateString); // yyyy-mm-dd
        }
        else{
          setSelectedDate();
        }
      }
      else{
        setStartDate(dateString);
        console.log(dateString); // yyyy-mm-dd
      }

      
    }
  };

  return (
    <div className="relative mt-2 ">
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select start date"
        className="w-[27vw] p-3 outline-0 rounded-lg"
      />
      <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-400 pointer-events-none">
        <RiCalendar2Line />
      </div>
    </div>
  );
};

export const Calander2 = ({
  setEndDate,
  startDate,
  setIsOlderDate,
  editEndDate,
}) => {
  const [selectedDate, setSelectedDate] = useState(editEndDate || null);
  const monthMap = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };

  function compareDates(date1, date2) {
    const [year1, month1, day1] = date1.split('-').map(Number);
    const [year2, month2, day2] = date2.split('-').map(Number);
    console.log('Start', year1, month1, day1);
    console.log('End', year2, month2, day2);

    if (day1 === day2 && month1 === month2 && year1 === year2) {
      return true;
    }

    if (year1 < year2) {
      return true;
    } else if (year1 > year2) {
      return false;
    }

    if (month1 < month2) {
      return true;
    } else if (month1 > month2) {
      return false;
    }

    return day1 < day2;
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const dateString =
      String(date).split(' ')[3] +
      '-' +
      monthMap[String(date).split(' ')[1]] +
      '-' +
      String(date).split(' ')[2];

    setEndDate(dateString);
    console.log(dateString); // yyyy-mm-dd

    const isOlder = compareDates(startDate, dateString);
    setIsOlderDate(isOlder);
    console.log(isOlder);
  };

  return (
    <div className="relative mt-2 ">
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select end date"
        className="w-[27vw] p-3 outline-0 rounded-lg"
      />
      <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-400 pointer-events-none">
        <RiCalendar2Line />
      </div>
    </div>
  );
};
