import { Routes, Route } from 'react-router-dom';
import { Layout } from '../layouts/MainLayout';
import { Signup } from '../features/signup/Signup';
import { Certificate, LandingPage, StartPage } from '../features/pages/LandingPage';
import { FillForm } from '../features/pages/FillFormPage';
import { Evaluation } from '../features/pages/Evaluation';
import { Interview } from '../features/pages/Interview';
// import { Login } from '../features/login/Login';
import ProtectedRoute from './ProtectedRoute';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { clearLocal } from '../utils/localStorage';
import ErrorPage from '../features/AdminPages/404Page'; // Import ErrorPage

export const UserRoute = () => {
  // const location = useLocation();

  // useEffect(() => {
  //   if (['/login', '/signup', '/'].includes(location.pathname)) {
  //     clearLocal();
  //   }
  // }, [location.pathname]);

  return (
    <Routes>
      <Route exact path="/interview/candidate/:intLink" element={<Layout />}>
        <Route exact index element={<LandingPage />} />
        <Route exact path="details" element={<FillForm />} />
        <Route exact path="start-interview" element={<StartPage />} />
        <Route exact path="interview" element={<Interview />} />
        <Route exact path="certificate" element={<Certificate />} />
        <Route path="*" element={<ErrorPage />} /> {/* Nested catch-all route for undefined paths */}
      </Route>

      {/* Other routes can be added here */}
    </Routes>
  );
};
