import React from "react";
import { ErrorPages } from "../../assets/imgs";

export const ErrorPage = () => {
    return(
       <div>
        <img src={ErrorPages} alt="404 error image" className="flex w-full h-full items-center p-8 justify-center"/>
       </div>
    );
}
export default ErrorPage;