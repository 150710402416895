import { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { RiCalendar2Line } from "react-icons/ri";
import { saveAs } from "file-saver";
import {
  Backbutton,
  Button,
  CustomButton,
  Nextbutton,
} from "../../components/buttons";
import {
  DeleteQues,
  resumeUpload,
  AddQues,
  Ellipse,
  LinkIcon,
  deleteIcon,
  editIcon,
} from "../../assets/imgs";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownJobRole,
} from "../../components/AdminComponents/dropdown";
import { Calander, Calander2 } from "../../components/AdminComponents/calander";
import {
  ModalAddCandidate,
  ModalAddConfirmationPopup,
  ModalAddHrQuestions,
  ModalAddJobRole,
  ModalCandidateConfirmationPopup,
  ModalChangeCampaign,
  ModalDeleteCampaign,
  ModalDeleteJobRole,
  ModalDeleteQuestionPopup,
  ModalImportMembers,
} from "../../components/AdminComponents/modal";
import { AnalyticsPageBtn } from "../../components/buttons";
import { getLocal, setLocal } from "../../utils/localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { validateName } from "../../utils/validation";
import { fetchData, handleApiResponse } from "../../API/services";
import { Loader } from "../../components/loader";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import { set } from "date-fns";

export const AddCampaign = () => {
  const location = useLocation();
  const campUid = location?.state?.uid || "";
  const jr_name = location?.state?.jr_name || "";
  const start_date = location?.state?.start_date || "";
  const end_date = location?.state?.end_date || "";
  const campaign_type = location?.state?.campaign_type || "";
  const name = location?.state?.name || "";
  const jr_uid = location?.state?.jr_uid;
  const desc = location?.state?.desc;
  const resumeOn = location?.state?.resumeOn;
  const codingOn = location?.state?.codingOn;
  const skills = location?.state?.skills;
  const navigate = useNavigate();
  console.log(location?.state);
  const editData = [
    campUid,
    jr_name,
    start_date,
    end_date,
    campaign_type,
    name,
    jr_uid,
    desc,
    resumeOn,
    codingOn,
    skills,
  ];
  const [current, setCurrent] = useState(() => {
    const storedValue = sessionStorage.getItem("currentAddCampaign");
    return storedValue ? JSON.parse(storedValue) : 0;
  });
  const [isNextAcitvated, setIsNextActivated] = useState(false);
  const [campaignType, setCampaignType] = useState(1);
  const [allReqData, setAllReqData] = useState([null, null, null, null]);
  const [uploadFile, setUploadFile] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [singleAddCandidate, setSingleAddCandidate] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectAll, setSelectAll] = useState(
    sessionStorage.getItem("savedAllSelcted")
      ? sessionStorage.getItem("savedAllSelcted")
      : false
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const shouldHide = current === 3 && getLocal("mail_share_type") === "1";
  const [backClicked, setBackClicked] = useState(false);
  const [prevEmail, setPrevEmail] = useState();
  console.log(allReqData);
  console.log(selectedData);
  const step = [
    { name: "Campaign Details" },
    { name: "Question Builder" },
    { name: "Add Candidate" },
    // { name: "Review & Share" },
  ];

  useEffect(() => {
    console.log("editData", editData);
    setLocal("currentAddCampaign", JSON.stringify(current));
  }, [current]);

  useEffect(() => {
    console.log("all req data", allReqData);
  }, [current]);

  return (
    <main className="mt-4 flex bg-fill-color-code1 gap-12 fill-available  mx-[4.375rem] justify-center text-text-color-code1 add-member-animation">
      <section className=" text-[0.9vw] w-[14rem] bg-white m-6 rounded-lg flex flex-col items-center justify-center">
        <div
          className={`flex justify-center items-center w-[5rem] h-[5rem] ${
            current === 0 ? "bg-text-color-code1" : "bg-fill-color-code1"
          } rounded-[100%] relative`}
        >
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_250_3465)">
              <path
                d="M21.9062 43.8753C21.9062 44.5643 22.1799 45.225 22.6671 45.7121C23.1542 46.1993 23.815 46.473 24.5039 46.473H30.4414C31.1303 46.473 31.7911 46.1993 32.2782 45.7121C32.7654 45.225 33.0391 44.5643 33.0391 43.8753V43.8219L39.0508 46.1227V17.875L21.9062 24.4441V43.8753ZM30.8125 43.8753C30.8125 43.9737 30.7734 44.0681 30.7038 44.1377C30.6342 44.2073 30.5398 44.2464 30.4414 44.2464H24.5039C24.4055 44.2464 24.3111 44.2073 24.2415 44.1377C24.1719 44.0681 24.1328 43.9737 24.1328 43.8753V40.4093L30.8125 42.9691V43.8753Z"
                fill={`${current === 0 ? "white" : "#A6AEBA"}`}
              />
              <path
                d="M43.1328 17.5273H41.2773V46.4727H43.1328C43.8218 46.4727 44.4825 46.199 44.9696 45.7118C45.4568 45.2247 45.7305 44.5639 45.7305 43.875V20.125C45.7305 19.4361 45.4568 18.7753 44.9696 18.2882C44.4825 17.801 43.8218 17.5273 43.1328 17.5273Z"
                fill={`${current === 0 ? "white" : "#A6AEBA"}`}
              />
              <path
                d="M13 27.5469V36.4531C13 37.1421 13.2737 37.8028 13.7608 38.2899C14.248 38.7771 14.9087 39.0508 15.5977 39.0508H19.6797V24.9492H15.5977C14.9087 24.9492 14.248 25.2229 13.7608 25.7101C13.2737 26.1972 13 26.8579 13 27.5469Z"
                fill={`${current === 0 ? "white" : "#A6AEBA"}`}
              />
              <path
                d="M48.4023 33.1133H49.8867C50.182 33.1133 50.4651 32.996 50.6739 32.7872C50.8827 32.5784 51 32.2953 51 32C51 31.7047 50.8827 31.4216 50.6739 31.2128C50.4651 31.004 50.182 30.8867 49.8867 30.8867H48.4023C48.1071 30.8867 47.8239 31.004 47.6151 31.2128C47.4064 31.4216 47.2891 31.7047 47.2891 32C47.2891 32.2953 47.4064 32.5784 47.6151 32.7872C47.8239 32.996 48.1071 33.1133 48.4023 33.1133Z"
                fill={`${current === 0 ? "white" : "#A6AEBA"}`}
              />
              <path
                d="M48.4469 26.3166C48.5767 26.3166 48.7055 26.294 48.8276 26.2498L50.2229 25.7421C50.5005 25.6411 50.7265 25.434 50.8514 25.1664C50.9762 24.8987 50.9897 24.5924 50.8887 24.3149C50.7877 24.0373 50.5806 23.8113 50.3129 23.6864C50.0453 23.5616 49.739 23.5482 49.4615 23.6491L48.0661 24.1575C47.8205 24.2473 47.6142 24.4206 47.4836 24.6472C47.3529 24.8737 47.3062 25.1391 47.3516 25.3966C47.3971 25.6542 47.5317 25.8875 47.732 26.0558C47.9322 26.224 48.1853 26.3163 48.4469 26.3166Z"
                fill={`${current === 0 ? "white" : "#A6AEBA"}`}
              />
              <path
                d="M50.2229 38.2584L48.8276 37.7507C48.55 37.6497 48.2438 37.6631 47.9761 37.788C47.7084 37.9128 47.5013 38.1389 47.4004 38.4164C47.2994 38.694 47.3128 39.0003 47.4376 39.2679C47.5625 39.5356 47.7886 39.7427 48.0661 39.8437L49.4614 40.3513C49.739 40.4523 50.0453 40.4389 50.3129 40.314C50.5806 40.1892 50.7877 39.9631 50.8886 39.6856C50.9896 39.408 50.9762 39.1018 50.8514 38.8341C50.7265 38.5664 50.5004 38.3593 50.2229 38.2584Z"
                fill={`${current === 0 ? "white" : "#A6AEBA"}`}
              />
            </g>
            <defs>
              <clipPath id="clip0_250_3465">
                <rect
                  width="38"
                  height="38"
                  fill="white"
                  transform="translate(13 13)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <p className="flex items-center justify-center w-[10rem] text-center">
          Campaign Details
        </p>

        <div>
          {" "}
          <svg
            width="2"
            height="90"
            viewBox="0 0 2 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L0.999997 81"
              stroke="#A6AEBA"
              stroke-linecap="round"
              stroke-dasharray="3 3"
            />
          </svg>
        </div>
        <div
          className={`flex items-center justify-center w-[5rem] h-[5rem] ${
            current === 1 ? "bg-text-color-code1" : "bg-fill-color-code1"
          } rounded-full relative`}
        >
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.9312 34.3757H20.1875C19.4525 34.3757 18.875 33.7982 18.875 33.0632C18.875 32.3414 19.4525 31.7507 20.1875 31.7507H26.3825C26.015 30.9632 25.7263 30.1364 25.5163 29.2701H20.1875C19.4525 29.2701 18.875 28.6795 18.875 27.9576C18.875 27.2357 19.4525 26.6451 20.1875 26.6451H25.1356C25.1225 26.422 25.1225 26.212 25.1225 25.9889C25.1225 24.427 25.385 22.9307 25.8575 21.5264H18.35C15.7381 21.5264 13.625 23.6526 13.625 26.2514V39.8751C13.625 42.3032 15.4756 44.3245 17.8512 44.5607V47.3301C17.8512 48.1045 18.2844 48.787 18.98 49.1282C19.2556 49.2595 19.5575 49.3251 19.8462 49.3251C20.2925 49.3251 20.7256 49.1807 21.08 48.892L24.0331 46.5689C25.6344 45.2957 27.6556 44.587 29.7162 44.587H38.7987C41.3975 44.587 43.5238 42.4739 43.5238 39.8751V39.1926C42.1194 39.6651 40.6231 39.9276 39.0612 39.9276C34.52 39.9276 30.4775 37.7489 27.9312 34.3757ZM28.5744 39.4945H20.1875C19.4525 39.4945 18.875 38.9039 18.875 38.182C18.875 37.4601 19.4525 36.8695 20.1875 36.8695H28.5744C29.2963 36.8695 29.8869 37.4601 29.8869 38.182C29.8869 38.9039 29.2963 39.4945 28.5744 39.4945Z"
              fill={`${current === 1 ? "white" : "#A6AEBA"}`}
            />
            <path
              d="M39.0613 14.6748C32.8138 14.6748 27.7476 19.7542 27.7476 25.9886C27.7476 32.2361 32.8138 37.3023 39.0613 37.3023C45.2957 37.3023 50.3751 32.2361 50.3751 25.9886C50.3751 19.7542 45.2957 14.6748 39.0613 14.6748ZM39.0482 33.2729C38.3263 33.2729 37.7357 32.6823 37.7357 31.9604C37.7357 31.2386 38.3132 30.6479 39.0351 30.6479H39.0482C39.7701 30.6479 40.3607 31.2386 40.3607 31.9604C40.3607 32.6823 39.7701 33.2729 39.0482 33.2729ZM40.3738 27.2092V28.0098C40.3738 28.7317 39.7832 29.3223 39.0613 29.3223C38.3394 29.3223 37.7488 28.7317 37.7488 28.0098V26.1461C37.7488 25.4242 38.3394 24.8336 39.0613 24.8336C39.6782 24.8336 40.1901 24.3348 40.1901 23.7048C40.1901 23.0879 39.6782 22.5892 39.0613 22.5892C38.4444 22.5892 37.9326 23.0879 37.9326 23.7048C37.9326 24.4398 37.3419 25.0173 36.6201 25.0173C35.8982 25.0173 35.3076 24.4398 35.3076 23.7048C35.3076 21.6442 36.9876 19.9642 39.0613 19.9642C41.1219 19.9642 42.8151 21.6442 42.8151 23.7048C42.8151 25.3192 41.7913 26.6711 40.3738 27.2092Z"
              fill={`${current === 1 ? "white" : "#A6AEBA"}`}
            />
          </svg>
        </div>
        <p>Question Builder</p>
        {campaignType !== 2 && editData[4] !== "Mass" && (
          <span className="flex flex-col items-center">
            <div>
              {" "}
              <svg
                width="2"
                height="90"
                viewBox="0 0 2 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L0.999997 81"
                  stroke="#A6AEBA"
                  stroke-linecap="round"
                  stroke-dasharray="3 3"
                />
              </svg>
            </div>
            <div
              className={`flex items-center justify-center w-[5rem] h-[5rem] ${
                current === 2 ? "bg-text-color-code1" : "bg-fill-color-code1"
              } rounded-full relative`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  d="M17.2083 18.3962C22.0178 18.3962 25.9167 14.4973 25.9167 9.68783C25.9167 4.87835 22.0178 0.979492 17.2083 0.979492C12.3989 0.979492 8.5 4.87835 8.5 9.68783C8.5 14.4973 12.3989 18.3962 17.2083 18.3962Z"
                  fill={`${current === 2 ? "white" : "#A6AEBA"}`}
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.4742 33.4566C17.8227 31.6896 16.8126 29.3162 16.8126 26.7084C16.8126 24.0468 17.8655 21.6307 19.5755 19.851C18.8044 19.7924 18.0127 19.7607 17.2084 19.7607C11.9486 19.7607 7.292 21.0765 4.40083 23.0477C2.19525 24.5519 0.979248 26.463 0.979248 28.4691V30.7665C0.979248 31.479 1.26266 32.1646 1.76775 32.6697C2.27283 33.1732 2.95683 33.4582 3.67091 33.4582L19.4742 33.4566Z"
                  fill={`${current === 2 ? "white" : "#A6AEBA"}`}
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M26.7085 18.3965C22.12 18.3965 18.396 22.1205 18.396 26.709C18.396 31.2975 22.12 35.0215 26.7085 35.0215C31.297 35.0215 35.021 31.2975 35.021 26.709C35.021 22.1205 31.297 18.3965 26.7085 18.3965ZM27.896 25.5215V23.9382C27.896 23.2827 27.364 22.7507 26.7085 22.7507C26.053 22.7507 25.521 23.2827 25.521 23.9382V25.5215H23.9377C23.2822 25.5215 22.7502 26.0535 22.7502 26.709C22.7502 27.3645 23.2822 27.8965 23.9377 27.8965H25.521V29.4798C25.521 30.1353 26.053 30.6673 26.7085 30.6673C27.364 30.6673 27.896 30.1353 27.896 29.4798V27.8965H29.4793C30.1348 27.8965 30.6668 27.3645 30.6668 26.709C30.6668 26.0535 30.1348 25.5215 29.4793 25.5215H27.896Z"
                  fill={`${current === 2 ? "white" : "#A6AEBA"}`}
                />
              </svg>
            </div>
            <p>Add Candidate</p>
            {/* <div>
              {" "}
              <svg
                width="2"
                height="90"
                viewBox="0 0 2 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L0.999997 81"
                  stroke="#A6AEBA"
                  stroke-linecap="round"
                  stroke-dasharray="3 3"
                />
              </svg>
            </div> */}
            {/* <div
              className={`flex items-center justify-center w-[5rem] h-[5rem] ${
                current === 3 ? "bg-text-color-code1" : "bg-fill-color-code1"
              } rounded-full relative`}
            >
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.7499 38.0313C23.2825 38.033 24.758 37.4495 25.8749 36.4001L25.8782 36.3966L34.4695 41.7228L34.4687 41.7251C34.292 42.2893 34.2034 42.8775 34.2062 43.4688C34.2027 44.8614 34.6795 46.2126 35.5562 47.2946C36.4329 48.3766 37.656 49.1232 39.019 49.4085C40.3821 49.6937 41.8019 49.5002 43.0389 48.8606C44.276 48.221 45.2546 47.1744 45.8098 45.8972C46.365 44.6201 46.4628 43.1905 46.0868 41.8496C45.7107 40.5088 44.8838 39.3386 43.7455 38.5364C42.6071 37.7342 41.2269 37.3491 39.8377 37.446C38.4485 37.5429 37.1351 38.1159 36.1192 39.0684L27.531 33.7442C27.881 32.608 27.8812 31.3925 27.5312 30.2563L36.1192 24.9318C37.1319 25.8777 38.4396 26.4457 39.8222 26.5403C41.2047 26.6348 42.5776 26.25 43.7097 25.4507C44.8417 24.6514 45.6637 23.4865 46.0373 22.152C46.4109 20.8175 46.3132 19.3951 45.7607 18.1242C45.2082 16.8533 44.2347 15.8117 43.004 15.1746C41.7734 14.5376 40.3608 14.3441 39.0041 14.6267C37.6474 14.9093 36.4296 15.6508 35.5557 16.7263C34.6818 17.8018 34.2052 19.1455 34.2062 20.5313C34.2034 21.1226 34.292 21.7108 34.4687 22.2751L34.4695 22.2773L25.8782 27.6036L25.8749 27.6001C25.1573 26.9263 24.2858 26.4384 23.3364 26.1788C22.3869 25.9193 21.3884 25.896 20.4279 26.1109C19.4673 26.3259 18.574 26.7726 17.8258 27.4121C17.0775 28.0516 16.4971 28.8644 16.1351 29.7797C15.7732 30.695 15.6407 31.685 15.7492 32.6633C15.8577 33.6416 16.2039 34.5785 16.7576 35.3923C17.3113 36.206 18.0557 36.8719 18.9259 37.3319C19.7961 37.7919 20.7656 38.032 21.7499 38.0313Z"
                  fill={`${current === 3 ? "white" : "#A6AEBA"}`}
                />
              </svg>
            </div> */}
            {/* <p>Review & Share</p> */}
          </span>
        )}
      </section>
      <section
        className="flex flex-col my-6 text-sm mt-10 gap-4 mr-24"
        style={{ width: "-webkit-fill-available" }}
      >
        <p className="text-base">Step:{current + 1}</p>
        <div className="flex flex-col gap-[1.125rem] tracking-[2%]">
          <p className="text-2xl font-semibold">{step[current].name}</p>
          <p>
            {current === 3
              ? allReqData[0]
                ? allReqData[0]?.description
                : desc
              : ""}
          </p>
        </div>

        <div className="fill-available tracking-[2%] max-w-[52rem]">
          {current === 0 ? (
            <CampaignForm
              campaignType={campaignType}
              setCampaignType={setCampaignType}
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              setCurrent={setCurrent}
              allReqData={allReqData}
              setAllReqData={setAllReqData}
              editData={editData}
              backClicked={backClicked}
            />
          ) : current === 1 ? (
            <QuestionBuilder
              campaignType={campaignType}
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              setCurrent={setCurrent}
              setAllReqData={setAllReqData}
              jr_uid={jr_uid}
            />
          ) : current === 2 ? (
            <AddCandidate
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              setCurrent={setCurrent}
              setAllReqData={setAllReqData}
              uploadFile={uploadFile}
              setUploadFile={setUploadFile}
              excelData={excelData}
              setExcelData={setExcelData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              selectedData={selectedData}
              singleAddCandidate={singleAddCandidate}
              setSingleAddCandidate={setSingleAddCandidate}
              setSelectedData={setSelectedData}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              editData={editData}
              prevEmail={prevEmail}
              setPrevEmail={setPrevEmail}
            />
          ) : current === 3 ? (
            <ReviewCandidate
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              allReqData={allReqData}
              prevEmail={prevEmail}
            />
          ) : (
            <AddCandidate
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              setCurrent={setCurrent}
              setAllReqData={setAllReqData}
            />
          )}
        </div>

        <div className="w-[60%] pb-8 absolute bottom-[-7rem] right-[10rem] flex justify-end gap-2">
          <div
            className={`${current === 0 ? "hidden" : "block"}`}
            onClick={() => {
              setBackClicked(true);
              setCurrent(current - 1);
              window.scrollTo(0, 0);

              if (current === 1) {
              }
            }}
          >
            <Backbutton />
          </div>
          <div
            className={shouldHide ? "hidden" : "block"}
            onClick={(e) => {
              e.preventDefault();
              console.log(campaignType);
              if (current === 2) {
                sessionStorage.removeItem("isButtonDisabled");
              }
              setIsNextActivated(true);
              setBackClicked(false);
            }}
          >
            <Nextbutton campaignType={campaignType} current={current} />
          </div>
        </div>
      </section>
    </main>
  );
};

export const InputBox = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
  error,
}) => {
  return (
    <span className="flex flex-col ">
      <label htmlFor="">{name}</label>
      <input
        type={type}
        id={name?.toLowerCase().replace(/\s+/g, "")}
        placeholder={holder}
        className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px]"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
      {error && <div className="text-red-500"> {error} </div>}
    </span>
  );
};

export const TextBoxCustomNBuild = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
  error,
  regenerate,
  mandatory,
  edit,
  selectedOption,
  isHr,
}) => {
  return (
    <span className="flex flex-col ">
      <label
        htmlFor=""
        className={
          name.includes("HR ")
            ? "text-green-600 font-bold"
            : "text-blue-600 font-bold"
        }
      >
        {name}
      </label>
      {selectedOption === "option3" ? (
        <textarea
          type={type}
          id={name?.toLowerCase().replace(/\s+/g, "")}
          placeholder={holder}
          className={`p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] ${
            regenerate ? "text-green-600" : mandatory ? "text-blue-600" : ""
          }
          `}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          disabled={!edit && !isHr}
        />
      ) : (
        <textarea
          type={type}
          id={name?.toLowerCase().replace(/\s+/g, "")}
          placeholder={holder}
          className={`p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] ${
            regenerate ? "text-green-600" : mandatory ? "text-blue-600" : ""
          }
          `}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
        />
      )}
      {error && <div className="text-red-500"> {error} </div>}
    </span>
  );
};

export const InputBox1 = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
}) => {
  return (
    <span className="flex flex-col ">
      <label htmlFor="">{name}</label>
      <input
        type={type}
        id={name.toLowerCase().replace(/\s+/g, "")}
        placeholder={holder}
        className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
    </span>
  );
};

const CampaignForm = ({
  isNextAcitvated,
  setIsNextActivated,
  current,
  setCurrent,
  editData,
  setAllReqData,
  campaignType,
  setCampaignType,
  backClicked,
  allReqData,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let creatorId = location?.state?.creator;
  let creator = sessionStorage.getItem("uid");
  let userType = sessionStorage.getItem("userType");
  const [getCampaign, setCampaign] = useState([]);

  useEffect(() => {
    handleGetCampaigndata();
  }, []);

  const handleGetCampaigndata = async () => {
    const reqData = {
      is_deleted: 0,
      creator: "",
    };
    const response = await handleApiResponse(
      "/api/v1/interview/campaign_list/",
      reqData,
      1
    );
    setCampaign(response?.data?.data?.data);
  };

  const [campName, setCampName] = useState(
    editData[5]
      ? editData[5]
      : allReqData[0]
      ? allReqData[0].name
      : getLocal("campName")
  );

  const getLocalSkills = () => {
    const skills = getLocal("skills");
    return skills ? skills : [];
  };

  const initialSkills = editData[10]
    ? editData[10]
    : allReqData[0]
    ? allReqData[0]?.required_skills
    : "";

  const [campSkills, setCampSkills] = useState(() => {
    if (Array.isArray(initialSkills)) {
      return initialSkills;
    } else if (typeof initialSkills === "string") {
      const localSkills = getLocal("skills");
      if (Array.isArray(localSkills)) {
        return localSkills;
      } else if (localSkills) {
        return localSkills.split(",").map((skill) => skill.trim());
      } else {
        return [];
      }
    } else {
      return getLocalSkills();
    }
  });

  const [jobRoleUid, setJobRoleUid] = useState(
    editData[6]
      ? editData[6]
      : allReqData[0]
      ? allReqData[0]?.jobrole
      : getLocal("roleId")
  );
  const [campType, setCampType] = useState(() => {
    const localCampType = getLocal("camp_type");
    if (localCampType) {
      return localCampType === "1" ? 1 : 2;
    }

    if (editData[4]) {
      return editData[4] === "Regular" ? 1 : 2;
    }

    if (allReqData[0]) {
      return allReqData[0].campaign_type === 1 ? "Regular" : "Mass";
    }

    return null;
  });
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const [startDate, setStartDate] = useState(() => {
    const localStartDate = getLocal("createdDate");
    const initialStartDate =
      editData[2] ||
      (allReqData[0] ? allReqData[0].start_date : localStartDate);
    return formatDate(initialStartDate);
  });

  const [endDate, setEndDate] = useState(() => {
    const localEndDate = getLocal("endDate1");
    const initialEndDate =
      editData[3] || (allReqData[0] ? allReqData[0].end_date : localEndDate);
    return formatDate(initialEndDate);
  });

  const [isModalOpen3, setIsModalOpen3] = useState(false);

  const [endDateError, setEndDateError] = useState(false);

  const [clickedButtonResume, setClickedButtonResume] = useState(
    editData[8]
      ? editData[8]
      : allReqData[0]
      ? allReqData[0]?.is_candidate_resume_allow
      : false
  );
  const [clickedButtonCoding, setClickedButtonCoding] = useState(
    editData[9]
      ? editData[9]
      : allReqData[0]
      ? allReqData[0]?.is_coding_quest_allow
      : getLocal("coding_ques")
      ? getLocal("coding_ques")
      : false
  );
  const [isOlderDate, setIsOlderDate] = useState(true);
  const [uid, setUid] = useState(
    editData[0] ? editData[0] : allReqData[0] ? allReqData[0]?.uid : null
  );

  console.log(creator, creatorId, "creatorId", editData[0]);

  const [toggle, setToggle] = useState({
    jobRole: false,
    CampType: false,
  });
  const isResume = sessionStorage.getItem("isResume");

  const [campaignError, setCampaignError] = useState({
    campName: false,
    campNameErrorMsg: "",
    jobRole: false,
    campType: false,
    startDate: false,
    endDate: false,
    // description: false,
  });

  console.log(campaignError, "campaignError");
  const [jobroleValue, setjobroleValue] = useState(
    editData[1] ? editData[1] : allReqData[0] ? allReqData[0]?.jrName : ""
  );
  const [campTypeValue, setCampTypeValue] = useState(
    editData[4]
      ? editData[4]
      : allReqData[0]
      ? allReqData[0]?.campaign_type === 1
        ? "Regular"
        : "Mass"
      : ""
  );
  const [data, setData] = useState([{ name: "Fetching..." }]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    if (editData[0]?.length !== 0) {
      console.log("edit data in campForm", editData);
      sessionStorage.setItem("campaignUid", editData[0]);
    }
  }, []);

  useEffect(() => {
    setCampaignType(campType);
  }, [campType]);

  useEffect(() => {
    try {
      if (allReqData[0]) {
        console.log("the name of the camp *******", allReqData[0]?.start_date);
        console.log("this is the start date ******", startDate);
      }
    } catch (e) {
      console.log(e);
    }
  }, [backClicked]);

  const handleYesClick = (e) => {
    e.preventDefault();
    setClickedButtonResume(true);
  };

  const handleNoClick = (e) => {
    e.preventDefault();
    setClickedButtonResume(false);
  };

  const handleYes1Click = (e) => {
    e.preventDefault();
    setClickedButtonCoding(true);
  };

  const handleNo1Click = (e) => {
    e.preventDefault();
    setClickedButtonCoding(false);
  };

  useEffect(() => {
    if (jobRoleUid) {
      console.log(jobRoleUid);
      sessionStorage.setItem("roleId", jobRoleUid);
      sessionStorage.setItem("roleName", jobroleValue);
    }
  }, [jobRoleUid]);

  const handleCampNameChange = (e) => {
    let name = e.target.value.trimStart();

    if (name === "") {
      setCampaignError((prevState) => ({
        ...prevState,
        campName: true,
        campNameErrorMsg: "Fill a valid Campaign name",
      }));
    } else {
      const nameExists = getCampaign.some(
        (campaign) => campaign.name.toLowerCase() === name.toLowerCase()
      );

      if (nameExists) {
        setCampaignError((prevState) => ({
          ...prevState,
          campName: true,
          campNameErrorMsg:
            "This campaign name already exists. Please choose a different name.",
        }));
      } else {
        setCampaignError((prevState) => ({
          ...prevState,
          campName: false,
          campNameErrorMsg: "",
        }));
      }
    }

    setCampName(name);
  };

  const handleReqSkillsChange = (e) => {
    const skills = e.target.value;
    setCampSkills(skills);
  };

  const handleKeyDown1 = (e) => {
    const key = e.key;
    if (!key.match(/[a-zA-Z\s]/)) {
      e.preventDefault();
    }
  };

  // const handleKeyDown = (e) => {
  //   const key = e.key;

  //   // Get the current value of the input
  //   const currentValue = e.target.value;

  //   // Check if the first character is a space
  //   if (currentValue.length === 0 && key === " ") {
  //     e.preventDefault();
  //     return;
  //   }

  //   // Allow only alphabetic characters and spaces
  //   if (!key.match(/[a-zA-Z0-9\s]/)) {
  //     e.preventDefault();
  //   }
  // };

  const handleKeyDown = (e) => {
    const key = e.key;
    const currentValue = e.target.value;

    // Prevent the first character from being a space
    if (currentValue?.length === 0 && key === " ") {
      e.preventDefault();
    }
  };

  const openModalAddJobRole = () => {
    setIsModalOpen3(true);
  };

  const closeModalAddJobRole = () => {
    setIsModalOpen3(false);
  };

  console.log(campSkills.length);

  // const requiredSkills = campSkills.split(',').map(skill => skill.trim());
  console.log(typeof campSkills);
  const sendData = async () => {
    let requiredSkills = [];

    // Ensure campSkills is a string
    if (typeof campSkills === "string") {
      requiredSkills = campSkills.split(",").map((skill) => skill.trim());
    } else {
      console.error("campSkills is not a string:", campSkills);
      requiredSkills = campSkills;
      // Handle the case where campSkills is not a string
      // For example, initialize requiredSkills with an empty array or some default value
    }

    console.log(requiredSkills, "requiredSkills", campSkills);
    const reqData = {
      name: campName,
      jobrole: jobRoleUid,
      campaign_type: campType,
      start_date: startDate + "T00:00:00Z",
      end_date: endDate + "T23:59:00Z",
      is_candidate_resume_allow: true,
      is_coding_quest_allow: clickedButtonCoding,
      // description: desc,
      ...(campSkills.length > 0 && {
        required_skills:
          requiredSkills.length > 0 ? requiredSkills : campSkills,
      }),
    };

    const editReqData = {
      name: campName,
      jobrole: jobRoleUid,
      campaign_type: campType,
      start_date: startDate + "T00:00:00Z",
      end_date: endDate + "T23:59:00Z",
      uid: uid,
      is_candidate_resume_allow: true,
      is_coding_quest_allow: clickedButtonCoding,
      // description: desc,
      ...(campSkills.length > 0 && {
        required_skills:
          requiredSkills.length > 0 ? requiredSkills : campSkills,
      }),
    };

    try {
      clearError();
      const response = await handleApiResponse(
        "/api/v1/interview/add_campaign/",
        uid !== undefined || creator === creatorId ? editReqData : reqData,
        1
      );

      if (response?.data?.status_code === 200) {
        setAllReqData((prev) => {
          let data = prev;
          if (data) {
            reqData["jrName"] = jobroleValue;
            reqData["uid"] = response?.data?.data?.uid;

            data[0] = reqData;
          }
          return data;
        });
        setCampaignType(response?.data?.data?.campaign_type);
        toast.success(`${uid ? "edited" : "added"}`);
        clearError();
        sessionStorage.setItem("campaignUid", response?.data?.data?.uid);
        sessionStorage.setItem("campName", response?.data?.data?.name);
        sessionStorage.setItem("jrName", response?.data?.data?.jr_name);
        sessionStorage.setItem(
          "camp_type",
          response?.data?.data?.campaign_type
        );
        sessionStorage.setItem("skills", response?.data?.data?.required_skills);
        sessionStorage.setItem("createdDate", response?.data?.data?.start_date);
        sessionStorage.setItem("endDate1", response?.data?.data?.end_date);
        sessionStorage.setItem(
          "coding_ques",
          response?.data?.data?.is_coding_quest_allow
        );
        setCurrent(1);
      } else {
        const errorMsg = response?.data?.msg;
        if (
          errorMsg?.jobrole &&
          Array.isArray(errorMsg.jobrole) &&
          errorMsg.jobrole[0]
        ) {
          toast.error("Job role does not exist");
        } else {
          toast.error("Failed");
        }
      }
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("An unexpected error occurred.");
    }
  };

  const isDataValidToSend = () => {
    let isValid = 1;
    let errorsInData = Object.values(campaignError);
    if (errorsInData.includes(true)) {
      isValid = 0;
    }
    if (!isOlderDate) {
      isValid = 0;
    }
    if (!campName) {
      console.log("set campName");
      setCampaignError((prevState) => ({
        ...prevState,
        campName: true,
      }));
      isValid = 0;
    }

    if (!jobRoleUid) {
      console.log("set jobRole");
      setCampaignError((prevState) => ({
        ...prevState,
        jobRole: true,
      }));
      isValid = 0;
    }

    if (!campType) {
      console.log("set campType");
      setCampaignError((prevState) => ({
        ...prevState,
        campType: true,
      }));
      isValid = 0;
    }
    if (!startDate) {
      console.log("set start_date");
      setCampaignError((prevState) => ({
        ...prevState,
        start_date: true,
      }));
      isValid = 0;
    }
    if (!endDate) {
      console.log("set end_date");
      setCampaignError((prevState) => ({
        ...prevState,
        end_date: true,
      }));
      isValid = 0;
    }

    // if (!desc) {
    //   console.log("set end_date");
    //   setCampaignError((prevState) => ({
    //     ...prevState,
    //     description: true,
    //   }));
    //   isValid = 0;
    // }

    return isValid;
  };

  const clearError = () => {
    setCampaignError({
      campName: false,
      jobRole: false,
      campType: false,
      startDate: false,
      endDate: false,
      // description: false,
    });
  };

  useEffect(() => {
    setCampaignError((prevState) => ({
      ...prevState,
      start_date: false,
    }));
  }, [startDate]);

  useEffect(() => {
    setCampaignError((prevState) => ({
      ...prevState,
      end_date: false,
    }));
  }, [endDate]);

  useEffect(() => {
    setCampaignError((prevState) => ({
      ...prevState,
      jobRole: false,
    }));
  }, [jobRoleUid, jobroleValue]);

  useEffect(() => {
    setCampaignError((prevState) => ({
      ...prevState,
      campType: false,
    }));
  }, [campType]);

  useEffect(() => {
    if (isNextAcitvated) {
      console.log("this ran");
      if (current === 0) {
        console.log("this is 0");
        console.log(isDataValidToSend());
        if (isDataValidToSend()) {
          sendData();
        }
      }
      setIsNextActivated(false);
    }
  }, [isNextAcitvated]);

  const Error = ({ msg }) => {
    return <div className="text-red-500">{msg}</div>;
  };

  const closeToggle = () => {
    setToggle((prev) => ({
      ...prev,
      jobRole: false,
      CampType: false,
    }));
  };

  // console.log(isPopupVisible);
  // useEffect(() => {
  //   if (jobroleValue) {
  //     setIsPopupVisible(true);
  //   }
  // }, [jobroleValue]);
  // console.log(startDate);
  console.log(
    "clickedButtonCoding",
    clickedButtonCoding,
    typeof clickedButtonCoding,
    clickedButtonCoding === (false || "false")
  );
  // console.log(typeof(clickedButtonCoding));
  return (
    <form action="" className=" flex flex-col gap-6 fill-available">
      {/* {isPopupVisible && (
        <ModalChangeCampaign
          isOpen={isPopupVisible}
          // deleteUid={deleteUid}
          // onClose={}
        />
      )} */}
      {toggle.jobRole || toggle.CampType ? (
        <div
          className="absolute z-10 w-[100vw] h-[100vh] top-0 left-0"
          onClick={closeToggle}
        ></div>
      ) : null}
      <div className=" grid grid-cols-2 gap-6">
        <span className="flex flex-col ">
          <label htmlFor="">
            Campaign Name
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            placeholder="Enter campaign name"
            value={campName}
            className="w-[55vw] p-3 rounded-lg outline-0 mt-2 placeholder:text-sm placeholder:tracking-[0.6px]"
            onChange={handleCampNameChange}
            // onKeyDown={handleKeyDown1}
          />
          {campaignError.campNameErrorMsg === ""
            ? campaignError["campName"] && (
                <Error msg={"Fill a valid Campaign name"} />
              )
            : campaignError?.campName && (
                <Error msg={campaignError.campNameErrorMsg} />
              )}

          <div className="flex justify-between w-[55vw] mt-4">
            <label htmlFor="">
              Job Role<span className="text-red-500">*</span>
            </label>
            <div
              className="font-[600] cursor-pointer"
              onClick={openModalAddJobRole}
            >
              + Add new job role
            </div>
            {isModalOpen3 && (
              <ModalAddJobRole
                onClose={closeModalAddJobRole}
                setData={setData}
                setjobroleValue={setjobroleValue}
                setJobRoleUid={setJobRoleUid}
              />
            )}
          </div>
          <span>
            <DropdownJobRole
              holder="Select job role"
              jobroleValue={jobroleValue}
              setjobroleValue={setjobroleValue}
              setJobRoleUid={setJobRoleUid}
              toggle={toggle}
              setToggle={setToggle}
              setData={setData}
              data={data}
              isPopupVisible={isPopupVisible}
              // setIsPopupVisible={setIsPopupVisible}
            />
          </span>
          {campaignError["jobRole"] && <Error msg={"Fill a valid Job Role"} />}
          <label className="mt-4" htmlFor="">
            Campaign Type<span className="text-red-500">*</span>
          </label>
          <span>
            <Dropdown
              holder="Select campaign type"
              campTypeValue={campTypeValue}
              setCampTypeValue={setCampTypeValue}
              setCampType={setCampType}
              toggle={toggle}
              setToggle={setToggle}
            />
          </span>
          {campaignError["campType"] && (
            <Error msg={"Fill a valid Campaign Type"} />
          )}
          <div className="flex items-center mb-2">
            <label htmlFor="skills" className="mt-4">
              Technical Skills Required <b>(Optional)</b>
            </label>
            <FaInfoCircle
              data-tooltip-id="skillsInfo"
              className="ml-2 cursor-pointer text-gray-500"
            />
            <Tooltip id="skillsInfo" place="top" effect="solid">
              Coding questions will be asked based on the skills you enter.
            </Tooltip>
          </div>

          <input
            id="skills"
            type="text"
            placeholder="Enter technical skills required"
            className="w-[55vw] p-3 rounded-lg outline-0 placeholder:text-sm placeholder:tracking-[0.6px]"
            onChange={handleReqSkillsChange}
            value={campSkills}
          />

          <div className="flex gap-4 mt-4">
            <div>
              <label htmlFor="">
                Campaign Start Date<span className="text-red-500">*</span>
              </label>
              <Calander
                setStartDate={setStartDate}
                endDate={endDate}
                editStartDate={
                  allReqData[0]
                    ? allReqData[0]?.start_date
                    : editData[2]?.length
                    ? editData[2]
                    : getLocal("createdDate")
                    ? getLocal("createdDate")
                    : ""
                }
              />
              {campaignError["start_date"] && (
                <Error msg={"Fill a valid Start Date"} />
              )}
            </div>
            <div>
              <label htmlFor="">
                Campaign End Date<span className="text-red-500">*</span>
              </label>
              {startDate || editData[2] ? (
                <Calander2
                  setEndDate={setEndDate}
                  startDate={startDate}
                  setIsOlderDate={setIsOlderDate}
                  editEndDate={
                    allReqData[0]
                      ? allReqData[0]?.end_date
                      : editData[3]?.length
                      ? editData[3]
                      : getLocal("endDate1")
                      ? getLocal("endDate1")
                      : ""
                  }
                />
              ) : (
                <span>
                  <span className="relative">
                    <input
                      className=" relative mt-2 w-[27vw] p-3 outline-0 rounded-lg"
                      type="text"
                      placeholder="Select end date"
                      readOnly
                      onClick={() => setEndDateError(true)}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-400 pointer-events-none">
                      <RiCalendar2Line />
                    </div>
                  </span>

                  {endDateError && <Error msg={"First fill Start Date"} />}
                </span>
              )}
              {campaignError["end_date"] && isOlderDate && (
                <Error msg={"Fill a valid End Date"} />
              )}

              {!isOlderDate && (
                <Error msg={"End date cannot be older than start date"} />
              )}
            </div>
          </div>
          {/* <div className="flex"> */}
          {/* {isResume === 'true' && (
            <div className="gap-5 flex items-center mt-4">
              <div>Do you want to upload resume?</div>
              <div className="flex">
                <button
                  id="yesButton"
                  className={`rounded bg-${
                    clickedButtonResume === true ? '[#F1F2F4]' : 'white'
                  } text-[#A6AEBA] w-[74px] h-[40px] `}
                  onClick={(e) => handleYesClick(e)}
                >
                  Yes
                </button>
                <button
                  id="noButton"
                  className={`rounded bg-${
                    clickedButtonResume === false ? '[#F1F2F4]' : 'white'
                  } text-[#A6AEBA] w-[74px] h-[40px] `}
                  onClick={(e) => handleNoClick(e)}
                >
                  No
                </button>
              </div>
            </div>
          )} */}
          <div className="gap-5 flex items-center mt-4">
            <div>Do you want coding question?</div>
            <div className="flex">
              <button
                id="yesButton"
                className={`rounded ${
                  clickedButtonCoding === (true || "true")
                    ? "bg-[#F1F2F4]"
                    : "bg-white"
                } text-[#A6AEBA] w-[74px] h-[40px]`}
                onClick={(e) => handleYes1Click(e)}
              >
                Yes
              </button>
              <button
                id="noButton"
                className={`rounded ${
                  clickedButtonCoding === false ||
                  clickedButtonCoding === "false"
                    ? "bg-[#F1F2F4]"
                    : "bg-white"
                } text-[#A6AEBA] w-[74px] h-[40px]`}
                onClick={(e) => handleNo1Click(e)}
              >
                No
              </button>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="mt-4">
            <label htmlFor="">
              Brief description<span className="text-red-500">*</span>
            </label>
            <textarea
              type="text"
              placeholder="Enter description"
              className="w-[55vw] h-[6vw] p-3 rounded-lg outline-0 mt-2 placeholder:text-sm placeholder:tracking-[0.6px]"
              onChange={handleDescChange}
              value={desc}
              onKeyDown={handleKeyDown}
            />
            {campaignError["description"] && (
              <Error msg={"Fill a valid Description"} />
            )}
          </div> */}
        </span>
      </div>
    </form>
  );
};

const QuestionBuilder = ({
  isNextAcitvated,
  setIsNextActivated,
  current,
  setCurrent,
  setAllReqData,
  campaignType,
}) => {
  const navigate = useNavigate();
  const [resData, setResData] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [buttonContent, setButtonContent] = useState("Generate Questions");
  const [multipleError, setmMultipleError] = useState("");
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [questions, setQuestions] = useState([
    {
      id: 1,
      name: "Question 1",
      question: "",
      answer: "",
      QError: "",
      AError: "",
      isHr: true,
      addedHrQuestion: true,
      mandatory: false,
    },
  ]);
  const [questionDeletePopup, setQuestionDeletePopup] = useState(false);
  const [isQuestGenerate, setQuestGenerate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hint, setHint] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [questions2, setQuestions2] = useState([{ id: 1, name: "Question 1" }]);
  const [questions1, setQuestions1] = useState([]);
  const [questions3, setQuestions3] = useState([]);
  const [hrQuesCount, setHrQuesCount] = useState(0);
  const [deletedQuestions, setDeletedQuest] = useState([]);
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [count, setCount] = useState(0);
  const [questionsAllowed, setQuestionsAllowed] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [blendedmandatoryCount, setBlendedMandatoryCount] = useState(0);
  const [editIndex, setEditIndex] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [builderOption, setBuilderOption] = useState();
  const [builderMandatory, setBuilderMandatory] = useState(false);

  console.log(selectedOption, "selectedOption");

  const getId = async () => {
    const reqData = {
      campaign: sessionStorage.getItem("campaignUid"),
    };
    const response = await handleApiResponse(
      "/api/v1/interview/question/get_question/",
      reqData,
      1
    );
    console.log(response, "response");
    if (response?.data?.status_code === 200) {
      setIsUpdate(response?.data?.data?.uid);
    }
  };

  const getQuestions = async () => {
    const reqData = {
      campaign: sessionStorage.getItem("campaignUid"),
    };
    const response = await handleApiResponse(
      "/api/v1/interview/question/get_question/",
      reqData,
      1
    );
    if (response?.data?.status_code === 200) {
      setIsUpdate(response?.data?.data?.uid);
      console.log(response?.data?.data);
      setSelectedOption(`option${response?.data?.data?.quest_type}`);

      const prevQuestion = response?.data?.data?.quest;
      if (response?.data?.data?.quest_type === 1) {
        const updatedQuestions = prevQuestion.map((item, index) => ({
          id: index + 1,
          name: `Question ${index + 1}`,
          question: item.question,
          answer: item.answer,
          QError: "",
          AError: "",
          mandatory: item.mandatory,
        }));
        setQuestions1(updatedQuestions);
      } else if (response?.data?.data?.quest_type === 2) {
        const updatedQuestions = prevQuestion.map((item, index) => ({
          id: index + 1,
          name: `Question ${index + 1}`,
          question: item.question,
          answer: item.answer,
          QError: "",
          AError: "",
          isHr: item.isHr,
          mandatory: item.mandatory,
          addedHrQuestion: item.addedHrQuestion,
        }));
        setQuestions(updatedQuestions);
      } else if (response?.data?.data?.quest_type === 3) {
        let count = prevQuestion.filter((item) => item.mandatory);
        setBlendedMandatoryCount(count?.length);
        const hrQuestion = prevQuestion.filter((item) => item.mandatory);
        const nonHrQuestion = prevQuestion.filter((item) => !item.mandatory);
        const updatedQuestions = nonHrQuestion.map((item, index) => ({
          id: index + 1,
          name: `Question ${index + 1}`,
          question: item.question,
          answer: item.answer,
          QError: "",
          AError: "",
          isHr: item?.isHr,
          isEdit: item?.isEdit,
          mandatory: item.mandatory,
        }));
        const updatedHrQuestions = hrQuestion.map((item, index) => ({
          id: index + 1,
          name: `Question ${index + 1}`,
          question: item.question,
          answer: item.answer,
          QError: "",
          AError: "",
          isHr: item?.isHr,
          isEdit: item?.isEdit,
          mandatory: item.mandatory,
        }));
        setQuestions3([...updatedQuestions, ...updatedHrQuestions]);
      }
    }
  };

  useEffect(() => {
    setAllReqData((prev) => {
      let data = prev;
      data[1] = selectedOption;
      return data;
    });
  }, [selectedOption]);

  useEffect(() => {
    getId();
    if (
      getLocal("isButtonDisabled") === null ||
      getLocal("isButtonDisabled") !== "false"
    ) {
      console.log(getLocal("isButtonDisabled"));
      getQuestions();
    }
  }, []);

  const handleAddQuestion = () => {
    sessionStorage.setItem("hrQuestion", "option2");
    const newId = questions?.length + 1;
    setQuestions([
      ...questions,
      {
        id: newId,
        name: `Question ${newId}`,
        question: "",
        answer: "",
        QError: "",
        AError: "",
        isHr: true,
        mandatory: false,
        addedHrQuestion: true,
      },
    ]);
  };

  const handleUploadQuestionAnswer = async (data) => {
    sessionStorage.setItem("hrQuestion", "option2");
    console.log("received data", data);
    if (data.length === 0) {
      return;
    }
    const newId = questions?.length;
    if (newId === 1 && questions[0]?.question?.length === 0) {
      const dataToSet = data.map((ele, index) => {
        return {
          id: index + 1,
          name: `Question ${index + 1}`,
          question: ele[0],
          answer: ele[1],
          QError: "",
          AError: "",
          isHr: true,
          mandatory: false,
          addedHrQuestion: true,
        };
      });

      console.log(dataToSet.flat());
      setQuestions([...dataToSet]);
    } else {
      const dataToSet = data.map((ele, index) => {
        return {
          id: newId + index,
          name: `Question ${newId + index + 1}`,
          question: ele[0],
          answer: ele[1],
          QError: "",
          AError: "",
          isHr: true,
          mandatory: false,
          addedHrQuestion: true,
        };
      });

      console.log(dataToSet.flat());
      setQuestions([...questions, ...dataToSet]);
    }
  };

  const handleAddQuestion3 = () => {
    if (blendedmandatoryCount < 3) {
      const newId = blendedmandatoryCount + 1;
      setBlendedMandatoryCount(newId);
      setQuestions3([
        ...questions3,
        {
          name: `Question ${newId}`,
          question: "",
          answer: "",
          QError: "",
          AError: "",
          gen: "user",
          isHr: true,
          mandatory: true,
          isEdit: false,
        },
      ]);
    } else {
      toast.warning("Maximum 3 Hr or Mandatory Questions are allowed to add !");
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setDeletedQuest([]);
    setButtonContent("Generate Questions");
    setQuestions3([]);
    setQuestions1([]);
    setQuestions2([]);
    setQuestions([
      {
        id: 1,
        name: "Question 1",
        question: "",
        answer: "",
        QError: "",
        AError: "",
        isHr: true,
        mandatory: false,
        addedHrQuestion: true,
      },
    ]);
    setCheckedQuestions([]);
    sessionStorage.removeItem("hrQuestion");
    setCount(0);
    // fetchData(false, false, true);
    setBuilderOption();
    setBuilderMandatory();
    // handleTabChange();
  };

  // const handleTabChange = async () => {
  //   const data = {
  //     quest: [],
  //     campaign: sessionStorage.getItem("campaignUid"),
  //     quest_type:
  //       selectedOption === "option1" ? 1 : selectedOption === "option2" ? 2 : 3,
  //     quest_count: 0,
  //     uid: isUpdate,
  //   };
  //   const response = await handleApiResponse(
  //     "/api/v1/interview/question/add_question/",
  //     data,
  //     1
  //   );
  //   if (response?.data?.status_code === 200) {
  //     if (campaignType === 1) {
  //       getId();
  //     }
  //   }
  // };

  const handleDeleteQuestion = (index) => {
    if (selectedOption === "option1" && questions1?.length > 0) {
      if (!checkedQuestions[index]) {
        const confirmDelete = () => {
          setDeletedQuest((prev) => [...prev, questions1[index]]);
          const updatedQuestions1 = questions1.filter(
            (_, idx) => idx !== index
          );
          setCount(count + 1);
          setQuestionsAllowed(true);
          setQuestions1(updatedQuestions1);
        };

        setQuestionDeletePopup(true);
        setConfirmDelete(() => confirmDelete);
      }
    } else if (selectedOption === "option3" && questions3?.length > 0) {
      if (!checkedQuestions[index]) {
        const isHRQuestion = questions3[index].mandatory;
        const updatedQuestions3 = questions3.filter((_, idx) => idx !== index);

        const confirmDelete = () => {
          if (isHRQuestion) {
            setBlendedMandatoryCount(blendedmandatoryCount - 1);
            let hrIndex = 1;
            updatedQuestions3?.forEach((item) => {
              if (item.mandatory) {
                item.name = `Question ${hrIndex}`;
                hrIndex += 1;
              }
            });
          }
          setCount(count + 1);
          setQuestionsAllowed(true);
          setQuestions3(updatedQuestions3);
        };

        setQuestionDeletePopup(true);
        setConfirmDelete(() => confirmDelete);
      }
    }
  };

  const addQuestions = (q, a, index) => {
    if (checkedQuestions?.length) {
      if (selectedOption === "option1") {
        questions1.map((item, ind) => {
          if (ind === checkedQuestions[index - 1]) {
            item.question = q;
            item.answer = a;
            item.regenerate = true;
          }
          if (Number(index) === checkedQuestions?.length) {
            setCheckedQuestions([]);
          }
          return item;
        });
      } else {
        questions3.map((item, ind) => {
          if (ind === checkedQuestions[index - 1]) {
            item.question = q;
            item.answer = a;
            item.regenerate = true;
          }
          if (Number(index) === checkedQuestions?.length) {
            setCheckedQuestions([]);
          }
          return item;
        });
      }

      setCount(count + 1);
      setQuestionsAllowed(true);
      setLoader(false);
    } else if (builderOption === 1) {
      const newId = questions1?.length;
      setQuestions1([
        ...questions1,
        {
          id: newId,
          name: `Question ${newId}`,
          question: q,
          answer: a,
          QError: "",
          AError: "",
          regenerate: false,
          mandatory: false,
        },
      ]);
      setCount(count + 1);
      setQuestionsAllowed(true);
      setLoader(false);
    } else if (builderOption === 3) {
      const newId = questions3?.length;
      const nonMandatoryQuestion = questions3.filter(
        (item) => !item?.mandatory
      );
      const mandatoryQuestion = questions3.filter((item) => item?.mandatory);

      setQuestions3([
        ...nonMandatoryQuestion,
        {
          id: newId,
          name: `Question ${newId}`,
          question: q,
          answer: a,
          QError: "",
          AError: "",
          regenerate: false,
          mandatory: false,
          isEdit: false,
          isHr: false,
        },
        ...mandatoryQuestion,
      ]);

      setCount(count + 1);
      setQuestionsAllowed(true);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (resData) {
      console.log(resData.trim(), "works");
      let data = JSON.parse(resData.trim());
      console.log(data.data, "works");
      addQuestions(
        data?.data?.question,
        data?.data?.answer,
        data?.data?.current_question
      );
    }
  }, [resData]);

  const handleGenerateQuestions = async (option, mandatory) => {
    let data = {
      jobrole: sessionStorage.getItem("roleId"),
      campaign_uid: sessionStorage.getItem("campaignUid"),
    };

    let mandatoryIndices = [];
    let blendedMandatoryIndices = [];

    if (questions1) {
      questions1?.forEach((item, index) => {
        if (item?.mandatory) {
          mandatoryIndices?.push(index);
        }
      });
    }

    if (questions3) {
      questions3?.forEach((item, index) => {
        if (item?.mandatory) {
          blendedMandatoryIndices?.push(index);
        }
      });
    }

    let filteredCheckedQuestions = checkedQuestions?.filter((item) => {
      return !mandatoryIndices?.includes(item);
    });

    let BlendedFilteredCheckedQuestions = checkedQuestions?.filter((item) => {
      return !blendedMandatoryIndices?.includes(item);
    });

    if (option === 1) {
      data["question"] = [...questions1];
      data["deleted_count"] =
        checkedQuestions?.length > 0 && mandatory === true
          ? 0
          : checkedQuestions?.length > 0 && mandatory === false
          ? 0
          : checkedQuestions?.length !== filteredCheckedQuestions?.length
          ? 0
          : filteredCheckedQuestions?.length
          ? filteredCheckedQuestions?.length
          : 20 - questions1?.length;
      data["total_question"] = 20 - questions1?.length;
    } else if (option === 3) {
      let fill = questions3.filter((item) => !item.isHr);
      const sentQuestion = fill.map((item, index) => ({
        question: item.question,
        answer: item.answer,
        isHr: item.gen ? true : false,
      }));

      data["question"] = [...sentQuestion];
      data["deleted_count"] =
        checkedQuestions?.length > 0 && mandatory === true
          ? 0
          : checkedQuestions?.length > 0 && mandatory === false
          ? 0
          : BlendedFilteredCheckedQuestions?.length !== checkedQuestions?.length
          ? 0
          : BlendedFilteredCheckedQuestions?.length
          ? BlendedFilteredCheckedQuestions?.length
          : 20 - questions3?.length;

      data["total_question"] = 20 - questions3?.length;
    }

    const commonElementsCount = checkedQuestions?.filter((item) =>
      mandatoryIndices?.includes(item)
    )?.length;

    const commonElementsCount1 = checkedQuestions?.filter((item) =>
      blendedMandatoryIndices?.includes(item)
    )?.length;

    let mandatoryWarningShown = false;
    const showWarningNonMadatory = () => {
      if (!mandatoryWarningShown) {
        toast.warning(
          "Select only non-mandated questions to make them mandated !"
        );
        mandatoryWarningShown = true;
      }
    };

    const showWarning = () => {
      if (!mandatoryWarningShown) {
        toast.warning(`Maximum limit of 20 questions and answers reached!`);
        mandatoryWarningShown = true;
      }
    };
    if (
      data["question"].length === 0 ||
      buttonContent === "Regenerate Question"
    ) {
      setLoader(true);
    }

    if (commonElementsCount > 0 || commonElementsCount1 > 0) {
      showWarningNonMadatory();
      setCheckedQuestions([]);
      setLoader(false);
    } else if (data["deleted_count"] === 0) {
      showWarning();
      setLoader(false);
    } else {
      fetchData(setResData, data);
      setBuilderOption(option);
      setBuilderMandatory(mandatory);
    }
  };

  const handleMandatory = (option, mandatory) => {
    let mandatoryIndices = [];
    let blendedMandatoryIndices = [];

    if (questions1) {
      questions1?.forEach((item, index) => {
        if (item?.mandatory) {
          mandatoryIndices?.push(index);
        }
      });
    }

    if (questions3) {
      questions3?.forEach((item, index) => {
        if (item?.mandatory) {
          blendedMandatoryIndices?.push(index);
        }
      });
    }

    let filteredCheckedQuestions = checkedQuestions?.filter((item) => {
      return !mandatoryIndices?.includes(item);
    });

    let BlendedFilteredCheckedQuestions = checkedQuestions?.filter((item) => {
      return !blendedMandatoryIndices?.includes(item);
    });

    const newQuestions = [];
    if (option === 1) {
      let mandatoryWarningShown = false;
      let nonMandatoryWarningShown = false;
      const showWarningNonMadatory = () => {
        if (!mandatoryWarningShown) {
          toast.warning(
            "Select only non-mandated questions to make them mandated !"
          );
          mandatoryWarningShown = true;
        }
      };

      const showWarning = () => {
        if (!mandatoryWarningShown) {
          toast.warning(
            "Select mandated questions to make them non-mandated !"
          );
          mandatoryWarningShown = true;
        }
      };
      const showWarningRegenerate = () => {
        if (!mandatoryWarningShown) {
          toast.warning(
            "Perform the non-mandated action to regenerate the question !"
          );
          mandatoryWarningShown = true;
        }
      };
      const commonElementsCount = checkedQuestions.filter((item) =>
        mandatoryIndices.includes(item)
      )?.length;

      // console.log(commonElementsCount);

      const updatedQuestions = questions1.map((item, index) => {
        if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length + mandatoryIndices?.length <= 3 &&
          mandatory &&
          commonElementsCount === 0
        ) {
          return {
            name: `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            regenerate: false,
            mandatory: true,
          };
        } else if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length > 3 &&
          mandatory
        ) {
          if (!mandatoryWarningShown) {
            toast.warning("A maximum of 3 mandated questions can be added !");
            mandatoryWarningShown = true;
          }
          return item;
        } else if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length + mandatoryIndices?.length > 3 &&
          mandatory &&
          commonElementsCount === 0
        ) {
          if (!nonMandatoryWarningShown) {
            toast.warning("A maximum of 3 mandatory questions can be added !");
            nonMandatoryWarningShown = true;
          }
          return item;
        } else if (
          checkedQuestions.includes(index) &&
          mandatoryIndices.length > 0 &&
          mandatory !== undefined &&
          !mandatory
        ) {
          return {
            name: `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            regenerate: item.regenerate,
            mandatory: false,
          };
        } else if (
          checkedQuestions.includes(index) &&
          filteredCheckedQuestions.length > 0 &&
          newQuestions.length > 0 &&
          mandatory === undefined
        ) {
          const newQuestion = newQuestions.shift();
          return {
            name: `Question ${index + 1}`,
            question: newQuestion.question,
            answer: newQuestion.answer,
            QError: "",
            AError: "",
            regenerate: true,
            mandatory: false,
          };
        } else {
          commonElementsCount > 0 &&
            builderMandatory &&
            showWarningNonMadatory();
          checkedQuestions?.length === filteredCheckedQuestions?.length &&
            mandatory !== undefined &&
            !mandatory &&
            showWarning();

          commonElementsCount > 0 &&
            mandatory === undefined &&
            showWarningRegenerate();
          return {
            name: `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            regenerate: item.regenerate,
            mandatory: item.mandatory,
          };
        }
      });

      const updateGenerateQues = newQuestions.map((item, index) => ({
        name: `Question ${index + 1}`,
        question: item.question,
        answer: item.answer,
        QError: "",
        AError: "",
        regenerate: false,
        mandatory: false,
      }));
      setQuestions1([...updatedQuestions, ...updateGenerateQues]);
    } else {
      let warningShown = false;

      const updatedQuestions = questions3.map((item, index) => {
        const showWarning = () => {
          if (!warningShown) {
            toast.warning("A maximum of 3 mandatory questions can be added !");
            warningShown = true;
          }
        };

        const showWarningNonMadatorys = () => {
          if (!warningShown) {
            toast.warning(
              "Select mandated questions to make them non-mandated !"
            );
            warningShown = true;
          }
        };

        const showWarningRegenerate = () => {
          if (!warningShown) {
            toast.warning(
              "Perform the non-mandated action to regenerate the question !"
            );
            warningShown = true;
          }
        };

        const showWarningNonMadatory = () => {
          if (!warningShown) {
            toast.warning(
              "Select only non-mandated questions to make them mandated !"
            );
            warningShown = true;
          }
        };

        const showConfirmationMandatory = () => {
          if (!warningShown) {
            toast.success("Selected question mandated and moved to Hr !");
            warningShown = true;
          }
        };

        const commonElementsCount = checkedQuestions.filter((item) =>
          blendedMandatoryIndices.includes(item)
        )?.length;

        if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length + blendedMandatoryIndices?.length <= 3 &&
          mandatory &&
          commonElementsCount === 0
        ) {
          setBlendedMandatoryCount(
            checkedQuestions?.length + blendedMandatoryIndices?.length
          );
          // console.log("first", item);
          mandatory &&
            blendedMandatoryIndices?.length < 3 &&
            showConfirmationMandatory();
          return {
            name: item.isHr ? item.name : `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            isEdit: item.isEdit || false,
            isHr: item.isHr ? true : false,
            regenerate: false,
            mandatory: true,
          };
        } else if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length + blendedMandatoryIndices?.length > 3 &&
          mandatory
        ) {
          // console.log("second");
          if (
            checkedQuestions?.length + blendedMandatoryIndices?.length > 3 &&
            commonElementsCount === 0
          ) {
            showWarning();
          } else {
            showWarningNonMadatory();
          }
          return item;
        } else if (
          checkedQuestions.includes(index) &&
          blendedMandatoryIndices.length > 0 &&
          mandatory !== undefined &&
          !mandatory
        ) {
          const commonElementsCount = checkedQuestions.filter((item) =>
            blendedMandatoryIndices.includes(item)
          )?.length;
          setBlendedMandatoryCount(blendedmandatoryCount - commonElementsCount);
          // console.log("third");
          return {
            name: `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            isEdit: false,
            regenerate: item.regenerate,
            mandatory: false,
            isHr: false,
          };
        } else if (
          checkedQuestions.includes(index) &&
          BlendedFilteredCheckedQuestions.length > 0 &&
          newQuestions.length > 0 &&
          mandatory === undefined
        ) {
          // console.log("fourth");
          const newQuestion = newQuestions.shift();
          return {
            name: `Question ${index + 1}`,
            question: newQuestion.question,
            answer: newQuestion.answer,
            QError: "",
            AError: "",
            isEdit: false,
            regenerate: true,
            mandatory: false,
            isHr: false,
          };
        } else {
          let fill = questions3.filter((item) => item?.isEdit);
          let fill1 = questions3.filter((item) => item?.isHr);
          if (
            item?.edit &&
            !item.isEdit &&
            commonElementsCount === 0 &&
            fill?.length + fill1?.length < 3
          ) {
            if (blendedmandatoryCount < 3 && !item.mandatory) {
              setBlendedMandatoryCount(blendedMandatoryIndices?.length + 1);
            }
            // console.log("fifth", item);

            item?.edit &&
              blendedmandatoryCount === 3 &&
              !item.mandatory &&
              showWarningNonMadatory();

            return {
              name: `Question ${index + 1}`,
              question: item.question,
              answer: item.answer,
              QError: "",
              AError: "",
              isEdit:
                fill?.length + fill1?.length === 3 ||
                (blendedmandatoryCount === 3 && !item.mandatory)
                  ? false
                  : true,
              regenerate: item.regenerate,
              mandatory:
                fill?.length + fill1?.length === 3 ||
                (blendedmandatoryCount === 3 && !item.mandatory)
                  ? false
                  : true,
              isHr: false,
            };
          } else {
            console.log("sixth");
            mandatory && commonElementsCount > 0 && showWarningNonMadatory();
            checkedQuestions?.length ===
              BlendedFilteredCheckedQuestions?.length &&
              mandatory !== undefined &&
              !mandatory &&
              showWarningNonMadatorys();
            // mandatory &&
            //   item?.edit &&
            //   item?.isEdit &&
            //   showWarningNonMadatory();
            // item?.edit &&
            //   fill?.length + fill1?.length === 3 &&
            //   showWarningNonMadatory();

            mandatory === undefined &&
              commonElementsCount > 0 &&
              showWarningRegenerate();
            return {
              name: `Question ${index + 1}`,
              question: item.question,
              answer: item.answer,
              QError: "",
              AError: "",
              isEdit: item.isEdit || false,
              regenerate: item.regenerate,
              mandatory: item.mandatory ? item.mandatory : false,
              isHr: item.isHr || false,
            };
          }
        }
      });

      const mandatoryQuestions = updatedQuestions.filter(
        (item) => item.mandatory
      );

      let mandatoryCount = 1;
      mandatoryQuestions?.forEach((item) => {
        if (item.mandatory) {
          item.name = `Question ${mandatoryCount}`;
          mandatoryCount++;
        }
      });

      const filterUpdatedQuestions = updatedQuestions.filter(
        (item) => !item.mandatory
      );

      const updateGenerateQues = newQuestions.map((item, index) => ({
        name: `Question ${index + 1}`,
        question: item.question,
        answer: item.answer,
        QError: "",
        AError: "",
        regenerate: false,
        mandatory: false,
        isEdit: false,
        isHr: false,
      }));

      setQuestions3([
        ...filterUpdatedQuestions,
        ...updateGenerateQues,
        ...mandatoryQuestions,
        // ...hrQuestion,
      ]);
    }

    setCheckedQuestions([]);
    setCount(count + 1);
    setQuestionsAllowed(true);
    // setLoader(false);
    setQuestGenerate(true);
    setButtonContent("Regenerate Questions");
    setDeletedQuest([]);
  };

  const handleHrAnswerInput = (e, Aid) => {
    setQuestions((prev) => {
      let data = prev.map((item) => {
        if (item.id === Aid) {
          return { ...item, AError: "", answer: e.target.value };
        }
        return item;
      });
      return data;
    });
  };

  const handleBlendedAnswerInput = (e, Aid, hr) => {
    let newValue = e.target.value;
    setQuestions3((prev) => {
      let data = prev.map((item, idx) => {
        if (idx === Aid) {
          return {
            ...item,
            AError: "",
            edit: true,
            answer: newValue,
          };
        }
        return item;
      });
      return data;
    });
  };

  const handleHrQuestionInput = (e, Qid) => {
    setQuestions((prev) => {
      let data = prev.map((item) => {
        if (item.id === Qid) {
          return { ...item, QError: "", question: e.target.value };
        }
        return item;
      });
      return data;
    });
  };

  const handleBlendedQuestionInput = (e, Qid, hr) => {
    let newValue = e.target.value;
    setQuestions3((prev) => {
      let data = prev.map((item, idx) => {
        if (idx === Qid) {
          return {
            ...item,
            QError: "",
            edit: true,
            question: newValue,
          };
        }
        return item;
      });
      return data;
    });
  };

  const setErrors = () => {
    if (selectedOption === "option2") {
      questions.map((ele) => {
        if (!ele.answer) {
          ele.AError = "Answer cannot be empty";
        }
        if (!ele.question) {
          ele.QError = "Question cannot be empty";
        }
      });
      console.log(questions);
    }
    if (selectedOption === "option3") {
      questions3.map((ele) => {
        if (!ele.answer) {
          ele.AError = "Answer cannot be empty";
        }
        if (!ele.question) {
          ele.QError = "Question cannot be empty";
        }
      });
    }
  };

  const apiCall = async () => {
    if (questionsAllowed) {
      const uploadQuestions = questions.map(
        ({ question, answer, isHr, mandatory, addedHrQuestion }) => ({
          question,
          answer,
          isHr,
          mandatory,
          addedHrQuestion,
        })
      );

      const uploadBlendedQuestions = questions3.map(
        ({ question, answer, isHr, mandatory, isEdit, name }) => ({
          name,
          question,
          answer,
          isHr,
          mandatory,
          isEdit,
        })
      );
      const uploadAiQuestions = questions1.map(
        ({ question, answer, mandatory, name }) => ({
          question,
          answer,
          mandatory,
          name,
        })
      );
      const reqDataAI = {
        quest: uploadAiQuestions,
        campaign: sessionStorage.getItem("campaignUid"),
        quest_type: "1",
        quest_count: questions.length,
      };
      const reqDataAIUpdate = {
        quest: uploadAiQuestions,
        campaign: sessionStorage.getItem("campaignUid"),
        quest_type: "1",
        quest_count: questions.length,
        uid: isUpdate,
      };

      const reqDataBlended = {
        quest: uploadBlendedQuestions,
        campaign: sessionStorage.getItem("campaignUid"),
        quest_type: "3",
        quest_count: questions.length,
      };
      const reqDataBlendedUpdate = {
        quest: uploadBlendedQuestions,
        campaign: sessionStorage.getItem("campaignUid"),
        quest_type: "3",
        quest_count: questions.length,
        uid: isUpdate,
      };

      // setAllReqData((prev) => {
      //   let newData = [...prev, reqData];
      //   return newData;
      // });
      console.log(isUpdate);
      if (isUpdate) {
        let reqData = {
          quest: uploadQuestions,
          campaign: sessionStorage.getItem("campaignUid"),
          quest_type: "2",
          quest_count: questions.length,
          uid: isUpdate,
        };
        if (selectedOption === "option1") {
          reqData = reqDataAIUpdate;
        } else if (selectedOption === "option3") {
          reqData = reqDataBlendedUpdate;
        }
        console.log(reqData);
        if (reqData.quest.length) {
          const response = await handleApiResponse(
            "/api/v1/interview/question/add_question/",
            reqData,
            1
          );
          if (response?.data?.status_code === 200) {
            if (campaignType === 1) {
              getId();
              // toast.success("Questions updated");
              // setCurrent(2);
            } else {
              getId();
              setIsNextActivated(false);
              // toast.success("Campaign Updated successfully.");
              // navigate("/admin/campaign");
            }
          } else {
            try {
              toast.error(response?.data?.msg?.jobrole[0]);
              console.log("error", response?.data?.msg?.jobrole[0]);
            } catch {
              toast.error(response?.data?.msg);
            }
          }
        }
        // else {
        //   toast.warning("No question are generated/added");
        // }
      } else {
        let reqData = {
          quest: uploadQuestions,
          campaign: sessionStorage.getItem("campaignUid"),
          quest_type: "2",
          quest_count: questions.length,
        };
        if (selectedOption === "option1") {
          reqData = reqDataAI;
        } else if (selectedOption === "option3") {
          reqData = reqDataBlended;
        }
        console.log(reqData);
        if (reqData.quest.length) {
          const response = await handleApiResponse(
            "/api/v1/interview/question/add_question/",
            reqData,
            1
          );
          if (response?.data?.status_code === 200) {
            console.log("first");
            if (campaignType === 1) {
              getId();
              // toast.success("Questions Added");
              // setCurrent(2);
            } else {
              getId();
              setIsNextActivated(false);
              // toast.success("Campaign Created successfully.");
              // navigate("/admin/campaign");
            }
          } else {
            try {
              toast.error(response?.data?.msg?.jobrole[0]);
              console.log("error", response?.data?.msg?.jobrole[0]);
            } catch {
              toast.error(response?.data?.msg);
            }
          }
        } else {
          toast.warning("There should be exact 20 Questions");
        }
      }
    }
  };

  useEffect(() => {
    if (count > 0) {
      apiCall();
    }
  }, [count]);

  const handleCheckboxChange = (index) => {
    if (checkedQuestions.includes(index)) {
      setCheckedQuestions((prevChecked) =>
        prevChecked.filter((item) => item !== index)
      );
    } else {
      setCheckedQuestions((prevChecked) => [...prevChecked, index]);
    }
  };

  const hintGeneration = async () => {
    try {
      const response = await handleApiResponse(
        "/api/v1/interview/question/get_hint/",
        {
          jobrole_uid: getLocal("roleId"),
        },
        1
      );
      console.log("API Response:", response.data);
      if (response?.data?.status_code === 200) {
        setHint(response?.data?.data?.hint_msg);
      } else if (response.status_code === 400 && response.status === false) {
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    hintGeneration();
  }, []);

  useEffect(() => {
    if (checkedQuestions.length) {
      setButtonContent("Regenerate Question");
    }
    if (checkedQuestions.length === 0) {
      setButtonContent("Generate Question");
    }
    if (isNextAcitvated) {
      if (current === 1) {
        if (selectedOption) {
          setErrors();
          let errorCollection = questions.filter((e) => e.QError || e.AError);
          let errorCollection2 = questions3.filter((e) => e.QError || e.AError);

          if (errorCollection.length === 0 && errorCollection2.length === 0) {
            if (selectedOption === "option3" && questions3.length < 15) {
              if (questions3.length < 1) {
                toast.info("Generate Blended Question");
              } else {
                toast.info(
                  `A minimum of 15 questions is required for Blended Question. You currently have ${questions3.length} questions.`
                );
              }
            } else if (
              (selectedOption === "option3" &&
                hrQuesCount + blendedmandatoryCount < 1) ||
              hrQuesCount + blendedmandatoryCount > 3
            ) {
              toast.warning(
                "minimum 1 and maximum 3 HR or Mandatory questions allowed !"
              );
            } else if (selectedOption === "option2" && questions.length < 15) {
              if (questions.length < 1) {
                toast.info("minimum 1 HR question");
              } else {
                toast.info(
                  `A minimum of 15 questions is required for HR Question. You currently have ${questions.length} questions.`
                );
              }
            } else if (selectedOption === "option1" && questions1.length < 15) {
              if (questions1.length < 1) {
                toast.info("Generate Ai Question");
              } else {
                toast.info(
                  `A minimum of 15 questions is required for AI Question. You currently have ${questions1.length} questions.`
                );
              }
            } else {
              setmMultipleError("");
              setIsComponentVisible(true);
              let hr = questions3.filter((item) => item?.isHr);
              if (
                (selectedOption === "option3" && hr?.length > 0) ||
                (selectedOption === "option2" && questions?.length > 0)
              ) {
                setCount(count + 1);
                setQuestionsAllowed(true);
                if (selectedOption === "option2" && questions?.length > 0) {
                  sessionStorage.removeItem("hrQuestion");
                }
              }
            }
          } else {
            const questionWithError = errorCollection
              .map((ele) => ele.name)
              .join(", ");
            console.log("question with error ", questionWithError);
            // setmMultipleError(`Error in ${questionWithError}`);
            setmMultipleError(
              `please fill HR questions and answers ${questionWithError}`
            );
          }
        } else {
          toast.warning("Please Select the Question type");
        }
      }
      setIsNextActivated(false);
    }
  }, [isNextAcitvated, checkedQuestions, questionsAllowed]);

  const handleCopy = (e) => {
    e.preventDefault();
    console.log("worked");
  };

  const closeModal5 = () => {
    setIsComponentVisible(false);
  };

  const closeModalDeleteQuestion = () => {
    setQuestionDeletePopup(false);
  };

  const handleHrQuestionMandatory = (mandatory) => {
    const selectedQuestions = questions.filter((_, index) =>
      checkedQuestions.includes(index)
    );

    const mandatoryQuestionsCount = questions.filter(
      (question) => question.mandatory
    ).length;

    let warningShown = false;

    if (mandatory) {
      const alreadyMandatoryCount = selectedQuestions.filter(
        (question) => question.mandatory
      ).length;

      if (
        mandatoryQuestionsCount -
          alreadyMandatoryCount +
          selectedQuestions.length >
        3
      ) {
        toast.warning("Maximum limit of 3 mandatory questions reached");
      } else {
        const updatedHrQuestions = questions.map((item, index) => {
          if (checkedQuestions.includes(index)) {
            if (item.mandatory) {
              if (!warningShown) {
                toast.warning("Question is already mandatory");
                warningShown = true;
              }
            } else {
              item.mandatory = true;
            }
          }
          return item;
        });
        setQuestions(updatedHrQuestions);
        setCount(count + 1);
        setQuestionsAllowed(true);
      }
    } else {
      const updatedHrQuestions = questions.map((item, index) => {
        if (checkedQuestions.includes(index)) {
          item.mandatory = false;
        }
        return item;
      });
      setQuestions(updatedHrQuestions);
    }
    setCheckedQuestions([]);
  };

  const handleDeleteQuestion1 = (id) => {
    const confirmDelete = () => {
      const updatedQuestions = questions.filter(
        (question) => question.id !== id
      );
      const renumberedQuestions = updatedQuestions.map((item, index) => ({
        ...item,
        name: `Question ${index + 1}`,
      }));

      setQuestions(renumberedQuestions);
    };

    setQuestionDeletePopup(true);
    setConfirmDelete(() => confirmDelete);
  };

  const handleEditBlendedQuestAns = (index) => {
    if (questions3[index].isHr) {
      setCount(count + 1);
      setQuestionsAllowed(true);
      setQuestions3((prev) => {
        let data = prev.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              edit: false,
            };
          }
          return item;
        });
        return data;
      });
    } else {
      handleMandatory(3, true);
    }
    setEditIndex(null);
  };

  let filterAiMadated = questions1?.filter((item) => item?.mandatory);
  let filterHrMadated = questions?.filter((item) => item?.mandatory);

  const handleEditClick = (index) => {
    if (blendedmandatoryCount < 3) {
      if (editIndex === index) {
        setEditIndex(null);
      } else {
        setEditIndex(index);
      }
    } else {
      if (!questions3[index].mandatory) {
        toast.warning("A maximum of 3 mandated questions can be edit !");
      } else {
        if (editIndex === index) {
          setEditIndex(null);
        } else {
          setEditIndex(index);
        }
      }
    }
  };

  const handleDownloadFormat = () => {
    const data = [
      ["Question", "Answer"],
      ["Q1", "A1"],
      ["Q2", "A2"],
      ["Q3", "A3"],
      ["Q4", "A4"],
      ["Q5", "A5"],
    ];

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(data);

    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");

    XLSX.writeFile(workBook, "Clevercruit_Question_and_Answer.xlsx");
  };

  return (
    <section className="gap-4 fill-available">
      {questionDeletePopup && (
        <ModalDeleteQuestionPopup
          onConfirm={confirmDelete}
          onClose={closeModalDeleteQuestion}
        />
      )}
      {isComponentVisible && (
        <ModalAddConfirmationPopup
          // uid={uuid}
          apiCall={apiCall}
          onClose={closeModal5}
          setQuestionsAllowed={setQuestionsAllowed}
          selectedOption={selectedOption}
          questions3={questions3?.length}
          questions1={questions1?.length}
          filterAiMadated={filterAiMadated?.length}
          hrQuesCount={hrQuesCount}
          questions={questions?.length}
          filterHrMadated={filterHrMadated?.length}
          blendedmandatoryCount={blendedmandatoryCount}
          setCurrent={setCurrent}
          campaignType={campaignType}
          // setUuid={setUuid}
        />
      )}
      <div className="flex gap-4">
        <p>Question Type:</p>
        <div className="flex gap-4">
          <label className="flex items-center">
            <input
              type="radio"
              name="options"
              value="option1"
              checked={selectedOption === "option1"}
              onChange={handleOptionChange}
              className="mr-2 form-radio text-gray-500 focus:border-gray-300 focus:ring-gray-300"
            />
            AI Questions
          </label>
          <br />
          <label className="flex items-center">
            <input
              type="radio"
              name="options"
              value="option3"
              checked={selectedOption === "option3"}
              onChange={handleOptionChange}
              className="mr-2 form-radio text-gray-500 focus:border-gray-300 focus:ring-gray-300"
            />
            Blended Questions
          </label>

          <br />
          <label className="flex items-center">
            <input
              type="radio"
              name="options"
              value="option2"
              checked={selectedOption === "option2"}
              onChange={handleOptionChange}
              className="mr-2 form-radio text-gray-500 focus:border-gray-300 focus:ring-gray-300"
            />
            HR Questions
          </label>
        </div>
      </div>

      {selectedOption === "option2" && (
        <>
          <div className="mt-4 ">
            {/* <p>
          (Questions will be completely AI generated The purpose of lorem ipsum
          is to create a natural looking block of text (sentence, paragraph,
          page, etc.) that doesn't distract from the layout. A practice not
          without controversy, laying out pages with meaningless filler text can
          be very useful when the focus is meant to be on design, not content.)
        </p> */}
            <p>
              <b>NOTE </b> : These are the types of questions that will be
              generated by AI: introduction, conclusion, deep dive and
              follow-ups.
            </p>
          </div>
          <div className="mt-4">
            <p>
              <b>HINT</b> : {hint}
            </p>
          </div>
        </>
      )}
      {selectedOption === "option1" && (
        <>
          <div className="flex mt-4 gap-4 items-center justify-between mb-4">
            <div className="text-[1.111vw] font-[600]">AI Questions</div>
            {questions1?.length > 0 && (
              <>
                <div className="flex">
                  <div onClick={() => handleMandatory(1, true)}>
                    <Button name={"Make Mandatory"} />
                  </div>
                  <FaInfoCircle
                    data-tooltip-id="Make Mandatory"
                    className="ml-2 cursor-pointer text-gray-500"
                  />
                  <Tooltip id="Make Mandatory" place="top" effect="solid">
                    Clicking on this will make the selected questions mandatory.
                  </Tooltip>
                </div>

                <div className="flex">
                  <div
                    // onClick={() => handleGenerateQuestions(1, false)}
                    onClick={() => handleMandatory(1, false)}
                  >
                    <Button name={"Not Mandatory"} />
                  </div>
                  <FaInfoCircle
                    data-tooltip-id="Not Mandatory"
                    className="ml-2 cursor-pointer text-gray-500"
                  />
                  <Tooltip id="Not Mandatory" place="top" effect="solid">
                    Clicking on this will make the mandatory questions not
                    mandatory.
                  </Tooltip>
                </div>
              </>
            )}
            <div className="flex">
              <div onClick={() => handleGenerateQuestions(1)}>
                <Button name={buttonContent} />
              </div>
              <FaInfoCircle
                data-tooltip-id="skillsInfo"
                className="ml-2 cursor-pointer text-gray-500"
              />
              <Tooltip id="skillsInfo" place="top" effect="solid">
                Clicking on the Generate Question button will generate questions
                from AI. Next, you can regenerate the selected questions from AI
                again by clicking Regenerate Questions.
              </Tooltip>
            </div>
          </div>
          <div className=" mt-2 flex h-[60vh] flex-col overflow-y-scroll">
            <>
              {questions1?.map((question, ind) => {
                return (
                  <div className="flex" key={ind}>
                    <div className="flex items-baseline">
                      <input
                        type="checkbox"
                        checked={checkedQuestions.includes(ind)}
                        onChange={() => handleCheckboxChange(ind)}
                        className="mr-4 mt-1"
                      />
                    </div>
                    <div className="w-[80%]">
                      <label
                        htmlFor=""
                        className={`${question.mandatory && "text-blue-600"}`}
                      >
                        {question.mandatory
                          ? "AI Question " + (ind + 1) + " (Mandated)"
                          : "AI Question " + (ind + 1)}
                      </label>
                      <textarea
                        type="text"
                        className={`p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] fill-available ${
                          question?.regenerate
                            ? "text-green-600"
                            : question?.mandatory
                            ? "text-blue-600"
                            : ""
                        }`}
                        value={question?.question}
                        onCopy={(e) => handleCopy(e)}
                        onCut={(e) => handleCopy(e)}
                        onPaste={(e) => handleCopy(e)}
                        readOnly
                      />
                      <textarea
                        type="text"
                        className={`w-full h-[7rem] p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] ${
                          question?.regenerate
                            ? "text-green-600"
                            : question?.mandatory
                            ? "text-blue-600"
                            : ""
                        }`}
                        value={question?.answer}
                        onCopy={(e) => handleCopy(e)}
                        onCut={(e) => handleCopy(e)}
                        onPaste={(e) => handleCopy(e)}
                        readOnly
                      />
                    </div>
                    <div className="flex items-center">
                      <img
                        src={DeleteQues}
                        className="delete button cursor-pointer h-[2.7rem]"
                        alt="Delete"
                        onClick={() => handleDeleteQuestion(ind)}
                      />
                    </div>
                  </div>
                );
              })}

              {loader && <Loader />}
            </>
          </div>
        </>
      )}
      {selectedOption === "option2" && (
        <>
          <div className="flex justify-between">
            <div className="text-[1.111vw] font-[600] mt-10">HR Questions</div>
            <>
              <div className="flex gap-3">
                <AnalyticsPageBtn
                  name={"Download Format"}
                  img={4}
                  onClick={handleDownloadFormat}
                />
                <div onClick={() => setOpenImport(true)}>
                  <Button name={"Upload Questions"} />
                </div>
                <div onClick={() => handleHrQuestionMandatory(true)}>
                  <Button name={"Make Mandatory"} />
                </div>
                <FaInfoCircle
                  data-tooltip-id="Make Mandatory"
                  className="ml-2 cursor-pointer text-gray-500"
                />
                <Tooltip id="Make Mandatory" place="top" effect="solid">
                  Clicking on this will make the selected questions mandatory.
                </Tooltip>
              </div>

              <div className="flex">
                <div onClick={() => handleHrQuestionMandatory(false)}>
                  <Button name={"Not Mandatory"} />
                </div>
                <FaInfoCircle
                  data-tooltip-id="Not Mandatory"
                  className="ml-2 cursor-pointer text-gray-500"
                />
                <Tooltip id="Not Mandatory" place="top" effect="solid">
                  Clicking on this will make the mandatory questions not
                  mandatory.
                </Tooltip>
              </div>
            </>
            <div onClick={handleAddQuestion}>
              <Button name={"Add Question"} />
            </div>
          </div>
          {openImport && (
            <ModalAddHrQuestions
              onClose={setOpenImport}
              excelData={excelData}
              setExcelData={handleUploadQuestionAnswer}
            />
          )}
          <div className="mt-2 flex h-[58vh] flex-col overflow-y-scroll">
            {questions?.map((question, ind) => (
              <div key={question.id} className="flex">
                <div className="flex items-baseline">
                  <input
                    type="checkbox"
                    checked={checkedQuestions.includes(ind)}
                    onChange={() => handleCheckboxChange(ind)}
                    className="mr-4 mt-1"
                  />
                </div>
                <div className="w-[80%]">
                  <TextBoxCustomNBuild
                    name={
                      question.mandatory
                        ? question.name + " (mandated)"
                        : question.name
                    }
                    onChange={(e) => handleHrQuestionInput(e, question.id)}
                    error={question.QError}
                    value={question.question}
                    mandatory={question.mandatory}
                  />
                  <textarea
                    type="text"
                    value={question.answer}
                    onChange={(e) => handleHrAnswerInput(e, question.id)}
                    className={`w-full h-[7rem] p-3 rounded-lg outline-0 mt-2 ${
                      question.mandatory && "text-blue-600"
                    } placeholder-text-sm placeholder-tracking-[0.6px]`}
                  />
                  {question.AError && (
                    <div className="text-red-500">{question.AError}</div>
                  )}
                </div>
                <div className="flex items-center">
                  <img
                    src={DeleteQues}
                    className="delete button cursor-pointer h-[2.7rem]"
                    alt="Delete"
                    onClick={() => handleDeleteQuestion1(question.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {selectedOption === "option3" && (
        <>
          <div className="flex justify-between">
            <div className="text-[1.111vw] font-[600] mt-10">
              Blended Questions
            </div>
            <div className="flex gap-2 mt-4">
              {questions3?.length > 0 && (
                <>
                  <div className="flex">
                    <div onClick={() => handleMandatory(3, true)}>
                      <Button name={"Make Mandatory"} />
                    </div>
                    <FaInfoCircle
                      data-tooltip-id="Make Mandatory Blended"
                      className="ml-2 cursor-pointer text-gray-500"
                    />
                    <Tooltip
                      id="Make Mandatory Blended"
                      place="top"
                      effect="solid"
                    >
                      Clicking on this will make the selected questions
                      mandatory.
                    </Tooltip>
                  </div>
                  <div className="flex">
                    <div onClick={() => handleMandatory(3, false)}>
                      <Button name={"Non Mandatory"} />
                    </div>
                    <FaInfoCircle
                      data-tooltip-id="Non Mandatory Blended"
                      className="ml-2 cursor-pointer text-gray-500"
                    />
                    <Tooltip
                      id="Non Mandatory Blended"
                      place="top"
                      effect="solid"
                    >
                      Clicking on this will make the mandatory questions not
                      mandatory.
                    </Tooltip>
                  </div>
                </>
              )}
              {questions3?.length > 0 && (
                <div onClick={handleAddQuestion3}>
                  <Button name={"Add Question"} />
                </div>
              )}

              <div className="flex">
                <div onClick={() => handleGenerateQuestions(3)}>
                  <Button name={buttonContent} />
                </div>
                <FaInfoCircle
                  data-tooltip-id="skillsInfo"
                  className="ml-2 cursor-pointer text-gray-500"
                />
                <Tooltip id="skillsInfo" place="top" effect="solid">
                  Clicking on the Generate Question button will generate
                  questions from AI. Next, you can regenerate the selected
                  questions from AI again by clicking Regenerate Questions.
                </Tooltip>
              </div>
            </div>
          </div>
          <div className=" mt-2 flex h-[58vh] flex-col overflow-y-scroll">
            <>
              {questions3?.map((question, ind) => {
                const isEditing = editIndex === ind;
                return (
                  <div className="flex">
                    <div className="flex items-baseline">
                      {!question.isHr && (
                        <input
                          type="checkbox"
                          checked={checkedQuestions.includes(ind)}
                          onChange={() => handleCheckboxChange(ind)}
                          className="mr-4 mt-1"
                        />
                      )}
                    </div>
                    <div
                      key={ind}
                      className={`w-[80%] ${!question.isHr ? "" : "ml-8"}`}
                    >
                      <TextBoxCustomNBuild
                        name={
                          question.mandatory &&
                          !question.isHr &&
                          question.isEdit
                            ? "HR " + question.name + "(AI Question Modified)"
                            : question.mandatory && !question.isHr
                            ? "HR " + question.name + "(AI Question Mandated)"
                            : question.isHr
                            ? "HR " + question.name
                            : "AI " + "Question " + (ind + 1)
                        }
                        checkedQuestions={checkedQuestions.includes(ind)}
                        value={question.question}
                        onChange={(e) =>
                          handleBlendedQuestionInput(e, ind, question?.isHr)
                        }
                        error={question.QError}
                        regenerate={question.regenerate}
                        mandatory={question.mandatory}
                        edit={isEditing}
                        selectedOption={selectedOption}
                        isHr={question.isHr}
                      />
                      <textarea
                        type="text"
                        className={`w-full h-[7rem] p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] ${
                          question?.regenerate
                            ? "text-green-600"
                            : question?.mandatory
                            ? "text-blue-600"
                            : ""
                        }`}
                        value={question.answer}
                        onChange={(e) =>
                          handleBlendedAnswerInput(e, ind, question?.isHr)
                        }
                        disabled={!isEditing && !question.isHr}
                      />
                      {question.AError && (
                        <div className="text-red-500"> {question.AError} </div>
                      )}
                    </div>

                    <div className="flex items-center justify-center flex-col gap-4 ms-5 mt-10 cursor-pointer">
                      <img
                        src={DeleteQues}
                        // className="delete button cursor-pointer h-[2.7rem]"
                        alt="Delete"
                        onClick={() => {
                          handleDeleteQuestion(ind);
                        }}
                      />
                      {!question.isHr && (
                        <img
                          src={editIcon}
                          alt="editIcon"
                          onClick={() => handleEditClick(ind)}
                        />
                      )}

                      {question.edit && (
                        <button
                          className="mt-3"
                          onClick={() => handleEditBlendedQuestAns(ind)}
                        >
                          save
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          </div>
          {loader && <Loader />}
        </>
      )}
      {multipleError && (
        <div className="mt-4 text-center text-red-500 max-h-[5rem] overflow-auto">
          {multipleError}
        </div>
      )}
    </section>
  );
};

const UploadResumeOnly = ({
  uploadResume,
  setUploadResume,
  setErrorFormat,
  excelData,
  setExcelData,
  selectedCheckboxes,
  setSelectedCheckboxes,
  selectAll,
  setSelectAll,
  editData,
  prevEmail,
  setPrevEmail,
  setEmptyError,
  setIncorrectError,
  setSelectedData,
  setIsNewCandidatesAdded,
  selectedData,
  setIsNextActivated,
  newCandidatesAdded,
  singleAddCandidate,
  setSingleAddCandidate,
  isNextAcitvated,
  isVisibleConfirmation,
  setIsVisibleConfirmation,
  handleCallApi,
  checkedFilteredData,
  setCheckedFilteredData,
  filteredData,
  setFilteredData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [rowToDelete, setRowToDelete] = useState(null);
  const [forceRender, setForceRender] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [sameCandidate, setSameCandidate] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [questionDeletePopup, setQuestionDeletePopup] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const filterData = () => {
      const combinedData = [...singleAddCandidate, ...(excelData || [])];

      const filtered = combinedData.filter(
        ({ first_name, last_name, email, mobile }) => {
          const combinedFields =
            `${first_name} ${last_name} ${email} ${mobile}`.toLowerCase();
          return combinedFields.includes(searchTerm.toLowerCase());
        }
      );

      const checkedData = filtered.filter(
        (item) =>
          !checkedFilteredData.some(
            (checkedItem) => checkedItem.email === item.email
          )
      );

      setFilteredData(checkedData);
    };

    filterData();
  }, [singleAddCandidate, excelData, searchTerm, checkedFilteredData]);

  useEffect(() => {
    const getCandidateList = async () => {
      const reqData = {
        campaign: getLocal("campaignUid"),
        is_deleted: 0,
      };
      const response = await handleApiResponse(
        "/api/v1/interview/candidate_list/",
        reqData,
        1
      );
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      const transformedData = response?.data?.data?.data.map((candidate) => ({
        first_name: candidate.first_name,
        last_name: candidate.last_name,
        email: candidate.email,
        mobile: candidate.mobile,
        is_selected: candidate.is_selected === 1,
        uid: candidate.uid,
        initiallySelected: candidate.is_selected === 1,
      }));

      let checkedData = transformedData
        .map((item, index) => ({ ...item, originalIndex: index }))
        .filter((item) => item.is_selected)
        .sort((a, b) => b.originalIndex - a.originalIndex);
      // let checkedData = transformedData.filter((item) => item.is_selected);

      let unCheckedData = transformedData.filter((item) => !item.is_selected);

      const savedExcelData = sessionStorage.getItem("excelData");
      const savedExcelList = JSON.parse(savedExcelData);
      const savedCheckedData = sessionStorage.getItem("checkedFilteredData");
      const savedCheckList = JSON.parse(savedCheckedData);

      console.log(savedCheckList, "savedCheckList", checkedData);
      if (transformedData?.length > 0) {
        if (savedExcelList?.length > 0 && savedExcelList !== null) {
          setExcelData(savedExcelList);
        } else {
          setExcelData(unCheckedData);
        }
        if (savedCheckList?.length > 0 && savedCheckList !== null) {
          setCheckedFilteredData(savedCheckList);
        } else {
          setCheckedFilteredData(checkedData);
        }

        setSelectedData(checkedData);
      } else if (savedExcelData !== null) {
        try {
          setExcelData(JSON.parse(savedExcelData));
        } catch (error) {
          console.error("Failed to parse savedExcelData:", error);
        }
      } else {
        setExcelData([]);
      }
    };

    getCandidateList();
  }, [getLocal("campaignUid")]);

  const callApi = async () => {
    const reqData = {
      campaign: editData[0],
    };

    // Simulated API call, adjust as per your project's API structure
    // const response = await fetch("/api/v1/interview/candidate_list/", {
    //   method: "POST",
    //   body: JSON.stringify(reqData),
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    // const data = await response.json();
    // const email = data?.data?.data?.map((ele) => ele.email) || [];
    // setPrevEmail(email);
    // Simulated response mapping, adjust as per your project's API structure

    const data = [
      { email: "example@email.com" },
      { email: "example2@email.com" },
    ];
    const email = data.map((ele) => ele.email);
    setPrevEmail(email);
  };

  useEffect(() => {
    if (editData[0]) {
      callApi();
    }
  }, []);

  // useEffect(() => {
  //   console.log("check", selectedCheckboxes);
  // }, [selectedCheckboxes]);

  const handleExtractSelectedData = () => {
    const selectedData = [];
    let emailError = false;

    filteredData?.forEach((row, rowIndex) => {
      let first_name, last_name, email, mobile, is_selected, uid;
      console.log(row, "roewsaaaaaaaaa");

      if (Array.isArray(row)) {
        [first_name, last_name, email, mobile] = row;
        is_selected = selectedCheckboxes[row.email] ? 1 : 0;
        uid = undefined;
      } else if (typeof row === "object" && row !== null) {
        first_name = row.first_name;
        last_name = row.last_name;
        email = row.email;
        mobile = row.mobile;
        is_selected = row.is_selected ? 1 : 0;
        uid = row.uid;
      } else {
        console.error("Unexpected row type:", row);
        return;
      }

      if (!email) {
        emailError = true;
      }

      selectedData.push({
        first_name,
        last_name,
        email,
        mobile,
        campaign: sessionStorage.getItem("campaignUid"),
        organization: sessionStorage.getItem("parentUid"),
        is_selected,
        uid,
      });
    });

    setHasEmailError(emailError);
    console.log(selectedData, "selectedData");

    if (emailError) {
      return [];
    }

    return selectedData;
  };

  useEffect(() => {
    const updatedSelectedData = handleExtractSelectedData();
    setSelectedData(updatedSelectedData);
  }, [selectedCheckboxes, filteredData, singleAddCandidate]);

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    const updatedExcelData = excelData.map((row) => ({
      ...row,
      is_selected: !isChecked,
    }));

    const updatedSingleAddCandidate = singleAddCandidate.map((row) => ({
      ...row,
      is_selected: !isChecked,
    }));

    const updatedFilteredData = [
      ...updatedSingleAddCandidate,
      ...updatedExcelData,
    ];

    sessionStorage.setItem("excelData", JSON.stringify(updatedExcelData));
    sessionStorage.setItem(
      "singleAddCandidate",
      JSON.stringify(updatedSingleAddCandidate)
    );

    setFilteredData(updatedFilteredData);
    setSingleAddCandidate(updatedSingleAddCandidate);

    const updatedCheckboxes = updatedFilteredData.reduce((acc, row) => {
      acc[row.email] = !isChecked;
      return acc;
    }, {});

    sessionStorage.setItem(
      "selectedCheckboxes",
      JSON.stringify(updatedCheckboxes)
    );

    setSelectedCheckboxes(updatedCheckboxes);

    setSelectAll(isChecked);
    sessionStorage.setItem("savedAllSelcted", JSON.stringify(isChecked));

    // const updatesExcelData = excelData.map((row) => {
    //   let data = row;
    //   data.is_selected = !isChecked;
    //   return data;
    // });
    // setExcelData(updatesExcelData);
    setExcelData(updatedExcelData);
  };

  const handleCheckboxChange = (rowIndex, isChecked, currentRow, email) => {
    // setSearchTerm("");
    setSelectedCheckboxes((prev) => {
      let updatedCheckBoxes;
      if (!isChecked) {
        const { [email]: removed, ...rest } = prev;

        updatedCheckBoxes = rest;
      } else {
      }
      updatedCheckBoxes = {
        ...prev,
        [email]: isChecked,
      };
      sessionStorage.setItem(
        "selectedCheckboxes",
        JSON.stringify(updatedCheckBoxes)
      );
      return updatedCheckBoxes;
    });

    const updatedRow = { ...currentRow, is_selected: isChecked };

    setFilteredData((prev) =>
      prev.map((row, index) => (index === rowIndex ? updatedRow : row))
    );

    setExcelData((prev) => {
      const updatedData = prev.map((row) =>
        row.email === email ? updatedRow : row
      );
      sessionStorage.setItem("excelData", JSON.stringify(updatedData));
      return updatedData;
    });

    setSingleAddCandidate((prev) => {
      const updatedData = prev.map((row) =>
        row.email === email ? updatedRow : row
      );
      sessionStorage.setItem("singleAddCandidate", JSON.stringify(updatedData));
      return updatedData;
    });

    // setExcelData((prev) => {
    //   const newData = prev.map((row, index) =>
    //     index === rowIndex ? updatedRow : row
    //   );
    //   sessionStorage.setItem("excelData", JSON.stringify(newData));
    //   return newData;
    // });

    // setSingleAddCandidate((prev) =>
    //   prev.filter((candidate) => candidate.email !== email)
    // );
    // setExcelData((prev) =>
    //   prev.filter((candidate) => candidate.email !== email)
    // );
  };

  const handleCheckboxChange1 = (rowIndex, isChecked, currentRow, email) => {
    // setSearchTerm("");
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [rowIndex]: isChecked,
    }));

    const updatedRow = { ...currentRow, is_selected: isChecked };

    if (isChecked) {
      // Add to checkedFilteredData and remove from filteredData
      setCheckedFilteredData((prev) => {
        const newCheckedFilteredData = [
          updatedRow,
          ...prev.filter((row) => row.email !== email),
        ];
        sessionStorage.setItem(
          "checkedFilteredData",
          JSON.stringify(newCheckedFilteredData)
        );
        return newCheckedFilteredData;
      });
      setFilteredData((prev) => {
        const newFilteredData = prev.filter((row) => row.email !== email);
        sessionStorage.setItem("filteredData", JSON.stringify(newFilteredData));
        return newFilteredData;
      });
    } else {
      // Remove from checkedFilteredData and add to filteredData
      setCheckedFilteredData((prev) => {
        const newCheckedFilteredData = prev.filter(
          (row) => row.email !== email
        );
        sessionStorage.setItem(
          "checkedFilteredData",
          JSON.stringify(newCheckedFilteredData)
        );
        return newCheckedFilteredData;
      });
      setFilteredData((prev) => {
        const newFilteredData = [
          updatedRow,
          ...prev.filter((row) => row.email !== email),
        ];
        sessionStorage.setItem("excelData", JSON.stringify(newFilteredData));
        return newFilteredData;
      });
    }
  };

  const handleSortList = () => {
    sessionStorage.setItem("sortlist", true);

    const selectedEmails = Object.entries(selectedCheckboxes)
      .filter(([_, isChecked]) => isChecked)
      .map(([email]) => email);

    // Filter and sort filteredData based on selectedEmails
    const newCheckedFilteredDataList = filteredData.filter((row) =>
      selectedEmails.includes(row.email)
    );

    const updatedFilteredData = newCheckedFilteredDataList.map((row) => ({
      ...row,
      is_selected: true,
    }));

    setCheckedFilteredData((prevChecked) => {
      const newCheckedFilteredData = [...prevChecked, ...updatedFilteredData];
      sessionStorage.setItem(
        "checkedFilteredData",
        JSON.stringify(newCheckedFilteredData)
      );
      return newCheckedFilteredData;
    });

    setFilteredData((prev) => prev.filter((row) => !row.is_selected));
    setSearchTerm("");
    setSelectAll(false)
  };

  const handleDropResume = (e) => {
    e.preventDefault();
    const permissible = [".pdf", ".xlsx", ".xls"];
    const files = Array.from(e.dataTransfer.files);
    if (
      files?.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setUploadResume(files[0]);
      handleUpload(files[0]);
    }
  };

  useEffect(() => {
    const savedUploadResume = sessionStorage.getItem("uploadResume");
    const savedExcelData = sessionStorage.getItem("excelData");
    const savedPrevEmail = sessionStorage.getItem("prevEmail");
    const savedSingleAddCandidate =
      sessionStorage.getItem("singleAddCandidate");
    const savedSelectedCheckboxes =
      sessionStorage.getItem("selectedCheckboxes");
    const savedCheckedFilteredData = sessionStorage.getItem(
      "checkedFilteredData"
    );

    sessionStorage.setItem("sortedData", true);

    if (savedUploadResume !== null) {
      setUploadResume(savedUploadResume);
    }
    if (savedExcelData !== null) {
      try {
        const parsedExcelData = JSON.parse(savedExcelData);
        // setExcelData(parsedExcelData);

        // Filter data based on checkedFilteredData
        if (savedCheckedFilteredData !== null) {
          const parsedCheckedFilteredData = JSON.parse(
            savedCheckedFilteredData
          );
          setCheckedFilteredData(parsedCheckedFilteredData);
          setExcelData(
            parsedExcelData.filter(
              (row) =>
                !parsedCheckedFilteredData.some(
                  (checkedRow) => checkedRow.email === row.email
                )
            )
          );
        } else {
          setExcelData(parsedExcelData);
        }
      } catch (error) {
        console.error("Failed to parse excelData:", error);
      }
    }
    if (savedPrevEmail !== null) {
      try {
        setPrevEmail(JSON.parse(savedPrevEmail));
      } catch (error) {
        console.error("Failed to parse prevEmail:", error);
      }
    }
    if (savedSingleAddCandidate !== null) {
      try {
        setSingleAddCandidate(JSON.parse(savedSingleAddCandidate));
      } catch (error) {
        console.error("Failed to parse singleAddCandidate:", error);
      }
    }
    if (savedSelectedCheckboxes !== null) {
      const parsedCheckboxes = JSON.parse(savedSelectedCheckboxes);
      if (parsedCheckboxes && typeof parsedCheckboxes === "object") {
        setSelectedCheckboxes((prev) => ({ ...prev, ...parsedCheckboxes }));
      } else {
        console.error("Parsed checkboxes are not valid:", parsedCheckboxes);
      }
    }
  }, []);

  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    const savedUploadResume = sessionStorage.getItem("uploadResume");

    if (savedUploadResume !== file.name) {
      if (file.name.endsWith(".xlsx")) {
        setUploadResume(file);
        handleUpload(file);
        sessionStorage.setItem("uploadResume", file.name);
      }
    }
  };

  const handleUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const firstRow = parsedData[0];
      const expected = ["First Name", "Last Name", "Email", "Mobile"];

      // Validate headers
      if (JSON.stringify(firstRow) !== JSON.stringify(expected)) {
        setExcelData([]);
        setUploadResume(false);
        setErrorFormat(true);
        setSelectedCheckboxes({});
        sessionStorage.removeItem("excelData");
        sessionStorage.removeItem("prevEmail");
        toast.error(
          "Invalid file format. Please ensure the headers are correct."
        );
        return;
      }

      setErrorFormat(false);

      const dataRows = parsedData.slice(1);

      if (dataRows.length === 0) {
        setExcelData([]);
        setFilteredData([]);
        setUploadResume(false);
        sessionStorage.removeItem("excelData");
        sessionStorage.removeItem("prevEmail");
        // setSelectedCheckboxes({});
        // sessionStorage.removeItem("selectedCheckboxes");
        toast.error(
          "The file contains no data. Please ensure there are entries below the headers."
        );
        return;
      }

      const validRows = [];
      const errorMessagesSet = new Set();

      const isValidName = (name) =>
        typeof name === "string" && name.trim() !== "";

      const isValidMobile = (mobile) => typeof mobile === "number";

      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      const uploadedEmailsSet = new Set();

      dataRows.forEach((row, index) => {
        const [firstName, lastName, email, mobile] = row;

        const errors = {
          firstName: !isValidName(firstName)
            ? "Invalid or empty first name"
            : "",
          lastName: !isValidName(lastName) ? "Invalid or empty last name" : "",
          email: !isValidEmail(email) ? "Invalid or empty email" : "",
        };

        if (!firstName || !lastName || !email) {
          errors.emptyFields = "All fields are required";
        }

        if (uploadedEmailsSet.has(email)) {
          errors.email =
            "You can't upload it. Duplicate email found in the upload file.";
        } else {
          uploadedEmailsSet.add(email);
        }

        if (!Object.values(errors).some((error) => error)) {
          validRows.push({
            first_name: firstName,
            last_name: lastName,
            email: email,
            mobile: mobile,
            is_selected: true,
          });
        } else {
          Object.values(errors)
            .filter(Boolean)
            .forEach((errorMessage) => errorMessagesSet.add(errorMessage));
        }
      });

      const aggregatedErrorMessages = Array.from(errorMessagesSet).join("\n");

      if (aggregatedErrorMessages) {
        toast.error(`${aggregatedErrorMessages}`);
        return;
      }

      const existingEmails = [
        ...(filteredData?.map((ele) =>
          typeof ele === "object" ? ele.email : ele[2]
        ) || []),
        ...(checkedFilteredData?.map((ele) =>
          typeof ele === "object" ? ele.email : ele[2]
        ) || []),
        ...(JSON.parse(sessionStorage.getItem("excelData") || "[]").map(
          (ele) => ele.email
        ) || []),
      ];
      const combinedExistingEmails = new Set([...existingEmails]);
      const uniqueNewCandidates = validRows.filter(
        (row) => !combinedExistingEmails.has(row.email)
      );
      const notUniqueNewCandidates = validRows.filter((row) =>
        combinedExistingEmails.has(row.email)
      );

      if (uniqueNewCandidates?.length === 0) {
        toast.warn(
          "All uploaded candidates have duplicate emails. No new candidates were added."
        );
        return;
      }

      if (notUniqueNewCandidates?.length > 0) {
        toast.warn(
          `${notUniqueNewCandidates?.length} upload candidate have duplicate email. Can't be import`
        );
      }

      setExcelData((prevData) => {
        const prevDataArray = Array.isArray(prevData) ? prevData : [];
        const newData = [...prevDataArray, ...uniqueNewCandidates];

        if (newData.length > 500) {
          toast.warn("Cannot upload more than 500 entries in total.");
          return prevDataArray;
        }
        console.log(newData, "newData", selectedCheckboxes);
        let uploadSelected = newData.reduce((acc, item) => {
          acc[item.email] = true;
          return acc;
        }, {});

        setSelectedCheckboxes((prev) => {
          const updatedSelectedCheckboxes = {
            ...prev,
            ...uploadSelected,
          };

          sessionStorage.setItem(
            "selectedCheckboxes",
            JSON.stringify(updatedSelectedCheckboxes)
          );

          return updatedSelectedCheckboxes;
        });

        let uploadNewData = newData.map((item) => ({
          ...item,
          is_selected: true,
        }));

        sessionStorage.setItem("excelData", JSON.stringify(uploadNewData));
        return uploadNewData;
      });

      setUploadResume(file);
      sessionStorage.setItem(
        "prevEmail",
        JSON.stringify([
          ...existingEmails,
          ...uniqueNewCandidates.map((row) => row.email),
        ])
      );
      // setSelectedCheckboxes({});
    };

    reader.readAsArrayBuffer(file);
  };

  const handleRemoveResume = () => {
    setExcelData([]);
    setCheckedFilteredData([]);
    const currentFileName = uploadResume ? uploadResume.name : null;
    setUploadResume(null);
    setSelectedCheckboxes({});
    sessionStorage.removeItem("uploadResume");
    sessionStorage.removeItem("excelData");
    sessionStorage.removeItem("prevEmail");
    sessionStorage.removeItem("checkedFilteredData");
    sessionStorage.removeItem("selectedCheckboxes");
    sessionStorage.removeItem("savedAllSelcted");
    const fileInput = document.getElementById("resume-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    const handleUploadResumeAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setUploadResume(file);
      } else {
        setUploadResume(file);
      }
    };
    fileInput.addEventListener("change", handleUploadResumeAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  useEffect(() => {
    const dataToSort = Array.isArray(excelData) ? excelData : [];
    const candidates = Array.isArray(singleAddCandidate)
      ? singleAddCandidate
      : [];

    const sortedExcelData = dataToSort.sort((a, b) => {
      if (
        typeof a === "object" &&
        a !== null &&
        typeof b === "object" &&
        b !== null
      ) {
        return (b.is_selected ? 1 : 0) - (a.is_selected ? 1 : 0);
      }
      return 0;
    });

    let combinedData = [...candidates, ...sortedExcelData];

    const uniqueEmails = new Set();
    let filteredData = combinedData.filter((item) => {
      if (uniqueEmails.has(item?.email)) {
        return false;
      } else {
        uniqueEmails.add(item?.email);
        return true;
      }
    });

    filteredData = filteredData.filter((item) =>
      checkedFilteredData.every(
        (checkedItem) => checkedItem.email !== item.email
      )
    );

    setFilteredData(filteredData);
  }, [excelData, singleAddCandidate, checkedFilteredData]);

  const handleDeleteRow = async (id, email) => {
    setSearchTerm("");
    if (id && email) {
      try {
        const response = await handleApiResponse(
          "/api/v1/interview/update_candidate/",
          {
            uid: id,
            is_deleted: 1,
          },
          1
        );
        if (response?.code === "token_not_valid") {
          navigate("/");
          sessionStorage.clear();
          return;
        }
        if (response?.data?.status_code === 200) {
          toast.success("Candidate Deleted.");
        } else if (response.status_code === 400) {
          toast.error("An error occured while deleting.");
        }
      } catch (error) {
        console.error("API Error:", error);
      }

      const updatedExcelData = excelData?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setExcelData(updatedExcelData);

      const updatedSingleAddCandidate = singleAddCandidate?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setSingleAddCandidate(updatedSingleAddCandidate);

      const updatedChecked = checkedFilteredData?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setCheckedFilteredData(updatedChecked);
      sessionStorage.setItem(
        "checkedFilteredData",
        JSON.stringify(updatedChecked)
      );
    } else {
      const updatedExcelData = excelData?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setExcelData(updatedExcelData);

      sessionStorage.setItem("excelData", JSON.stringify(updatedExcelData));

      const updatedSingleAddCandidate = singleAddCandidate?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setSingleAddCandidate(updatedSingleAddCandidate);

      sessionStorage.setItem(
        "singleAddCandidate",
        JSON.stringify(updatedSingleAddCandidate)
      );

      const updatedChecked = checkedFilteredData?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setCheckedFilteredData(updatedChecked);
      sessionStorage.setItem(
        "checkedFilteredData",
        JSON.stringify(updatedChecked)
      );

      toast.success("Candidate Deleted.");
    }
  };

  const closeModalDeleteQuestion = () => {
    setQuestionDeletePopup(false);
  };

  const confirmDelete = () => {
    handleDeleteRow(rowToDelete.id, rowToDelete.email);
    setQuestionDeletePopup(false);
  };

  const handleDeleteClick = (id, email) => {
    console.log(id, email, "emailaaaaaaaaaaaa");

    setRowToDelete({ id, email });
    setQuestionDeletePopup(true);
  };

  const handleCancelVisibleBtn = () => {
    setIsVisibleConfirmation(false);
  };

  let selectedFilteredData = filteredData.filter((item) => item?.is_selected);

  return (
    <section className="flex flex-col gap-4 fill-available">
      <div className="flex flex-col fill-available">
        {questionDeletePopup && (
          <ModalDeleteQuestionPopup
            onConfirm={confirmDelete}
            onClose={closeModalDeleteQuestion}
            rowToDelete={rowToDelete?.email}
          />
        )}
        {isVisibleConfirmation && (
          <ModalCandidateConfirmationPopup
            totatCandidates={filteredData?.length + checkedFilteredData?.length}
            checkedFilteredData={
              checkedFilteredData?.length > 0
                ? location.pathname === "/editcampaign/addCampaign"
                  ? checkedFilteredData?.length + selectedFilteredData?.length
                  : checkedFilteredData?.length
                : selectedFilteredData?.length
            }
            handleCallApi={handleCallApi}
            onClose={handleCancelVisibleBtn}
          />
        )}

        <div
          className="bg-white border-2 flex flex-col h-[12.56rem] items-center gap-3"
          onDrop={handleDropResume}
          onDragOver={(e) => e.preventDefault()}
        >
          <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
          <p>Drag or drop Candidate list here (xlsx)</p>
          <label
            htmlFor="resume-upload"
            className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
          >
            Browse
            <input
              type="file"
              id="resume-upload"
              accept=".xlsx"
              className="hidden"
              onChange={handleUploadResume}
            />
          </label>
        </div>

        <div
          className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${
            uploadResume ? "block" : "hidden"
          }`}
        >
          <p>
            {uploadResume?.name
              ? uploadResume?.name
              : sessionStorage.getItem("uploadResume") || ""}
          </p>
          <p className="cursor-pointer" onClick={handleRemoveResume}>
            Close
          </p>
        </div>

        {checkedFilteredData?.length > 0 && (
          <div className=" flex flex-col bg-[#FFFFFF] border-2 border-blue-500 p-4 rounded-lg fill-available h-[8rem]">
            <section className="overflow-auto">
              <table className="w-full mt-4">
                <thead className="bg-[#F9FAFB] text-[0.833vw] text-[600]">
                  <tr>
                    <th key="empty">Selected</th>

                    <>
                      <th className="text-left">First Name</th>
                      <th className="text-left">Last Name</th>
                      <th className="text-left">Email</th>
                      <th className="text-left">Mobile</th>
                    </>
                  </tr>
                </thead>
                <tbody>
                  {checkedFilteredData.map((row, rowIndex) => {
                    const email = Array.isArray(row) ? row[2] : row.email;
                    const isObjectRow =
                      typeof row === "object" &&
                      !Array.isArray(row) &&
                      row !== null;

                    return (
                      <tr key={`checked-${rowIndex}`}>
                        <td className="flex w-max-content cursor-pointer gap-2">
                          <img
                            onClick={() =>
                              location.pathname === "/campaign/addCampaign"
                                ? handleDeleteClick(row.uid, email)
                                : handleDeleteClick(row.uid, email)
                            }
                            src={deleteIcon}
                            alt="delete candidate icon"
                          />
                          <input
                            type="checkbox"
                            checked={row.is_selected || false}
                            onChange={(e) =>
                              handleCheckboxChange1(
                                rowIndex,
                                e.target.checked,
                                row,
                                email
                              )
                            }
                            disabled={
                              sameCandidate.includes(email) ||
                              row.initiallySelected
                            }
                          />
                        </td>
                        {Array.isArray(row)
                          ? row.map((cell, cellIndex) => (
                              <td key={cellIndex}>{cell}</td>
                            ))
                          : isObjectRow && (
                              <>
                                <td>{row.first_name}</td>
                                <td>{row.last_name}</td>
                                <td>{row.email}</td>
                                <td>{row.mobile}</td>
                              </>
                            )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </div>
        )}

        {/* {excelData?.length || singleAddCandidate?.length > 0 && ( */}
        <div className=" flex flex-col bg-[#FFFFFF] p-4 rounded-lg fill-available h-[14rem]">
          <section>
            <p>Candidate List</p>
            <div className="flex items-center justify-between">
              <input
                placeholder="Search candidate"
                className="h-10 p-3 outline-0 border-[#F1F2F4] border-2 bg-[#FFFFFF] rounded-lg mt-2 placeholder-text-sm"
                value={searchTerm}
                onChange={handleSearchChange}
              />

              {JSON.parse(selectAll) && (
                <button
                  onClick={handleSortList}
                  className="border-1 bg-red-400 text-white "
                  type="button"
                >
                  Shortlist
                </button>
              )}

              {searchTerm === "" && (
                <div class="flex items-center">
                  <input
                    id="default-checkbox"
                    checked={JSON.parse(selectAll)}
                    onChange={handleSelectAllChange}
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-[#A6AEBA] bg-gray-100 border-gray-300 rounded"
                  />
                  <label
                    for="default-checkbox"
                    class="ms-2 text-sm font-medium"
                  >
                    UnSelected All
                  </label>
                </div>
              )}
            </div>
          </section>
          <section className="overflow-auto">
            {filteredData?.length > 0 && (
              <table className="w-full mt-4">
                <thead className="bg-[#F9FAFB] text-[0.833vw] text-[600]">
                  <tr>
                    <th key="empty">UnSelected</th>
                    {/* If excelData[0] is an array, render the headers as usual */}
                    {/* {Array.isArray(excelData[0]) ? (
                        excelData[0].map((cell, index) => (
                          <th className="text-left" key={index + 1}>
                            {cell}
                          </th>
                        ))
                      ) : ( */}
                    <>
                      {/* Define headers for the object data structure */}
                      <th className="text-left">First Name</th>
                      <th className="text-left">Last Name</th>
                      <th className="text-left">Email</th>
                      <th className="text-left">Mobile</th>
                    </>
                    {/* )} */}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((row, rowIndex) => {
                    const email = Array.isArray(row) ? row[2] : row?.email;
                    const isObjectRow =
                      typeof row === "object" &&
                      !Array.isArray(row) &&
                      row !== null;

                    return (
                      <tr key={`unchecked-${rowIndex}`}>
                        <td className="flex w-max-content cursor-pointer gap-2">
                          <img
                            onClick={() =>
                              location.pathname === "/campaign/addCampaign"
                                ? handleDeleteClick(row.uid, email)
                                : handleDeleteClick(row.uid, email)
                            }
                            src={deleteIcon}
                            alt="delete candidate icon"
                          />
                          <input
                            type="checkbox"
                            // checked={selectedCheckboxes[rowIndex] || false}
                            checked={selectedCheckboxes[email] || false}
                            onChange={(e) =>
                              handleCheckboxChange(
                                rowIndex,
                                e.target.checked,
                                row,
                                email
                              )
                            }
                            disabled={sameCandidate.includes(email)}
                          />
                        </td>
                        {Array.isArray(row)
                          ? row.map((cell, cellIndex) => (
                              <td key={cellIndex}>{cell}</td>
                            ))
                          : isObjectRow && (
                              <>
                                <td
                                  className={`${
                                    !row.first_name
                                      ? "border border-red-600"
                                      : ""
                                  }`}
                                >
                                  {row.first_name}
                                </td>
                                <td
                                  className={`${
                                    !row.last_name
                                      ? "border border-red-600"
                                      : ""
                                  }`}
                                >
                                  {row.last_name}
                                </td>
                                <td
                                  className={`${
                                    !row.email ? "border border-red-600" : ""
                                  }`}
                                >
                                  {row.email}
                                </td>
                                <td
                                  className={`${
                                    !row.mobile ? "border border-red-600" : ""
                                  }`}
                                >
                                  {row.mobile}
                                </td>
                              </>
                            )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </section>
        </div>
        {/* )} */}
      </div>
      {/* {hasEmailError && (
        <div style={{ color: "red" }}>
          One or more selected entries have an empty email field.
        </div>
      )} */}
    </section>
  );
};

const AddCandidate = ({
  isNextAcitvated,
  setIsNextActivated,
  current,
  setCurrent,
  setAllReqData,
  uploadFile,
  setUploadFile,
  excelData,
  setExcelData,
  singleAddCandidate,
  setSingleAddCandidate,
  selectedData,
  setSelectedData,
  setSelectedCheckboxes,
  selectedCheckboxes,
  selectAll,
  setSelectAll,
  editData,
  prevEmail,
  setPrevEmail,
  filteredData,
  setFilteredData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorFormat, setErrorFormat] = useState();
  const [emptyError, setEmptyError] = useState("");
  const [incorrectError, setIncorrectError] = useState("");
  const [isModalAddCandidateOpen, setIsModalAddCandidateOpen] = useState(false);
  const [newCandidatesAdded, setIsNewCandidatesAdded] = useState([]);
  const [isVisibleConfirmation, setIsVisibleConfirmation] = useState(false);
  const [checkedFilteredData, setCheckedFilteredData] = useState([]);

  const selectedFilteredData = filteredData.filter((item) => item?.is_selected);
  const checkedData = checkedFilteredData.filter((item) => !item?.uid);

  console.log(
    selectedFilteredData,
    "selectedFilteredData",
    checkedData,
    checkedFilteredData
  );

  useEffect(() => {
    if (isNextAcitvated) {
      if (current === 2) {
        if (location.pathname === "/editcampaign/addCampaign") {
          if (JSON.parse(selectAll) !== true) {
            setIsVisibleConfirmation(true);
          } else {
            toast.warning(
              "Select and Sort list candidate to proceed to the next step."
            );
          }
        } else {
          if (
            checkedFilteredData?.length !== 0 ||
            (selectedFilteredData?.length !== 0 &&
              JSON.parse(selectAll) !== true)
          ) {
            setIsVisibleConfirmation(true);
          } else {
            if (filteredData?.length === 0) {
              toast.warning(
                "Import or add a candidate to proceed to the next step."
              );
            } else {
              toast.warning(
                "Select and Sort list candidate to proceed to the next step."
              );
            }
          }
        }
      }
      setIsNextActivated(false);
    }
  }, [isNextAcitvated]);

  const callApi = async () => {
    let checkedData = checkedFilteredData.map((item) => ({
      ...item,
      is_selected: item.is_selected ? 1 : 0,
      campaign: sessionStorage.getItem("campaignUid"),
      organization: sessionStorage.getItem("parentUid"),
      uid: item.uid ? item.uid : undefined,
    }));

    let allData = [...checkedData, ...selectedData];

    let filterData = allData.filter((item) => item.uid);
    let filterData1 = allData.filter((item) => !item.uid);

    // console.log("allData", filterData, filterData1);

    try {
      if (location.pathname === "/editcampaign/addCampaign") {
        if (filterData?.length > 0) {
          const updateEndpoint = "/api/v1/interview/update_candidate/";
          const updateResponse = await handleApiResponse(
            updateEndpoint,
            filterData,
            1
          );

          if (updateResponse?.data?.status_code === 200) {
            toast.success("Campaign successfully updated");
            let combinedData = [];
            selectedFilteredData?.map((item) => {
              if (!item?.initiallySelected) {
                combinedData.push(item?.uid);
              }
            });

            if (combinedData?.length > 0) {
              const reqDataLink = {
                candidate_list: combinedData,
              };

              const responseLink = await handleApiResponse(
                "/api/v1/interview/generate_unique_link/",
                reqDataLink,
                1
              );

              if (responseLink?.data?.status_code === 200) {
                filterData1?.length <= 0 &&
                  toast.success("Email has been shared with the candidates.");
                clearsessionStorageAndNavigate();
                navigate("/admin/campaign");
              } else {
                toast.error("There was an error sending the email.");
              }
            } else {
              clearsessionStorageAndNavigate();
              navigate("/admin/campaign");
            }
          } else {
            toast.error(
              updateResponse?.data?.message || "Error updating candidates"
            );
          }
        }

        if (filterData1?.length > 0) {
          const addEndpoint = "/api/v1/interview/add_candidate/";
          const addResponse = await handleApiResponse(
            addEndpoint,
            filterData1,
            1
          );

          handleResponse(addResponse);
        }
      } else {
        if (filterData1.length > 0) {
          const addEndpoint = "/api/v1/interview/add_candidate/";
          const response = await handleApiResponse(addEndpoint, filterData1, 1);
          handleResponse(response);
        }
      }
    } catch (error) {
      console.error("Error occurred while calling API:", error);
      toast.error("Failed to process candidates. Please try again.");
    }
  };

  const handleResponse = async (response) => {
    if (response?.data?.status_code === 200) {
      sessionStorage.removeItem("currentAddCampaign");
      sessionStorage.removeItem("roleId");
      if (location.pathname !== "/editcampaign/addCampaign") {
        toast.success("Campaign successfully added");
      }

      let combinedData = [];

      response?.data?.data?.filter((item) => {
        if (item?.is_selected === 1) {
          combinedData.push(item?.uid);
        }
      });

      if (combinedData?.length > 0) {
        const reqDataLink = {
          candidate_list: combinedData,
        };

        const responseLink = await handleApiResponse(
          "/api/v1/interview/generate_unique_link/",
          reqDataLink,
          1
        );

        if (responseLink?.data?.status_code === 200) {
          toast.success("Email has been shared with the candidates.");
          clearsessionStorageAndNavigate();
          navigate("/admin/campaign");
        } else {
          toast.error("There was an error sending the email.");
        }
      } else {
        clearsessionStorageAndNavigate();
        navigate("/admin/campaign");
      }
    }
    // else {
    //   toast.error(
    //     "Duplicate emails are not acceptable.A candidate with this email already exists."
    //   );
    //   // toast.error(response?.data?.message);
    // }
  };

  const clearsessionStorageAndNavigate = () => {
    sessionStorage.removeItem("campaignUid");
    sessionStorage.removeItem("campName");
    sessionStorage.removeItem("jrName");
    sessionStorage.removeItem("camp_type");
    sessionStorage.removeItem("skills");
    sessionStorage.removeItem("createdDate");
    sessionStorage.removeItem("endDate1");
    sessionStorage.removeItem("coding_ques");
    sessionStorage.removeItem("desc");
  };

  const generateExcelFile = () => {
    const requiredHeaders = ["First Name", "Last Name", "Email", "Mobile"];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(ws, [requiredHeaders], { origin: "A1" });

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "add_candidate_template.xlsx");
  };

  const openModalAddCandidate = () => {
    setIsModalAddCandidateOpen(true);
  };

  const closeModalAddCandidate = () => {
    setIsModalAddCandidateOpen(false);
  };

  const handleAddCandidate = (newCandidate) => {
    setExcelData((prevData) => {
      if (!uploadFile && prevData?.length === 0) {
        const newData = [
          ["First Name", "Last Name", "Email", "Mobile"],
          newCandidate,
        ];
        return newData;
      } else {
        const updatedData = [...prevData];
        updatedData.splice(1, 0, newCandidate);
        return updatedData;
      }
    });
  };

  return (
    <section className="fill-available flex flex-col gap-4">
      {isModalAddCandidateOpen && (
        <ModalAddCandidate
          head={"Add"}
          isOpen={isModalAddCandidateOpen}
          onClose={closeModalAddCandidate}
          setExcelData={setExcelData}
          excelData={excelData}
          setSingleAddCandidate={setSingleAddCandidate}
          setSelectedCheckboxes={setSelectedCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          setSelectedData={(newCandidate) => {
            setSelectedData((prev) => [newCandidate, ...prev]);
            setIsNewCandidatesAdded((prev) => [newCandidate, ...prev]);
          }}
          onAddCandidate={handleAddCandidate}
        />
      )}
      {/* {renderTable()} */}
      <div className="self-end flex gap-2">
        <AnalyticsPageBtn
          name={"Download Format"}
          img={1}
          onClick={generateExcelFile}
        />
        <FaInfoCircle
          data-tooltip-id="skillsInfo"
          className=" cursor-pointer text-gray-500 mr-2"
        />
        <Tooltip id="skillsInfo" place="top" effect="solid">
          All fields are mandatory to filled in the format.
        </Tooltip>
        <AnalyticsPageBtn
          name={"Add Candidate"}
          img={5}
          onClick={openModalAddCandidate}
        />
      </div>
      <div className="container">
        <UploadResumeOnly
          setUploadResume={setUploadFile}
          uploadResume={uploadFile}
          setErrorFormat={setErrorFormat}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setEmptyError={setEmptyError}
          setIncorrectError={setIncorrectError}
          excelData={excelData}
          setExcelData={setExcelData}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectedCheckboxes={selectedCheckboxes}
          setSelectedCheckboxes={setSelectedCheckboxes}
          editData={editData}
          prevEmail={prevEmail}
          setPrevEmail={setPrevEmail}
          newCandidatesAdded={newCandidatesAdded}
          setIsNewCandidatesAdded={setIsNewCandidatesAdded}
          setIsNextActivated={setIsNextActivated}
          isNextAcitvated={isNextAcitvated}
          singleAddCandidate={singleAddCandidate}
          setSingleAddCandidate={setSingleAddCandidate}
          isVisibleConfirmation={isVisibleConfirmation}
          setIsVisibleConfirmation={setIsVisibleConfirmation}
          handleCallApi={callApi}
          checkedFilteredData={checkedFilteredData}
          setCheckedFilteredData={setCheckedFilteredData}
        />
      </div>
      {errorFormat &&
        (selectedData?.length < 1 || checkedFilteredData.length < 1) && (
          <div className="text-red-500">File not in right format</div>
        )}
      {emptyError &&
        (selectedData?.length < 1 || checkedFilteredData.length < 1) && (
          <div className="text-red-500">No candidate has been selected</div>
        )}

      {incorrectError &&
        (selectedData?.length < 1 || checkedFilteredData.length < 1) && (
          <div className="text-red-500">File not selected.</div>
        )}
    </section>
  );
};

const ReviewCandidate = ({
  isNextAcitvated,
  current,
  allReqData,
  setIsNextActivated,
  prevEmail,
}) => {
  const [candidates, setCandidates] = useState([]);
  const [roleName, setRoleName] = useState(
    sessionStorage.getItem("roleName") ? sessionStorage.getItem("roleName") : ""
  );
  const [start, setStart] = useState(
    sessionStorage.getItem("expFrom") ? sessionStorage.getItem("expFrom") : ""
  );
  const [end, setEnd] = useState(
    sessionStorage.getItem("expTo") ? sessionStorage.getItem("expTo") : ""
  );
  const navigate = useNavigate();
  const [qtype, setQType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [campaign_type, setCampaign_type] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [hasGeneratedLinks, setHasGeneratedLinks] = useState(false);

  useEffect(() => {
    const linkType = sessionStorage.getItem("mail_share_type");
    if (linkType === "1") {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    console.log(allReqData[0]);
    setStartDate(allReqData[0]?.start_date);
    setEndDate(allReqData[0]?.end_date);
    setCampaign_type(allReqData[0]?.campaign_type === 1 ? "Regular" : "Mass");

    console.log(allReqData[1]);
    setQType(() => {
      return allReqData[1] === "option1"
        ? "AI Questions"
        : allReqData[1] === "option2"
        ? "HR Questions"
        : allReqData[1] === "option3"
        ? "Blended Questions"
        : null;
    });

    setCandidates(allReqData[2]);
  }, [allReqData]);

  const downloadLinks = async () => {
    callApi1();
    if (!hasGeneratedLinks) {
      const reqData = {
        campaign_uid: getLocal("campaignUid"),
      };
      const response = await handleApiResponse(
        "/api/v1/interview/generate_unique_link/",
        reqData,
        1
      );

      if (response?.data?.status_code === 200) {
        const dataArray = response?.data?.data;
        const formattedData = dataArray.map((item) => ({
          first_name: item.candidate.first_name,
          last_name: item.candidate.last_name,
          emailid: item.candidate.email,
          links:
            `${process.env.REACT_APP_FRONEND}/interview/candidate/` + item.link,
        }));
        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Links");

        // Generate a buffer
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        // Create a blob from the buffer
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });

        // Save the file
        saveAs(blob, "links.xlsx");
      }
    }
  };

  const callApi1 = async () => {
    const reqData = candidates;
    const response = await handleApiResponse(
      "/api/v1/interview/add_candidate/",
      reqData,
      1
    );
    if (response?.data?.status_code === 200) {
      sessionStorage.removeItem("currentAddCampaign");
      sessionStorage.removeItem("roleId");
      sessionStorage.removeItem("campaignUid");
      sessionStorage.removeItem("campName");
      sessionStorage.removeItem("jrName");
      sessionStorage.removeItem("camp_type");
      sessionStorage.removeItem("skills");
      sessionStorage.removeItem("createdDate");
      sessionStorage.removeItem("endDate1");
      sessionStorage.removeItem("coding_ques");
      sessionStorage.removeItem("desc");
      toast.success("Campaign successfully added");
      navigate("/admin/campaign");
    } else {
      toast.error(response?.data?.message);
    }
  };

  const callApi = async () => {
    const reqData = candidates;
    console.log(reqData);
    if (!reqData.hasOwnProperty("organization")) {
      // Add organization key to reqData
      reqData.organization = sessionStorage.getItem("parentUid");
    }
    if (!reqData.hasOwnProperty("campaign")) {
      // Add campaign key to reqData
      reqData.campaign = sessionStorage.getItem("campaignUid");
    }
    const response = await handleApiResponse(
      "/api/v1/interview/add_candidate/",
      reqData,
      1
    );
    if (response?.data?.status_code === 200) {
      sessionStorage.removeItem("currentAddCampaign");
      sessionStorage.removeItem("roleId");
      toast.success("Campaign successfully added");
      const reqData = {
        campaign_uid: sessionStorage.getItem("campaignUid"),
      };
      const response = await handleApiResponse(
        "/api/v1/interview/generate_unique_link/",
        reqData,
        1
      );
      console.log(response?.data);
      if (response?.data?.status_code === 200) {
        toast.success("Email has been shared to the candidates.");
        sessionStorage.removeItem("campaignUid");
        sessionStorage.removeItem("campName");
        sessionStorage.removeItem("jrName");
        sessionStorage.removeItem("camp_type");
        sessionStorage.removeItem("skills");
        sessionStorage.removeItem("createdDate");
        sessionStorage.removeItem("endDate1");
        sessionStorage.removeItem("coding_ques");
        sessionStorage.removeItem("desc");
      }
      navigate("/admin/campaign");
    } else {
      toast.error(response?.data?.message);
    }
  };

  const generateUniqueLink = async (id) => {
    const reqData = {
      campaign_uid: sessionStorage.getItem("campaignUid"),
      candidate_uid: id,
    };
    const response = await handleApiResponse(
      "/api/v1/interview/generate_unique_link/",
      reqData,
      1
    );
    console.log("this is status", response?.data?.status_code);
    if (response?.data?.status_code === 200) {
      let link =
        `${process.env.REACT_APP_FRONEND}/interview/candidate/` +
        response?.data?.data?.link;

      //navigator.clipboard.writeText(link)
      // copyToClipboard(link);

      toast.success("link has been sent to email !");
      // setRedirectLink(link);
      // setCount((prev) => prev + 1);
    } else {
      toast.error(response?.data?.msg);
    }
  };

  // const handleRegenerate = (id) => {
  //   // console.log(id);
  //   // props?.callApi();
  //   // if (!props.candidateId.includes(id)) {
  //   //   props.setCandidateId((prev) => [...prev, id]);
  //   // }
  //   generateUniqueLink(id);
  // };

  useEffect(() => {
    if (isNextAcitvated) {
      console.log("this ran");
      if (current === 3) {
        console.log("this is 3", allReqData);
        callApi();
      }
      setIsNextActivated(false);
    }
  }, [isNextAcitvated]);
  return (
    <section className="fill-available flex flex-col gap-4">
      <div className="text-[1.389vw] text-[600] tracking-[2%] mb-4">
        {roleName} ({start}-{end} Years)
      </div>
      <div className="flex gap-8 mb-4">
        <div className="flex gap-2">
          <img src={Ellipse} alt="ellipse" />
          <div>{roleName}</div>
        </div>
        <div className="flex gap-2">
          <img src={Ellipse} alt="ellipse " />
          <div>{campaign_type}</div>
        </div>
        <div className="flex gap-2">
          <img src={Ellipse} alt="ellipse " />
          <div>
            {startDate} to {endDate}
          </div>
        </div>
        <div className="flex gap-2">
          <img src={Ellipse} alt="ellipse " />
          <div>{qtype}</div>
        </div>
      </div>
      {isVisible && (
        <div className="self-end">
          <AnalyticsPageBtn
            name={"Download Links"}
            img={1}
            onClick={downloadLinks}
          />
        </div>
      )}
      <div>
        <div className="bg-[#FFFFFF] mt-2 p-4 rounded-lg">
          <p className="mt-4 mb-4 text-[1.111vw] font-medium leading-[2%]">
            Added Candidate
          </p>
          <div className="grid grid-cols-2 h-[27rem] overflow-auto">
            {candidates &&
              candidates.map((e) => {
                return (
                  <div className="w-[22vw] h-fit mt-8 border-2 p-2 rounded-lg border-[#A6AEBA33] flex justify-between">
                    <p> ({e.email})</p>
                    <img src={LinkIcon} alt="Link" className="w-4 h-4" />
                  </div>
                );
              })}
            {prevEmail &&
              prevEmail.map((e) => {
                return (
                  <div className="w-[22vw] h-fit mt-8 border-2 p-2 rounded-lg border-[#A6AEBA33] flex justify-between">
                    <p> ({e})</p>
                    <img src={LinkIcon} alt="Link" className="w-4 h-4" />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};
