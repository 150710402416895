import React, { useState, useRef } from 'react';
import axios from 'axios';

export const VoiceRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [gptTranscript, setGptTranscript] = useState('');
  const [whisperTranscript, setWhisperTranscript] = useState('');
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const apiKey = process.env.REACT_APP_WHISPER_API_KEY;

  const handleRecord = async () => {
    if (isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    } else {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: 'audio/wav',
        });
        audioChunksRef.current = [];

        // Convert Blob to Base64
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64String = reader.result.split(',')[1];

          // Create FormData for Whisper API
          const formData = new FormData();
          formData.append('file', audioBlob, 'recording.wav');
           formData.append('model', 'whisper-3');
           formData.append('language', 'en');

          try {
            setGptTranscript('');
            setWhisperTranscript('');
            // Send to Whisper API
            const whisperResponse = await axios.post(
              'https://api.openai.com/v1/audio/transcriptions',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${apiKey}`,
                },
            
              }
            );
            const transcription = whisperResponse.data.text;
            setWhisperTranscript(transcription);

            // Send to GPT-4o API
            const gptResponse = await axios.post(
              'https://api.openai.com/v1/chat/completions',
              {
                model: 'gpt-4o',
                messages: [
                  {
                    role: 'system',
                    content:
                      'Sending audio converted into base64 decode it and send the transcription. If you are not clear about the accuracy, do not add anything unnecessary. Add punctuation such as periods, commas, and capitalization only where necessary, and ensure the transcription is clear and formal.',
                  },
                  {
                    role: 'user',
                    content: base64String,
                  },
                ],
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${apiKey}`,
                },
              }
            );
            setGptTranscript(gptResponse.data.choices[0].message.content);
          } catch (error) {
            console.error('Error transcribing audio:', error);
          }
        };
        reader.readAsDataURL(audioBlob); // Converts Blob to Base64
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    }
  };

  return (
    <div>
      <button onClick={handleRecord}>
        {isRecording ? 'Stop Recording' : 'Start Recording'}
      </button>
      <div
        style={{
          backgroundColor: 'orange',
          padding: '10px',
          marginTop: '10px',
        }}
      >
        <h2>GPT-4o Transcript:</h2>
        <p>{gptTranscript}</p>
      </div>
      <div
        style={{
          backgroundColor: 'skyblue',
          padding: '10px',
          marginTop: '10px',
        }}
      >
        <h2>Whisper Transcript:</h2>
        <p>{whisperTranscript}</p>
      </div>
    </div>
  );
};

export default VoiceRecorder;
