import { userActions } from "./actions";
import { noError, initialState } from "./defaultStates";

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.SIGNUP_USER:
      return {
        ...state,
        isLoading: true,
        user: {},
        token: "",
        isLoggedIn: false,
        error: noError,
      };

      case userActions.SIGNUP_USER_SUCCESS:
        console.log(action.payload)
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        token: action.payload.access,
        isLoggedIn: true,
        error: null,
      };

      case userActions.SIGNUP_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        user: {},
        token: '',
        isLoggedIn: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};
export default userReducer;
