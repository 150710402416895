import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const VideoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);

  // Retrieve the video URL from sessionStorage
  // const videoUrl = location?.search;
  const videoUrl = location?.search.slice(1).split('=')[1];
    console.log(location?.search.slice(1).split('=')[1]);
  console.log(videoUrl);
  useEffect(() => {
    if (videoRef.current && videoUrl) {
      videoRef.current.src = videoUrl;
      videoRef?.current?.load();
    }
  }, [videoUrl]);

  const handleVideoEnd = () => {
    navigate(-1); // Go back after video is played
  };

  return (
    <div className="flex flex-col items-end justify-end">
      {videoUrl ? (
        <video
          // ref={videoRef}
          ref={videoRef}
          className="w-full h-full"
          controls
          onEnded={handleVideoEnd}
        >
          Your browser does not support the video tag.
        </video>
      ) : (
        <p>No video to display.</p>
      )}
    </div>
  );
};

export default VideoPage;
