import React, { useState, useEffect } from "react";
import { AnalyticsPageBtn } from "../../components/buttons";
import { deleteIcon, editIcon, userImg, searchIcon } from "../../assets/imgs/index";
import {
  ModalAddJobRole,
  ModalViewJobRole,
} from "../../components/AdminComponents/modal";
import { JobRoleTable } from "../../components/AdminComponents/table";
import { handleApiResponse } from "../../API/services";
import { ModalDeleteJobRole } from "../../components/AdminComponents/modal";

function JobRole() {
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpenResume, setIsModalOpenResume] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEditConfirmation, setOpenEditConfirmation] = useState(false);
  const [search, setSearch] = useState('');
  const [uuid, setUuid] = useState("");
  const [openWhat, setOpenWhat] = useState(false);

  const itemsPerPage = 6;

  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      limit: userLimit,
      page: pageNo,
      is_deleted: 0,
    };
    const response = await handleApiResponse(
      "/api/v1/interview/jobrole_list/",
      reqData,
      1
    );
    if (response.data.status_code === 200) {
      setTableData(Object.values(response.data.data).flat());
    }
  };

  useEffect(() => {
    getUser(itemsPerPage, pageNo);
  }, [pageNo, isModalOpen3, openDeleteConfirmation, openEditConfirmation]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filtered data based on search input
  const filteredData = tableData.filter((rowData) => {
    const row14Value = `${rowData.experience_from} - ${rowData.experience_to} years`;
    return (
      rowData.name.toLowerCase().includes(search.toLowerCase()) ||
      rowData.description.toLowerCase().includes(search.toLowerCase()) ||
      row14Value.toLowerCase().includes(search.toLowerCase()) ||
      (rowData.created_date && new Date(rowData.created_date).toISOString().split("T")[0].includes(search.toLowerCase())) ||
      rowData.creater_name.toLowerCase().includes(search.toLowerCase())
    );
  });

  const currentPageData = filteredData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openModalAddJobRole = () => {
    setIsModalOpen3(true);
  };

  const closeModal5 = () => {
    setOpenDeleteConfirmation(false);
  };

  const openModalResume = () => {
    setIsModalOpenResume(true);
  };

  const openModalEditJobRole = () => {
    setIsModalOpen4(true);
  };

  const closeModalResume = () => {
    setIsModalOpenResume(false);
  };

  const closeModalAddJobRole = () => {
    setOpenEditConfirmation(false);
    setIsModalOpen3(false);
  };

  const closeModalEditJobRole = () => {
    setIsModalOpen4(false);
  };

  const openModalViewJobRole = () => {
    setIsModalOpen2(true);
  };

  const closeModalViewJobRole = () => {
    setIsModalOpen2(false);
  };

  const checkUid = () => {
    if (uuid.length) {
      if (openWhat.length) {
        setOpenDeleteConfirmation(true);
      } else {
        setOpenEditConfirmation(true);
      }
      console.log("open");
    }
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on search input change
  };

  useEffect(() => {
    checkUid();
    console.log("this is useEffect", uuid);
  }, [uuid]);

  return (
    <div className="text-[#A6AEBA] ml-[1.5rem] mr-[1.5rem] gap-16 flex flex-col">
      {openDeleteConfirmation && (
        <ModalDeleteJobRole
          uid={uuid}
          onClose={closeModal5}
          setUuid={setUuid}
        />
      )}
      {openEditConfirmation && (
        <ModalAddJobRole
          head={"Edit"}
          openEditConfirmation={openEditConfirmation}
          isOpen={isModalOpen3}
          onClose={closeModalAddJobRole}
        />
      )}
      <div className="flex justify-between">
        <div className="font-semibold text-[1.25vw] mb-[1rem]">Job Role</div>
        <AnalyticsPageBtn
          name={"Add Job Role"}
          img={5}
          onClick={openModalAddJobRole}
        />
        {isModalOpen3 && (
          <ModalAddJobRole
            head={"Add"}
            isOpen={isModalOpen3}
            onClose={closeModalAddJobRole}
          />
        )}
        <ModalViewJobRole
          isOpen={isModalOpen2}
          onClose={closeModalViewJobRole}
        />
      </div>
      <div className="flex p-4">
        <input
          placeholder="Search job role"
          onChange={handleSearchInputChange}
          className="w-[85vw] h-10 p-5 outline-0 border-[#F1F2F4] border-2 bg-[#FFFFFF] rounded-lg mt-2 placeholder-text-sm"
          value={search}
          // onKeyDown={handleKeyDown}
        />
        <img
          src={searchIcon}
          alt="search icon"
          className="cursor-pointer right-8 relative w-[16px] h-[51.2px]"
        />
      </div>
      <div>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-[#A6AEBA] text-left rtl:text-right">
            <thead className="text-[0.833vw] font-semibold text-[#A6AEBA] border-b h-[2px] border-[#E4E7EB]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  S.No.
                </th>
                <th scope="col" className="px-6 py-3">
                  Job Role Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Brief Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Experience
                </th>
                <th scope="col" className="px-6 py-3">
                  Created On
                </th>
                <th scope="col" className="px-6 py-3">
                  Created By
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  Edited By
                </th> */}
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((rowData, index) => (
                <JobRoleTable
                  setOpenEditConfirmation={setOpenEditConfirmation}
                  isModalOpen5={isModalOpen5}
                  setIsModalOpen5={setIsModalOpen5}
                  key={index}
                  icon={userImg}
                  number={startIndex + index + 1} // Correct the row number for pagination
                  row12={rowData.name}
                  row13={rowData.description}
                  row14={`${rowData.experience_from} - ${rowData.experience_to} years`}
                  row15={
                    rowData.created_date
                      ? new Date(rowData.created_date)
                          .toISOString()
                          .split("T")[0]
                      : "N/A"
                  }
                  row16={rowData.creater_name}
                  row17={rowData.jd}
                  editIcon={editIcon}
                  deleteIcon={deleteIcon}
                  openModal={(uid) => {
                    openModalEditJobRole();
                    console.log("Open modal for uid:", uid);
                  }}
                  closeModal={closeModalEditJobRole}
                  openModal1={openModalViewJobRole}
                  openModalResume={openModalResume}
                  closeModalResume={closeModalResume}
                  closeModal1={closeModalViewJobRole}
                  isModalOpenResume={isModalOpenResume}
                  isModalOpen4={isModalOpen4}
                  isModalOpen2={isModalOpen2}
                  uid={rowData.uid}
                  setuuid={setUuid}
                  setOpenWhat={setOpenWhat}
                  setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                  rowData={rowData}
                />
              ))}
            </tbody>
          </table>
          <div className="pagination flex justify-end mt-4">
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page + 1}
                onClick={() => handlePageChange(page + 1)}
                className={`px-3 py-2 mx-1 border rounded-md ${
                  currentPage === page + 1
                    ? "bg-[#A6AEBA1A] text-[#A6AEBA]"
                    : "bg-white text-[#A6AEBA]"
                }`}
              >
                {page + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobRole;
