import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { handleApiResponse } from "../../API/services";
import { useDispatch } from "react-redux";
import { userDetailsSuccess } from "../../redux/userDetails/actions";
import { setLocal } from "../../utils/localStorage";
import { LinkedinShareButton } from "react-share";
import { bulkCandidateDetailsSuccess } from "../../redux/bulkCandidateUserDetails/actions";
import { MediaDevices } from "../mediaDevices";
import { CustomButtonWhite } from "../../components/buttons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import Certificatepage from "../../components/AdminComponents/certificate";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import MicConfig from "../../utils/MicConfig";

export const LandingPage = () => {
  const { intLink } = useParams();
  const [userData, setUserData] = useState(false);
  const [allowed, setAllowed] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [resumeAllowed, setResumeAllowed] = useState("");
  const dispatch = useDispatch();
  // const campType=sessionStorage.getItem('campType');
  const [campType, setCampType] = useState("");
  const navigate = useNavigate();
  sessionStorage.removeItem("openEditor");
  sessionStorage.removeItem("curr");
  sessionStorage.removeItem("codingQuestion");
  sessionStorage.removeItem("code");

  console.log(intLink);
  console.log(campType);
  useEffect(() => {
    (async () => {
      let data = { interview_link_token: intLink };
      sessionStorage.clear()
      const response1 = await handleApiResponse(
        "/api/v1/interview/general_link_view/",
        data,
        0
      );
      sessionStorage.removeItem("upload_id");
      console.log(response1?.data?.campaign_type);
      if (response1?.status_code === 200) {
        console.log(response1?.data?.campign_info?.campaign_type);
        if (response1?.data?.campign_info?.campaign_type === 1) {
          setCampType("Regular");
        } else if (response1?.data?.campaign_type === 2) {
          setCampType("Mass");
        }
      } else if (response1?.status_code === 400) {
        setAllowed(false);
        setMessage(response1?.msg);
      }
      if (campType === "Regular") {
        let data = { interview_link_token: intLink };
        const response = await handleApiResponse(
          "/api/v1/interview/unique_link_view/",
          data,
          0
        );
        if (response?.status_code === 200) {
          dispatch(bulkCandidateDetailsSuccess(response.data));
          console.log(response.data);
          sessionStorage.setItem("campType", campType);
          sessionStorage.setItem("candFName", response?.data?.first_name);
          sessionStorage.setItem("user", response?.data?.uid);
          setLocal("organization", response?.data?.company);
          sessionStorage.setItem("candLName", response?.data?.last_name);
          sessionStorage.setItem("candMobile", response?.data?.mobile);
          sessionStorage.setItem("candEmail", response?.data?.email);
          sessionStorage.setItem(
            "jobRole",
            response?.data?.campign_info?.jr_name
          );
          // dispatch(setLinkType(campType))
          sessionStorage.setItem("CandidateUid", response.data.uid);
          sessionStorage.setItem("link", response.data.linkinfo.link);
          setResumeAllowed(
            response.data.campign_info.is_candidate_resume_allow
          );
          setUserData(response?.data);
          // setLocal('Cid',response?.data.uid)
          // setLocal('Oid',response?.data.organization)
          dispatch(userDetailsSuccess(response?.data));
          // setDontMoveForward(true);
        } else if (response?.status_code === 400) {
          setMessage(response.msg);
          setAllowed(false);
        } else {
          setError(response?.msg);
        }
      }
      if (campType === "Mass") {
        let data = { interview_link_token: intLink };
        const response = await handleApiResponse(
          "/api/v1/interview/bulk_link_view/",
          data,
          0
        );
        console.log("response", response?.data?.is_candidate_resume_allow);
        if (response?.status_code === 200) {
          sessionStorage.setItem("campType", campType);
          setUserData(response?.data);
          setResumeAllowed(response?.data?.is_candidate_resume_allow);
          // setDontMoveForward(true);
          setLocal("");
          setLocal("Oid", response?.data.organization);
          setLocal("Cid", response?.data.uid);
          setLocal("roleName", response?.data.jr_name);
          setLocal("roleName", response?.data.jr_name);
          setLocal("organization", response?.data.company);
          dispatch(userDetailsSuccess(response?.data));
        } else if (response?.status_code === 400) {
          setMessage(response.msg);
          setAllowed(false);
        } else {
          setError(response?.msg);
        }
      }
    })();
  }, [campType]);
  const sendToDetails = () => {
    navigate("details", {
      state: { resume: resumeAllowed },
    });
  };
  console.log(userData.uid);
  console.log(allowed);
  if (allowed) {
    return (
      <main className="flex flex-col text-text-color-code1 items-center justify-around h-[80vh]">
        <section className="flex flex-col items-center">
          <p className="text-[1.75rem] font-semibold">
            Hi {userData?.first_name} {userData?.last_name}, Welcome to the AI
            Interview Revolution!
          </p>
          <div className="text-text-colour-code1 flex flex-col items-center mt-[3.375rem]">
            <p>
              Get ready to dazzle in our AI interview app. Unleash your genius,
              conquer challenges, and let the AI magic begin.
            </p>
            <p>Let's rock this interview journey together!</p>
          </div>
        </section>
        <section>
          <button
            className="w-[27.5rem] h-12 bg-fill-btn-color-code1 rounded-lg "
            onClick={sendToDetails}
          >
            Start your Journey
          </button>
        </section>
      </main>
    );
  } else {
    return (
      <main className="flex flex-col text-text-color-code1 items-center justify-around h-[80vh]">
        <section className="flex flex-col items-center">
          <p className="text-[1.75rem] font-semibold">
            {`${message} .`}

            {/* This link has expired. Try reaching out to Admin for further queries. */}
          </p>
        </section>
      </main>
    );
  }
};

export const StartPage = () => {
  const location = useLocation();
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  let item = location?.state?.isCoding_quest_allow;
  const [isOk, setIsOK] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [micConfigOn, setMicConfigOn] = useState(false);
  const interview_link_token = useSelector((state) => {
    if (state?.candidateData?.user?.linkinfo?.link) {
      return state.candidateData.user.linkinfo.link;
    } else {
      return "";
    }
  });
  const { intLink } = useParams();
  const [uploadId, setUploadId] = useState("");

  console.log(uploadId);
  window.speechSynthesis.cancel();

  useEffect(() => {
    // Show the tooltip when the component mounts
    setShowTooltip(true);

    // Hide the tooltip after 2 minutes
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 30000); // 2 minutes in milliseconds

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     let data = { candidate_uid: sessionStorage.getItem('CandidateUid') };
  //     const response1 = await handleApiResponse(
  //       '/api/v1/interview/upload_video/',
  //       data,
  //       0
  //     );
  //     console.log(response1?.data?.upload_id);
  //     setLocal('upload_id', response1?.data?.upload_id);
  //   })();
  // }, []);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const updateProfilePhoto = async () => {
    console.log("updating profile");
    try {
      const formData = new FormData();
      formData.append("uid", sessionStorage.getItem("CandidateUid"));
      const base64Image = imgSrc.split(",")[1];
      const mimeType = "image/jpeg";
      const blob = base64ToBlob(base64Image, mimeType);

      formData.append("photo", blob, "profile.jpg");
      if (sessionStorage.getItem("campType") === "Regular") {
        formData.append("interview_link_token", interview_link_token);
      }

      if (sessionStorage.getItem("campType") === "Mass") {
        formData.append("interview_link_token", intLink);
      }
      const response = await handleApiResponse(
        "/api/v1/interview/add_bulk_candidate/",
        formData,
        0
      );
      if (response?.status_code === 200) {
        console.log("profile photo added.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // if (imgSrc) {
    // updateProfilePhoto(imgSrc);
    // }
  }, [imgSrc]);

  // const openFullscreen = () => {
  //   const elem = document.documentElement;

  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) {
  //     /* Safari */
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) {
  //     /* IE11 */
  //     elem.msRequestFullscreen();
  //   }
  // };

  const handleRequestPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      console.log("All access granted!");
    } catch (error) {
      console.error("Error requesting microphone permission:", error);
    }
  };

  useEffect(() => {
    handleRequestPermission();
    if (sessionStorage.getItem("isStarted")) {
      console.log("this is isStarted", sessionStorage.getItem("isStarted"));
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("firstLoadDone");
    } else {
      sessionStorage.setItem("firstLoadDone", 1);
    }
  }, []);

  const handleTooltipHover = () => {
    // Show the tooltip on hover after the initial 2 minutes
    if (!showTooltip) {
      setShowTooltip(true);
    }
  };

  return (
    <main className="flex flex-col text-text-color-code1 items-center justify-around h-[80vh]">
      {/* <div className="items-end flex">
        <FaInfoCircle
          data-tooltip-id="skillsInfo"
          className="ml-2 cursor-pointer text-gray-500"
        />
        <Tooltip id="skillsInfo" arrow={false} place="top" effect="solid" isOpen={showTooltip} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          1. Allow your camera and microphone permission to proceed with the interview.<br/>
          2. Click on camera icon to click a photograph and save. Click on Start your interview <br/>
          3. Give the permission of entire screen sharing to proceed with the interview. <br/>
          4. You will be given 2 minutes for each question and to submit your response early you <br />can turn off the mike after completing the answer and it will automatically send your <br />response.<br />
          5. Your response will be automatically submitted after two minutes.<br />
          6. For coding question you will be given 30 minutes. Click on Submit button after<br/> completing the interview.<br />
          7. Then you can stop sharing your screen and click on End Interview after completing<br/> the interview.
        </Tooltip>
        </div> */}
      <section className="flex flex-col items-center">
        {/* <div className="items-end flex">
        <FaInfoCircle
          data-tooltip-id="skillsInfo"
          className="ml-2 cursor-pointer text-gray-500"
        />
        <Tooltip id="skillsInfo" place="top" effect="solid">
          ***************** Content ********************
        </Tooltip>
        </div> */}
        <p className="text-[1.75rem] font-semibold">
          Profile ready for liftoff!
        </p>
        <div className="text-text-colour-code1 flex flex-col items-center mt-[3.375rem] text-center px-3">
          <p>
            You're officially set to dive into the AI interview experience.
            Fasten your seatbelt, showcase your brilliance, and let the
            interview excitement unfold. Best of luck!
          </p>
          {/* <p>Let's rock this interview journey together!</p> */}
        </div>
      </section>
      <section>
        <MediaDevices
          setIsOK={setIsOK}
          isOk={isOk}
          imgSrc={imgSrc}
          setImgSrc={setImgSrc}
          updateProfilePhoto={updateProfilePhoto}
        />
      </section>
      <span
        className={`${
          showWarning && !imgSrc && isOk ? "block text-red-500" : "hidden"
        }`}
      >
        Click a photo before procedding
      </span>
      <span
        className={`${
          showWarning && !imgSrc && !isOk ? "block text-red-500" : "hidden"
        }`}
      >
        Please give permissions to proceed.
      </span>

      {/* <MicConfig /> */}

      <section className={`${isOk ? null : "border-2 border-red-500"}`}>
        <NavLink
          to={`${
            sessionStorage.getItem("isStarted")
              ? "../certificate"
              : imgSrc && isOk
              ? "../interview"
              : "."
          }`}
          state={{
            isCoding_quest_allow: item,
            upload_id: uploadId,
          }}
        >
          <button
            className="w-[27.5rem] h-12 bg-fill-btn-color-code1 rounded-lg hover:bg-[#E3E4E7]"
            onClick={() => {
              if (!imgSrc) {
                setShowWarning(true);
              }
            }}
          >
            Start your interview
          </button>
          {/* <button onClick={openFullscreen}>Go Fullscreen</button> */}
        </NavLink>
      </section>
    </main>
  );
};

export const Certificate = () => {
  const certificateRef = useRef();
  const shareUrl = "https://example.com";
  // const urlCertificate = `http://localhost:3000${certificate}`;
  // const urlCertificate = 'https://drive.google.com/file/d/1Y2IHJdOn3pM_ypN0XwkfSNnjG5dSaxK3/view?usp=sharing';
  const urlCertificate = "https://doczoid.com/media/profile/car_vykcS4V.jpg";
  console.log(urlCertificate);
  const warningCount = sessionStorage.getItem("wc");
  const on_face_detect = sessionStorage.getItem("no_face_detect_count");
  const networkError = sessionStorage.getItem("networkError");
  const coveringCameraTimer = sessionStorage.getItem("coverCamera");

  useEffect(() => {
    // Add a new state to the history stack
    sessionStorage.removeItem("CandidateUid");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("isStarted");
    sessionStorage.removeItem("openEditor");
    sessionStorage.removeItem("curr");
    sessionStorage.removeItem("codingQuestion");
    sessionStorage.removeItem("code");

    window.history.pushState(null, null, window.location.href);

    const preventBack = () => {
      window.history.pushState(null, null, window.location.href);
    };

    // Listen for popstate event (which is fired on back/forward navigation)
    window.addEventListener("popstate", preventBack);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("popstate", preventBack);
    };
  }, []);

  const downloadPDF = () => {
    console.log("ddddddddddddddddddddddddd");
    const certificateElement = certificateRef.current;
    if (!certificateElement) {
      return;
    }

    html2canvas(certificateElement, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [canvas.width, canvas.height],
        });
        pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
        pdf.save("certificate.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF", error);
      });
  };

  return (
    <>
      {warningCount >= "5" ? (
        <div className="w-[60%] self-center flex items-center justify-center text-2xl text-center transform bg-red-500 text-white p-4 rounded shadow-lg z-50">
          Suspicious Activity Encountered! The interview has ended due to
          multiple warnings. For further questions, please connect with your HR.
        </div>
      ) : on_face_detect >= "3" ? (
        <div className="w-[60%] self-center flex items-center justify-center text-2xl text-center transform bg-red-500 text-white p-4 rounded shadow-lg z-50">
          Due to a failure to comply with the camera policy, the platform has
          ended your interview. Please reach out to HR for further assistance.
        </div>
      ) : networkError === "true" ? (
        <div className="w-[60%] self-center flex items-center justify-center text-2xl text-center transform bg-red-500 text-white p-4 rounded shadow-lg z-50">
          Network Failure Encountered! The interview has ended due to poor
          network. For further questions, please connect with your HR.
        </div>
      ) : coveringCameraTimer === "true" ? (
        <div className="w-[60%] self-center flex items-center justify-center text-2xl text-center transform bg-red-500 text-white p-4 rounded shadow-lg z-50">
          Due to a failure to comply with the camera policy, the platform has
          ended your interview. Please reach out to HR for further assistance.
        </div>
      ) : (
        <div>
          <div className="flex justify-end mr-[5rem] gap-4">
            <CustomButtonWhite text="Download" onClick={downloadPDF} />
            <LinkedinShareButton
              url={urlCertificate}
              className="h-12 w-[12.5rem] border-2 text-[#A6AEBA]"
              style={{
                color: "#A6AEBA",
                border: "2px solid #A6AEBA !important",
                backgroundColor: "white",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F1F2F4";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "";
              }}
            >
              Share on LinkedIn
            </LinkedinShareButton>
          </div>
          <main className=" w-[69rem] flex flex-col items-center justify-around ">
            {/* <img className=" h-[43rem]" src={certificate} alt="certificate" /> */}
            <div ref={certificateRef}>
              <Certificatepage />
            </div>
          </main>
        </div>
      )}
      {/* <div className="flex justify-end mr-[5rem] gap-4">
        <CustomButtonWhite text="Download" onClick={downloadPDF} />
        <LinkedinShareButton
          url={urlCertificate}
          className="h-12 w-[12.5rem] border-2 text-[#A6AEBA]"
          style={{
            color: '#A6AEBA',
            border: '2px solid #A6AEBA !important',
            backgroundColor: 'white',
            transition: 'background-color 0.3s',
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#F1F2F4';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '';
          }}
        >
          Share on LinkedIn
        </LinkedinShareButton>
      </div> */}
      {/* <main className=" w-[69rem] flex flex-col items-center justify-around "> */}
      {/* <img className=" h-[43rem]" src={certificate} alt="certificate" /> */}
      {/* <div ref={certificateRef}>
          <Certificatepage />
        </div>
      </main> */}
    </>
  );
};
