import React, { useState, useEffect } from "react";
import { AnalyticsPageBtn } from "../../components/buttons";
import {
  deleteIcon,
  editIcon,
  userImg,
  searchIcon,
} from "../../assets/imgs/index";
import { CampaignTable } from "../../components/AdminComponents/table";
import { useNavigate } from "react-router-dom";
import { handleApiResponse } from "../../API/services";
import { ModalDeleteCampaign } from "../../components/AdminComponents/modal";
import { CustomButtonWhite } from "../../components/buttons";

function Campaign() {
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState();
  const navigate = useNavigate();
  const [deleteUid, setDeleteUid] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  let creator = sessionStorage.getItem("uid");
  let userType = sessionStorage.getItem("userType");
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState();



  useEffect(()=>{
    sessionStorage.removeItem('uploadResume');
    sessionStorage.removeItem('excelData');
    sessionStorage.removeItem('prevEmail');
    sessionStorage.removeItem('singleAddCandidate');
    sessionStorage.removeItem('checkedFilteredData');
    sessionStorage.removeItem('hrQuestion');
    sessionStorage.removeItem('camp_type');
    sessionStorage.removeItem('coding_ques');
    sessionStorage.removeItem('endDate1');
    sessionStorage.removeItem('createdDate');
    sessionStorage.removeItem('skills');
    sessionStorage.removeItem('campName');
    sessionStorage.removeItem('JobRole');
    sessionStorage.removeItem('selectedCheckboxes');
    sessionStorage.removeItem('savedAllSelcted');
    sessionStorage.removeItem('JobRole');


  },[])

  const itemsPerPage = 5;

  // const tableData = [
  //   {
  //     name: "Shubham Uniyal 1",
  //     email: "uniyal4777@gmail.com",
  //     designation: "HR",
  //     createdOn: "24-02-2023",
  //     status: "Active",
  //   },
  //   {
  //     name: "Shubham Uniyal 2",
  //     email: "uniyal4777@gmail.com",
  //     designation: "HR",
  //     createdOn: "24-02-2023",
  //     status: "Active",
  //   },
  //   {
  //     name: "Shubham Uniyal 2",
  //     email: "uniyal4777@gmail.com",
  //     designation: "HR",
  //     createdOn: "24-02-2023",
  //     status: "Active",
  //   },
  //   {
  //     name: "Shubham Uniyal 2",
  //     email: "uniyal4777@gmail.com",
  //     designation: "HR",
  //     createdOn: "24-02-2023",
  //     status: "Active",
  //   },
  //   {
  //     name: "Shubham Uniyal 2",
  //     email: "uniyal4777@gmail.com",
  //     designation: "HR",
  //     createdOn: "24-02-2023",
  //     status: "Active",
  //   },
  //   {
  //     name: "Shubham Uniyal 2",
  //     email: "uniyal4777@gmail.com",
  //     designation: "HR",
  //     createdOn: "24-02-2023",
  //     status: "Active",
  //   },
  //   {
  //     name: "Shubham Uniyal 2",
  //     email: "uniyal4777@gmail.com",
  //     designation: "HR",
  //     createdOn: "24-02-2023",
  //     status: "Active",
  //   },
  //   {
  //     name: "Shubham Uniyal 2",
  //     email: "uniyal4777@gmail.com",
  //     designation: "HR",
  //     createdOn: "24-02-2023",
  //     status: "Active",
  //   },
  // ];
  const handleSearch = async () => {
    const reqData = {
      q: search,
      is_deleted: 0,
      creator: userType === "3" ? creator : "",
    };
    const response = await handleApiResponse(
      "/api/v1/interview/campaign_list/",
      reqData,
      1
    );
    console.log(response.data.data.data);
    setSearchResults(response.data.data.data);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      handleSearch();
    }
  };

  const findStatus = (start, end) => {
    const monthMap = {
      Jan: "1",
      Feb: "2",
      Mar: "3",
      Apr: "4",
      May: "5",
      Jun: "6",
      Jul: "7",
      Aug: "8",
      Sep: "9",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    function isSecDateBigger(date1, date2, pos) {
      // console.log(date1, date2);
      let [month1, day1, year1] = date1.split(" ");
      let [year2, month2, day2] = date2.split("-").map(Number);
      month1 = monthMap[month1];

      month1 = Number(month1);
      day1 = Number(day1);
      year1 = Number(year1);
      // console.log("today", year1, month1, day1);
      // console.log("start", year2, month2, day2);

      if (day1 === day2 && month1 === month2 && year1 === year2 && pos) {
        // console.log("true");
        return true;
      }

      if (year1 > year2) {
        return true;
      } else if (year1 < year2) {
        return false;
      }

      if (month1 > month2) {
        return true;
      } else if (month1 < month2) {
        return false;
      }

      return day1 > day2;
    }

    const todayDate = new Date();
    let thisDate = String(todayDate).slice(4, 15);
    let result = isSecDateBigger(thisDate, start, 1);
    if (result) {
      const res = isSecDateBigger(thisDate, end);
      if (res) {
        return "Completed";
      } else {
        return "Ongoing";
      }
    } else {
      return "Upcoming";
    }
  };

  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      "_limit": userLimit,
      "_page": pageNo,
      is_deleted: 0,
      // creator: "",
      creator: userType === "3" ? creator : "",
    };
    const response = await handleApiResponse(
      "/api/v1/interview/campaign_list/",
      reqData,
      1
    );
    console.log(response);
    if (response.data.status_code === 200) {
      console.log("camp displayed", Object.values(response.data.data).flat());
      console.log('response.data.data.total', response.data.data.total)
      setTotalData(response.data.data.total)
      setTableData(Object.values(response.data.data.data).flat());
    }
  };

  console.log('tableData',tableData)

  useEffect(() => {
    sessionStorage.setItem("currentAddCampaign", 0);

    getUser(itemsPerPage, currentPage);

    console.log("in camp", deleteUid);
  }, [isDeleteOpen]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [search, setSearch] = useState("");
  const currentPageData = tableData;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isSortingOpen, setIsSortingOpen] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData1, setFilteredData1] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);
  // console.log(filteredData1.length)
  const handleTypeToggle = () => {
    setIsTypeOpen(!isTypeOpen);
  };

  const handleSortToggle = () => {
    setIsSortOpen(!isSortOpen);
  };

  const handleSortingToggle = () => {
    setIsSortingOpen(!isSortingOpen);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openModalEditJobRole = () => {
    setIsModalOpen4(true);
  };

  const closeModalEditJobRole = () => {
    setIsModalOpen4(false);
  };

  const openDelete = () => {
    setIsDeleteOpen(true);
  };
  const closeDelete = () => {
    setIsDeleteOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // const handleTypeOptionClick = (typeOption) => {
  //   console.log(`Selected type option: ${typeOption}`);
  //   setIsTypeOpen(false); // Optionally close the type dropdown after selection
  // };

  // const handleOptionClick = (option) => {
  //   console.log(`Selected option: ${option}`);
  //   setIsOpen(false); // Optionally close the dropdown after selection
  // };

  const handleTypeOptionClick = (typeOption) => {
    setSelectedType(typeOption);
    if (typeOption === "Mass") {
      setFilteredData(tableData.filter((item) => item.campaign_type !== 1));
    } else if (typeOption === "Regular") {
      setFilteredData(tableData.filter((item) => item.campaign_type === 1));
    } else {
      setFilteredData(tableData);
    }
    setIsTypeOpen(false);
  };
  console.log(tableData);

  const handleSortOptionClick = (sortOption) => {
    setSelectedSort(sortOption);
    let filteredArray = [];

    if (sortOption === "Upcoming") {
      filteredArray = tableData.filter((item) => {
        let start_date = new Date(item?.start_date);
        return start_date > new Date();
      });
    } else if (sortOption === "Ongoing") {
      console.log("-------------------------");
      filteredArray = tableData.filter((item) => {
        let start_date = new Date(item?.start_date);
        let end_date = new Date(item?.end_date);
        let currentDate = new Date();
        return start_date <= currentDate && currentDate <= end_date;
      });
    } else if (sortOption === "Completed") {
      filteredArray = tableData.filter((item) => {
        let end_date = new Date(item?.end_date);
        return end_date < new Date();
      });
    }

    setFilteredData1(filteredArray);
    setIsSortOpen(false);
  };

  const handleSortingOptionClick = (sortOption) => {
    setSelectedSorting(sortOption);
    let filteredArray = [];

    if (sortOption === "Name") {
      filteredArray = [...tableData].sort((a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
    } else if (sortOption === "Ongoing") {
      filteredArray = tableData.filter((item) => {
        let start_date = new Date(item?.start_date);
        let end_date = new Date(item?.end_date);
        let currentDate = new Date();
        return start_date <= currentDate && currentDate <= end_date;
      });
    } else if (sortOption === "Completed") {
      filteredArray = tableData.filter((item) => {
        let end_date = new Date(item?.end_date);
        return end_date < new Date();
      });
    }

    setFilteredData2(filteredArray);
    setIsSortOpen(false);
  };

  console.log(filteredData2);
  return (
    <div className="text-[#A6AEBA] ml-[1.5rem] mr-[1.5rem] gap-16 flex flex-col">
      {isDeleteOpen && (
        <ModalDeleteCampaign
          isOpen={openDelete}
          deleteUid={deleteUid}
          onClose={closeDelete}
        />
      )}
      <div className="flex justify-between">
        <div className="font-semibold text-[1.25vw] mb-[1rem]">Campaign</div>
        <AnalyticsPageBtn
          name={'Add Campaign'}
          img={5}
          onClick={() => navigate('/campaign/addCampaign')}
        />
      </div>
      <div>
        <div class="relative overflow-x-auto">
          <div className="flex">
            <div className="flex p-4">
              <input
                placeholder="Search campaign"
                onChange={(e) => {setSearch(e.target.value)
                  handleSearch();
                } }
                className="w-[70vw] h-10 p-5 outline-0 border-[#F1F2F4] border-2 bg-[#FFFFFF] rounded-lg mt-2 placeholder-text-sm"
                value={search}
                onKeyDown={handleKeyDown}
              />
              <img
                src={searchIcon}
                alt="search icon"
                className="cursor-pointer right-8 relative w-[16px] h-[51.2px]"
              />
            </div>
            <div className="mt-4 relative">
              <CustomButtonWhite text={'Filter'} onClick={toggleDropdown} />
              {isDropdownOpen && (
                <div className="">
                  <div
                    className="py-1 mt-2 absolute w-full h-full"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div className="p-4 border-2 bg-white">
                      <button
                        onClick={handleTypeToggle}
                        className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      >
                        {selectedType ? selectedType : 'Type'}
                      </button>
                      {isTypeOpen && (
                        <div className="mt-2">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="type-options-menu"
                          >
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleTypeOptionClick('Mass')}
                            >
                              Mass
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleTypeOptionClick('Regular')}
                            >
                              Regular
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="p-4 border-2 bg-white">
                      <button
                        onClick={handleSortToggle}
                        className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      >
                        {selectedSort ? selectedSort : 'Status'}
                      </button>
                      {isSortOpen && (
                        <div className="mt-2">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="type-options-menu"
                          >
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick('Upcoming')}
                            >
                              Upcoming
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick('Ongoing')}
                            >
                              Ongoing
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick('Completed')}
                            >
                              Completed
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="p-4 border-2 bg-white">
                      <button
                        onClick={handleSortingToggle}
                        className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      >
                        {selectedSorting ? selectedSorting : 'Sort By'}
                      </button>
                      {isSortingOpen && (
                        <div className="mt-2">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="type-options-menu"
                          >
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortingOptionClick('Name')}
                            >
                              Name
                            </div>
                            {/* <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick("Ongoing")}
                            >
                              Ongoing
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                              role="menuitem"
                              onClick={() => handleSortOptionClick("Completed")}
                            >
                              Completed
                            </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <table class="w-full text-sm text-[#A6AEBA] text-left rtl:text-right">
            <thead class="text-[0.833vw] font-semibold text-[#A6AEBA] border-b h-[2px] border-[#E4E7EB]">
              <tr>
                <th scope="col" class="px-6 py-3">
                  #
                </th>
                <th scope="col" class="px-6 py-3">
                  Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Type
                </th>
                <th scope="col" class="px-6 py-3">
                  Campaign Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Job Role
                </th>
                <th scope="col" class="px-6 py-3">
                  Start Date
                </th>
                <th scope="col" class="px-6 py-3">
                  End Date
                </th>
                {/* {userType !== "2" && (  <th scope="col" class="px-6 py-3">
                  Created By
                </th>
                )}
                {userType !== "2" && (  <th scope="col" class="px-6 py-3">
                  Edited By
                </th>
                )} */}
                <th scope="col" class="px-8 py-3">
                  Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData1?.length > 0
                ? filteredData1
                  // ?.slice(startIndex, endIndex)
                  ?.map((rowData, index) => (
                    <CampaignTable
                      key={index}
                      icon={userImg}
                      number={startIndex + index }
                      row12={rowData?.name}
                      row13={
                        rowData?.campaign_type === 1 ? "Regular" : "Mass"
                      }
                      row14={rowData?.jr_name}
                      row15={rowData?.start_date?.slice(0, 10)}
                      row16={rowData?.end_date.slice(0, 10)}
                      row17={rowData?.creater_name}
                      row19={rowData?.updater_name}
                      row18={findStatus(
                        rowData?.start_date?.slice(0, 10),
                        rowData?.end_date.slice(0, 10)
                      )}
                      launched={rowData?.status}
                      skills={rowData?.required_skills}
                      description={rowData?.description}
                      editIcon={editIcon}
                      resumeOn={rowData?.is_candidate_resume_allow}
                      codingOn={rowData?.is_coding_quest_allow}
                      deleteIcon={deleteIcon}
                      openModal={openModalEditJobRole}
                      closeModal={closeModalEditJobRole}
                      isModalOpen4={isModalOpen4}
                      uid={rowData.uid}
                      type={rowData.campaign_type}
                      openDelete={openDelete}
                      setDeleteUid={setDeleteUid}
                      jobRoleUid={rowData.jobrole}
                      creator={rowData.creator}
                    />
                  ))
                : filteredData?.length > 0
                  ? filteredData
                    // ?.slice(startIndex, endIndex)
                    ?.map((rowData, index) => (
                      <CampaignTable
                        key={index}
                        icon={userImg}
                        launched={rowData?.status}
                        number={startIndex + index }
                        row12={rowData?.name}
                        row13={
                          rowData?.campaign_type === 1 ? "Regular" : "Mass"
                        }
                        row14={rowData?.jr_name}
                        row15={rowData?.start_date?.slice(0, 10)}
                        row16={rowData?.end_date.slice(0, 10)}
                        row17={rowData?.creater_name}
                        row19={rowData?.updater_name}
                        row18={findStatus(
                          rowData?.start_date?.slice(0, 10),
                          rowData?.end_date.slice(0, 10)
                        )}
                        skills={rowData?.required_skills}
                        description={rowData?.description}
                        editIcon={editIcon}
                        resumeOn={rowData?.is_candidate_resume_allow}
                        codingOn={rowData?.is_coding_quest_allow}
                        deleteIcon={deleteIcon}
                        openModal={openModalEditJobRole}
                        closeModal={closeModalEditJobRole}
                        isModalOpen4={isModalOpen4}
                        uid={rowData.uid}
                        type={rowData.campaign_type}
                        openDelete={openDelete}
                        setDeleteUid={setDeleteUid}
                        jobRoleUid={rowData.jobrole}
                        creator={rowData.creator}
                      />
                    ))
                  : filteredData2?.length > 0
                    ? filteredData2
                      ?.slice(startIndex, endIndex)
                      ?.map((rowData, index) => (
                        <CampaignTable
                          key={index}
                          icon={userImg}
                          number={startIndex + index }
                          row12={rowData?.name}
                          launched={rowData?.status}
                          row13={
                            rowData?.campaign_type === 1 ? "Regular" : "Mass"
                          }
                          row14={rowData?.jr_name}
                          row15={rowData?.start_date?.slice(0, 10)}
                          row16={rowData?.end_date.slice(0, 10)}
                          row17={rowData?.creater_name}
                          row19={rowData?.updater_name}
                          row18={findStatus(
                            rowData?.start_date?.slice(0, 10),
                            rowData?.end_date.slice(0, 10)
                          )}
                          skills={rowData?.required_skills}
                          description={rowData?.description}
                          editIcon={editIcon}
                          resumeOn={rowData?.is_candidate_resume_allow}
                          codingOn={rowData?.is_coding_quest_allow}
                          deleteIcon={deleteIcon}
                          openModal={openModalEditJobRole}
                          closeModal={closeModalEditJobRole}
                          isModalOpen4={isModalOpen4}
                          uid={rowData.uid}
                          type={rowData.campaign_type}
                          openDelete={openDelete}
                          setDeleteUid={setDeleteUid}
                          jobRoleUid={rowData.jobrole}
                          creator={rowData.creator}
                        />
                      ))
                    : searchResults?.length > 0
                      ? searchResults?.map((rowData, index) => (
                        <CampaignTable
                          key={index}
                          icon={userImg}
                          number={startIndex + index }
                          row12={rowData?.name}
                          row13={rowData?.campaign_type === 1 ? "Regular" : "Mass"}
                          row14={rowData?.jr_name}
                          row15={rowData?.start_date?.slice(0, 10)}
                          row16={rowData?.end_date.slice(0, 10)}
                          row17={rowData?.creater_name}
                          row19={rowData?.updater_name}
                          launched={rowData?.status}
                          row18={findStatus(
                            rowData?.start_date?.slice(0, 10),
                            rowData?.end_date.slice(0, 10)
                          )}
                          skills={rowData?.required_skills}
                          description={rowData?.description}
                          editIcon={editIcon}
                          resumeOn={rowData?.is_candidate_resume_allow}
                          codingOn={rowData?.is_coding_quest_allow}
                          deleteIcon={deleteIcon}
                          openModal={openModalEditJobRole}
                          closeModal={closeModalEditJobRole}
                          isModalOpen4={isModalOpen4}
                          uid={rowData.uid}
                          type={rowData.campaign_type}
                          openDelete={openDelete}
                          setDeleteUid={setDeleteUid}
                          jobRoleUid={rowData.jobrole}
                          creator={rowData.creator}
                        />
                      ))
                      : currentPageData.map((rowData, index) => (
                        <CampaignTable
                          key={index}
                          icon={userImg}
                          number={startIndex + index }
                          row12={rowData?.name}
                          row13={rowData?.campaign_type === 1 ? "Regular" : "Mass"}
                          row14={rowData?.jr_name}
                          row15={rowData?.start_date?.slice(0, 10)}
                          row16={rowData?.end_date?.slice(0, 10)}
                          row17={rowData?.creater_name}
                          row19={rowData?.updater_name}
                          launched={rowData?.status}
                          row18={findStatus(
                            rowData?.start_date?.slice(0, 10),
                            rowData?.end_date.slice(0, 10)
                          )}
                          skills={rowData?.required_skills}
                          description={rowData?.description}
                          editIcon={editIcon}
                          resumeOn={rowData?.is_candidate_resume_allow}
                          codingOn={rowData?.is_coding_quest_allow}
                          deleteIcon={deleteIcon}
                          openModal={openModalEditJobRole}
                          closeModal={closeModalEditJobRole}
                          isModalOpen4={isModalOpen4}
                          uid={rowData.uid}
                          type={rowData.campaign_type}
                          openDelete={openDelete}
                          setDeleteUid={setDeleteUid}
                          jobRoleUid={rowData.jobrole}
                          creator={rowData.creator}
                        />
                      ))}
            </tbody>
          </table>
          <div className="pagination flex justify-end mt-4">
            {totalData > 0 && itemsPerPage > 0 && (
              [...Array(Math.ceil(totalData / itemsPerPage)).keys()].map((page) => (
                <button
                  key={page + 1}
                  onClick={() => {
                    handlePageChange(page + 1);
                    getUser(itemsPerPage, page + 1); // Pass the page number to getUser
                  }}
                  className={`px-3 py-2 mx-1 border rounded-md ${currentPage === page + 1
                      ? "bg-[#A6AEBA1A] text-[#A6AEBA]"
                      : "bg-white text-[#A6AEBA]"
                    }`}
                >
                  {page + 1}
                </button>
              ))
            )}
          </div>

        </div>
      </div>
    </div>
  );
}

export default Campaign;
