import { NavBar } from '../components/AdminComponents/navbar';
import { SideBar } from '../components/AdminComponents/sidebar';
import { Outlet, useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const AdminLayout = () => {
  const [logout, setLogout] = useState(false);
  const navigate = useNavigate();
  return (
    <header className="h-[100vh] w-full bg-white flex">
      {logout && (
        <div className="absolute z-10 w-[100vw] flex justify-center items-center text-[#718096] h-[100vh] backdrop-blur">
          <span className=" p-[2.2rem] bg-white border-2 w-[22rem] h-[10rem]">
            <p className="text-center text-lg">Do you want to Exit?</p>
            <span className="flex gap-[4rem] justify-center mt-6">
              <button
                className="border-2 px-4 py-1"
                onClick={() => navigate('/')}
              >
                Yes
              </button>
              <button
                className="border-2 px-4 py-1"
                onClick={() => setLogout(false)}
              >
                No
              </button>
            </span>
          </span>
        </div>
      )}
      <SideBar setLogout={setLogout} />
      <div className="flex flex-col gap-[1.1875rem] fill-available fill-available-firefox">
        <NavBar />

        <div className="flex flex-col h-full">
          <Outlet />
        </div>
      </div>
    </header>
  );
};
