import setDefaultHeaders from "./setDefaultHeaders";

export const setLocal = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const setSession = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const getLocal = (key) => sessionStorage.getItem(key);

export const getSession = (key) => sessionStorage.getItem(key);

export const deleteLocal = (key) => sessionStorage.removeItem(key);

export const clearLocal = () => {
   sessionStorage.clear();
};

export const isAuthorized = () => {
  const authToken = getLocal("authToken");
  if (authToken) {
    // setDefaultHeaders("authorization", `Bearer ${authToken}`);
    return true;
  }
  return false;
};
