// timerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  interviewTimeTotal: 0,
  interviewTimeRunTotal: false,
  timerAnswer: 90,
  timerRunningAnswer: false,
};

const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setInterviewTimeTotal: (state, action) => {
      state.interviewTimeTotal = action.payload;
    },
    setInterviewTimeRunTotal: (state, action) => {
      state.interviewTimeRunTotal = action.payload;
    },
    setTimerAnswer: (state, action) => {
      state.timerAnswer = action.payload;
    },
    setTimerRunningAnswer: (state, action) => {
      state.timerRunningAnswer = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
    setInterviewTimeTotal,
    setInterviewTimeRunTotal,
    setTimerAnswer,
    setTimerRunningAnswer,
  reset,
} = timerSlice.actions;

export default timerSlice.reducer;
