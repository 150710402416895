export const SideBar = () => {
  return (
    <aside className="w-[5rem] bg-fill-color-code1 flex flex-col justify-center">
      <div className='ml-[1.5rem] flex'>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1_182)">
            <path
              d="M15.7719 15.4145C17.8896 15.4145 19.7233 14.655 21.2216 13.1564C22.7199 11.6582 23.4794 9.82491 23.4794 7.707C23.4794 5.58982 22.7199 3.75633 21.2214 2.25756C19.7229 0.759519 17.8894 0 15.7719 0C13.654 0 11.8208 0.759519 10.3225 2.2578C8.82422 3.75609 8.06445 5.58958 8.06445 7.707C8.06445 9.82491 8.82422 11.6584 10.3227 13.1567C11.8213 14.6547 13.6548 15.4145 15.7719 15.4145Z"
              fill="#E4E7EB"
            />
            <path
              d="M29.258 24.6063C29.2147 23.9828 29.1273 23.3026 28.9987 22.5844C28.8688 21.8608 28.7016 21.1767 28.5014 20.5514C28.2946 19.9052 28.0133 19.267 27.6657 18.6554C27.3048 18.0207 26.881 17.4679 26.4054 17.0131C25.9081 16.5373 25.2992 16.1547 24.5951 15.8757C23.8935 15.5981 23.1159 15.4575 22.2841 15.4575C21.9574 15.4575 21.6415 15.5915 21.0314 15.9887C20.6559 16.2336 20.2167 16.5168 19.7265 16.83C19.3073 17.0971 18.7394 17.3473 18.038 17.5739C17.3537 17.7953 16.6589 17.9076 15.9731 17.9076C15.2873 17.9076 14.5927 17.7953 13.9077 17.5739C13.207 17.3476 12.6391 17.0973 12.2204 16.8303C11.7348 16.52 11.2954 16.2368 10.9143 15.9885C10.3049 15.5912 9.98874 15.4572 9.66208 15.4572C8.83005 15.4572 8.05271 15.5981 7.3513 15.8759C6.64769 16.1545 6.03856 16.537 5.54076 17.0134C5.06542 17.4684 4.64135 18.0209 4.281 18.6554C3.93359 19.267 3.65234 19.905 3.44531 20.5517C3.24536 21.1769 3.07812 21.8608 2.94824 22.5844C2.81958 23.3017 2.73218 23.9821 2.68896 24.6071C2.64648 25.2194 2.625 25.8549 2.625 26.4967C2.625 28.1671 3.156 29.5194 4.20312 30.5167C5.23729 31.5009 6.6057 32.0001 8.26975 32.0001H23.6779C25.342 32.0001 26.7099 31.5011 27.7443 30.5167C28.7916 29.5201 29.3226 28.1676 29.3226 26.4965C29.3224 25.8517 29.3007 25.2157 29.258 24.6063Z"
              fill="#E4E7EB"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_182">
              <rect width="31.9999" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span className='h-[2.125rem] border-2 rounded-xl ml-4'></span>
      </div>
    </aside>
  );
};
