import axios from "axios";
const base_url = process.env.REACT_APP_URL;
//const base_url = 'http://10.1.7.186:8001';

export const handleApiResponse = async (url, data, isAuth) => {
  if (isAuth) {
    try {
      const AuthTkn = sessionStorage.getItem("adminToken");
      if (AuthTkn) {
        console.log(`${base_url}${url}`);
        const response = await axios.post(`${base_url}${url}`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AuthTkn}`,
          },
        });
        return response;
      }
    } catch (error) {
      throw error;
    }
  } else {
    try {
      console.log(`${base_url}${url}`);
      const response = await axios.post(`${base_url}${url}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export const handleFormDataApiResponse = async (url, data, isAuth) => {
  if (isAuth) {
    try {
      const AuthTkn = sessionStorage.getItem("adminToken");
      console.log(`${base_url}${url}`);
      const response = await axios.post(`${base_url}${url}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${AuthTkn}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  } else {
    try {
      console.log(`${base_url}${url}`);
      const response = await axios.post(`${base_url}${url}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

let activeController = null;
export const fetchData = async (setData, data3) => {
  if (activeController) {
    activeController.abort();
  }
  const AuthTkn = sessionStorage.getItem("adminToken");
  const controller = new AbortController();
  const { signal } = controller;
  activeController = controller
  try {
    const response = await fetch(
      `${base_url}/api/v1/interview/question/generate_question/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AuthTkn}`,
        },
        body: JSON.stringify(data3),
        signal,
      }
    );
    if (!response.ok || !response.body) {
      throw response.statusText;
    }

    const reader = response.body.getReader();
    // const decoder = new TextDecoder("utf-8");
    const decoder = new TextDecoder();

    while (true) {
      console.log('chuck type 1');
      const { value, done } = await reader.read();
      if (done) {
        console.log("setLoading(false)");
        break;
      }

      const decodedChunk = decoder.decode(value);
      console.log('chuck type', typeof decodedChunk);
      setData(decodedChunk);
    }
  } catch (error) {
    console.log("setLoading(false)");
    // Handle other errors
  }
};
