import { useState, useEffect } from "react";
import { Backbutton, Finishbutton, Nextbutton } from "../../components/buttons";
import { Check, resumeUpload } from "../../assets/imgs";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { handleApiResponse } from "../../API/services";
import { useDispatch } from "react-redux";
import { getLocal, setLocal } from "../../utils/localStorage";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/loader";
import {
  setFirstName1,
  setLastName1,
  setNumber1,
  setJob1,
  clearForm,
  setExperience,
  setEmail,
} from "../../redux/formDetails/actions";
import { loginSuccess } from "../../redux/login/actions";
import { bulkCandidateDetailsSuccess } from "../../redux/bulkCandidateUserDetails/actions";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { set } from "date-fns";

export const FillForm = () => {
  const getFileFromLocal = (key) => {
    const item = sessionStorage.getItem(key);
    if (item) {
      const fileInfo = JSON.parse(item);
      const arrayBuffer = base64ToArrayBuffer(fileInfo.base64);
      return new File([arrayBuffer], fileInfo.name, { type: fileInfo.type });
    }
    return null;
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const firstName = useSelector(
    (state) => state?.candidateData?.user?.first_name
  );
  const email = useSelector((state) => state?.candidateData?.user?.email);
  const lastName = useSelector(
    (state) => state?.candidateData?.user?.last_name
  );
  const job = useSelector((state) => state?.candidateData?.user?.job_profile);
  const number = useSelector((state) => state?.candidateData?.user?.mobile);
  const exp = useSelector(
    (state) => state?.candidateData?.user?.work_experience
  );
  const [newExp, setNewExp] = useState(getLocal("newExp") || "");
  console.log(newExp);
  const [newExpMonths, setNewExpMonths] = useState(
    getLocal("newExpMonths") || ""
  );
  const firstName1 = useSelector((state) => state?.fillForm?.firstName) || "";
  const email1 = useSelector((state) => state?.fillForm?.email) || "";
  const lastName1 = useSelector((state) => state?.fillForm?.lastName) || "";
  const job1 = useSelector((state) => state?.fillForm?.job) || "";
  const number1 = useSelector((state) => state?.fillForm?.number) || "";
  const exp1 = useSelector((state) => state?.fillForm?.exp) || "";
  console.log("firstname data", firstName1);
  const { intLink } = useParams();
  console.log(intLink);
  const [uploadFile, setUploadFile] = useState(
    () => getFileFromLocal("file") || "false"
  );
  console.log(uploadFile);
  const [uploadJD, setUploadJD] = useState(false);
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [isCodingQuestAllow, setIsCodingQuestAllow] = useState(Boolean);
  const [error, setError] = useState("");
  const [current, setCurrent] = useState(0);
  const uid = useSelector((state) => state?.candidateData?.user?.uid);
  const dispatch = useDispatch();
  const location = useLocation();
  const campType = sessionStorage.getItem("campType");
  const [isLoader, setLoader] = useState(false);
  const interview_link_token = getLocal("link");
  const isResumeAllowed = location?.state?.resume;
  const userEmail = location?.state?.email;
  const step = [
    { name: "Personal Details" },
    { name: "Upload Your Resume" },
    { name: "Guidelines" },
  ];

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = arrayBufferToBase64(reader.result);
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  useEffect(() => {
    sessionStorage.removeItem("interviewTime");
    sessionStorage.removeItem("timer");
    sessionStorage.removeItem("interviewTimeRun");
    sessionStorage.removeItem("timerRunning");
    sessionStorage.removeItem("wc");
    sessionStorage.removeItem("hasStartedRecording");
    sessionStorage.removeItem("isNext");
    sessionStorage.removeItem("isEnd");
    sessionStorage.removeItem("showNext");
    sessionStorage.removeItem("endInterview");
    sessionStorage.removeItem("lastEndInterview");
    sessionStorage.removeItem("chatroomLoading");
    sessionStorage.removeItem("questionCounter");
    sessionStorage.removeItem("setIsEndTimer");
    sessionStorage.removeItem("setEndTimer");
    sessionStorage.removeItem("setEndInterviewCounter");
    sessionStorage.removeItem("no_face_detect_count");
    sessionStorage.removeItem("no_face_detect");
    sessionStorage.removeItem("networkError");
    sessionStorage.removeItem("setCoveringCamera");
    sessionStorage.removeItem("setcoveringCTimer");
    sessionStorage.removeItem("coverCamera");
    sessionStorage.removeItem("setcoverModel");
    sessionStorage.removeItem("codingQuestion");
    sessionStorage.removeItem("code");
    sessionStorage.removeItem("openEditor");
  }, []);

  const handleExpeChange = (newExp) => {
    setNewExp(newExp);
  };

  const handleExpeMonthsChange = (newExpMonths) => {
    setNewExpMonths(newExpMonths);
  };
  console.log(newExpMonths);
  sessionStorage.removeItem("isStarted");
  sessionStorage.removeItem("wc");

  const handleSubmit1 = async (e) => {
    setLoader(true);
    if (isResumeAllowed) {
      if (!uploadFile) {
        setLoader(false);
        toast.warning("Please upload all required files !");
        setCurrent(0);
        return;
      }
    }
    console.log(firstName);
    console.log(lastName);
    console.log(number);
    console.log(job);
    console.log(exp);
    if (
      firstName === "" ||
      lastName === "" ||
      number === "" ||
      job === "" ||
      newExp === "NaN" ||
      newExpMonths === "NaN"
    ) {
      setLoader(false);
      setCurrent(0);
      setError("Please fill in all the details.");
    } else {
      console.log("------------");
      const formData = new FormData();
      formData.append("first_name", firstName1);
      formData.append("last_name", lastName1);
      formData.append("mobile", number1);
      formData.append("job_role", job1);
      formData.append("work_experience", `${newExp}.${newExpMonths}`);
      formData.append("email", email1);
      formData.append("interview_link_token", intLink);
      formData.append("organization", sessionStorage.getItem("Oid"));
      formData.append("campaign", sessionStorage.getItem("Cid"));
      formData.append("resume", uploadFile);
      if (sessionStorage.getItem("CandidateUid")) {
        formData.append("uid", sessionStorage.getItem("CandidateUid"));
      }
      // if (isResumeAllowed) {
      //   console.log(uploadFile);
      //   if (!uploadFile?.url) {
      //     formData.append('resume', uploadFile);
      //   }
      // }

      console.log(formData);
      const response = await handleApiResponse(
        "/api/v1/interview/add_bulk_candidate/",
        formData,
        0
      );
      console.log(response);
      if (response.status_code === 200) {
        setLoader(false);
        dispatch(bulkCandidateDetailsSuccess(response.data));
        toast.success("Added successfully.");
        setCurrent(2);
        setLocal("CandidateUid", response?.data.uid);
        setLocal("JobRole", response?.data.uid);
        setLocal("candFName", response?.data?.first_name);
        setLocal("candLName", response?.data?.last_name);
        setLocal("");
      } else if (response?.status_code === 400) {
        console.log("first");
        setCurrent(0);
        setLoader(false);
        toast.error(
          Array.isArray(response?.msg?.non_field_errors) &&
            response.msg.non_field_errors.length > 0
            ? response.msg.non_field_errors[0]
            : response.msg.email
            ? response.msg.email[0]
            : response?.msg?.startsWith(
                "duplicate key value violates unique constraint"
              )
            ? "Candidate with this email already exists."
            : response?.msg
        );
        // toast.error(
        //   response?.msg?.startsWith("duplicate key value violates unique constraint")
        //     ? "Candidate with this email already exists."
        //     : Array.isArray(response?.msg?.non_field_errors) && response.msg.non_field_errors.length > 0
        //     ? response.msg.non_field_errors[0]
        //     : response.msg.email
        //     ? response.msg.email[0]
        //     : response?.msg
        // );
        return;
      }
      if (uid) {
        formData.append("uid", uid);
        // setLocal('uid', uid);
      }
    }
  };
  console.log("typeof(uploadFile)", typeof uploadFile);
  useEffect(() => {
    console.log("resume", isResumeAllowed);
  }, []);

  const saveFileToLocal = async (key, file) => {
    const base64 = await fileToBase64(file);
    const fileInfo = {
      name: file.name,
      type: file.type,
      base64,
    };
    sessionStorage.setItem(key, JSON.stringify(fileInfo));
  };

  console.log(uploadFile);
  const handleSubmit = async (e) => {
    setLoader(true);
    console.log(uploadFile);
    if (!uploadFile) {
      toast.warning("Please upload resume !");
      setCurrent(0);
      setLoader(false);
      return 0;
    } else {
      console.log(typeof uploadFile);
      const formData = new FormData();
      formData.append("uid", getLocal("CandidateUid"));
      formData.append("resume", uploadFile);
      formData.append("work_experience", `${newExp}.${newExpMonths}`);
      formData.append("interview_link_token", interview_link_token);

      const response = await handleApiResponse(
        "/api/v1/interview/add_bulk_candidate/",
        formData
      );
      if (response?.status_code === 400) {
        toast.error(response?.msg);
        setCurrent(0);
        setLoader(false);
        return 0;
      } else {
        setIsCodingQuestAllow(
          response?.data?.campign_info?.is_coding_quest_allow
        );
        setLocal("newExp", newExp);
        setLocal("newExpMonths", newExpMonths);
        await saveFileToLocal("file", uploadFile);
        console.log(response?.data);
        setCurrent(2);
        setLoader(false);
        return 1;
      }
    }
  };

  console.log(current);
  return (
    <main className="flex bg-fill-color-code1 gap-12 fill-available h-[52rem] mx-[4.375rem] mt-[11.125rem] my-[1.125rem] justify-center text-text-color-code1">
      <section className="w-[14rem] h-[38rem] bg-white m-6 rounded-lg flex flex-col items-center justify-center">
        {isLoader ? <Loader /> : ""}
        <div
          className={`w-[5rem] h-[5rem] ${
            current === 0 ? "bg-text-color-code1" : "bg-fill-color-code1"
          } rounded-full relative`}
        >
          <svg
            className="absolute top-[1.3rem] left-[1.3rem]"
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.9062 10.6875C26.2675 10.6875 24.9375 9.3575 24.9375 7.71875V1.1875H7.71875C6.08 1.1875 4.75 2.5175 4.75 4.15625V33.8438C4.75 35.4825 6.08 36.8125 7.71875 36.8125H30.2812C31.92 36.8125 33.25 35.4825 33.25 33.8438V10.6875H27.9062ZM13.0625 20.1875C13.0625 18.0619 14.1906 16.2213 15.865 15.1644C15.2356 14.44 14.8438 13.5019 14.8438 12.4688C14.8438 10.1769 16.7081 8.3125 19 8.3125C21.2919 8.3125 23.1562 10.1769 23.1562 12.4688C23.1562 13.5019 22.7644 14.44 22.135 15.1644C23.8094 16.2094 24.9375 18.0619 24.9375 20.1875V20.7812C24.9375 22.42 23.6075 23.75 21.9688 23.75H16.0312C14.3925 23.75 13.0625 22.42 13.0625 20.7812V20.1875ZM10.6875 26.125H11.875C12.5281 26.125 13.0625 26.6594 13.0625 27.3125C13.0625 27.9656 12.5281 28.5 11.875 28.5H10.6875C10.0344 28.5 9.5 27.9656 9.5 27.3125C9.5 26.6594 10.0344 26.125 10.6875 26.125ZM27.3125 33.25H10.6875C10.0344 33.25 9.5 32.7156 9.5 32.0625C9.5 31.4094 10.0344 30.875 10.6875 30.875H27.3125C27.9656 30.875 28.5 31.4094 28.5 32.0625C28.5 32.7156 27.9656 33.25 27.3125 33.25ZM27.3125 28.5H15.4375C14.7844 28.5 14.25 27.9656 14.25 27.3125C14.25 26.6594 14.7844 26.125 15.4375 26.125H27.3125C27.9656 26.125 28.5 26.6594 28.5 27.3125C28.5 27.9656 27.9656 28.5 27.3125 28.5Z"
              stroke="#A6AEBA"
              fill={`${current === 0 ? "white" : "#A6AEBA"}`}
            />
            <path
              d="M16.0312 21.375H21.9688C22.3012 21.375 22.5625 21.1137 22.5625 20.7812V20.1875C22.5625 18.2281 20.9594 16.625 19 16.625C17.0406 16.625 15.4375 18.2281 15.4375 20.1875V20.7812C15.4375 21.1137 15.6987 21.375 16.0312 21.375Z"
              fill={`${current === 0 ? "white" : "#A6AEBA"}`}
              stroke="#A6AEBA"
            />
            <path
              d="M27.9062 8.31251H32.6206L27.3125 1.94751V7.71876C27.3125 8.05126 27.5737 8.31251 27.9062 8.31251Z"
              fill={`${current === 0 ? "white" : "#A6AEBA"}`}
              stroke="#A6AEBA"
            />
            <path
              d="M19 14.25C19.9838 14.25 20.7812 13.4525 20.7812 12.4688C20.7812 11.485 19.9838 10.6875 19 10.6875C18.0162 10.6875 17.2188 11.485 17.2188 12.4688C17.2188 13.4525 18.0162 14.25 19 14.25Z"
              fill={`${current === 0 ? "white" : "#A6AEBA"}`}
              stroke="#A6AEBA"
            />
          </svg>
        </div>
        <p className="w-[10rem] text-center">Personal Details</p>
        {/* <div> */} {/*  --------line--------- */}
        {/* <svg
            width="2"
            height="90"
            viewBox="0 0 2 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L0.999997 81"
              stroke="#A6AEBA"
              stroke-linecap="round"
              stroke-dasharray="3 3"
            />
          </svg> */}
        {/* </div> */}
        {/* {isResumeAllowed} */}
        {isResumeAllowed && (
          <span className="flex flex-col items-center">
            {/* <div
              className={`w-[5rem] h-[5rem] ${
                current === 1 ? "bg-text-color-code1" : "bg-fill-color-code1"
              } rounded-full relative`}
            >
              <svg
                className="absolute top-[1.3rem] left-[1.3rem]"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.33337 30C3.33337 32.7615 5.57196 35 8.33337 35H31.6667C34.4282 35 36.6667 32.7615 36.6667 30V15C36.6667 12.2386 34.4282 10 31.6667 10H29.2254L27.3764 7.2265C26.449 5.8355 24.8879 5 23.216 5H8.33337C5.57196 5 3.33337 7.23858 3.33337 10V30ZM24.6029 9.0755L25.2192 10H18.2279C17.7334 10 17.2642 9.7803 16.9475 9.4003L16.0584 8.33333H23.216C23.7734 8.33333 24.2937 8.61183 24.6029 9.0755ZM18.3305 26.6667C18.3305 27.5872 19.0767 28.3333 19.9972 28.3333C20.9175 28.3333 21.6639 27.5872 21.6639 26.6667V22.3545L22.1554 22.8457C22.8065 23.4963 23.8619 23.4958 24.5124 22.8447C25.163 22.1935 25.1625 21.1382 24.5114 20.4877L21.1752 17.1543C20.5247 16.5044 19.4707 16.5041 18.8199 17.1537L15.4894 20.4773C14.8379 21.1277 14.8368 22.1828 15.487 22.8343C16.1372 23.486 17.1925 23.487 17.844 22.8368L18.3305 22.3513V26.6667Z"
                  fill={`${current === 1 ? "white" : "#A6AEBA"}`}
                />
              </svg>
            </div> */}
            {/* <p>Upload Resume</p> */}
            <div>
              {" "}
              {/*  --------line--------- */}
              <svg
                width="2"
                height="90"
                viewBox="0 0 2 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L0.999997 81"
                  stroke="#A6AEBA"
                  stroke-linecap="round"
                  stroke-dasharray="3 3"
                />
              </svg>
            </div>
          </span>
        )}
        <div
          className={`w-[5rem] h-[5rem] ${
            current === 2 ? "bg-text-color-code1" : "bg-fill-color-code1"
          } rounded-full relative`}
        >
          <svg
            className="absolute top-[1.3rem] left-[1.3rem]"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.6666 30H28.3333C29.6582 29.996 30.9276 29.468 31.8645 28.5311C32.8013 27.5943 33.3293 26.3248 33.3333 25V8.33331C33.3293 7.00845 32.8013 5.73898 31.8645 4.80215C30.9276 3.86532 29.6582 3.33727 28.3333 3.33331H11.6666C10.3418 3.33727 9.07229 3.86532 8.13546 4.80215C7.19864 5.73898 6.67058 7.00845 6.66663 8.33331V31.6666C6.66663 32.9927 7.19341 34.2645 8.13109 35.2022C9.06877 36.1399 10.3405 36.6666 11.6666 36.6666H31.6666C32.1087 36.6666 32.5326 36.4911 32.8451 36.1785C33.1577 35.8659 33.3333 35.442 33.3333 35C33.3333 34.558 33.1577 34.134 32.8451 33.8215C32.5326 33.5089 32.1087 33.3333 31.6666 33.3333H11.6666C11.2246 33.3333 10.8007 33.1577 10.4881 32.8452C10.1756 32.5326 9.99996 32.1087 9.99996 31.6666C9.99996 31.2246 10.1756 30.8007 10.4881 30.4881C10.8007 30.1756 11.2246 30 11.6666 30ZM20 8.33331C20.3296 8.33331 20.6518 8.43106 20.9259 8.6142C21.2 8.79733 21.4136 9.05763 21.5398 9.36217C21.6659 9.66672 21.6989 10.0018 21.6346 10.3251C21.5703 10.6484 21.4116 10.9454 21.1785 11.1785C20.9454 11.4116 20.6484 11.5703 20.3251 11.6346C20.0018 11.6989 19.6667 11.6659 19.3622 11.5398C19.0576 11.4136 18.7973 11.2 18.6142 10.9259C18.431 10.6518 18.3333 10.3296 18.3333 9.99998C18.3333 9.55795 18.5089 9.13403 18.8214 8.82147C19.134 8.50891 19.5579 8.33331 20 8.33331ZM18.3333 15C18.3333 14.558 18.5089 14.134 18.8214 13.8215C19.134 13.5089 19.5579 13.3333 20 13.3333C20.442 13.3333 20.8659 13.5089 21.1785 13.8215C21.491 14.134 21.6666 14.558 21.6666 15V23.3333C21.6666 23.7753 21.491 24.1993 21.1785 24.5118C20.8659 24.8244 20.442 25 20 25C19.5579 25 19.134 24.8244 18.8214 24.5118C18.5089 24.1993 18.3333 23.7753 18.3333 23.3333V15Z"
              fill={`${current === 2 ? "white" : "#A6AEBA"}`}
            />
          </svg>
        </div>
        <p>Guidelines</p>
      </section>
      <section
        className="flex flex-col my-6 text-sm justify-between mr-24"
        style={{ width: "-webkit-fill-available" }}
      >
        <p className="text-base">
          Step: {isResumeAllowed ? current + 1 : current === 2 ? 2 : 1}
        </p>
        <div className="flex flex-col gap-[1.125rem] tracking-[2%]">
          <p className="text-2xl font-semibold">{step[current].name}</p>
          <p>
            {/* The purpose of lorem ipsum is to create a natural looking block of
            text (sentence, paragraph, page, etc.) that doesn't distract from
            the layout. */}
          </p>
        </div>
        <div className="fill-available tracking-[2%] max-w-[52rem]">
          {current === 0 ? (
            <UserForm
              campType={campType}
              handleExpeChange={handleExpeChange}
              handleExpeMonthsChange={handleExpeMonthsChange}
              newExp={newExp}
              newExpMonths={newExpMonths}
              setUploadResume={setUploadFile}
              uploadResume={uploadFile}
              uploadFile={uploadFile}
            />
          ) : current === 1 ? (
            <UploadResume
              setUploadResume={setUploadFile}
              uploadResume={uploadFile}
              // uploadJD={uploadJD}
              // setUploadJD={setUploadJD}
            />
          ) : (
            <Guidelines setIsAllCheck={setIsAllCheck} isAllCheck={isAllCheck} />
          )}
        </div>

        <div className="flex justify-end gap-2 mt-12">
          <div
            className={`${current === 0 ? "hidden" : "block"}`}
            onClick={() => {
              if (current == 2) {
                setCurrent(current - 2);
              } else {
                if (isResumeAllowed) {
                  setCurrent(current - 1);
                } else {
                  setCurrent(current - 2);
                }
              }
            }}
          >
            <Backbutton />
          </div>
          <div
            className={`${current === 2 ? "hidden" : "block"}`}
            onClick={(e) => {
              e.preventDefault();
              console.log(campType);
              if (campType === "Regular") {
                console.log(current);
                console.log(firstName);
                console.log(lastName);
                console.log(number);
                console.log(exp);
                console.log(newExp);
                if (current === 0) {
                  console.log(firstName);
                  console.log(lastName);
                  console.log(number);
                  console.log(exp);
                  if (
                    // firstName === "" ||
                    // lastName === "" ||
                    // number === "" ||
                    newExp === "" ||
                    newExpMonths === "" ||
                    // firstName === undefined ||
                    // lastName === undefined ||
                    // number === undefined ||
                    newExpMonths === undefined ||
                    newExp === undefined ||
                    !uploadFile
                  ) {
                    toast.warning("Please fill in all required fields.");
                  } else {
                    // if (!isResumeAllowed) {
                    //   setLoader(true);
                    //   handleSubmit1();
                    //   setCurrent(2);
                    // } else {
                    //   // handleSubmit2();
                    //   //////////////////////////
                    handleSubmit();
                    // setCurrent(2);
                    // }
                  }
                }
                // else if (
                //   current === 1
                // ) {
                //   handleSubmit()
                //   // setCurrent(1);
                // }
                //  else if (
                //   current === 1 &&
                //   (uploadFile === "" || !uploadFile)
                // ) {
                //   setCurrent(1);
                // }
                // else if()
                else {
                  console.log("yes");
                  if (isResumeAllowed) {
                    console.log("yes1");
                    setCurrent(current + 1);
                  } else {
                    setCurrent(current + 2);
                    console.log("yes2");
                  }
                }
                // if (current === 1 && uploadFile ) {
                //   console.log('yes')
                //   handleSubmit();
                //   if (uploadFile) {
                //     setCurrent(current + 1);
                //   }
                // }
              } else if (campType === "Mass") {
                e.preventDefault();
                console.log("------");
                if (current === 0) {
                  console.log(firstName1);
                  console.log(lastName1);
                  console.log(number1);
                  // console.log(exp1);
                  console.log(job1);
                  console.log(newExp);
                  console.log(newExpMonths);
                  if (
                    firstName1 === "" ||
                    lastName1 === "" ||
                    newExp === "" ||
                    newExpMonths === "" ||
                    firstName1 === undefined ||
                    lastName1 === undefined ||
                    newExp === undefined ||
                    newExpMonths === undefined ||
                    !uploadFile
                  ) {
                    toast.warning("Please fill in all required fields.");
                  } else {
                    if (!isResumeAllowed) {
                      setLoader(true);
                      handleSubmit1();
                      setCurrent(2);
                    } else {
                      // setCurrent(1);
                      handleSubmit1();
                    }
                  }
                }
                // else if (
                //   current === 1
                // ) {
                //   handleSubmit1();
                //   // setCurrent(1);
                // }
                //  else if (
                //   current === 1 &&
                //   (uploadFile === "" || !uploadFile)
                // ) {
                //   setCurrent(1);
                // }
                else {
                  if (current === 0) {
                    setCurrent(2);
                  } else {
                    if (isResumeAllowed) {
                      setCurrent(current + 1);
                    } else {
                      setCurrent(current + 2);
                    }
                  }
                }
                // if (current === 1) {
                //   handleSubmit1();
                //   setCurrent(current + 1);
                // }
              }
            }}
          >
            <Nextbutton />
          </div>
          <div
            className={` ${current === 2 ? "block" : "hidden"} ${
              isAllCheck ? null : "pointer-events-none"
            }`}
          >
            <NavLink
              to={"../start-interview"}
              state={{ isCoding_quest_allow: isCodingQuestAllow }}
            >
              <Finishbutton isAllCheck={isAllCheck} />
            </NavLink>
          </div>
        </div>
      </section>
    </main>
  );
};

export const InputBox4 = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
}) => {
  return (
    <span className="flex flex-col ">
      <div className="flex">
        <label htmlFor="">{name}</label>
        {name !== "Mobile Number" && <span className="text-red-500">*</span>}
      </div>
      <input
        type={type}
        id={name.toLowerCase().replace(/\s+/g, "")}
        name={name.toLowerCase().replace(/\s+/g, "")}
        placeholder={holder}
        className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] bg-gray-100 cursor-not-allowed"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
    </span>
  );
};

export const InputBox6 = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
}) => {
  return (
    <span className="flex flex-col ">
      <div className="flex">
        <label htmlFor="">{name}</label>
        {name !== "Mobile Number" && <span className="text-red-500">*</span>}
      </div>
      <input
        type={type}
        id={name.toLowerCase().replace(/\s+/g, "")}
        name="email"
        placeholder={holder}
        className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px]"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
    </span>
  );
};

export const InputBox5 = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  value1,
  onChange,
  holder1,
  onChange1,
}) => {
  return (
    <span className="flex flex-col ">
      <div className="flex">
        <label htmlFor="">{name}</label>
        <span className="text-red-500">*</span>
      </div>
      <div className="flex gap-2">
        <input
          type={type}
          // id={name.toLowerCase().replace(/\s+/g, '')}
          // name="email"
          id="experienceYear"
          name="experienceYear"
          placeholder={holder}
          className="w-[50%] p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px]"
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
        />
        <input
          type={type}
          // id={name.toLowerCase().replace(/\s+/g, '')}
          // name="email"
          id="experienceMonth"
          name="experienceMonth"
          placeholder={holder1}
          className="w-[50%] p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px]"
          onChange={onChange1}
          value={value1}
          onKeyDown={onKeyDown}
        />
      </div>
    </span>
  );
};

export const InputBox = ({
  onKeyDown,
  head,
  name,
  type,
  holder,
  value,
  onChange,
  handleBlur,
  showPassword,
  showPassword1,
}) => {
  return (
    <span className="flex flex-col">
      <div className="flex">
        <label htmlFor={name.toLowerCase().replace(/\s+/g, "")}>{head}</label>
        {name !== "mobile" && <span className="text-red-500">*</span>}
      </div>
      <input
        type={
          name === "password"
            ? showPassword
              ? "text"
              : "password"
            : name === "confirmPassword"
            ? showPassword1
              ? "text"
              : "password"
            : type
        }
        id={name.toLowerCase().replace(/\s+/g, "")}
        name={name}
        placeholder={holder}
        className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px]"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
    </span>
  );
};

export const InputBoxColor = ({
  onKeyDown,
  head,
  name,
  type,
  holder,
  value,
  onChange,
  handleBlur,
  showPassword,
  showPassword1,
}) => {
  return (
    <span className="flex flex-col ">
      <div className="flex">
        <label htmlFor="">{head}</label>
        <span className="text-red-500">*</span>
      </div>
      <input
        type={
          name === "password"
            ? showPassword
              ? "text"
              : "password"
            : name === "confirmPassword"
            ? showPassword1
              ? "text"
              : "password"
            : type
        }
        id={name.toLowerCase().replace(/\s+/g, "")}
        name={name}
        placeholder={holder}
        className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] bg-[#FBFBFB]"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
    </span>
  );
};

export const InputBox3 = ({
  onKeyDown,
  name,
  holder,
  value,
  onChange,
  error,
  showErrorOnInput,
}) => {
  return (
    <span className="flex flex-col">
      <span className="flex">
        <label htmlFor={name.toLowerCase().replace(/\s+/g, "")}>{name}</label>
        <span className="text-red-500">*</span>
      </span>
      <textarea
        id={name.toLowerCase().replace(/\s+/g, "")}
        placeholder={holder}
        className={`h-[10vh] p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm`}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
      {showErrorOnInput === "1" ? (
        <div className="text-red-500">{error}</div>
      ) : null}
    </span>
  );
};

export const InputBox2 = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
}) => {
  return (
    <span className="flex flex-col ">
      <div className="flex">
        <label htmlFor="">{name}</label>
        <span className="text-red-500">*</span>
      </div>
      <input
        type={type}
        id={name.toLowerCase().replace(/\s+/g, "")}
        placeholder={holder}
        className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] bg-gray-100 cursor-not-allowed"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
        readOnly
      />
    </span>
  );
};

export const InputBox1 = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
  error,
  showErrorOnInput,
}) => {
  return (
    <span className="flex flex-col ">
      <span className="flex">
        <label htmlFor="">{name}</label>
        <span className="text-red-500">*</span>
      </span>
      <input
        type={type}
        id={name.toLowerCase().replace(/\s+/g, "")}
        placeholder={holder}
        className={`p-3 outline-0 bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm`}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
      {showErrorOnInput === "1" ? (
        <div className="text-red-500">{error}</div>
      ) : null}
    </span>
  );
};

export const InputBoxRed = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
  error,
  showErrorOnInput,
  openEditConfirmation,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isEmpty, setIsEmpty] = useState(!value);

  useEffect(() => {
    // Show the tooltip when the component mounts
    setShowTooltip(true);

    // Hide the tooltip after 2 minutes
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 5000); // 2 minutes in milliseconds

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Update the isEmpty state whenever the value changes
    setIsEmpty(!value);
  }, [value]);

  const handleIconClick = () => {
    setShowTooltip((prev) => !prev);
  };

  return (
    <span className="flex flex-col ">
      <span className="flex">
        <label htmlFor="">{name}</label>
        <span className="text-red-500">*</span>
        {openEditConfirmation && (
          <>
            <FaInfoCircle
              data-tooltip-id="skillsInfo"
              className="ml-2 cursor-pointer text-gray-500"
              onClick={handleIconClick}
            />
            <Tooltip
              id="skillsInfo"
              arrow={false}
              place="right"
              effect="solid"
              isOpen={showTooltip}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              You need to change the Job Role Name mandatorily.
            </Tooltip>
          </>
        )}
      </span>
      <input
        type={type}
        id={name.toLowerCase().replace(/\s+/g, "")}
        placeholder={holder}
        className={`p-3 outline-0 ${
          isEmpty && openEditConfirmation ? "border-red-500" : "border-gray-300"
        } border bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm`}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
      {showErrorOnInput === "1" ? (
        <div className="text-red-500">{error}</div>
      ) : null}
    </span>
  );
};

const UserForm = ({
  uploadFile,
  userEmail,
  emptySubmit,
  campType,
  handleExpeChange,
  handleExpeMonthsChange,
  newExp,
  newExpMonths,
  setUploadResume,
  uploadResume,
}) => {
  const firstName = getLocal("candFName");
  const email = getLocal("candEmail");
  const lastName = getLocal("candLName");
  const job = getLocal("jobRole");
  const number = getLocal("candMobile");
  // const exp = useSelector((state) => state?.candidateData?.user?.work_experience);
  const com = getLocal("organization");
  const initialExp = useSelector(
    (state) => state?.candidateData?.user?.work_experience
  );
  const [exp, setExp] = useState(initialExp);
  const [monthsExp, setMonthsExp] = useState(initialExp);
  console.log(campType);
  console.log(com);

  const user = userEmail;
  const uid = useSelector((state) => state?.login?.user?.uid);
  const firstName1 = useSelector((state) => state?.fillForm?.firstName);
  const lastName1 = useSelector((state) => state?.fillForm?.lastName);
  const job1 = getLocal("roleName");
  const number1 = useSelector((state) => state?.fillForm?.number);
  const exp1 = useSelector((state) => state?.fillForm?.exp);
  const com1 = getLocal("organization");
  const email1 = useSelector((state) => state?.fillForm?.email);
  const [error1, setError1] = useState("");
  console.log(job1);
  const dispatch = useDispatch();
  const [forceRender, setForceRender] = useState(false);
  const [previousJD, setPreviousJD] = useState(null);

  const handleDropResume = (e) => {
    e.preventDefault();
    const permissible = [".pdf"];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setUploadResume(files[0]);
    }
  };

  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    const allowedExtensions = [
      "pdf",
      "docx",
      "csv",
      "xlsx",
      "odt",
      "txt",
      "ods",
    ];

    if (allowedExtensions.includes(extension)) {
      setUploadResume(file);
    } else {
      // Optionally, add a warning or error message for unsupported file types
      toast.warning(
        "Unsupported file type. Please upload a PDF, DOCX, or CSV file."
      );
      setUploadResume("");
    }
  };

  const handleRemoveResume = () => {
    sessionStorage.removeItem("file");
    const currentFileName = uploadResume ? uploadResume.name : null;
    setUploadResume(null);
    const fileInput = document.getElementById("resume-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    const handleUploadResumeAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setUploadResume(file);
      } else {
        setUploadResume(file);
      }
    };
    fileInput.addEventListener("change", handleUploadResumeAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  const handleFirstNameChange = (e) => {
    const name = e.target.value;

    dispatch(setFirstName1(name));
  };

  const handleLastNameChange = (e) => {
    const lastName = e.target.value;
    dispatch(setLastName1(lastName));
  };

  const handleEmailChange = (e) => {
    dispatch(setEmail(e.target.value));
  };

  const handleNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, "");
    if (inputNumber.length <= 10) {
      dispatch(setNumber1(inputNumber));
      setError1("Enter valid mobile number.");
    }
  };

  const handleExpChange = (e) => {
    let inputNumber = e.target.value.replace(/\D/g, "");
    inputNumber = Math.min(60, parseInt(inputNumber, 10)).toString();

    dispatch(setExperience(inputNumber));
  };

  const handleExpChange1 = (e) => {
    // Validate that the new value is at most 2 digits
    if (/^\d{0,2}$/.test(e.target.value)) {
      setExp(e.target.value);
      handleExpeChange(e.target.value);
    }
  };

  const handleExpMonthsChange1 = (e) => {
    const value = e.target.value;
    // Validate that the new value is at most 2 digits and between 0 and 12
    if (
      /^\d{0,2}$/.test(value) &&
      (value === "" || (Number(value) >= 0 && Number(value) <= 12))
    ) {
      setMonthsExp(value);
      handleExpeMonthsChange(value);
    }
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (!key.match(/[a-zA-Z]/)) {
      e.preventDefault();
    }
  };
  console.log("uploadResume?.name", uploadResume?.name);
  console.log("uploadFile?.name", uploadFile?.name);
  console.log("uploadResume", uploadResume);
  if (campType === "Regular") {
    return (
      <form action="" className=" flex flex-col gap-6 fill-available">
        <div className=" grid grid-cols-2 gap-6">
          <InputBox4
            name={"First Name"}
            holder={"Enter first name"}
            value={firstName}
            readOnly
          />
          <InputBox4
            name={"Last Name"}
            holder={"Enter last name"}
            value={lastName}
            readOnly
          />
          <InputBox4
            name={"Email"}
            type="email"
            holder={"Enter email"}
            value={email}
            readOnly
          />
          <InputBox4
            name={"Mobile Number"}
            type="number"
            holder={"Enter mobile number"}
            value={number}
            readOnly
          />
          <InputBox4
            name={"Company"}
            holder={"Enter company name"}
            value={com}
            readOnly
          />
          <span className="flex flex-col ">
            <label htmlFor="">Job Profile</label>
            <input
              type="text"
              placeholder="Enter job profile"
              className="p-3 rounded-lg outline-0 mt-2 placeholder:text-sm placeholder:tracking-[0.6px] bg-gray-100 cursor-not-allowed"
              value={job}
              readOnly
            />
          </span>
        </div>
        <InputBox5
          name={"Total years of Experience"}
          type="number"
          holder={"Enter total years of experience"}
          value={newExp}
          value1={newExpMonths}
          onChange={handleExpChange1}
          holder1={"Enter total months of experience"}
          onChange1={handleExpMonthsChange1}
          // readOnly
        />
        <div className="flex flex-col fill-available">
          <label htmlFor="" className="mb-2">
            Resume
          </label>
          <div
            className="bg-white border-2 flex flex-col h-[12.56rem] items-center gap-3"
            onDrop={handleDropResume}
            onDragOver={(e) => e.preventDefault()}
          >
            <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
            <p>Drag and drop your Resume here (PDF, DOCX, CSV)</p>
            <label
              htmlFor="resume-upload"
              className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
            >
              Browse
              <input
                type="file"
                id="resume-upload"
                className="hidden"
                onChange={handleUploadResume}
              />
            </label>
          </div>

          {(uploadResume?.name || uploadFile?.name) && (
            <div
              className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${
                uploadResume ? "block" : "hidden"
              }`}
            >
              <p>{uploadResume?.name || uploadFile?.name || ""}</p>
              <p onClick={handleRemoveResume}>Close</p>
            </div>
          )}
        </div>
      </form>
    );
  }
  console.log(firstName1);
  console.log(lastName1);
  console.log(email1);
  console.log(number1);
  console.log(exp1);
  console.log("camp type ==", campType);

  if (campType === "Mass") {
    return (
      <form action="" className=" flex flex-col gap-6 fill-available">
        <div className=" grid grid-cols-2 gap-6">
          <InputBox6
            name={"First Name"}
            holder={"Enter first name"}
            value={firstName1}
            onChange={handleFirstNameChange}
            onKeyDown={handleKeyDown}
            emptySubmit={emptySubmit}
            required
          />
          <InputBox6
            name={"Last Name"}
            holder={"Enter last name"}
            value={lastName1}
            onChange={handleLastNameChange}
            onKeyDown={handleKeyDown}
            emptySubmit={emptySubmit}
            required
          />
          <InputBox6
            name={"Email"}
            type="email"
            holder={"Enter email"}
            emptySubmit={emptySubmit}
            onChange={handleEmailChange}
            value={email1}
          />
          <InputBox6
            name={"Mobile Number"}
            type="text"
            holder={"Enter mobile number"}
            value={number1}
            emptySubmit={emptySubmit}
            onChange={handleNumberChange}
            required
          />
          <InputBox4
            name={"Job Profile"}
            holder={"Enter job profile"}
            value={job1}
            readOnly
          />
          <InputBox2
            name={"Company"}
            holder={"Enter company name"}
            value={com1}
          />
        </div>
        <InputBox5
          name={"Total years of Experience"}
          type="number"
          holder={"Enter total years of experience"}
          value={newExp}
          value1={newExpMonths}
          onChange={handleExpChange1}
          holder1={"Enter total months of experience"}
          onChange1={handleExpMonthsChange1}
          // readOnly
        />
        <div className="flex flex-col fill-available">
          <label htmlFor="" className="mb-2">
            Resume
          </label>
          <div
            className="bg-white border-2 flex flex-col h-[12.56rem] items-center gap-3"
            onDrop={handleDropResume}
            onDragOver={(e) => e.preventDefault()}
          >
            <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
            <p>Drag and drop your Resume here (PDF, DOCX, CSV)</p>
            <label
              htmlFor="resume-upload"
              className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
            >
              Browse
              <input
                type="file"
                id="resume-upload"
                className="hidden"
                onChange={handleUploadResume}
              />
            </label>
          </div>

          <div
            className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${
              uploadResume ? "block" : "hidden"
            }`}
          >
            <p>{uploadResume?.name || ""}</p>
            <p onClick={handleRemoveResume}>Close</p>
          </div>
        </div>
      </form>
    );
  }
};

export const UploadResume = ({
  uploadResume,
  setUploadResume,
  setUploadJD,
  uploadJD,
}) => {
  const [forceRender, setForceRender] = useState(false);
  const [previousJD, setPreviousJD] = useState(null);

  const handleDropResume = (e) => {
    e.preventDefault();
    const permissible = [".pdf"];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setUploadResume(files[0]);
    }
  };

  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    const allowedExtensions = [
      "pdf",
      "docx",
      "csv",
      "xlsx",
      "odt",
      "txt",
      "ods",
    ];

    if (allowedExtensions.includes(extension)) {
      setUploadResume(file);
    } else {
      // Optionally, add a warning or error message for unsupported file types
      toast.warning(
        "Unsupported file type. Please upload a PDF, DOCX, or CSV file."
      );
      setUploadResume("");
    }
  };

  const handleRemoveResume = () => {
    const currentFileName = uploadResume ? uploadResume.name : null;
    setUploadResume(null);
    const fileInput = document.getElementById("resume-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    const handleUploadResumeAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setUploadResume(file);
      } else {
        setUploadResume(file);
      }
    };
    fileInput.addEventListener("change", handleUploadResumeAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  return (
    <section className="fill-available flex gap-4 fill-available">
      <div className="flex flex-col fill-available">
        <div
          className="bg-white border-2 flex flex-col h-[12.56rem] items-center gap-3"
          onDrop={handleDropResume}
          onDragOver={(e) => e.preventDefault()}
        >
          <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
          <p>Drag and drop your Resume here (PDF, DOCX, CSV)</p>
          <label
            htmlFor="resume-upload"
            className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
          >
            Browse
            <input
              type="file"
              id="resume-upload"
              className="hidden"
              onChange={handleUploadResume}
            />
          </label>
        </div>

        <div
          className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${
            uploadResume ? "block" : "hidden"
          }`}
        >
          <p>{uploadResume?.name || ""}</p>
          <p onClick={handleRemoveResume}>Close</p>
        </div>
      </div>
    </section>
  );
};

const CheckBox = ({ isChecked, setIsChecked, idx }) => {
  const handleClick = () => {
    const updatedChecked = [...isChecked];
    updatedChecked[idx].checked = !updatedChecked[idx].checked;
    setIsChecked(updatedChecked);
  };

  return (
    <section className="mt-[4px] cursor-pointer">
      <div
        className={`w-4 h-4    ${
          isChecked[idx].checked
            ? "border-0 relative"
            : "block border-[0.1rem] border-text-color-code1"
        }`}
        onClick={handleClick}
      >
        {isChecked[idx].checked ? (
          <img
            src={Check}
            alt="checked"
            className="w-[1.24rem] h-4 absolute z-[10]"
          />
        ) : null}
      </div>
    </section>
  );
};

const Guidelines = ({ setIsAllCheck, isAllCheck }) => {
  // useEffect(() => {
  //   setCurrent(2);
  // });

  const initialGuidelines = [
    {
      checked: false,
      text: "Allow your camera and microphone permission to proceed with the interview.",
    },
    {
      checked: false,
      text: "Give the permission of entire screen sharing to proceed with the interview.",
    },
    {
      checked: false,
      text: "You will be given 90 seconds for each question and to submit your response early you can turn off the mike after completing the answer and it will automatically send your response.",
    },
    {
      checked: false,
      text: "Your response will be automatically submitted after 90 seconds.",
    },
    {
      checked: false,
      text: "For coding question you will be given 30 minutes. Click on Submit button after completing the interview.",
    },
    {
      checked: false,
      text: "Then you can stop sharing your screen and click on End Interview after completing the interview.",
    },
  ];
  const [isChecked, setIsChecked] = useState(initialGuidelines);

  useEffect(() => {
    const allChecked = isChecked.every((item) => item.checked === true);
    console.log(allChecked);
    setIsAllCheck(allChecked);
  }, [isAllCheck, isChecked]);

  return (
    <section className="fill-available flex flex-col gap-4">
      {isChecked.map((item, idx) => (
        <div className="flex gap-2 items-start" key={idx}>
          <CheckBox
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            idx={idx}
          />
          <div
            className={`${
              idx + 1 === isChecked.length ? "text-sm" : "text-base font-medium"
            }`}
          >
            {item.text}
          </div>
        </div>
      ))}
    </section>
  );
};

export const UploadResumeOnly = ({
  uploadResume,
  setUploadResume,
  setEditResumeChange,
  error,
}) => {
  const [forceRender, setForceRender] = useState(false);
  const handleDropResume = (e) => {
    e.preventDefault();
    const permissible = [".pdf"];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setUploadResume(files[0]);
    }
  };

  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    const allowedExtensions = ["pdf", "docx", "doc"];
    if (allowedExtensions.includes(extension)) {
      setUploadResume(file);
    }
  };

  console.log(uploadResume);
  const handleRemoveResume = () => {
    setEditResumeChange(true);
    const currentFileName = uploadResume ? uploadResume.name : null;
    setUploadResume(null);
    const fileInput = document.getElementById("resume-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    const handleUploadResumeAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setUploadResume(file);
      } else {
        setUploadResume(file);
      }
    };
    fileInput.addEventListener("change", handleUploadResumeAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  return (
    <section className="fill-available flex gap-4">
      <div className="flex flex-col fill-available">
        <div
          className="bg-white border-2 flex flex-col h-[12.56rem] items-center gap-3"
          onDrop={handleDropResume}
          onDragOver={(e) => e.preventDefault()}
        >
          <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
          <p>Drag and drop your JD here (PDF,DOCX)</p>
          <label
            htmlFor="resume-upload"
            className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
          >
            Browse
            <input
              type="file"
              id="resume-upload"
              className="hidden"
              accept=".doc,.docx,.pdf,.csv"
              onChange={handleUploadResume}
            />
          </label>
        </div>

        <div
          className={`bg-fill-btn-color-code1 p-4 rounded-lg flex justify-between ${
            uploadResume ? "block" : "hidden"
          }`}
        >
          <p>
            {uploadResume?.name ||
              (uploadResume &&
                uploadResume.split("/").pop().split(".").shift())}
          </p>
          <p onClick={handleRemoveResume}>Close</p>
        </div>
        {error["jd"] === "1" ? (
          <div className="text-red-500">Fill the resume</div>
        ) : null}
      </div>
    </section>
  );
};
