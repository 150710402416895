import { downloadIcon, shareIcon, importIcon, addIcon } from "../assets/imgs";
import { FiLogOut } from "react-icons/fi";

export const Nextbutton = ({ isFinal, campaignType, current }) => {
  const final = campaignType === 2 && current === 1 ? true : isFinal;
  return (
    <button className="border-2 border-solid h-12 w-[12.5rem] bg-transparent hover:bg-fill-btn-color-code1 ">
      {final ? "Finish" : "Next"}
    </button>
  );
};

export const CustomButton = (props) => {
  return (
    <button
      className="h-12 w-[12.5rem] border-2"
      onChange={props?.onChange}
      type={props.type}
      style={{ backgroundColor: "white", transition: "background-color 0.3s" }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = "#F1F2F4";
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = "";
      }}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const CustomButtonWhite = (props) => {
  return (
    <button
      className="h-12 w-[12.5rem] border-2 text-[#A6AEBA]"
      style={{ backgroundColor: "white", transition: "background-color 0.3s" }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = "#F1F2F4";
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = "";
      }}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const Finishbutton = ({ isAllCheck }) => {
  return (
    <button
      className={`h-12 w-[12.5rem] bg-fill-btn-color-code1 ${
        isAllCheck ? "bg-red-300 text-white" : null
      }`}
    >
      Accept
    </button>
  );
};

export const Backbutton = () => {
  return (
    <button className="border-2 border-solid h-12 w-[12.5rem] bg-transparent hover:bg-fill-btn-color-code1">
      Back
    </button>
  );
};

export const AnalyticsPageBtn = ({ img, name, onClick }) => {
  return (
    <button
      className="h-12 w-[10.7rem] bg-fill-btn-color-code1 flex items-center justify-center gap-[0.4rem] rounded-lg"
      onClick={onClick}
    >
      {img === 1 ? (
        <img src={downloadIcon} alt="" />
      ) : img === 4 ? (
        <img src={importIcon} alt="" />
      ) : img === 5 ? (
        <img src={addIcon} alt="" />
      ) : img === 3 ? (
        <FiLogOut />
      ) : (
        <img src={shareIcon} alt="" />
      )}
      {name}
    </button>
  );
};

export const Button = ({ name, onClick }) => {
  return (
    <button
      className="h-12 w-[10.7rem] bg-fill-btn-color-code1 flex items-center justify-center gap-[0.4rem] rounded-lg"
      onClick={onClick}
    >
      {name}
    </button>
  );
};
