import React, { useEffect, useState } from 'react';
import { ScoreCard } from '../../components/AdminComponents/card';
import {
  membersGraph,
  coloredCircle,
  circle,
  campaignGraph,
} from '../../assets/imgs/index';
import {
  CampaignProgressBar,
  SingleCircularProgressBar,
  TripleCircularProgressBar,
} from '../../components/AdminComponents/progressBar';
import { handleApiResponse } from '../../API/services';

export const Dashboard = () => {
  const [KPIData, setKPIData] = useState(null);
  const [campDetails, setCampDetails] = useState([]);
  const callKPI = async () => {
    const reqData = {}; //no data required for this KPI
    const response = await handleApiResponse(
      '/api/v1/interview/data/get_dashboard_kpi/',
      reqData,
      1
    );
    if (response.data.status_code === 200) {
      console.log(response.data.data);
      setKPIData(response.data.data);
      setCampDetails(response.data.data.campaigns_info);
    }
  };

  useEffect(() => {
    callKPI();
  }, []);

  return (
    <div className="text-[#A6AEBA] ml-[1.5rem] ">
      <div className="font-semibold text-[1.25vw] mb-[1rem] mt-[3rem]">
        Dashboard
      </div>
      <div className="flex">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <ScoreCard
              text={'Total Campaigns'}
              number={KPIData?.total_campaigns}
            />
            <ScoreCard
              text={'Ongoing Campaigns'}
              number={KPIData?.ongoing_campaigns}
            />
            <ScoreCard
              text={'Upcoming Campaigns'}
              number={KPIData?.upcoming_campaigns}
            />
          </div>
          <div className="flex gap-4">
            <div className="p-2 flex flex-col gap-[2rem] w-[29.653vw] items-center bg-white border-[0.05rem] rounded-lg px-8 py-4 shadow-custom">
              <p className="text-[1.111vw] font-medium ">Members</p>

              <SingleCircularProgressBar
                bulkProgress={(KPIData?.active_users-1)}
                name2={'Acitve'}
                specificProgress={KPIData?.inactive_users}
                name1={'Inactive'}
              />

              <div className="flex gap-4 self-center">
                <div className="flex text-[0.972vw] gap-1 align-center">
                  <img
                    src={coloredCircle}
                    alt="colored circle"
                    className="h-[0.972vw]"
                  />
                  <div>Active</div>
                </div>
                <div className="flex text-[0.972vw] gap-1 align-center">
                  <img
                    src={circle}
                    alt="colored circle"
                    className="h-[0.972vw]"
                  />
                  <div>Inactive</div>
                </div>
              </div>
            </div>
            <div className="p-2 flex flex-col gap-[2rem] w-[29.199vw] h-[29vw] items-center bg-white border-[0.05rem] rounded-lg px-8 py-4 shadow-custom">
              <p className="text-[1.111vw] font-medium ">
                Campaign Composition
              </p>
              <div className="flex self-center">
                <SingleCircularProgressBar
                  bulkProgress={KPIData?.bulk_campaigns}
                  name2={'Mass'}
                  specificProgress={KPIData?.specific_campaigns}
                  name1={'Regular'}
                />
              </div>
              <div className="mt-6 flex gap-4 self-center">
                <div className="flex text-[0.972vw] gap-1 align-center">
                  <img
                    src={coloredCircle}
                    alt="colored circle"
                    className="h-[0.972vw]"
                  />
                  <div>Mass Campaign</div>
                </div>
                <div className="flex text-[0.972vw] gap-1 align-center">
                  <img
                    src={circle}
                    alt="colored circle"
                    className="h-[0.972vw]"
                  />
                  <div>Regular Campaign</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-4">
          <div className="flex-col p-2 flex gap-[1rem] w-[29.9vw] h-[71.4vh] items-start bg-white border-[0.05rem] rounded-lg px-8 py-4 shadow-custom ">
            <div className="w-full flex items-center justify-between border-b-1 border-[#ECEEF6] h-fit">
              <p className="text-[1.111vw] font-medium ">Completed Campaigns</p>
              <p className="w-[3.333vw] h-[2.431vw] bg-[#A6AEBA1A] flex items-center justify-center rounded-[1.111vw] font-semibold text-[0.972vw]">
                {KPIData?.total_campaigns -
                  (KPIData?.ongoing_campaigns + KPIData?.upcoming_campaigns)}
              </p>
            </div>
            <hr className="w-[439px] ml-[-2rem]" />
            <div className="h-[71.4vh] overflow-auto">
              {campDetails.map((ele) => {
                return (
                  <CampaignProgressBar
                    name={ele?.campaign_name}
                    role={`(${ele?.jobrole})`}
                    completed={
                      ele?.total_candidates -
                      (ele?.ongoing_candidates + ele?.pending_candidates)
                    }
                    total={ele?.total_candidates}
                    img={''}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};