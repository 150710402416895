import { closeButton } from "../../assets/imgs/index";
import { useNavigate } from "react-router-dom";
import { deleteLocal } from "../../utils/localStorage";

export const CampaignHeader = (props) => {
  const navigate = useNavigate();
  return (
    <div className="p-2 text-[#A6AEBA] flex justify-between gap-[2rem] items-start bg-white px-8 pt-12 pb-0">
      <p className="self-center text-[1.111vw] font-medium ">{props.heading}</p>
      <img
        src={closeButton}
        alt="close button"
        onClick={() => {
          navigate("/admin/campaign");
          deleteLocal("currentAddCampaign");
          deleteLocal("campaignUid");
          sessionStorage.removeItem("campName");
      sessionStorage.removeItem("jrName");
      sessionStorage.removeItem("camp_type");
      sessionStorage.removeItem("skills");
      sessionStorage.removeItem("createdDate");
      sessionStorage.removeItem("endDate1");
      sessionStorage.removeItem("coding_ques");
      sessionStorage.removeItem("desc");
        }}
        className="cursor-pointer"
      />
    </div>
  );
};
