export const userActions = {
    SET_FIRST_NAME : 'SET_FIRST_NAME',
    SET_LAST_NAME : 'SET_LAST_NAME',
    SET_NUMBER : 'SET_NUMBER',
    SET_JOB : 'SET_JOB',
    SET_EMAIL : 'SET_EMAIL',
    EXPERIENCE : 'EXPERIENCE',
    CLEAR_FORM:'CLEAR_FORM',
  };
  
  export const setFirstName1 = (firstName) => ({
    type: userActions.SET_FIRST_NAME,
    payload: firstName,
  });
  
  export const setLastName1 = (lastName) => ({
    type: userActions.SET_LAST_NAME,
    payload: lastName,
  });
  
  export const setNumber1 = (number) => ({
    type: userActions.SET_NUMBER,
    payload: number,
  });
  
  export const setJob1 = (job) => ({
    type: userActions.SET_JOB,
    payload: job,
  });

  export const setExperience = (exp) => ({
    type: userActions.EXPERIENCE,
    payload: exp,
  });

  export const setEmail = (email) => ({
    type: userActions.SET_EMAIL,
    payload: email,
  });

  export const clearForm = () => ({
    type: userActions.CLEAR_FORM,
  });