export const userActions = {
    GET_DETAILS_SUCCESS: "GET_DETAILS_SUCCESS",
    LINK_TYPE: "LINK_TYPE"
  };

export const bulkCandidateDetailsSuccess = (data) => ({
    type: userActions.GET_DETAILS_SUCCESS,
    payload: data,
  });

  export const setLinkType = (data) => ({
    type: userActions.LINK_TYPE,
    payload: data,
  });