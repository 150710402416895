import { useEffect, useRef, useState } from "react";
import { ai, userImg } from "../assets/imgs";
import { formatTime } from "../utils/formatTime";

const ChatBox = ({
  messages,
  setOpenEditor,
  timer,
  setTimer,
  timerRunning,
  setTimerRunning,
  setIsListening,
  setIsSpeaking,
  nextQuestion,
  setSpeech,
  setNextQuestion,
  next,
  setNext,
  setLoader,
  setMessage,
  setMessages,
  first_name,
  first_name1,
  last_name,
  last_name1,
  exitquestion,
  setExitQuestion,
  setExit,
  exit,
  setRefreshCount,
  refreshCount,
  handleSocket,
}) => {
  const selectChatRef = useRef(null);
  const locallyNext = sessionStorage.getItem("showNext");
  const endTimer = sessionStorage.getItem("setIsEndTimer");
  const [handleCodeEditor, setHandleCodeEditor] = useState(false);
  const [count, setCount] = useState(3);

  useEffect(() => {
    let interval;
    if (handleCodeEditor) {
      interval = setInterval(() => {
        setCount((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            if (
              messages.every(
                (message) => message.text !== "Code has been submitted"
              )
            ) {
              sessionStorage.setItem("openEditor", true);
              setOpenEditor(true);
            }

            setTimerRunning(false);
            setIsListening(false);
            setTimer(0);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [handleCodeEditor]);

  useEffect(() => {
    const chatContainer = selectChatRef.current;
    const lastMessage = messages[messages.length - 1];
    if (chatContainer && lastMessage) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const showTimer = messages.some((message, index) => {
      const isLastMessage = index === messages.length - 1;
      return (
        (message.text !== "Do you want to proceed to the next question?" ||
          message.text !== "do you want to continue?") &&
        !message.isUser &&
        !message.codeEditor &&
        isLastMessage &&
        timerRunning
      );
    });
  }, [timer, timerRunning]);

  useEffect(() => {
    const hasCodeEditorMessage = messages.some((message) => message.codeEditor);
    if (hasCodeEditorMessage) {
      setHandleCodeEditor(true);
    }
    const chatContainer = selectChatRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages, timerRunning]);

  useEffect(() => {
    const hasCodeEditorMessage = messages.some((message) => message.codeEditor);
    if (hasCodeEditorMessage) {
      setHandleCodeEditor(true);
    }
  }, [messages]);

  useEffect(() => {
    if (next === "NO") {
      setTimerRunning(true);
      setIsListening(true);
      setLoader(false);
    } else if (next === "YES") {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Do you want to proceed to the next question?",
          isUser: false,
        },
      ]);
    } else if (next === "Yes..") {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "do you want to continue?",
          isUser: false,
        },
      ]);
    } else {
      setNext("");
    }
  }, [next]);

  return (
    <div className="relative w-full h-full">
      <div ref={selectChatRef} className="h-full overflow-y-auto select-none">
        {messages?.map((message, index) => {
          const isLastMessage = index === messages.length - 1;
          const isSecondLastMessage = index === messages?.length - 2;
          const showTimer =
            (message.text !== "Do you want to proceed to the next question?" ||
              message.text !== "do you want to continue?") &&
            !message.isUser &&
            !message.codeEditor &&
            isLastMessage &&
            timerRunning;
          return (
            <div
              key={index}
              className={`flex justify-${
                message.isUser ? "end ml-[3rem]" : "start mr-[3rem]"
              } mb-2`}
            >
              {(message.text ===
                "Do you want to proceed to the next question?" &&
                isLastMessage) ||
              (message.text === "do you want to continue?" &&
                isLastMessage &&
                exit !== "NO") ||
              message?.text === "" ? (
                ""
              ) : (
                <img
                  src={ai}
                  alt="User Icon"
                  className={`w-8 h-8 mr-2 rounded-full ${
                    message.isUser ? "hidden" : "block"
                  }`}
                />
              )}
              <span
                className={`flex flex-col text-sm gap-1 ${
                  message.isUser ? "items-end" : "items-start"
                }`}
              >
                {message.isUser ? (
                  <p>(You)</p>
                ) : (
                  <>
                    {(message.text ===
                      "Do you want to proceed to the next question?" &&
                      isLastMessage) ||
                    (message.text === "do you want to continue?" &&
                      isLastMessage &&
                      exit !== "NO") ||
                    message?.text === "" ? (
                      ""
                    ) : (
                      <p>AI Interviewer (Host)</p>
                    )}
                  </>
                )}
                <div
                  className={`${
                    message.isUser && !message.nextId
                      ? "bg-fill-btn-color-code1 max-w-80 overflow-auto"
                      : "bg-white"
                  } p-2 rounded-lg`}
                >
                  <span className="whitespace-pre-line">
                    {message?.text === "YES" || message?.text === "no_response"
                      ? `YES (${
                          !first_name1 && !last_name1
                            ? `${first_name} ${last_name}`
                            : `${first_name1} ${last_name1}`
                        } skipped the question. It is encouraged to answer all the questions of the interview.)`
                      : next === "NO" && isSecondLastMessage
                      ? `${formatTime(timer)} ${message.text}`
                      : (message.text ===
                          "Do you want to proceed to the next question?" &&
                          isLastMessage) ||
                        (message.text === "do you want to continue?" &&
                          isLastMessage)
                      ? ""
                      : message.text}
                  </span>
                  <br />
                  {endTimer !== "true" &&
                    locallyNext !== "NO" &&
                    showTimer &&
                    formatTime(timer) && (
                      <div className="flex justify-end mt-5">
                        {showTimer && formatTime(timer)}(You){" "}
                        <img
                          src={ai}
                          alt="User Icon"
                          className={`w-6 h-6 mr-2 rounded-full `}
                        />
                      </div>
                    )}
                  {message?.codeEditor && (
                    <span
                      onClick={() =>
                        setOpenEditor(
                          index !== messages.length - 1 ? false : true
                        )
                      }
                      className="text-[blue] cursor-pointer"
                    >
                      Open Editor
                    </span>
                  )}
                </div>
              </span>
              {/* <img
                src={userImg}
                alt="User Icon"
                className={`w-8 h-8 ml-2 ${
                  message.isUser ? "block" : "hidden"
                }`}
              /> */}
            </div>
          );
        })}
        {sessionStorage.getItem("isNext") && (
          <div className="ms-10">
            <p className="mb-2 text-black">
              Do you want to proceed to the next question ?
            </p>
            <div className="flex justify-center">
              <button
                onClick={() => {
                  setNext("NO");
                  setNextQuestion(false);
                  sessionStorage.removeItem("isNext");
                  sessionStorage.setItem("showNext", "NO");
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                No
              </button>
              <button
                onClick={() => {
                  setSpeech("YES");
                  setNext("YES");
                  setNextQuestion(false);
                  sessionStorage.removeItem("isNext");
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Yes
              </button>
            </div>
          </div>
        )}
        {sessionStorage.getItem("isEnd") && (
          <div className="ms-10">
            <p className="mb-2 text-black">do you want to continue ?</p>
            <div className="flex justify-center">
              <button
                onClick={() => {
                  setExit("NO");
                  setNext("NO");
                  setExitQuestion(false);
                  sessionStorage.removeItem("isEnd");
                  sessionStorage.setItem("endInterview", "NO");
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                No
              </button>
              <button
                onClick={() => {
                  setSpeech("Yes..");
                  setNext("Yes..");
                  // setExit("Yes..");
                  setExitQuestion(false);
                  sessionStorage.removeItem("isEnd");
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Yes
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBox;
