import React, { useRef, useState, useEffect } from "react";
import { closeButton, uploadSettings } from "../../assets/imgs/index";
import { validateName } from "../../utils/validation";
import { useDispatch } from "react-redux";
import { InputBox } from "../pages/FillFormPage";
// import { addProfileRequest,addProfileSuccess,addProfileFailure ,fetchDataRequest,fetchDataSuccess,fetchDataFailure} from '../../Redux/Profile/actions.js';
import {
  handleFormDataApiResponse,
  handleApiResponse,
} from "../../API/services.js";
import { toast } from "react-toastify";
import { getLocal } from "../../utils/localStorage.js";
import { CustomButtonWhite } from "../../components/buttons.js";
import { useNavigate } from "react-router-dom";
function ProfilePage() {
  const [imageUrl, setImageUrl] = useState("");
  const fileInputRef = useRef(null);
  const [nameError, setNameError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [number, setNumber] = useState("");
  const [emptyError, setEmptyError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [organization, setOrganization] = useState("");
  const [department, setDepartment] = useState("");
  const [lastName, setLastName] = useState("");
  const [previousImageUrl, setPreviousImageUrl] = useState("");
  const [reset, setReset] = useState(false);
  const [editProfileChange, setEditProfileChange] = useState(false);
  const uid = sessionStorage.getItem("uid");
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_URL;
console.log(imageUrl)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleApiResponse("/api/v1/get_profile/", {}, 1);
        console.log("API Response:", response?.data);
        if (response?.data?.status_code === 200) {
          if (reset) {
            setFirstName("");
            setLastName("");
            setNumber("");
            setOrganization("");
            setDepartment("");
          } else {
            setFirstName(response?.data?.data?.first_name);
            setImageUrl([base_url + response?.data?.data?.profile_img]);
            setLastName(response?.data?.data?.last_name);
            setEmail(response?.data?.data?.email);
            setNumber(response?.data?.data?.mobile);
            setOrganization(response?.data?.data?.organization);
            setDepartment(response?.data?.data?.department);
          }
          console.log(response?.data?.data?.profile_img);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch, previousImageUrl, firstName]);

console.log(department);

  const handleDepartmentChange = (e) => {
    setDepartmentError("")
    setEmptyError('')
    setDepartment(e.target.value);
  };
  const handleNumberChange = (e) => {
    setPhoneError("")
      const inputValue = e.target.value;
      const isValidNumber = /^\d{0,10}$/.test(inputValue); // Check if input consists of only digits and has a maximum length of 10
    
      if (isValidNumber) {
        setEmptyError('');
        setNumber(inputValue);
      } 
  };

  const handleUploadClick = () => {
    setEditProfileChange(true);
    fileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    try {
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setImageUrl([imageUrl, file]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmptyError("");
    setNameError("");
    setPhoneError("")
    setDepartmentError("")
    if(department==='' && number===''){
      setDepartmentError("Please enter Department Name.");
      setPhoneError("Please enter Phone Number.")
      return;
    }
    if (department === "" ) {
      setDepartmentError("Please enter Department Name.");
    }
    else if(number===''){
      setPhoneError("Please enter Phone Number.")
    } else {
      console.log(imageUrl)
      const file = imageUrl;
      console.log(file);
      if (file) {
        try {
          const formData = new FormData();
          console.log(imageUrl);
          if(editProfileChange){
            formData.append("profile_img", imageUrl[1]);
          }
          // formData.append("profile_img", imageUrl[1]);
          formData.append("department", department);
          formData.append("mobile", number);
          console.log(uid);
          formData.append("uid", uid);
          const response = await handleFormDataApiResponse(
            "/api/v1/updateuser/",
            formData,
            1
          );
          console.log("API Response:", response?.data);

          if (response?.data.status_code === 200) {
            toast.success("Updated successfully.");
            navigate(-1);
            return 1;
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Selected format for file is not correct.");
        console.error("No file selected.");
      }
    }
  };

  sessionStorage.setItem('profileImage',imageUrl[0])

  return (
    <div className="text-[#A6AEBA] p-12 gap-16 flex flex-col bg-[#E4E7EB]">
      <section className="flex justify-center custom-height overflow-y-auto">
        <div className="z-3 flex flex-col w-[90%] justify-center mb-[1.323vw] mt-[1.323vw] ml-[2.646vw] bg-white z-5 h-[54.894vw] rounded-[1.058vw] bg-gradient-to-r from-opacity-white to-white shadow-md p-8">
          <div className="flex justify-between">
            <div className="mb-[0.794vw] text-[2.19vw] font-bold sans1">
              Profile
            </div>
            <img
              src={closeButton}
              className="cursor-pointer"
              alt="close button"
              onClick={() => {
                navigate("/admin/dashboard");
              }}
            />
          </div>
          <div className="mb-[0.794vw] text-[1.2vw] font-normal">
            Change or edit your profile picture
          </div>
          <hr className="mb-[1.058vw] mx-[-2rem] text-[#EEEFF2]" />
          <div className="mb-[1.19vw] text-[1.358vw] font-medium">
            Your Profile Picture
          </div>
          <div
            className="mb-[1.587vw] w-[8.598vw] h-[8.73vw] bg-[#E9F2FF] border-[1px] border-dashed rounded-[1.19vw] border-purple-500 items-center flex justify-center"
            onClick={handleUploadClick}
          >
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
            <img
              src={imageUrl[0]}
              className="w-[15.598vw] h-[8.73vw] object-contain"
            />
          </div>
          <hr className="mb-[1.587vw] text-[#E0E4EC] border-[0.132vw]" />
          <div className="gap-[1.587vw] grid grid-cols-2 grid-rows-2">
            <div>
              <div className="mb-[0.529vw] text-[1.058vw] font-medium">
                First Name
              </div>
              <input
                type="text"
                placeholder="Please enter the first name"
                className="outline-0 p-[0.661vw] input-field w-[30.423vw] h-[3.439vw] text-[#A6AEBA] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#d1daeb]"
                required
                value={firstName}
                readOnly
              />
            </div>
            <div>
              <div className="mb-[0.529vw] text-[1.058vw] font-medium">
                Last Name
              </div>
              <input
                type="text"
                placeholder="Please enter the first name"
                className="outline-0 p-[0.661vw] input-field w-[30.423vw] h-[3.439vw] text-[#A6AEBA] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#d1daeb]"
                required
                value={lastName}
                readOnly
              />
            </div>
            <div>
              <div className="mb-[0.529vw] text-[1.058vw] font-medium">
                Email
              </div>
              <input
                type="email"
                placeholder="abcpersona@xyz.com"
                className="outline-0 p-[0.661vw] input-field w-[30.423vw] h-[3.439vw] text-[#A6AEBA] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#d1daeb]"
                required
                value={email}
                readOnly
              />
            </div>
            {/* <div>
              <div className="mb-[0.529vw] text-[1.058vw] font-medium">
                Phone number
              </div>
              <input
                type="tel"
                placeholder="+91"
                readOnly
                className="outline-0 rounded-tl rounded-bl rounded-none p-[0.661vw] input-field w-[3.646vw] h-[3.439vw] text-[#A6AEBA] bg-[#FAFBFC]  border-[1px]  border-solid  border-[#d1daeb]"
              />
              <input
                type="tel"
                placeholder="Please enter your phone number"
                className="outline-0 rounded-tr  rounded-br  rounded-none  p-[0.661vw]  input-field  w-[26.778vw]  h-[3.439vw]  text-[#A6AEBA]  bg-[#FAFBFC]  border-[1px]  border-solid  border-[#d1daeb]"
                required
                value={number}
                onChange={(e) => {
                  handleNumberChange(e);
                }}
              />
              <div style={{ color: "red" }}>{phoneError}</div>
            </div>             */}
            <div>
              <div className="mb-[0.529vw] text-[1.058vw] font-medium outline-0">
                Organization
              </div>
              <input
                type="text"
                placeholder="Please enter the organization name outline-0"
                className="p-[0.661vw] input-field w-[30.423vw] h-[3.439vw] text-[#A6AEBA] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#d1daeb]"
                required
                value={organization}
                readOnly
              />
            </div>
            <div>
              <div className="mb-[0.529vw] text-[1.058vw] outline-0 font-medium">
                Department
              </div>
              <input
                type="text"
                placeholder="Please enter the department"
                className="outline-0 p-[0.661vw] input-field w-[30.423vw] h-[3.439vw] text-[#A6AEBA] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#d1daeb]"
                required
                value={department}
                onChange={(e) => {
                  handleDepartmentChange(e);
                }}
              />
              
              <div style={{ color: "red" }}>{departmentError}</div>
            </div>
          </div>
          <div style={{ color: "red" }}>{emptyError}</div>
          <div className="mt-12 gap-4 flex justify-end">
            <CustomButtonWhite text={"Update Profile"} onClick={handleSubmit} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProfilePage;
