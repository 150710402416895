import { InputBox } from "../pages/FillFormPage";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { handleApiResponse } from "../../API/services";
import { setLocal } from "../../utils/localStorage.js";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import OtpGenerator from "../../components/AdminComponents/OtpGenerator.js";
import {
  signupRequest,
  signupSuccess,
  signupFailure,
} from "../../redux/signup/actions.js";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownShareType,
} from "../../components/AdminComponents/dropdown.js";
import axios from "axios";

export const HrSignup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const initialvalues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    organization: "",
    department: "",
    otp: "",
  };
  const [formvalues, setFormValues] = useState(initialvalues);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [organizationError, setOrganizationError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [existError, setExistError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [mobile, setMobile] = useState("");
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [toggle, setToggle] = useState({
    jobRole: false,
    CampType: false,
  });
  const [isExist, setIsExist] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [linkTypeValue, setLinkTypeValue] = useState();
  const [linkTypeString, setLinkTypeString] = useState();
  const nameRegex = /^[A-Za-z][A-Za-z\s]{2,199}$/;
  const emailRegex = /^[^\s@]+([^\s@\.](\.[^\s@\.])?)*@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  const [otp, setOtp] = useState(null);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);

  useEffect(() => {
    checkExist();
  })

  const handleChange = (event) => {
    setVerified(false);
    setOtpError(false);
    const { name, value } = event.target;
    if (value.trim() !== "") {
      setFormValues({ ...formvalues, [name]: value });
    } else {
      setFormValues({ ...formvalues, [name]: "" });
    }
    if (name === "firstName") {
      validateName(value);
    } else if (name === "lastName") {
      validateLastName(value);
    } else if (name === "email") {
      validateEmail(value);
    }
    // else if(name ==="otp"){
    //   validateOtp(value);
    // }
    if (name === "password") {
      validatePassword(value);
    } else if (name === "confirmPassword") {
      validateConfirmPassword(value);
    }
  };

  console.log(errors);
  console.log(formvalues.email);

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setTouched({ ...touched, [name]: true });
    if (name === "email" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "email is required",
      }));
    } else if (name === "password" && value.trim() === "") {
      validatePassword();
    } else if (name === "confirmPassword" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "confirm password is required",
      }));
    }
    // else if (name === "mobile" && value.trim() === "") {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     mobile: "mobile number is required",
    //   }));
    // }
  };

  const validateName = (firstName) => {
    if (firstName.length <= 2) {
      setErrors((prevError) => ({
        ...prevError,
        firstName: "Minimum 3 characters required.",
      }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, firstName: "" }));
      return true;
    }
  };

  const validateLastName = (lastName) => {
    if (lastName === "") {
      setErrors((prevError) => ({
        ...prevError,
        lastName: "last name is required",
      }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, lastName: "" }));
      return true;
    }
  };
  console.log(isExist);

  const checkExist = async () => {
    console.log('validating value');
    console.log(formvalues.email);
    const reqData = { email: formvalues.email };
    console.log(reqData)
    try {
      const response = await handleApiResponse("/api/v1/auth/get_userexistance/", reqData, 0);
      console.log(response);
      if (response.status_code === 200) {
        console.log(response.data.is_exist)
        if (response.data.is_exist === true) {
          setExistError(true);
        }
        else if (response.data.is_exist === false) {
          setExistError(false);
        }
        else {
          console.log('response not ok');
        }
      }
      else {
        console.log('response not 200');
      }
    } catch (e) {
      console.log(e)
    }
  }

  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      return false;
    }

    else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
      return true;
    }
  };

  const validatePassword = (password) => {
    let errorMessage = "Password must be at least 8 characters long.\nAt least one uppercase letter.\nAt least one lowercase letter.\nAt least one number.\nAt least one special character.";

    // Check for minimum length
    if (password?.length >= 8) {
      errorMessage = errorMessage.replace("Password must be at least 8 characters long.\n", "");
    }

    // Check for uppercase letter
    if (/[A-Z]/.test(password)) {
      errorMessage = errorMessage.replace("At least one uppercase letter.\n", "");
    }

    // Check for lowercase letter
    if (/[a-z]/.test(password)) {
      errorMessage = errorMessage.replace("At least one lowercase letter.\n", "");
    }

    // Check for number
    if (/\d/.test(password)) {
      errorMessage = errorMessage.replace("At least one number.\n", "");
    }

    // Check for special character
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errorMessage = errorMessage.replace("At least one special character.", "");
    }

    setErrors((prevError) => ({
      ...prevError,
      password: errorMessage.trim() ? errorMessage : "",
    }));

    return !errorMessage.trim(); // Return true if no errors
  };

  const validateConfirmPassword = (confirmpassword) => {
    if (confirmpassword !== formvalues.password) {
      setErrors((prevError) => ({
        ...prevError,
        confirmpassword: "confirm password didn't match !",
      }));
    } else {
      setErrors((prevError) => ({ ...prevError, confirmpassword: "" }));
      return true;
    }
  };

  const handleMobileChange = (e) => {
    const { name, value } = e.target;

    // Check if the value starts with a digit between 6 and 9 and has a maximum length of 10
    if (/^[6-9]\d{0,9}$/.test(value) || value === "") {
      setMobile(value);
    }

    // Perform validation if the input name is "mobile" and there's at least one digit
    if (name === "mobile" && value.length >= 1) {
      mobileValidate(value);
    } else if (name === "mobile" && value.length < 1) {
      setErrors((prevError) => ({ ...prevError, mobile: "" }));
      return true;
    }
  };

  const mobileValidate = (mobile) => {
    if (mobile.length <= 9) {
      setErrors((prevError) => ({
        ...prevError,
        mobile: "Enter 10 digits number !",
      }));
      return false;
    } else {
      setErrors((prevError) => ({ ...prevError, mobile: "" }));
      return true;
    }
  };
  console.log(formvalues.otp)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (key.match(/[^\w.@-]/)) {
      e.preventDefault();
    }
  };

  const handleKeyDownOTP = (e) => {
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-" ||
      (e.target.value.length >= 6 && e.key !== 'Backspace' && e.key !== 'Delete')) {
      e.preventDefault();
    }
  };

  const handleKeyDown3 = (e) => {
    const key = e.key;
    const disallowedChars = [".", ",", ":", ";", "'", '"', "(", ")", " "];
    if (disallowedChars.includes(key)) {
      e.preventDefault();
    }
  };

  const handleKeyDown2 = (e) => {
    const key = e.key;
    const isAlphabetic = key.match(/[a-zA-Z]/);
    const isSpace = key === " ";
    if (!isAlphabetic && !isSpace) {
      e.preventDefault();
    }
  };

  const handleEmailVerification = async () => {
    setError(false);
    setError1(false);
    setEmailError(false);
    setOtpError(false);
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      organization,
      department,
      otp
    } = formvalues;
    if (!validateName(firstName)) {
      return;
    }
    if (!validateLastName(lastName)) {
      return;
    }
    if (!validateEmail(email)) {
      return;
    }
    if (!validatePassword(password)) {
      return;
    }
    if (!validateConfirmPassword(confirmPassword)) {
      return;
    }
    if (!mobileValidate) {
      return;
    }
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      organization === "" ||
      department === ""
    ) {
      toast.warning("Please fill all the mandatory fields.");
    } else {
      dispatch(
        signupRequest({
          first_name: firstName,
          last_name: lastName,
          organization: organization,
          email: email,
          password: password,
          cpassword: confirmPassword,
          mobile: mobile,
          department: department,
          mail_share_type: linkTypeValue,
        })
      );
      try {
        const response = await handleApiResponse("/api/v1/auth/register/", {
          first_name: firstName,
          last_name: lastName,
          organization: organization,
          email: email,
          password: password,
          cpassword: confirmPassword,
          mobile: mobile,
          department: department,
          mail_share_type: linkTypeValue,
        });
        console.log("API Response:", response, response.data);
        if (response.status_code === 200) {
          dispatch(signupSuccess(response.data));
          setLocal("adminToken", response.data.access);
          setLocal("first_name", response.data.first_name);
          setLocal("last_name", response.data.last_name);
          setLocal("uid", response.data.uid);
          setLocal("parentUid", response.data.parent);
          setLocal("companyId", response?.data?.id);
          setLocal("mail_share_type", response?.data?.mail_share_type);
          sessionStorage.setItem("organization", response.data.organization);
          const tkn = response?.data?.access;
          console.log(tkn);
          sessionStorage.setItem("accesTKN", tkn);
          console.log("organisation", response.data.organization);
          setLocal(
            "notPermited",
            response?.data?.user_type === 3 ? true : false
          );
          navigate('/admin/dashboard');
          // if (!response?.data.is_email_verified) {
          //   setShowEmailVerification(true);
          // }
        }
        else if (response.status_code === 400 && response?.msg === "otp is required!") {
          setEmailError(true);
        }
        else if (response.status_code === 400 && response?.msg === "User already exists") {
          setError(true);
        }
        else if (response.status_code === 400 && response?.msg === "incorrect otp") {
          setOtpError(true);
        }
        else {
          if (response.status_code === 400) {
            setError1(true);
          }
        }
      } catch (error) {
        dispatch(signupFailure(error.message));
        console.error("API Error:", error);
      }
    }
  };
  console.log(!formvalues.email === "")
  console.log(formvalues.email)

  const handleVerifyClick = () => {
    // setShowOtpInput(true);
  };

  const generateOtp = async () => {
    setEmailError(false);
    setShowEmailVerification(true);
    try {
      const response = await handleApiResponse("/api/v1/generateotp/", {
        email: formvalues.email,
        otp_type : 1
      });
      console.log("API Response:", response);
      if (response.status_code === 200) {
        toast.success("OTP has been sent to your registered email.");
        // setOtp(response?.data.otp);
        //   console.log("GOINGGGGG");
        //   setShowEmailVerification(true);
        // }
        // dispatch(signupSuccess(response.data));
        // console.log(response.data.access);
        // setLocal('authToken', response?.data?.access);
        // toast.success('Signup successful.');
        // navigate('/', { state: { email: response?.data?.username } });
      } else if (response.status_code === 400) {
        toast.error("There was an error sending mail to your email.")
        // setPasswordError('User already exists.');
      }
    } catch (error) {
      // dispatch(signupFailure(error.message));
      console.error("API Error:", error);
    }
  };

  return (
    <div className="flex justify-end">
      {showEmailVerification ? (
        <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur">
          <OtpGenerator
            email={formvalues.email}
            setShowEmailVerification={setShowEmailVerification}
            setVerified={setVerified}
          />
        </div>
      ) : null}

      <div className="w-[35vw] pt-[2rem] h-auto px-[2rem] text-text-color-code1 font-sans1 text-base bg-fill-color-code1">
        <p className="font-sans font-medium text-[22px] mb-[19px] line-[26px]">
          AI Video Interview
        </p>
        <p className="font-semibold text-[28px] line-[38px] tracking-2 mb-[20px]">
          Sign Up
        </p>
        <p className="font-medium text-[14px] tracking-2 mb-[19px]">
          {/* The purpose of lorem ipsum is to create a natural looking block of
          text that doesn't distract from the layout. */}
        </p>
        <div className="font-normal mb-[10px]">
          <InputBox
            type={"text"}
            head={"First Name"}
            name={"firstName"}
            holder={"Enter first name"}
            onChange={handleChange}
            value={formvalues.firstName}
            handleBlur={handleBlur}
            required
          />
        </div>
        {errors.firstName && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errors.firstName}
          </div>
        )}
        <div className="font-normal mb-[10px]">
          <InputBox
            type={"text"}
            head={"Last Name"}
            name={"lastName"}
            holder={"Enter last name"}
            onChange={handleChange}
            value={formvalues.lastName}
            handleBlur={handleBlur}
            required
          />
        </div>
        {errors.lastName && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errors.lastName}
          </div>
        )}
        <div className="font-normal mb-[10px] flex flex-col">
          <InputBox
            type={"text"}
            head={"Email"}
            name={"email"}
            holder={"Enter email"}
            onChange={handleChange}
            value={formvalues.email}
            onKeyDown={handleKeyDown}
            handleBlur={handleBlur}
          />
          {emailError && (
            <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>
              Please verify your email.
            </div>
          )}
          {existError && (
            <div style={{ color: "red", marginBottom: "", marginTop: "10px" }}>
              User Already Exists.
            </div>
          )}
          {(!errors.email && formvalues.email !== "" && !existError) && (
            <p
              className={`flex justify-end mt-2 text-[0.9em] cursor-pointer ${verified ? 'text-green-500' : ''}`}
              onClick={!verified ? generateOtp : undefined} // Disable click if already verified
              style={{ width: 'fit-content', alignSelf: 'flex-end', display: 'flex' }}
            >
              {verified ? 'Verified' : 'Verify'}
            </p>
          )}

        </div>
        {(touched.email && errors.email && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errors.email}
          </div>
        )) ||
          (errors.email && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.email}
            </div>
          ))}
        {/* {(showOtpInput && formvalues.email!=="") && (
        <div className="font-normal mb-[10px]">
          <InputBox
            type={"number"}
            head={"Verify OTP"}
            name={"otp"}
            holder={"Enter OTP"}
            onChange={handleChange}
            value={formvalues.otp}
            onKeyDown={(e) => handleKeyDownOTP(e)}
            handleBlur={handleBlur}
          />
           {otpError && (
          <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>
            Incorrect OTP.
          </div>
        )}
        {formvalues.otp==="" && (
          <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>
            OTP is required.
          </div>
        )}
        </div>
      )} */}
        <div className="w-auto flex font-normal mb-[10px] relative">
          <span className="flex flex-col w-screen">
            <InputBox
              type={"password"}
              head={"Password"}
              name={"password"}
              holder={"Create password"}
              onChange={handleChange}
              value={formvalues.password}
              onKeyDown={handleKeyDown3}
              showPassword={showPassword}
              handleBlur={handleBlur}
              onFocus={() => validatePassword(formvalues.password)}
            />
          </span>
          <button
            onClick={togglePasswordVisibility}
            className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>
        {(touched.password && errors.password && (
          <div style={{ color: "red", marginBottom: "10px", whiteSpace: "pre-line" }}>
            {errors.password}
          </div>
        )) ||
          (errors.password && (
            <div style={{ color: "red", marginBottom: "10px", whiteSpace: "pre-line" }}>
              {errors.password}
            </div>
          ))}
        <div className="w-auto flex font-normal relative mb-[10px]">
          <span className="flex flex-col w-screen">
            <InputBox
              type={"password"}
              head={"Confirm Password"}
              name={"confirmPassword"}
              holder={"Re-enter password"}
              onChange={handleChange}
              value={formvalues.confirmPassword}
              showPassword1={showPassword1}
              onKeyDown={handleKeyDown3}
              handleBlur={handleBlur}
            />
          </span>
          <button
            onClick={togglePasswordVisibility1}
            className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
          >
            {showPassword1 ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>
        {(touched.confirmpassword && errors.confirmpassword && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errors.confirmpassword}
          </div>
        )) ||
          (errors.confirmpassword && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.confirmpassword}
            </div>
          ))}

        {/* <div className="font-normal mb-[10px]">
          <label className="mt-4" htmlFor="">
            Link Share Type<span className="text-red-500">*</span>
          </label>
          <span>
            <DropdownShareType
              holder="Select link share type"
              linkTypeValue={linkTypeValue}
              setLinkTypeValue={setLinkTypeValue}
              setLinkTypeString={setLinkTypeString}
              linkTypeString={linkTypeString}
              // setCampType={setCampType}
              toggle={toggle}
              setToggle={setToggle}
            />
          </span>
        </div> */}

        <div className="font-normal mb-[10px]">
          <InputBox
            type={"text"}
            head={"Organization Name"}
            name={"organization"}
            holder={"Enter organization name"}
            onChange={handleChange}
            value={formvalues.organization}
          />
        </div>
        <div className="font-normal mb-[10px]">
          <InputBox
            head={"Mobile Number"}
            name={"mobile"}
            holder={"Enter mobile number"}
            value={mobile}
            onChange={(e) => handleMobileChange(e)}
            handleBlur={handleBlur}
          />
        </div>
        {(touched.mobile && errors.mobile && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errors.mobile}
          </div>
        )) ||
          (errors.mobile && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.mobile}
            </div>
          ))}
        <div className="font-normal mb-[10px]">
          <InputBox
            type={"text"}
            head={"Department Name"}
            name={"department"}
            holder={"Enter department name"}
            onChange={handleChange}
            value={formvalues.department}
          />
        </div>
        <div style={{ color: "red", marginBottom: "10px" }}>
          {organizationError}
        </div>
        {error && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            User already exists.
          </div>
        )}
        {error1 && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            Email not verified.
          </div>
        )}
        {/* <div className="font-normal mb-[10px]">
        <label className="mt-4" htmlFor="">
            Link Share Type<span className="text-red-500">*</span>
          </label>
          <span>
            <DropdownShareType
              holder="Select link share type"
              // campTypeValue={campTypeValue}
              // setCampTypeValue={setCampTypeValue}
              // setCampType={setCampType}
               toggle={toggle}
               setToggle={setToggle}
            />
          </span>
          </div> */}
        <button
          className="mb-[20px] fill-available h-12 bg-fill-btn-color-code1 rounded-lg "
          onClick={() => {
            handleEmailVerification();
          }}
        >
          Sign Up
        </button>
        <p className="font-normal w-full size-[14px] mb-[20px] text-center">
          Already have an account?
          <Link to="/" className="font-semibold">
            Sign In
          </Link>
        </p>
      </div>
    </div>
  );
};
