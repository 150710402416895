import { InputBox } from "../pages/FillFormPage";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { handleApiResponse } from "../../API/services";
import { validateEmail } from "../../utils/validation";
import { getLocal, setLocal, setSession } from "../../utils/localStorage.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import backgroundImage from '../../assets/imgs/Background.png';
import rectangleSignin from '../../assets/imgs/signinRectangle.png';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
} from "../../redux/login/actions.js";
import OtpGenerator from "../../components/AdminComponents/OtpGenerator.js";

import {
  ModalCheckOTP,
  ModalForgetPassword,
  ModalSetPassword,
} from "../../components/AdminComponents/modal.js";
import axios from "axios";

export const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const initialvalues = { email: "Saurabh@mindmapconsulting.digital", password: "Saurabh@123" };
  const [formValues, setFormvalues] = useState(initialvalues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showCheckOTP, setShowCheckOTP] = useState(false);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showEmailVerification, setShowEmailVerification] = useState(false);


  console.log(errors);

  const emailRegex = /^[^\s@]+([^\s@\.](\.[^\s@\.])?)*@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

  const call = async () => {
    const reqData = {};
    const response = await handleApiResponse("/api/v1/get_credit/", reqData, 1);
    if (response.data.status_code === 200) {
      sessionStorage.setItem("total_credit", response.data.data.total_credits);
    }
  };

  // const handleKeyPress = useCallback((event) => {
  //   if (event.key === 'Enter') {
  //     handleLoginSubmit();
  //   }
  // }, [formValues]);

  // useEffect(() => {
  //   window.addEventListener('keypress', handleKeyPress);

  //   return () => {
  //     window.removeEventListener('keypress', handleKeyPress);
  //   };
  // }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value)
    if (value.trim() !== "") {
      setFormvalues({ ...formValues, [name]: value });
    } else {
      setFormvalues({ ...formValues, [name]: "" });
    }

    if (name === "email") {
      validateEmail(value);
    } else if (name === "password") {
      validatePassword(value);
    }
  };

  useEffect(() => {
    console.log("this is value", formValues)
  }, [formValues])

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setTouched({ ...touched, [name]: true });
    if (name === "email" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "email is required",
      }));
    } else if (name === "password" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "password is required",
      }));
    }
  };

  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
      return true;
    }
  };

  const validatePassword = (password) => {
    if (password?.length <= 4) {
      setErrors((prevError) => ({
        ...prevError,
        password: "Password must be at least 8 characters long",
      }));
      return false;
    } else if (!passwordRegex.test(password)) {
      setErrors((prevError) => ({
        ...prevError,
        password:
          "Password must include uppercase and lowercase letters, numbers, and symbols",
      }));
      return false;
    } else {
      setErrors((prevError) => ({ ...prevError, password: "" }));
      return true;
    }
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (key.match(/[^\w.@-]/)) {
      e.preventDefault();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginSubmit = async (e) => {
    console.log(formValues, 'formValues')
    const { email, password } = formValues;
    console.log('{ email, password }', { email, password })
    if (!validateEmail(email)) {
      return;
    }
    if (!validatePassword(password)) {
      return;
    }

    dispatch(
      loginRequest({
        email: email,
        password: password,
      })
    );
    console.log('{ email, password }', { email, password })
    console.log(email, password)
    try {
      console.log({
        email: email,
        password: password,
      })
      const response = await handleApiResponse("/api/v1/auth/login/", {
        email: email,
        password: password,
      });
      console.log("API Response:", response, response.data);
      if (response.status_code === 200) {
        dispatch(loginSuccess(response.data.user_type));
        console.log(response.data);
        setLocal("adminToken", response?.data?.access);
        setLocal("first_name", response?.data?.first_name);
        setLocal("last_name", response?.data?.last_name);
        setSession("last_name", response?.data?.last_name)
        setLocal("uid", response?.data?.uid);
        setLocal("parentUid", response?.data?.parent);
        setLocal("isResume", response?.data?.is_resume_upload_allow);
        setLocal("organization", response?.data?.organization);
        setLocal("companyId", response?.data?.id);
        setLocal("mail_share_type", response?.data?.mail_share_type)
        sessionStorage.setItem(
          'profileImage',
          `${response?.data?.profile_img}`
        );
        console.log(
          `${process.env.REACT_APP_URL}${response?.data?.profile_img}`
        );
        call();
        setLocal("notPermited", response?.data?.user_type === 3 ? true : false);
        setLocal("userType", response?.data?.user_type);
        console.log(response?.data);
        if (response?.data?.is_deleted) {
          toast.error("Access Revoked");
          return;
        }
        if (response?.data.is_email_verified) {
          toast.success("Login successful.");
          navigate("/admin/dashboard");
        } else {
          setShowEmailVerification(true);
        }
      } else if (response.status_code === 400) {
        dispatch(loginFailure(response.msg));
        setErrors((prevError) => ({
          ...prevError,
          password: "Invalid credentials: email or password is incorrect.",
        }));
        // setPasswordError(
        //   "Invalid credentials: email or password is incorrect."
        // );
      }
    } catch (error) {
      dispatch(loginFailure(error));
      console.error("API Error:", error);
    }
  };

  return (
    <div className="flex justify-end" style={{ 
      // backgroundImage: `url(${backgroundImage})`, 
      backgroundSize: 'cover', 
      backgroundPosition: 'center' 
    }}>
      {showEmailVerification ? (
        <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur">
          <OtpGenerator
            email={formValues.email}
            setShowEmailVerification={setShowEmailVerification}
          />
        </div>
      ) : null}
      {showForgetPassword && (
        <ModalForgetPassword
          setShowForgetPassword={setShowForgetPassword}
          setShowCheckOTP={setShowCheckOTP}
          email={formValues.email}
          setEmail={setFormvalues}
          handleChange={handleChange}
        />
      )}
      {showCheckOTP && (
        <ModalCheckOTP
          setShowCheckOTP={setShowCheckOTP}
          email={formValues.email}
          setShowSetPassword={setShowSetPassword}
        />
      )}
      {showSetPassword && (
        <ModalSetPassword
          setShowSetPassword={setShowSetPassword}
          email={formValues.email}
        />
      )}

      <div className="w-[35vw] pt-[2rem] h-auto px-[2rem] text-text-color-code1 font-sans1 text-base bg-fill-color-code1" style={{ 
      // backgroundImage: `url(${rectangleSignin})`, 
      backgroundSize: 'cover', 
      backgroundPosition: 'center' 
    }}>
        <p className="font-sans font-medium text-[22px] mb-[40px] line-[26px]">
          AI Video Interview
        </p>
        <p className="font-semibold text-[28px] line-[38px] tracking-2 mb-[20px]">
          Sign In
        </p>
        <p className="font-medium text-[14px] tracking-2 mb-[40px]">
          {/* The purpose of lorem ipsum is to create a natural looking block of
          text that doesn't distract from the layout. */}

        </p>
        <div className="font-normal mb-[10px]">
          <InputBox
            head={"Email"}
            name={"email"}
            value={formValues.email}
            onChange={handleChange}
            holder={"Enter email"}
            // onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
            handleBlur={handleBlur}
          />
        </div>
        {(touched.email && errors.email && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errors.email}
          </div>
        )) ||
          (errors.email && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.email}
            </div>
          ))}
        <div className="w-auto flex font-normal mb-[10px] relative">
          <span className="flex flex-col w-screen">
            <label htmlFor="">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              name="password"
              value={formValues.password}
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLoginSubmit();
                }
              }}
              className=" p-3 rounded-lg outline-0 mt-2 placeholder:text-sm placeholder:tracking-[0.6px]"
            // onChange={handlePasswordChange}
            />
          </span>
          <button
            onClick={togglePasswordVisibility}
            className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>
        {(touched.password && errors.password && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errors.password}
          </div>
        )) ||
          (errors.password && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.password}
            </div>
          ))}
        <div className="text-right mb-[150px]">
          <span
            onClick={() => {
              if (!errors.email) {
                setShowForgetPassword(true);
              }
            }}
            className="font-medium text-[12px] cursor-pointer"
          >
            Forgot Password?
          </span>
        </div>
        <button
          className="mb-[20px] fill-available h-12 bg-fill-btn-color-code1 rounded-lg"
          onClick={() => {
            handleLoginSubmit();
          }}
        >
          Sign In
        </button>
        <p className="font-normal w-full size-[14px] mb-[10px] text-center">
          Do you wanna create an account?{" "}
          <NavLink to="admin/signup" className="font-semibold">
            Sign Up
          </NavLink>
        </p>
      </div>
    </div>
  );
};