import { createStore, combineReducers } from "redux";
import reducers from "../redux/signup/reducer";
import reducers1 from "../redux/login/reducer";
import reducers2 from "../redux/formDetails/reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import storageSession from 'redux-persist/lib/storage/session';
import detailsReducer from "../redux/userDetails/reducer";
import certificateData from "../redux/bulkCandidateUserDetails/reducer";
import { persistStore, persistReducer } from "redux-persist";
import TimerSlice from "./timerSlice/TimerSlice";
// import timerReducer from '../redux/timerSlice';

const rootReducer = combineReducers({
  signup: reducers,
  login: reducers1,
  fillForm: reducers2,
  candidateData: detailsReducer,
  certificateData: certificateData,
  timer : TimerSlice
});
const persistConfig = {
  key: "root",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(rootReducer, composeWithDevTools());
const store = createStore(persistedReducer, composeWithDevTools());

const persistor = persistStore(store);

export { store , persistor };
