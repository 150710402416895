export const userActions = {
    SIGNUP_USER: "SIGNUP_USER",
    SIGNUP_USER_SUCCESS: "SIGNUP_USER_SUCCESS",
    SIGNUP_USER_FAILURE: "SIGNUP_USER_FAILURE",
  };
  
  export const signupRequest = () => ({
    type: userActions.SIGNUP_USER,
  });
  
  export const signupSuccess = (user) => ({
    type: userActions.SIGNUP_USER_SUCCESS,
    payload: user,
  });
  
  export const signupFailure = (error) => ({
    type: userActions.SIGNUP_USER_FAILURE,
    payload: error,
  });
