import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

export const ScoreCard = (props) => {
  // Convert the score to a number first
  const score = parseFloat(props.score);

  // Check if the conversion was successful
  const formattedScore = !isNaN(score) ? score.toFixed(2) : 'N/A';

  return (
    <div className="p-2 flex flex-col gap-[2rem] w-[23.5rem] items-end bg-fill-color-code1 border-[0.05rem] rounded-lg px-8 py-4">
      <p className="text-lg">Overall Score</p>
      <p className="text-5xl font-semibold flex items-end">
        {formattedScore} <span className="text-4xl">/100</span>
      </p>
    </div>
  );
};



export const DurationCard = (props) => {
  return (
    <div className="p-2 flex flex-col gap-[2rem] w-[23.5rem] items-end bg-fill-color-code1 border-[0.05rem] rounded-lg px-8 py-4">
      <p className="text-lg">Time Duration</p>
      <p className="text-5xl font-semibold flex items-end">
        {props.min} <p className="text-4xl mr-[0.5rem]">min</p>
        {props.sec} <p className="text-4xl">sec</p>
      </p>
    </div>
  );
};

export const PersonalCard = (props) => {
  const percentage = props.per;
  return (
    <div className="flex flex-col gap-4 bg-white items-center py-4 ">
      <div className="h-[7.9rem] w-[7.9rem] bg-fill-color-code1 rounded-full relative">
        <CircularProgressbar
          value={percentage}
          styles={buildStyles({
            strokeLinecap: "round",
            textSize: "20px",
            pathTransitionDuration: 0.5,
            pathColor: `rgba(228, 231, 235)`,
            textColor: "#A6AEBA",
            backgroundColor: "rgba(228, 231, 235)",
          })}
        />
        <span className="absolute top-[3rem] left-[3rem] text-xl">
          {props.per}%
        </span>
      </div>
      <p>{props.name}</p>
    </div>
  );
};

export const PersonalCardStable = (props) => {
  return (
    <div className="flex flex-col gap-4 bg-white items-center py-4 ">
      <div className="h-[7.9rem] w-[7.9rem] bg-fill-color-code1 rounded-full relative">
        <span className="absolute top-[3rem] left-[3rem] text-xl">
          {props.per}
        </span>
      </div>
      <p>{props.name}</p>
    </div>
  );
};

export const PersonalCard1 = (props) => {
  const percentage = props.per;
  return (
    <div className="flex flex-col gap-4 bg-white items-center py-4 ">
      <div className="h-[7.9rem] w-[7.9rem] bg-fill-color-code1 rounded-full relative">
        <CircularProgressbar
          value={percentage}
          styles={buildStyles({
            strokeLinecap: "round",
            textSize: "20px",
            pathTransitionDuration: 0.5,
            pathColor: `rgba(228, 231, 235)`,
            textColor: "#A6AEBA",
            backgroundColor: "rgba(228, 231, 235)",
          })}
        />
        <span className="absolute top-[3rem] left-[3rem] text-xl">
          {props.per}
        </span>
      </div>
      <p>{props.name}</p>
    </div>
  );
};
