import { userActions } from "./actions";
import { noError, initialState } from "./defaultStates";

const detailsReducer = (state = initialState, action) => {
  switch (action.type) {

      case userActions.USER_DETAILS_SUCCESS:
        console.log(action.payload)
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        token: action.payload.access,
        isLoggedIn: true,
        error: null,
      };

    default:
      return { ...state };
  }
};
export default detailsReducer;
