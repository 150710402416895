import { NavBar } from "../components/NavBar";
import { SideBar } from "../components/SideBar";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  return (
    <header className="h-[100vh] w-full bg-white flex">
      <SideBar />
      <div className="flex flex-col gap-[1.1875rem] fill-available fill-available-firefox">
        <NavBar />
        <div className="flex flex-col justify-center h-full">
          <Outlet />
        </div>
      </div>
    </header>
  );
};
