import React, { useState } from "react";

export const CampaignProgressBar = (props) => {
  let completedPercent = (props.completed / props.total) * 100;
  return (
    <div className="flex flex-col">
      <p className="text-[0.972vw] font-semibold ">
        {props.name} <span className="text-[0.833vw]">{props.role} </span>
      </p>
      <p className="self-end mr-[2rem] text-[0.972vw] font-medium">
        {props.completed}
        <span className="text-[0.833vw] font-normal">/{props.total}</span>
      </p>
      <div className="w-[390px] bg-[#A6AEBA26] rounded-full h-2 dark:bg-gray-700">
        <div
          // className={`bg-[#A6AEBA] w-[${props.completed}%] rounded-full h-[8px]`}
          className={`bg-[#A6AEBA] rounded-full h-[8px]`}
          style={{
            width: `${isNaN(completedPercent) ? 0 : completedPercent}%`,
          }}
        ></div>
      </div>
      <hr className="my-4 w-[439px] ml-[-2rem] border-dashed" />
    </div>
  );
};

export const TripleCircularProgressBar = () => {
  const [progress1, setProgress1] = useState(75);
  const [progress2, setProgress2] = useState(50);
  const [progress3, setProgress3] = useState(25);

  return (
    <div className="flex justify-center items-center h-screen">
      <svg width="300" height="300">
        <circle
          cx="150"
          cy="150"
          r="100"
          fill="none"
          stroke="#F1F2F4"
          strokeWidth="20"
        />
        <circle
          cx="150"
          cy="150"
          r="100"
          fill="none"
          stroke="#A6AEBA"
          strokeWidth="20"
          strokeDasharray={`${(2 * Math.PI * 100 * progress3) / 100}, 628`}
          strokeLinecap="round"
        />
        <circle
          cx="150"
          cy="150"
          r="75"
          fill="none"
          stroke="#F1F2F4"
          strokeWidth="20"
        />
        <circle
          cx="150"
          cy="150"
          r="75"
          fill="none"
          stroke="#A6AEBA"
          strokeWidth="20"
          strokeDasharray={`${(2 * Math.PI * 75 * progress2) / 100}, 471`}
          strokeLinecap="round"
        />
        <circle
          cx="150"
          cy="150"
          r="50"
          fill="none"
          stroke="#F1F2F4"
          strokeWidth="20"
        />
        <circle
          cx="150"
          cy="150"
          r="50"
          fill="none"
          stroke="#A6AEBA"
          strokeWidth="20"
          strokeDasharray={`${(2 * Math.PI * 50 * progress1) / 100}, 314`}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export const SingleCircularProgressBar = ({
  bulkProgress,
  specificProgress,
  name1,
  name2,
}) => {
  const bulkColor = "#A6AEBA";
  const specificColor = "#2ecc71";
  const [hover, setHover] = useState(false);
  const [data, setData] = useState([]);

  const calculateDashArray = (progress, radius) => {
    const circumference = 2 * Math.PI * radius;
    const dashValue =
      (progress / (bulkProgress + specificProgress)) * circumference;
    return `${dashValue} ${circumference - dashValue}`;
  };

  return (
    <span>
      <svg width="300" height="257">
        <circle
          cx="130"
          cy="130"
          r="100"
          fill="none"
          stroke="#E4E7EB"
          strokeWidth="40"
          className="cursor-pointer"
          onMouseEnter={() => {
            setHover(true);
            setData([specificProgress, name1]);
          }}
          onMouseLeave={() => setHover(false)}
        />

        <circle
          cx="130"
          cy="130"
          r="100"
          fill="none"
          stroke={bulkColor}
          strokeWidth="55"
          strokeDasharray={calculateDashArray(bulkProgress, 100)}
          strokeLinecap="butt"
          className="cursor-pointer"
          onMouseEnter={() => {
            setHover(true);
            setData([bulkProgress, name2]);
          }}
          onMouseLeave={() => setHover(false)}
        />
      </svg>
      {hover && <ShowHover data={data} />}
    </span>
  );
};

const ShowHover = ({ data }) => {
  return (
    <span className=" absolute text-xl">
      <p>{`${data[1]} - ${data[0]}`}</p>
    </span>
  );
};
