import { userActions } from "./actions";
import { noError, certificateInitialState } from "../signup/defaultStates";

const certificateData = (state = certificateInitialState, action) => {
    switch (action.type) {
        case userActions.GET_DETAILS_SUCCESS:
          console.log(action.payload)
        return {
          ...state,
          data: action.payload
        };
        case userActions.LINK_TYPE:
          console.log(action)
        return {
          ...state,
          data: action
        };
      default:
        return { ...state };
    }
  };
  export default certificateData;
  