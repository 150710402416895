import { userActions } from "./actions";
import { formInitialState } from "../signup/defaultStates";

const userReducer2 = (state = formInitialState, action) => {
  switch (action.type) {
    case userActions.SET_FIRST_NAME:
        console.log('First Name:', action.payload);
        return {
          ...state,
          firstName:action.payload
        };
  
        case userActions.SET_LAST_NAME:
        return {
          ...state,
          lastName:action.payload
        };
  
        case userActions.SET_NUMBER:
        return {
          ...state,
          number:action.payload
        };

        case userActions.SET_EMAIL:
        return {
          ...state,
          email:action.payload
        };

        case userActions.SET_JOB:
        return {
          ...state,
          job:action.payload
        };

        case userActions.EXPERIENCE:
        return {
          ...state,
          exp:action.payload
        };

        case userActions.CLEAR_FORM:
        return {
          firstName:"",
          lastName:"",
          number:"",
          job:""
        };
  
      default:
        return { ...state };
    }
};
export default userReducer2;
